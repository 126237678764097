import { useCallback, useEffect, useState } from 'react';

// const
import { Actions, ACTIONS_FIELD_NAMES } from "./utils/actions";
import { ALLOCATION_COLUMNS } from "../utils/columns";

// store
import { useRecoilState } from "recoil";
import { reorderItemsState } from "@/store";

// styles
import "./data-grid-overrides.css";
import classNames from "classnames";
import { useStyles } from "./style";

// utils
import { editItems } from "./utils/edit-items";
import { getChildren } from "./utils/items-hirearchy";

// components
import CustomToolBar from './custom-toolbar';
import { DataGridPro, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";

// mui
import Tooltip from "@mui/material/Tooltip";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import {
  cashNullFormatter,
  numberNullFormatter,
  precentsNullFormatter
} from '../../../../utils/mui-data-grid/formatters';

const OrdersList = ({
  filteredItems,
  categories,
  groupByFields = [],
  columns,
  hiddenColumns,
  loading,
  setItemsColumns,
  onGroupBy,
  onColumns,
  onExport,
}) => {
  // state
  const classes = useStyles();
  const dataGridApiRef = useGridApiRef();
  const [items, setItems] = useRecoilState(reorderItemsState);
  const productLevel = groupByFields.length;
  const [isAllExpand, setIsAllExpand] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(hiddenColumns);

  // handlers
  const renderActions = useCallback((row) => {
    const onOrder = row[ACTIONS_FIELD_NAMES[Actions.OnOrder]];
    const isHide = row[ACTIONS_FIELD_NAMES[Actions.Hide]];
    const isOutOfStock = row[ACTIONS_FIELD_NAMES[Actions.OutOfStock]];

    return (
      <div className={classes.actionsWrapper}>
        <GridActionsCellItem
          icon={
            <Tooltip title="On Order" placement="bottom">
              <AssignmentTurnedInOutlinedIcon
                className={classNames(
                  "muiDataGridActionsIcon",
                  onOrder && "muiDataGridActionsActiveOnOrderIcon"
                )}
              />
            </Tooltip>
          }
          onClick={() => {
            const newItems = editItems(items, [{
              uid: row.uid,
              action: Actions.OnOrder,
              value: !onOrder,
            }]);
            setItems(newItems);
          }}
          label="On Order"
        />
        {onOrder ? (
          <span className={classes.onOrderText}>On Order</span>
        ) : (
          <>
            <GridActionsCellItem
              icon={
                <Tooltip title="Duplicate" placement="bottom">
                  <ContentCopyOutlinedIcon className="muiDataGridActionsIcon" />
                </Tooltip>
              }
              onClick={() => {
              }}
              label="Duplicate"
            />
            <GridActionsCellItem
              icon={
                <Tooltip title="Hide Item" placement="bottom">
                  <VisibilityOutlinedIcon
                    className={classNames(
                      "muiDataGridActionsIcon",
                      isHide && "muiDataGridActionsActiveIcon"
                    )}
                  />
                </Tooltip>
              }
              onClick={() => {
                const newItems = editItems(items, [{
                  uid: row.uid,
                  action: Actions.Hide,
                  value: !isHide,
                }]);
                setItems(newItems)
              }}
              label="Hide"
            />
            <GridActionsCellItem
              icon={
                <Tooltip title="Out of Stock" placement="bottom">
                  <ProductionQuantityLimitsOutlinedIcon
                    className={classNames(
                      isOutOfStock && "muiDataGridActionsActiveIcon",
                      "muiDataGridActionsIcon"
                    )}
                  />
                </Tooltip>
              }
              onClick={() => {
                const newItems = editItems(items, [{
                  uid: row.uid,
                  action: Actions.OutOfStock,
                  value: !isOutOfStock,
                }]);
                setItems(newItems)
              }}
              label="Out of stock"
            />
          </>
        )}
      </div>
    );
  }, [items]);

  const expandAllRows = (open = true) => {
    filteredItems.forEach(row => {
      // If expandable rows
      if (row.path.length <= 2) {
        dataGridApiRef.current.setRowChildrenExpansion(row.uid, open)
      }
    })
    setIsAllExpand(open)
  }

  const isProduct = (row) => row.path && row.path.length === groupByFields.length + 1;

  const initialColumns = [
    {
      field: ALLOCATION_COLUMNS["Warehouse"].field,
      headerName: "Warehouse",
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Vendor ID"].field,
      headerName: "Vendor ID",
      sortable: true,
      hide: true,
    },
    {
      field: ALLOCATION_COLUMNS["Vendor"].field,
      headerName: "Vendor",
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Brand ID"].field,
      headerName: "Brand ID",
      sortable: false,
      hide: true,
    },
    {
      field: ALLOCATION_COLUMNS["Brand"].field,
      headerName: "Brand",
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Product ID"].field,
      headerName: "Product ID",
      hide: true,
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Product Name"].field,
      headerName: "Product Name",
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Product Type ID"].field,
      headerName: "Product Type ID",
      hide: true,
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Product Type"].field,
      headerName: "Product Type",
      hide: true,
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Product Category ID"].field,
      headerName: "Product Category ID",
      sortable: false,
      hide: true,
    },
    {
      field: ALLOCATION_COLUMNS["Category"].field,
      headerName: "Category",
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Sub Category ID"].field,
      headerName: "Sub Category ID",
      sortable: false,
      hide: true,
    },
    {
      field: ALLOCATION_COLUMNS["Sub Category"].field,
      headerName: "Sub Category",
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Location ID"].field,
      headerName: "Location ID",
      hide: true,
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Location Name"].field,
      headerName: "Location Name",
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Location Type"].field,
      headerName: "Location Type",
      sortable: false,
    },
    {
      field: ALLOCATION_COLUMNS["Warehouse OH - Units"].field,
      headerName: "Warehouse OH - Units",
      sortable: false,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS["Warehouse OH - Cost"].field,
      headerName: "Warehouse OH - Cost",
      sortable: false,
      hide: true,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS["In Transit - Units"].field,
      headerName: "In Transit - Units",
      sortable: false,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS["In Transit - Cost"].field,
      headerName: "In Transit - Cost",
      sortable: false,
      hide: true,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS["Store OH - Units"].field,
      headerName: "Store OH - Units",
      sortable: false,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS["Target Store OH - Units"].field,
      headerName: "Target Store OH - Units",
      sortable: false,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS["Store Need - Units"].field,
      headerName: "Store Need - Units",
      sortable: false,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Store Need - Cost'].field,
      headerName: 'Store Need - Cost',
      sortable: false,
      hide: true,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Suggested Allocation - Units'].field,
      headerName: 'Suggested Allocation - Units',
      sortable: false,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Suggested Allocation - Cost'].field,
      headerName: 'Suggested Allocation - Cost',
      sortable: false,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Shortfall - Units'].field,
      headerName: 'Shortfall - Units',
      sortable: false,
      hide: true,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Shortfall - Cost'].field,
      headerName: 'Shortfall - Cost',
      sortable: false,
      hide: true,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Avg Weekly Sales - Units'].field,
      headerName: 'Avg Weekly Sales - Units',
      sortable: false,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['WOS'].field,
      headerName: 'WOS',
      sortable: false,
      valueFormatter: val => numberNullFormatter(val, 1),
    },
    {
      field: ALLOCATION_COLUMNS['% Committed'].field,
      headerName: '% Committed',
      sortable: false,
      valueFormatter: precentsNullFormatter,
    },
    {
      field: ALLOCATION_COLUMNS['AUV'].field,
      headerName: 'AUV',
      sortable: false,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['AUC'].field,
      headerName: 'AUC',
      sortable: false,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Gross Margin $'].field,
      headerName: 'Gross Margin $',
      sortable: false,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Gross Margin %'].field,
      headerName: 'Gross Margin %',
      sortable: false,
      valueFormatter: precentsNullFormatter,
    },
    {
      field: ALLOCATION_COLUMNS['COGS'].field,
      headerName: 'COGS',
      sortable: false,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Retail $'].field,
      headerName: 'Retail $',
      sortable: false,
      valueFormatter: cashNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['WGMROI'].field,
      headerName: 'WGMROI',
      sortable: false,
      valueFormatter: val => numberNullFormatter(val, 1),
    },
    {
      field: ALLOCATION_COLUMNS['Warehouse OOS'].field,
      headerName: 'Warehouse OOS',
      sortable: false,
      hide: true,
      valueFormatter: numberNullFormatter
    },
    {
      field: ALLOCATION_COLUMNS['Store OOS'].field,
      headerName: 'Store OOS',
      sortable: false,
      hide: true,
      valueFormatter: numberNullFormatter
    }
  ];

  // effects
  useEffect(() => {
    setColumnVisibilityModel(hiddenColumns.reduce((model, column) => Object.assign(model, { [column]: false }), {}))
  }, [hiddenColumns])

  useEffect(() => {
    setItemsColumns(initialColumns)
  }, []);

  const getRowClassName = ({ row }) => {
    const product = isProduct(row);
    if (product) {
      if (row[ACTIONS_FIELD_NAMES[Actions.OnOrder]]) {
        return `product-row ${classes.onOrder}`;
      } else if (!row.IsInBudget || row.IsHide) {
        return `product-row ${classes.noBudgetOrHide}`;
      }
      return `product-row`
    } else {
      // mark parent which all his children are on order as on order too
      const children = getChildren(filteredItems, row, productLevel, groupByFields)
      if (children.every(child => child[ACTIONS_FIELD_NAMES[Actions.OnOrder]])) {
        return classes.onOrder
      }
    }
    return "";
  };

  const groupingColDef = {
    headerName: 'Group',
    width: 200 + 15 * groupByFields.length,
    hideDescendantCount: true,
    renderHeader: () => {
      return <div className={classes.groupHeader}>
        <span>Group</span>
        <Tooltip title={`${isAllExpand ? 'Collapse' : 'Expand'} Groups`}>
          <div className={classes.expandAll} onClick={() => expandAllRows(!isAllExpand)}>
            {isAllExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </Tooltip>
      </div>
    }
  }

  return (
    <div className={classNames(classes.container, "item-planner-container")}>
      <DataGridPro
        loading={loading}
        rows={filteredItems}
        columns={columns}
        apiRef={dataGridApiRef}
        hideFooter
        treeData={groupByFields.length !== 0}
        density="compact"
        defaultGroupingExpansionDepth={-1}
        components={{
          Toolbar: CustomToolBar,
        }}
        componentsProps={{
          toolbar: {
            actions: {
              onGroupBy,
              onColumns,
              onExport,
            }
          }
        }}
        getRowId={(row) => row.uid}
        getTreeDataPath={(row) => row.path}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
        getRowClassName={getRowClassName}
        isCellEditable={(params) => isProduct(params.row)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
        }}
        groupingColDef={groupingColDef}
      />
    </div>
  );
};

export default OrdersList;
