import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

import { initialState as initialRoiBoosterState } from "../pages/account/roi-booster/store";
import { initialState as layoutState, reducer as layoutReducer } from "../routing/store/store";

const combinedInitialState = {
    currentOrgId: undefined,
    roiBooster: initialRoiBoosterState,
    layout: layoutState
}

const combinedReducer = (state, action) => {
    switch (action.target) {
        case "layout": return { ...state, layout: layoutReducer(state.layout, action) }
        case "logout": return combinedInitialState
        default: return state;
    }
};

const useValue = () => useReducer(combinedReducer, combinedInitialState);

export const {
    Provider,
    useTrackedState,
    useUpdate: useDispatch
} = createContainer(useValue);
