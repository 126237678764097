import { ACCESS_TOKEN_KEY } from '../store';
import API from './service';

const masterData = () => API.post('master/all-data');

const dates = {
  list: (orgId) =>
    API.get(`utils/dates?org_id=${orgId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`
      }
    })
};

const org = {
  create: (dataObj) => API.post('organization/create', dataObj),
  list: (recordCount = 10, pageNumber = 1) =>
    API.post(
      `organization/list?limit=${recordCount}&offset=${recordCount * (pageNumber - 1)
      }&search_keyword=`
    ),
  getInfo: (orgId) => API.post('organization/view', { org_id: orgId }),
  update: (dataObj) => API.post('organization/update', dataObj),
  delete: (orgId) => API.post('organization/delete', { org_id: orgId })
};

const dashboard = (orgId) => {
  return {
    roiBooster: () => API.post('dashboard/widgets/roi-booster', { org_id: orgId }),
    sales: () => API.post('dashboard/widgets/sales', { org_id: orgId }),
    reorderRecommendation: () =>
      API.post('dashboard/widgets/reorder-recommendation', { org_id: orgId }),
    newReorderRecommendation: (locationIds, h2_id, level) =>
      API.post('dashboard/widgets/new-reorder-recommendation', {
        org_id: orgId,
        locationIds,
        h2_id,
        level
      }),
    fiveWeeksForecast: (classId = '') =>
      API.post('dashboard/widgets/five-weeks-forecast', {
        org_id: orgId,
        class_id: classId
      })
  };
};

const brands = {
  list: (orgId) =>
    API.get(`brands?org_id=${orgId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`
      }
    })
};

const location = {
  create: (dataObj) => API.post('location/create', dataObj),
  list: (orgId, recordCount = 10, pageNumber = 1, sort_by = '', sort_type = '') =>
    API.post(
      `location/list?limit=${recordCount}&offset=${recordCount * (pageNumber - 1)
      }&sort_by=${sort_by}&sort_type=${sort_type}`,
      { org_id: orgId }
    ),
  update: (dataObj) => API.post('location/update', dataObj),
  delete: (orgId, locPublicId) =>
    API.post('location/delete', {
      org_id: orgId,
      loc_public_id: locPublicId
    })
};

const vendor = {
  create: (dataObj) => API.post('distributor/create', dataObj),
  list: (orgId, recordCount = 10, pageNumber = 1, sort_by = '', sort_type = '') =>
    API.post(
      `distributor/list?limit=${recordCount}&offset=${recordCount * (pageNumber - 1)
      }&sort_by=${sort_by}&sort_type=${sort_type}`,
      { org_id: orgId }
    ),
  update: (dataObj) => API.post('distributor/update', dataObj),
  delete: (orgId, distId) =>
    API.post('distributor/delete', {
      org_id: orgId,
      id: distId
    })
};

const user = {
  create: (dataObj) => API.post('organization/user/registration', dataObj),
  list: (orgId, recordCount = 10, pageNumber = 1, sort_by = '', sort_type = '') =>
    API.post(
      `organization/user/list?limit=${recordCount}&offset=${recordCount * (pageNumber - 1)
      }&sort_by=${sort_by}&sort_type=${sort_type}`,
      { org_id: orgId }
    ),
  update: (dataObj) => API.post('organization/user/update', dataObj),
  delete: (orgId, userPublicId) =>
    API.post('organization/user/delete', {
      org_id: orgId,
      user_public_id: userPublicId
    })
};

const subscription = {
  list: () => API.post('subscription/list'),
  update: (dataObj) => API.post('organization/subscription-assign', dataObj)
};

const settings = (orgId) => {
  return {
    list: (recordCount = 10, pageNumber = 1) =>
      API.post(`org/setting/list?limit=${recordCount}&offset=${recordCount * (pageNumber - 1)}`, {
        org_id: orgId
      }),
    update: (dataObj) =>
      API.post('org/setting/update', { org_id: orgId, ...dataObj })
  };
};

const forecast = (orgId) => {
  return {
    topDown: {
      list: (recordCount = 10, pageNumber = 1, searchObj) =>
        API.post(
          `forecast/td-forecast-list?` +
          `limit=${recordCount}&offset=${recordCount * (pageNumber - 1)}` +
          `&location_id=${searchObj.locationId ? searchObj.locationId : ''}&week_id=${searchObj.weekId ? searchObj.weekId : ''
          }`,
          { org_id: orgId }
        )
    },
    bottomUp: {
      list: (recordCount = 10, pageNumber = 1, searchObj) =>
        API.post(
          `forecast/bu-forecast-list?` +
          `limit=${recordCount}&offset=${recordCount * (pageNumber - 1)}` +
          `&location_id=${searchObj.locationId ? searchObj.locationId : ''}&product_id=${searchObj.product_id ? searchObj.product_id : ''
          }&distributor_id=${searchObj.distributor_id ? searchObj.distributor_id : ''
          }&week_id=${searchObj.weekId ? searchObj.weekId : ''}`,
          { org_id: orgId }
        )
    },
    roiBooster: {
      list: (recordCount = 10, pageNumber = 1) =>
        API.post(
          `forecast/roi-booster-list?limit=${recordCount}&offset=${recordCount * (pageNumber - 1)
          }`,
          { org_id: orgId }
        ),
      read: (boosterId) =>
        API.post('forecast/roi-booster-detail', {
          org_id: orgId,
          booster_id: boosterId
        }),
      dismiss: (boosterId) =>
        API.post('forecast/roi-booster-dismiss', {
          org_id: orgId,
          booster_id: boosterId
        })
    },
    types: () => API.post('forecast/types', { org_id: orgId }),
    reorder: (recordCount = 10, pageNumber = 1, searchObj) =>
      API.post(
        `forecast/reorder-list?` +
        `limit=${recordCount}&offset=${recordCount * (pageNumber - 1)}`,
        { org_id: orgId, ...searchObj }
      ),
    reorderSearchData: () =>
      API.post(`forecast/reorder-search-list`, { org_id: orgId }),
    reorderSebTypeData: (searchObj) =>
      API.post(`forecast/reorder-sub-type-list`, { org_id: orgId, ...searchObj }),
    table: (dataObj) => API.post(`forecast/table`, { org_id: orgId, ...dataObj }),
    forecastCompare: (dataObj) =>
      API.post(`forecast/forecast-compare`, { org_id: orgId, ...dataObj }),
    forecastAdjustments: (dataObj) =>
      API.post(`forecast/adjustments`, { org_id: orgId, ...dataObj }),
    hierarchy: (level, metaId) =>
      API.post(`forecast/${level}-list`, {
        org_id: orgId,
        [`${level === 'h2' ? 'h1' : level === 'h3' ? 'h2' : ''}_meta_id`]: metaId
      }),
    hierarchyList: () => API.post(`class/org-menu-list`, { org_id: orgId }),
    productTypes: () => API.get(`product-types?org_id=${orgId}`),
    download: (type, authToken) =>
      API.get(
        `forecast/${type}-forecast-download?org_id=${orgId}&token=${authToken}`
      ),
    upload: (type, file, weekArray, forceUpdate, onboarding) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('org_id', orgId);
      formData.append('type', `${type}_action`);
      if (onboarding) formData.append('onboarding', `${onboarding}`);
      if (weekArray) {
        formData.append('week_id', weekArray ? weekArray[0] : '');
        formData.append('import_type', weekArray ? weekArray[1] : '');
        formData.append('update_confirmed', forceUpdate ? 1 : 0);
      }
      if (type == 'product') return API.post(`/forecast/product-import`, formData);
      return ['location', 'distributor', 'product'].includes(type)
        ? API.post(`${type}/import`, formData)
        : API.post(`forecast/${type}-actual-import`, formData);
    },
    buUpload: (file, onUploadProgress, onDownloadProgress) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('org_id', orgId);
      return API.post(`forecast/bu-import`, formData, {
        onUploadProgress,
        onDownloadProgress
      });
    },
    getWeeks: (type, params) => {
      return API.post(`forecast/${type}`, params);
    }
  };
};

const ROI = (orgId) => {
  return {
    upload: (file) => {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('org_id', orgId);
      return API.post(`forecast/roi-booster-import`, formData);
    }
  };
};

const products = (orgId) => {
  return {
    list: (recordCount = 10, pageNumber = 1, sort_by = '', sort_type = '') =>
      API.post(
        `/forecast/product-list?limit=${recordCount}&offset=${recordCount * (pageNumber - 1)
        }&sort_by=${sort_by}&sort_type=${sort_type}`,
        { org_id: orgId }
      ),
    all: (orgId) => API.get(`/products?org_id=${orgId}`)
  };
};

const po = {
  list: (orgId, recordCount = 500, pageNumber = 1, sort_by = '', sort_type = '') =>
    API.get(
      `orders/purchase-orders?org_id=${orgId}&limit=${recordCount}&offset=${recordCount * (pageNumber - 1)
      }&sort_by=${sort_by}&sort_type=${sort_type}`
    ),
  update: (orgId, poNumber, data) =>
    API.put(`orders/purchase-orders/${poNumber}?org_id=${orgId}`, {
      org_id: orgId
    }),
  delete: (orgId, poNumber, data) =>
    API.delete(`orders/purchase-orders/${poNumber}?org_id=${orgId}`, {
      org_id: orgId,
      ...data
    }),
  create: (data) => API.post(`orders/purchase-orders`, { ...data }),
  duplicate: (orgId, poNumber, data) => API.post(`orders/purchase-orders/${poNumber}/duplicate`, { ...data }),
  listByPoNumber: (
    orgId,
    poNumber,
    recordCount = 10,
    pageNumber = 1,
    sort_by = '',
    sort_type = ''
  ) =>
    API.get(
      `orders/purchase-orders/${poNumber}?org_id=${orgId}&limit=${recordCount}&offset=${recordCount * (pageNumber - 1)
      }&sort_by=${sort_by}&sort_type=${sort_type}`
    ),
  importItems: (orgId) => API.get(`org/reports/reorder-report?org_id=${orgId}`),
  export: (orgId, poNumber) =>
    API.post(`/orders/purchase-order/export`, {
      org_id: orgId,
      poNumber: poNumber
    }),
  history: (orgId, poNumber) =>
    API.get(`orders/purchase-orders/${poNumber}/logs?org_id=${orgId}`)
};

const reports = {
  categoryRecap: (orgId, locationId, range, mode, locationIds) =>
    API.get(
      `org/reports/category-recap?org_id=${orgId}&locationId=${locationId}&range=${range}&mode=${mode}&locationIds=${locationIds}`
    ),
  locationRecap: (orgId, range, mode, locationIds, categoryIds) =>
    API.get(`org/reports/location-recap?org_id=${orgId}&range=${range}&mode=${mode}&locationIds=${locationIds}&categoryIds=${categoryIds}`),
  reorder: (orgId) => API.get(`org/reports/reorder-report?org_id=${orgId}`),
  fileBrowser: () => API.get(`org/reports/file-browser`),
  fileBrowserGetSignedUrls: async (paths) => {
    try {
      const response = await API({
        url: `org/reports/file-browser/download-zip`,
        method: 'POST',
        responseType: 'blob',
        data: {
          paths
        }
      });

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create an anchor tag to download the file
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `growthsayer_files.zip`);

      // Append to the HTML document and trigger 'click' to download
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link and revoking the blob URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error('Download error:', e)
    }
  },
};

const materials = {
  all: () => API.get(`org/materials/parentId=1`)
}

const OrganisationService = {
  po,
  org,
  ROI,
  user,
  dates,
  brands,
  vendor,
  reports,
  settings,
  forecast,
  products,
  location,
  materials,
  dashboard,
  masterData,
  subscription,
};

export default OrganisationService;
