// libs
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormLabel from "@mui/material/FormLabel";
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// deps
import { useRecoilValue } from 'recoil';
import blankPic from "../../../assets/blank-profile.png";
import { CardFooterStyle } from '../../../components/Card/card';
import AuthService from "../../../service/auth-service";
import { currentUser } from '../../../store';

const Profile = () => {
    const navigate = useNavigate();
    const user = useRecoilValue(currentUser);
    const [profilePicture, updateProfilePicture] = useState(user?.profilePicture || blankPic);
    const [processingPicture, setPictureProcessing] = useState(false);

    // data fetching
    const getProfile = async () => {
        try {
            setPictureProcessing(true);
            const { data } = await AuthService.profile.getInfo(user.user_public_id);
            if (data.errors) {
                // TBD: show snackbar
            } else {
                const profilePic = data.user[0].profile_image
                if (profilePic) {
                    updateProfilePicture(profilePic["preview"]);
                    AuthService.setuser({
                        ...user,
                        profilePicture: profilePic["preview"]
                    });
                }
            }
        } catch (e) {
            // TBD: show snackbar
        } finally {
            setPictureProcessing(false);
        }
    };

    // handlers
    const handleImageChange = async (evt) => {
        const file = evt.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        setPictureProcessing(true);

        try {
            const { data } = await AuthService.profile.updatePicture(formData);
            if (data.errors) {
                // TBD: show snackbar
            } else {
                updateProfilePicture(data.success[0].image_url[1].preview);
                AuthService.setuser({
                    ...user,
                    profilePicture: data.success[0].image_url[1].preview
                });
            }
        } catch (e) {
            // TBD: show snackbar
        } finally {
            setPictureProcessing(false);
        }
    }

    const deleteProfilePicture = async () => {
        try {
            setPictureProcessing(true);
            const { data } = await AuthService.profile.deletePicture();
            if (data.errors) {
                // TBD: show snackbar
            } else {
                updateProfilePicture(blankPic);
                AuthService.setuser({
                    ...user,
                    profilePicture: null
                });
            }
        } catch (e) {
            // TBD: show snackbar
        } finally {
            setPictureProcessing(false);
        }
    }

    // effects
    useEffect(() => {
        getProfile()
            .then();
    }, []);

    return user && (
        <Stack justifyContent="center" alignItems="center">
            <Card
                sx={{
                    width: "50%", minWidth: "315px", maxWidth: "400px",
                    backgroundColor: '#fafafa'
                }}
                sm={{ width: "100%" }}>
                <CardContent>
                    <Stack>
                        <Box
                            component="img"
                            sx={{
                                height: 'auto',
                                width: 200,
                                margin: 'auto',
                            }}
                            alt="The house from the offer."
                            src={profilePicture}
                        />
                        <Box sx={{ margin: 'auto' }}>
                            <Tooltip placement="left" title="Upload new profile picture">
                                <FormLabel htmlFor="file">
                                    <TextField disabled={processingPicture}
                                        type="file"
                                        name="file"
                                        id="file"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={handleImageChange} />
                                    <PhotoCameraBackIcon sx={{ color: '#17a2b8' }} size={18} />
                                </FormLabel>
                            </Tooltip>
                            <Button outline="true"
                                disabled={processingPicture || (profilePicture === blankPic)}
                                onClick={deleteProfilePicture}>
                                <Tooltip placement="right" title="Delete profile picture">
                                    <DeleteOutlineIcon size={18} />
                                </Tooltip>
                            </Button>
                        </Box>
                        <Box sx={{
                            padding: '25px 0',
                            margin: 'auto'
                        }}>
                            <Box>
                                <b>Name: </b>
                                {user.first_name} {user.last_name}
                            </Box>
                            <Box>
                                <b>E-Mail: </b>
                                {user.email}
                            </Box>
                            <Box>
                                <b>Phone: </b>
                                {user.phone_no}
                            </Box>
                        </Box>
                    </Stack>
                </CardContent>
                <CardActions>
                    <CardFooterStyle>
                        <Button
                            sx={{
                                backgroundColor: "#19d1dd",
                                color: "white"
                            }}
                            onClick={() => navigate("edit")}>
                            Edit
                        </Button>
                    </CardFooterStyle>
                </CardActions>
            </Card>
        </Stack>
    );
};

export default Profile
