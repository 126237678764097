import { makeStyles } from "@mui/styles";
import { commonStyles } from "../style";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    flex: 1,
    paddingTop: "2vh",
    paddingBottom: "5vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  link: {
    color: "black",
    textDecoration: "underline",
  },
  description: {
    textAlign: "center",
    fontSize: "clamp(20px, 1.75vw, 23px)",
    fontWeight: 100,
    paddingLeft: "7vw",
    paddingRight: "7vw",
  },
  startButton: {
    alignSelf: "end",
    fontSize: "16px",
    width: "fit-content",
    height: "fit-content",
  },
  ...commonStyles,
}));
