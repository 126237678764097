import * as React from 'react';
import PropTypes from 'prop-types';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const DeleteConfirmation = (props) => {
  const { onClose, onDelete, open, title = 'Delete', content = 'Are you sure you want to delete?', ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onDelete();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent dividers>
        { content }
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk} style={{ color: 'tomato' }}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteConfirmation.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteConfirmation;
