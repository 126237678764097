import { cashNullFormatter } from '../../../../utils/mui-data-grid/formatters';

export const ALLOCATION_COLUMNS = {
    'Warehouse': {
        field: 'Warehouse'
    },
    'Vendor ID': {
        field: 'Vendor ID',
        hide: true
    },
    'Vendor': {
        field: 'Vendor Name'
    },
    'Brand ID': {
        field: 'Brand ID',
        hide: true
    },
    'Brand': {
        field: 'Brand Name'
    },
    'Product ID': {
        hide: true,
        field: 'Product ID',
    },
    'Product Name': {
        field: 'Product Name'
    },
    'Product Type ID': {
        hide: true,
        field: 'Product Type ID',
    },
    'Product Type': {
        hide: true,
        field: 'Product Type'
    },
    'Product Category ID': {
        field: 'Product Category ID',
        hide: true
    },
    'Category': {
        field: 'Category'
    },
    'Sub Category ID': {
        field: 'Sub Category ID',
        hide: true
    },
    'Sub Category': {
        field: 'Sub Category'
    },
    'Location ID': {
        field: 'Location ID',
        hide: true
    },
    'Location Name': {
        field: 'Location Name'
    },
    'Location Type': {
        hide: true,
        field: 'Location Type',
    },
    'Warehouse OH - Units': {
        field: 'Warehouse OH - Units'
    },
    'Store OH - Units': {
        field: 'Store OH - Units'
    },
    'Target Store OH - Units': {
        field: 'Target Store OH - Units'
    },
    'Target Store OH - Cost': {
        field: 'Target Store OH - Cost',
        hide: true,
    },
    'Store Need - Units': {
        field: 'Store Need - Units'
    },
    'Avg Weekly Sales - Units': {
        field: 'Avg Weekly Sales - Units'
    },
    'WOS': {
        field: 'WOS'
    },
    'Parent calc': {
        field: 'Parent calc'
    },
    'Warehouse OH - Cost': {
        field: 'Warehouse OH - Cost',
        hide: true,
    },
    'In Transit - Units': {
        field: 'In Transit - Units'
    },
    'In Transit - Cost': {
        field: 'In Transit - Cost',
        hide: true,
    },
    'Store OH - Cost': {
        field: 'Store OH - Cost',
        hide: true,
    },
    'Store Need - Cost': {
        field: 'Store Need - Cost',
        hide: true
    },
    'Suggested Allocation - Units': {
        field: 'Suggested Allocation - Units'
    },
    'Suggested Allocation - Cost': {
        field: 'Suggested Allocation - Cost',
        hide: true
    },
    'Shortfall - Units': {
        field: 'Shortfall - Units',
        hide: true
    },
    'Shortfall - Cost': {
        field: 'Shortfall - Cost',
        hide: true
    },
    '% Committed': {
        field: '% Committed'
    },
    'AUV': {
        field: 'AUV',
        hide: true
    },
    'AUC': {
        field: 'AUC',
        hide: true
    },
    'Gross Margin $': {
        field: 'Gross Margin $',
        hide: true
    },
    'Gross Margin %': {
        field: 'Gross Margin %',
    },
    'COGS': {
        field: 'COGS',
        hide: true
    },
    'Retail $': {
        field: 'Retail $',
        hide: true
    },
    'WGMROI': {
        field: 'WGMROI'
    },
    'Warehouse OOS': {
        field: 'Warehouse OOS',
        hide: true
    },
    'Store OOS': {
        hide: true,
        field: 'Store OOS'
    }
}

export const ITEM_PLANNER_HIDDEN_COLUMNS_NAMES =
    Object.entries(ALLOCATION_COLUMNS)
        .reduce((hidden, [fieldName, field]) => {
            if (field.hide) { return [...hidden, fieldName] }
            return hidden;
        }, [])
