// components
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import Tooltip from "@mui/material/Tooltip";
import { useStyles } from "./style";

const actionsProps = {
  sx: {
    color: "#8a8a8a",
    "&:hover": { color: "#19d1dd" }
  }
}

const Actions = ({ onEdit, onDelete }) => {
  const classes = useStyles();

  return <div>
    <Tooltip title="Update" placement="bottom">
      <EditRoadIcon
        {...actionsProps}
        className={classes.action}
        onClick={onEdit}
        label="Update" />
    </Tooltip>
    <Tooltip title="Delete" placement="bottom">
      <DeleteOutlineIcon
        {...actionsProps}
        className={classes.action}
        onClick={onDelete}
        label="Delete"
      />
    </Tooltip>
  </div>
}

export default Actions;