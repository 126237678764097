import FormControl from "@mui/material/FormControl";
import React, { useMemo } from "react";
import "./select-unstyled.css";
import { CustomSelect, StyledOption } from "./style";

export const CustomSelectComponent = ({
  value,
  defaultValue,
  options,
  onChange,
  style = {},
  ...props
}) => {
  const handleChange = (e) => {
    onChange(e);
  };

  const selectOptions = useMemo(() => {
    if (typeof options[0] !== "object") {
      return options.map((opt) => ({ key: opt, value: opt }));
    }
    return options;
  }, [options]);

  return (
    <FormControl className="custom-select" sx={{ ...style }}>
      <CustomSelect {...(value !== undefined ? { value } : { defaultValue })} onChange={handleChange} {...props}>
        {selectOptions.map((op) => (
          <StyledOption key={op.key} value={op.key}>
            {op.value}
          </StyledOption>
        ))}
      </CustomSelect>
    </FormControl>
  );
};

export default CustomSelectComponent;
