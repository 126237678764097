import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
		padding: "0px 24px 0px 12px",
		paddingLeft: '14px !important'
	},
	actionsMenuTrigger: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	logo: {
		padding: "3px 0px",
		cursor: 'pointer',
		userDrag: 'none',
		userSelect: 'none',
		WebkitUserSelect: 'none'
	},
	avatar: {
		height: "35px",
		width: "35px",
	},
}));
