import { makeStyles } from "@mui/styles";
import { sectionCommonStyle } from "../../shared/sections-common-style";

export const useStyles = makeStyles((theme) => ({
  ...sectionCommonStyle,
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
}));
