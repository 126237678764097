import React, { useEffect, useState } from "react";
import { useStyles } from "./style";

import { useSnackbar } from "notistack";

import CustomSelect from "@/components/CustomSelect";
import ProductService from "@/service/org/product.service";
import { brandsState, vendorsState, productTypesState } from "@/store/admin-panel/admin-panel.store";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useRecoilValue } from "recoil";
import { customSelectStyle, GENERAL_SPACING } from "../../shared/sections-common-style";
import classNames from "classnames";
import InputAdornment from '@mui/material/InputAdornment';

const emptyFormData = {
  name: { display: 'Product Name', value: "", hasError: false, required: true },
  brand: { display: 'Brand', value: null, hasError: false, required: true },
  vendor: { display: 'Vendor', value: null, hasError: false, required: true },
  categoryId: { display: 'Category', value: null, hasError: false, required: true },
  subCategoryId: { display: 'Sub Category', value: null, hasError: false, required: true },
  defaultCost: { display: 'Default Cost', value: null, hasError: false },
  defaultPrice: { display: 'Default Price', value: null, hasError: false },
  caseRounding: { display: 'Items/Case', value: null, hasError: false },
  unit: { display: 'Unit of Measurement', value: "", hasError: false },
  taxRate: { display: 'Tax Rate', value: null, hasError: false },
}

const CreateProductDialog = ({
  show,
  onCloseDialog,
  onCreate,
  onUpdate,
  product
}) => {
  const classes = useStyles();
  const brands = useRecoilValue(brandsState);
  const vendors = useRecoilValue(vendorsState);
  const productTypes = useRecoilValue(productTypesState);

  const initialFormData = {
    name: { display: 'Product Name', value: product ? product.product_name : "", hasError: false, required: true },
    brand: { display: 'Brand', value: product ? product.brand_id : null, hasError: false, required: true },
    vendor: { display: 'Vendor', value: product ? product.distributor_id : null, hasError: false, required: true },
    categoryId: { display: 'Category', value: product ? product.category.id : null, hasError: false, required: true },
    subCategoryId: { display: 'Sub Category', value: product ? product.subCategory.id : null, hasError: false, required: true },
    defaultCost: { display: 'Default Cost', value: product ? product.default_cost : null, hasError: false },
    defaultPrice: { display: 'Default Price', value: product ? product.default_price : null, hasError: false },
    caseRounding: { display: 'Items/Case', value: product ? product.case_rounding : null, hasError: false },
    unit: { display: 'Unit of Measurement', value: product ? product.unit_of_measurement : "", hasError: false },
    taxRate: { display: 'Tax Rate', value: product ? product.tax_rate : null, hasError: false },
  };

  const [formError, setFormError] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [formData, updateFormData] = useState({ ...initialFormData });

  const categories = Object.entries(productTypes).reduce((categoriesList, [typeId, typeData]) => {
    return [...categoriesList, { key: typeId, value: typeData.name, children: typeData.children }]
  }, []);

  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    const subs = Object.entries(categories.find(category => +category.key === +formData.categoryId.value)
      ?.children ?? {})
      ?.map(c => c[1])
      ?.map(c => ({
        id: +c.id,
        name: c.name
      }))
    ?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setSubCategories(subs ?? []);
  }, [formData.categoryId]);

  useEffect(() => {
    updateFormData(initialFormData);
  }, [product]);

  const { enqueueSnackbar } = useSnackbar();

  const handleFormInputChanges = (events) => {
    let copyFormData = { ...formData };
    events.forEach(event => {
      const newValue = event.target.value;
      copyFormData = {
        ...copyFormData,
        [event.target.name]: {
          ...formData[event.target.name],
          value: newValue
        }
      }
    });
    updateFormData(copyFormData);
  };

  const handleFormInputChange = (event) => {
    const newValue = event.target.value;
    updateFormData({
      ...formData,
      [event.target.name]: {
        ...formData[event.target.name],
        value: newValue
      }
    })
  };

  const validateField = (formInput, value) => {
    const validateMap = {
      name: (val) => {
        if (val === "") {
          return "Name is a required field";
        } else {
          return ""
        }
      },
      brand: (val) => {
        if (val === null) {
          return "Brand is a required field";
        } else {
          return ""
        }
      },
      vendor: (val) => {
        if (val === null) {
          return "Vendor is a required field";
        } else {
          return ""
        }
      },
      categoryId: (val) => {
        if (val === null) {
          return "Category is a required field";
        } else {
          return ""
        }
      },
      subCategoryId: (val) => {
        if (val === null) {
          return "Sub category is a required field";
        } else {
          return ""
        }
      },
    }
    if (validateMap[formInput]) return validateMap[formInput](value);
    return "";
  }

  const validate = () => {
    let formDataCopy = { ...formData };
    let foundErrors = false;

    Object.keys(formData).forEach(inputKey => {
      const fieldValidate = (validateField(inputKey, formData[inputKey].value))
      if (fieldValidate !== "") {
        if (!foundErrors) {
          setFormError(fieldValidate);
          foundErrors = true;
        }
        formDataCopy[inputKey].hasError = true;
      } else {
        formDataCopy[inputKey].hasError = false;
      }
    })

    if (!foundErrors) {
      setFormError("")
      handleFormSubmission(formDataCopy)
    }

    updateFormData({ ...formDataCopy })
  }

  const handleFormSubmission = async () => {
    toggleLoading(true);
    try {
      const newData = {
        product_name: formData.name.value,
        brand_id: +formData.brand.value,
        distributor_id: +formData.vendor.value,
        class_id: +formData.subCategoryId.value,
        default_cost: +formData.defaultCost.value,
        default_price: +formData.defaultPrice.value,
        case_rounding: +formData.caseRounding.value,
        unit_of_measurement: formData.unit.value,
        tax_rate: formData.taxRate.value,
      }
      if (product) {
        await ProductService.updateProduct(product.id, newData);
        onUpdate();
        enqueueSnackbar("Product updated successfully", {
          variant: 'success'
        });
      } else {
        await ProductService.createProduct(newData)
        onCreate();
        enqueueSnackbar("Product created successfully", {
          variant: 'success'
        });
      }

      closeDialog();
    } catch (err) {
      toggleLoading(false);
      enqueueSnackbar(`Failed to ${product ? 'update' : 'create'} product`, {
        variant: 'error'
      })
    }
  }

  const handleCategoryChange = (newCategoryId) => {
    if (newCategoryId !== formData.categoryId.value) {
      handleFormInputChanges([{
        target: {
          name: 'subCategoryId',
          value: null
        }
      }, {
        target: {
          name: 'categoryId',
          value: newCategoryId
        }
      }]);
    }
  }

  const closeDialog = () => {
    updateFormData({ ...emptyFormData });
    setFormError("");
    toggleLoading(false);
    onCloseDialog();
  };

  console.log(product);

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          width: "517px",
        },
      }}
    >
      <DialogContent className={classes.wrapper}>
        <Box className={classes.form}>
          <Typography
            component="h2"
            sx={{
              lineHeight: "1",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {product ? 'Update product details' : 'Add product'}
          </Typography>

          <div className={classes.inputsContainer}>
            <TextField
              error={formData.name.hasError}
              value={formData.name.value}
              label={formData.name.display}
              required={formData.name.required}
              name="name"
              onChange={handleFormInputChange}
              size="small"
              color='primary'
              sx={{
                width: '100%',
                marginBottom: '5px',
              }}
            />
            <div className={classNames(classes.inputsRow, classes.row)}
              style={{ paddingBottom: '10px' }}>
              {brands.length > 0 &&
                <div className={classes.selectContainer}>
                  <span>* Brand</span>
                  <CustomSelect
                    options={brands.map(brand => ({
                      key: brand.id,
                      value: brand.name
                    }))}
                    style={customSelectStyle}
                    value={formData.brand.value && formData.brand.value}
                    onChange={(newbrandId) => handleFormInputChange({
                      target: {
                        name: 'brand',
                        value: newbrandId
                      }
                    })}
                  />
                </div>
              }
              {brands.length > 0 &&
                <div className={classes.selectContainer}>
                  <span>* Vendor</span>
                  <CustomSelect
                    options={vendors.map(vendor => ({
                      key: vendor.id,
                      value: vendor.distributor_name
                    }))}
                    style={customSelectStyle}
                    value={formData.vendor.value && formData.vendor.value}
                    onChange={(newVendorId) => handleFormInputChange({
                      target: {
                        name: 'vendor',
                        value: newVendorId
                      }
                    })}
                  />
                </div>
              }
            </div>
            <div className={classNames(classes.inputsRow, classes.row)}
              style={{ paddingBottom: '20px' }}>
              <div className={classes.selectContainer}>
                <span>* Category</span>
                <CustomSelect
                  options={categories}
                  style={customSelectStyle}
                  value={formData.categoryId.value && formData.categoryId.value.toString()}
                  onChange={handleCategoryChange}
                />
              </div>
              <div className={classes.selectContainer}>
                <span>* Sub Category</span>
                <CustomSelect
                  options={subCategories?.map(subCategory => ({
                    key: subCategory.id,
                    value: subCategory.name
                  }))}
                  style={customSelectStyle}
                  value={formData.subCategoryId.value}
                  onChange={(newSubCategoryId) => handleFormInputChange({
                    target: {
                      name: 'subCategoryId',
                      value: newSubCategoryId
                    }
                  })}
                />
              </div>
            </div>
            <div className={classes.inputsRow}
              style={{
                flexWrap: 'wrap',
                flexDirection: 'column',
                height: '165px',
              }}
            >
              <TextField
                error={formData.defaultCost.hasError}
                value={formData.defaultCost.value}
                label={formData.defaultCost.display}
                required={formData.defaultCost.required}
                name="defaultCost"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: {
                    adornedEnd: classes.adornedEnd
                  }
                }}
                onChange={handleFormInputChange}
                type="text"
                size="small"
                color='primary'

              />
              <TextField
                error={formData.defaultPrice.hasError}
                value={formData.defaultPrice.value}
                label={formData.defaultPrice.display}
                required={formData.defaultPrice.required}
                name="defaultPrice"
                onChange={handleFormInputChange}
                size="small"
                type="text"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: {
                    adornedEnd: classes.adornedEnd
                  }
                }}
                color='primary'
              />
              <TextField
                error={formData.caseRounding.hasError}
                value={formData.caseRounding.value}
                label={formData.caseRounding.display}
                required={formData.caseRounding.required}
                name="caseRounding"
                onChange={handleFormInputChange}
                size="small"
                type="number"
                color='primary'
              />
              <TextField
                error={formData.unit.hasError}
                value={formData.unit.value}
                label={formData.unit.display}
                required={formData.unit.required}
                name="unit"
                onChange={handleFormInputChange}
                size="small"
                color='primary'
              />
              <TextField
                error={formData.taxRate.hasError}
                value={formData.taxRate.value}
                label={formData.taxRate.display}
                required={formData.taxRate.required}
                name="taxRate"
                onChange={handleFormInputChange}
                size="small"
                type="text"
                color='primary'
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">%</InputAdornment>,
                  classes: {
                    adornedEnd: classes.adornedEnd
                  }
                }}
              />
            </div>
          </div>
          {formError &&
            <div style={{ marginTop: '5px', marginBottom: GENERAL_SPACING }}>
              <span className={classes.error}>{formError}</span>
            </div>
          }
          <LoadingButton
            loading={loading}
            onClick={validate}
            variant="contained"
            className={classes.submitButton}
          >
            {product ? 'Update' : 'Add'}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog >
  );
};

export default CreateProductDialog;
