import React from "react";
import Lottie from "lottie-react";
import animationData from "../../../../../assets/lotties/welcome_workbench_animation.json";

const WelcomeAnimation = () => {
  const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      speed={1.5}
      animationData={animationData}
      isClickToPauseDisabled
      height="40vh"
      options={lottieDefaultOptions}
      loop={true}
    />
  );
};

export default WelcomeAnimation;
