import { useState } from "react";
import { useRecoilState } from "recoil";
import { useStyles } from "./style";

// utils
import {
  ALL_BRANDS_OPTION, ALL_VENDORS_OPTION, selectedBrandFilterState,
  selectedCategoriesFilterState, selectedVendorFilterState,
  selectedProductFilterState,
  ALL_PRODUCTS_OPTION
} from "@/store/admin-panel/products.store";
import { SlideTransition } from "@/utils/transitions/transitions";

// components
import BootstrapInput from "@/components/BootstrapInput";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CategoriesHierarchyChooser from "@/components/CategorySelect";

const ProductsFilters = ({
  vendors,
  brands,
  products
}) => {
  const classes = useStyles();

  const [categoriesDialogOpen, setCategoriesDialogOpen] = useState(false);

  const [selectedVendorFilter, setSelectedVendorFilter] =
    useRecoilState(selectedVendorFilterState);

  const [selectedBrandFilter, setSelectedBrandFilter] =
    useRecoilState(selectedBrandFilterState);

  const [selectedCategoriesFilter, setSelectedCategoriesFilter] =
    useRecoilState(selectedCategoriesFilterState);

  const [selectedProductFilter, setSelectedProductFilter] = useRecoilState(selectedProductFilterState)

  const allCategoriesAreSelected = Object.values(selectedCategoriesFilter)
    .every(parent => parent.selected);

  return <>
    <div className={classes.formControl}>
      <span>Vendor</span>
      <Autocomplete
        value={selectedVendorFilter}
        autoComplete
        disablePortal
        blurOnSelect
        isOptionEqualToValue={(option, value) => {
          if (option.value === ALL_VENDORS_OPTION.value) return true;
          return option.value === value.value;
        }}
        onChange={(_, newValue) => {
          // In case user clears autocomplete or delete all content
          if (newValue) {
            setSelectedVendorFilter(newValue);
          } else {
            setSelectedVendorFilter(ALL_VENDORS_OPTION);
          }
        }}
        options={[
          ALL_VENDORS_OPTION,
          ...vendors.map((v) => ({
            value: v.id,
            label: v.distributor_name,
          })),
        ]}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <BootstrapInput
              inputProps={{ ...params }}
              style={{ width: "200px", padding: 0 }}
              className={classes.boostrapWrapper}
              {...params}
            />
          </div>
        )}
      />
    </div>
    <div className={classes.formControl}>
      <span>Brand</span>
      <Autocomplete
        value={selectedBrandFilter}
        autoComplete
        disablePortal
        blurOnSelect
        isOptionEqualToValue={(option, value) => {
          if (option.value === ALL_BRANDS_OPTION.value) return true;
          return option.value === value.value;
        }}
        onChange={(_, newValue) => {
          // In case user clears autocomplete or delete all content
          if (newValue) {
            setSelectedBrandFilter(newValue);
          } else {
            setSelectedBrandFilter(ALL_BRANDS_OPTION);
          }
        }}
        options={[
          ALL_BRANDS_OPTION,
          ...brands.map((b) => ({
            value: b.id,
            label: b.name,
          })),
        ]}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <BootstrapInput
              inputProps={{ ...params }}
              style={{ width: "200px", padding: 0 }}
              className={classes.boostrapWrapper}
              {...params}
            />
          </div>
        )}
      />
    </div>
    <div className={classes.formControl}>
      <span>Category</span>
      <BootstrapInput
        value={allCategoriesAreSelected ? 'All Categories' : 'Filtered Categories'}
        readOnly
        className={classes.boostrapWrapper}
        style={{ width: "200px", display: "flex", alignItems: "start" }}
        onClick={() => setCategoriesDialogOpen(true)}
      />
    </div>

    <div className={classes.formControl}>
      <span>Product</span>
      <Autocomplete
        value={selectedProductFilter}
        autoComplete
        disablePortal
        blurOnSelect
        isOptionEqualToValue={(option, value) => {
          if (option.value === ALL_PRODUCTS_OPTION.value) return true;
          return option.value === value.value;
        }}
        onChange={(_, newValue) => {
          // In case user clears autocomplete or delete all content
          if (newValue) {
            setSelectedProductFilter(newValue);
          } else {
            setSelectedProductFilter(ALL_PRODUCTS_OPTION);
          }
        }}
        options={[
          ALL_PRODUCTS_OPTION,
          ...products.map((b) => ({
            value: b.id,
            label: b.product_name,
          })),
        ]}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <BootstrapInput
              inputProps={{ ...params }}
              style={{ width: "200px", padding: 0 }}
              className={classes.boostrapWrapper}
              {...params}
            />
          </div>
        )}
      />
    </div>

    <Dialog
      open={categoriesDialogOpen}
      TransitionComponent={SlideTransition}
      onClose={() => setCategoriesDialogOpen(false)}
      PaperProps={{
        sx: {
          width: "350px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <CategoriesHierarchyChooser
          categories={selectedCategoriesFilter}
          setCategories={(newCategories) => {
            setSelectedCategoriesFilter(newCategories)
          }}
        />
      </DialogContent>
    </Dialog>
  </>
};

export default ProductsFilters;
