import React from "react";
import { useStyles } from "./style";

// icons
import SaveIcon from "@mui/icons-material/Save";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

const Actions = ({
  hasNewChanges = false,
  onSave = () => { },
  onTarget = () => { },
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.iconsContainer}>
        <div className={classes.iconContainer}>
          <SaveIcon
            className={classes.icon}
            fontSize="large"
            htmlColor={hasNewChanges ? "#4da1aa" : "gray"}
            onClick={onSave}
          />
          <span className={classes.iconText}>save</span>
        </div>
        <div className={classes.iconContainer}>
          <StackedBarChartIcon
            className={classes.icon}
            fontSize="large"
            htmlColor="#4da1aa"
            onClick={onTarget}
          />
          <span className={classes.iconText}>targets</span>
        </div>
      </div>
    </div>
  );
};

export default Actions;
