// libs
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';

// deps
import AuthService from "../../../service/auth-service";
import { CardFooterStyle } from '../../../components/Card/card';

const ProfileEditor = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState();
    const [, setSaving] = useState(false);

    // data fetching
    const getProfile = async () => {
        try {
            const { data } = await AuthService.getUserInfo();
            setUserInfo(data);
        } catch (e) {
            // TBD: show snackbar
        }
    };

    // effects
    useEffect(() => {
        getProfile()
            .then()
    }, []);


    const submit = (evt) => {
        evt.preventDefault()
        const formData = {};

        AuthService.profile.update({ ...formData })
            .then(res => {
                if (res.data.errors) {
                    // TBD: show snackbar
                } else {
                    // TBD: show snackbar
                    AuthService.setUserInfo({ ...userInfo, ...formData })
                    navigate(-1)
                }
            })
            .catch(() => console.log("Could not update profile info"))
            .finally(() => setSaving(false))
    }

    return (
        <Stack justifyContent="center" alignItems="center">
            <Card
                sx={{
                    width: "50%",
                    minWidth: "315px",
                    maxWidth: "500px",
                    backgroundColor: '#fafafa'
                }}
                sm={{ width: "100%" }}>
                <CardHeader title="Edit Profile" />
                <CardContent sx={{ backgroundColor: 'white' }}>
                    <Stack>
                        <Box sx={{
                            padding: '25px 0',
                            margin: 'auto'
                        }}>

                        </Box>
                    </Stack>
                </CardContent>
                <CardActions>
                    <CardFooterStyle>
                        <Button
                            sx={{
                                backgroundColor: "#19d1dd",
                                color: "white"
                            }}
                            onClick={submit}>
                            Update
                        </Button>
                        <Button
                            onClick={() => navigate("/account/profile")}>
                            Cancel
                        </Button>
                    </CardFooterStyle>
                </CardActions>
            </Card>
        </Stack>
    );
};

export default ProfileEditor
