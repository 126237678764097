import { atom, selector } from 'recoil';

export const ACCESS_TOKEN_KEY = 'access-token';

const localStorageEffect = key => ({ setSelf, onSet }) => {
  const savedValue = localStorage.getItem(key);
  if (savedValue != null) {
    setSelf(savedValue);
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, newValue);
  });
};

export const accessToken = atom({
  key: 'access_token',
  default: localStorage.getItem('access-token'),
  effects: [
    localStorageEffect(ACCESS_TOKEN_KEY),
  ]
});

export const currentOrgId = selector({
  key: 'current_org_id',
  get: ({ get }) => {
    return get(currentOrg)?.org_id
  }
});

export const currentOrg = atom({
  key: 'current_org',
  default: null
});

export const orgView = atom({
  key: 'org_view',
  default: null
});

export const currentUser = atom({
  key: 'current_user',
  default: null
});

export const drawerOpenState = atom({
  key: 'main_drawer',
  default: true
});

export const locations = atom({
  key: 'locations',
  default: []
});

export const districts = atom({
  key: 'districts',
  default: []
});

export const productTypes = atom({
  key: 'product_types',
  default: []
});
