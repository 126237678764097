import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

// utils
import {
  toShowOverview,
  saveShowOverviewPreference,
} from "../utils/localstorageHandler";

// components
import WelcomeAnimation from "./welcome-animation";
import CustomizedCheckbox from "../../../../components/CheckBox/CheckBox";

// mui
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// styles
import classNames from "classnames";
import { useStyles } from "./style";
import { commonStyles } from "../style";

// const
const support_URL =
  "https://support.growthsayer.com/knowledge-base/order-workbench-overview/";

const ReorderPro = ({ }) => {
  // state
  const classes = useStyles();
  const navigate = useNavigate();
  const [showOverviewAlways, setShowOverview] = useState(true);

  // effects
  useEffect(() => {
    setShowOverview(toShowOverview());
  }, []);

  useEffect(() => {
    saveShowOverviewPreference(showOverviewAlways);
  }, [showOverviewAlways]);

  // handlers
  const startWorkbench = () => {
    navigate(`../setup`);
  };

  return (
    <div className={classes.container}>
      <Typography
        component="h2"
        sx={{
          fontSize: "clamp(37px, 3.5vw, 50px)",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Welcome to the Reorder Pro
      </Typography>
      <div style={{ width: "inherit", marginBottom: "15px" }}>
        <WelcomeAnimation />
      </div>
      <span className={classes.description}>
        Using the Reorder Pro, you can complete your inventory purchases knowing
        that you’re on budget, and putting the inventory in the right places. To
        learn more about using the Reorder Pro, check out our{" "}
        <a href={support_URL} className={classes.link}>
          courses and documentation.
        </a>
      </span>
      <div className={classes.actions}>
        <Button
          variant="contained"
          sx={{ ...commonStyles.controlButton }}
          className={classNames(classes.startButton)}
          onClick={startWorkbench}
        >
          Get Started
        </Button>
        <div style={{ alignSelf: "start" }}>
          <CustomizedCheckbox
            checked={showOverviewAlways}
            color="#39828a"
            label={ <span>Show overview everytime</span>}
            onChange={() => {
              setShowOverview(!showOverviewAlways);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReorderPro;
