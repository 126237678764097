import ExcelJS from 'exceljs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toPercent } from '../../../../../../utils/textFormatter';
import {
  draftState,
  forecastState,
  locationState,
  metricsDisplayedState
} from '../../forecast.store';
import { currentOrg, locations as locationsState } from '../../../../../../store';
import { timeState } from '../toolbar/time-options';

const useForecastExport = () => {
  const org = useRecoilValue(currentOrg);
  const draft = useRecoilValue(draftState);
  const forecast = useRecoilValue(forecastState);
  const locations = useRecoilValue(locationsState);
  const location = useRecoilValue(locationState);
  const metrics = useRecoilValue(metricsDisplayedState);
  const { startQuarter, endQuarter } = useRecoilValue(timeState);
  const weekData = draft;

  const exportForecast = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Forecast - ${org.name}`, {
      properties: {
        tabColor: {
          argb: '15a58f'
        }
      }
    });

    // meta
    workbook.creator = 'Growthsayer';

    const singleDecimal = '$#,##0.00;($#,##0.0)';
    const doubleDecimal = '$#,##0.00;($#,##0.00)';
    const integer = '$#,##0;($#,##0)';


    const numberStyle = (metric, formatOverride) => {
      let numFmt = '$#,##0;($#,##0)';

      if (formatOverride) {
        numFmt = formatOverride;
      } else {
        switch (metric) {
        case 'auc_inventory':
        case 'auc_sales':
        case 'auv':
        case 'cost_weeks_on_hand': {
          numFmt = doubleDecimal
          break;
        }
        case 'gross_margin_pct':
        case 'weeks_on_hand': {
          numFmt = singleDecimal;
          break;
        }
        default: {
          numFmt = integer;
          break;
        }
        }
      }

      return {
        alignment: { horizontal: 'right' },
        numFmt,
        font: {
          size: 15
        }
      }
    };

    // header
    const headerContent = `${org.name}. Date range ${startQuarter} - ${endQuarter}`;
    worksheet.mergeCells('A1:J1');
    const headerRow = worksheet.getCell('A1');
    headerRow.value = headerContent;
    headerRow.font = { bold: true, size: 16 };
    headerRow.alignment = { vertical: 'middle' };
    worksheet.addRow(headerRow);

    // locations
    const locationHeaderRow = worksheet.getCell('A2');
    const locationName = locations?.find(l => l.id === location)?.location_name;

    locationHeaderRow.value = `Location: ${locationName}`;
    locationHeaderRow.font = { bold: true, size: 16 };
    worksheet.addRow(locationHeaderRow);

    // date range
    const dateRangeRow = worksheet.getCell('A3');
    dateRangeRow.value = `Date: ${startQuarter.replace('_', '-')} - ${endQuarter.replace('_', '-')}`;
    dateRangeRow.font = { bold: true, size: 16 };
    worksheet.addRow(dateRangeRow);

    // add table headers after the space
    const tableHeaders = ['', '', ...forecast.columns.map(w => w.display)];
    worksheet.addRow(tableHeaders);

    const currentRow = 5;

    // apply bold style to table headers
    tableHeaders.forEach((text, index) => {
      const headerCell = worksheet.getCell(currentRow, index + 1)
      headerCell.font = { bold: true, size: 14 };

      if (index > 1)
        headerCell.alignment = { horizontal: 'right' };
    });

    // add data for each metric
    metrics.forEach(metric => {
      if (metric.visible) {
        const metricName = metric.display;

        const workingPlanRow = worksheet.addRow([metricName, 'Working Plan']);
        const actualsRow = worksheet.addRow(['', 'Actuals']);
        const differenceRow = worksheet.addRow(['', '% Difference']);

        workingPlanRow.font = { size: 16 };
        actualsRow.font = { size: 16 };
        differenceRow.font = { size: 16 };

        weekData.forEach((week, weekIndex) => {
          const colIndex = weekIndex + 3; // because first two columns are metric and type

          // set main version data
          workingPlanRow.getCell(colIndex).value = week.main[metric.value];
          workingPlanRow.getCell(colIndex).style = numberStyle(metric.value);

          // set compared version data
          actualsRow.getCell(colIndex).value = week.compare[metric.value];
          actualsRow.getCell(colIndex).style = numberStyle(metric.value);

          // set % difference
          const mainValue = week.main[metric.value];
          const compareValue = week.compare[metric.value];

          differenceRow.getCell(colIndex).value = (mainValue && compareValue) ? toPercent((mainValue - compareValue) / Math.abs(compareValue), 1, 100) : 0;
          differenceRow.getCell(colIndex).style = numberStyle(metric.value, integer);
        });

        // set the width of the columns
        worksheet.columns.forEach(column => {
          column.width = 20;
        });
      }
    });

    // write the workbook to a Blob and create a download link
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `Forecast - ${org.name}.xlsx`;
    document.body.appendChild(anchor); // required for Firefox
    anchor.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(anchor); // cleanup
  };

  return {
    exportForecast
  };
};

export default useForecastExport;
