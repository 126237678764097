import React from 'react';
import './style.css';

//styles
import styled from 'styled-components';

import Tooltip from '@mui/material/Tooltip';
import ButtonGroup from '@mui/material/ButtonGroup';

const Actions = styled.div`
  float: right;
`

const NavMenu = (props) => {
  const { buttons } = props;

  return (
    <Actions>
      <ButtonGroup>
        {
          buttons && buttons.map((button, i) => {
            const {
              disabled,
              action,
              title,
              description,
              icon
            } = button;

            return (
              <React.Fragment key={i}>
                <Tooltip placement='top'
                         title={<span className='navmenu-description'>
										{description}
									</span>} target={`${title}-button`}>
                  <a id={`${title}-button`} className='btn' disabled={disabled}
                     onClick={action}>
                    <i className='material-icons navmenu-icon'>{icon}</i>
                    <span className='d-block'>{title}</span>
                  </a>
                </Tooltip>
              </React.Fragment>
            )
          })
        }
      </ButtonGroup>
    </Actions>
  )
}

export default NavMenu
