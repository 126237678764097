import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AuthService from "../../../../service/auth-service";
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const ProfilePasswordForm = (props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const initialFormData = {
		oldPassword: { display: 'Old Password', value: "", hasError: false },
		newPassword: { display: 'New Password', value: "", hasError: false },
		confirmedPassword: { display: 'Confirm Password', value: "", hasError: false },
	}

	const [formData, updateFormData] = useState({ ...initialFormData });
	const [formError, setFormError] = useState("");
	const [loading, toggleLoading] = useState(false);

	const handleFormInputChange = (event) => {
		const newValue = event.target.value;
		updateFormData({
			...formData,
			[event.target.name]: {
				...formData[event.target.name],
				value: newValue
			}
		})
	}

	const validateField = (formInput, value) => {
		const validateMap = {
			oldPassword: (val) => {
				if (val === "") {
					return "Please enter your current password";
				} else {
					return ""
				}
			},
			newPassword: (val) => {
				if (val === "") {
					return "Please enter a new password";
				} else if (val.length > 0 && val.length <= 3) {
					return "Password must be at least 3 characters"
				} else {
					return ""
				}
			},
			confirmedPassword: (val) => {
				if (val === "") {
					return "Please enter the new password again";
				} else if (val !== formData.newPassword.value) {
					return "Passwords are different. Please re-enter the password"
				} else {
					return ""
				}
			}
		}
		return validateMap[formInput](value)
	}

	const validate = () => {
		let formDataCopy = { ...formData };
		let foundErrors = false;

		Object.keys(formData).forEach(inputKey => {
			const fieldValidate = (validateField(inputKey, formData[inputKey].value))
			if (fieldValidate !== "") {
				if (!foundErrors) {
					setFormError(fieldValidate);
					foundErrors = true;
				}
				formDataCopy[inputKey].hasError = true;
			} else {
				formDataCopy[inputKey].hasError = false;
			}
		})

		if (!foundErrors) {
			setFormError("")
			handleFormSubmission(formDataCopy)
		}

		updateFormData({ ...formDataCopy })
	}

	const handleFormSubmission = (data) => {
		toggleLoading(true);
		AuthService.profile.updatePassword({ old_password: data.oldPassword.value, password: data.newPassword.value })
			.then(res => {
				if (res.data.errors) {
					setFormError(res.data.errors[0].msg)
				}
				else {
					enqueueSnackbar('Password has changed', { variant: 'success' });
					// clear form
					updateFormData({ ...initialFormData })
				}
			})
			.catch(err => enqueueSnackbar('An error has occurd', { variant: 'error' }))
			.finally(() => toggleLoading(false))
	}

	return (
		<div className={classes.container}>
			<Box className={classes.form}>
				<Typography component="h2" sx={{
					marginBottom: "20px",
					fontSize: "2rem",
					fontWeight: "bold",
					textAlign: 'center'
				}}>
					Change Password
				</Typography>
				<TextField
					error={formData.oldPassword.hasError}
					value={formData.oldPassword.value}
					label={formData.oldPassword.display}
					name="oldPassword"
					onChange={handleFormInputChange}
					type="password"
					size="small"
					color='primary'
				/>
				<Divider variant="inset" component="li" sx={{ margin: '10px 0px 15px 0px', listStyleType: 'none' }} />
				<TextField
					error={formData.newPassword.hasError}
					value={formData.newPassword.value}
					label={formData.newPassword.display}
					name="newPassword"
					onChange={handleFormInputChange}
					type="password"
					size="small"
					color='primary'
					sx={{
						marginBottom: '15px'
					}}
				/>
				<TextField
					error={formData.confirmedPassword.hasError}
					value={formData.confirmedPassword.value}
					label={formData.confirmedPassword.display}
					name="confirmedPassword"
					onChange={handleFormInputChange}
					type="password"
					size="small"
					color='primary'
					sx={{
						marginBottom: '15px'
					}}
				/>
				<div>
					<span className={classes.error}>{formError}</span>
				</div>
				<LoadingButton
					loading={loading}
					onClick={validate}
					variant='outlined'
					color='primary'
					startIcon={<LockOutlinedIcon />}
					sx={{
						marginTop: '15px',
						padding: '10px 0px'
					}}>
					Change Password
				</LoadingButton>
			</Box>
		</div >
	);
};

export default ProfilePasswordForm
