import React, { useState, useEffect } from "react";

// deps
import { useStyles } from "./style";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

// services
import useUserData from "@/utils/hooks/useUserData";
import OrgService from "@/service/master/org.service";

// store
import { useRecoilState, useSetRecoilState } from 'recoil';

// components
import Dialog from "@mui/material/Dialog";
import OrganizationList from "./organizations-list";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import { accessToken } from "../../../../../store";
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { userTypesState } from '../../../../../store/admin-panel/admin-panel.store';
import UserService from '../../../../../service/master/user.service';

const SwitchOrganizationDialog = ({
  show,
  onCloseDialog,
}) => {
  const classes = useStyles();

  const { org_public_id } = useUserData();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const setAccessToken = useSetRecoilState(accessToken);
  const [organizations, setOrganizations] = useState();
  const [orgId, setOrgId] = useState();
  const [userTypes, setUserTypes] = useRecoilState(userTypesState);
  const [loadingUserTypes, setLoadingUserTypes] = useState(false);

  const fetchOrganizations = async () => {
    try {
      const activeOrganizations = await OrgService.getActiveOrgs();
      setOrganizations(activeOrganizations.data);
    } catch (err) {
      setOrganizations([]);
    }
  }

  useEffect(() => {
    fetchOrganizations().then();
  }, []);

  const fetchOrgUserTypes = async (db_name) => {
    try {
      setLoadingUserTypes(true);
      const userTypes = await UserService.getUserTypes(db_name);
      setUserTypes(userTypes?.data ?? []);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingUserTypes(false);
    }
  };

  useEffect(() => {
    if (show) {
      setOrgId(undefined);
    }
  }, [show]);

  const switchOrganization = async () => {
    try {
      const newUser = (await OrgService.switchOrg(orgId)).data;
      if (newUser) {
        setAccessToken(newUser.accessToken)
        navigate(`/switch-organization`);
      }
    } catch (err) {
      enqueueSnackbar("Failed to switch organization", {
        variant: 'error',
      });
    }
  }

  return (
    <Dialog
      open={show}
      onClose={onCloseDialog}
      PaperProps={{
        sx: {
          width: "350px",
          maxHeight: '450px',
        },
      }}
    >
      <DialogTitle>Switch Organization</DialogTitle>
      <DialogContent className={classes.wrapper}>
        { !organizations  && <div className={classes.loading}>
          <CircularProgress />
        </div> }
        {
          organizations && !orgId &&
            <div>
              <OrganizationList
                organizations={organizations}
                selectedOrgPublicId={org_public_id}
                onOrgClick={(org) => {
                  fetchOrgUserTypes(org.db_name);
                  setOrgId(org.org_id);
                }} />
            </div>
        }
        { organizations && orgId && loadingUserTypes && <div>Loading user types...</div>}
        {
          organizations && orgId && !loadingUserTypes && userTypes?.length> 0 &&  <div>
            { userTypes.map(userType => <Button onClick={() =>{
              localStorage.setItem('userTypeId', userType.user_type_id.toString());
              switchOrganization().then();
            }}>
              { userType.user_type_id } - { userType.user_type_name }
              </Button>)
            }
          </div>
        }
        {
          organizations && orgId && !loadingUserTypes && userTypes?.length === 0 && <div>
          No user types for this organization
          </div>
        }
      </DialogContent>
    </Dialog>
  );
};

export default SwitchOrganizationDialog;
