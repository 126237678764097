import API from "../api"

const OrgService = {
  getCurrentOrg: () => API.get(`master/org/current`),
  getOrg: (orgPublicId) => API.get(`master/org/${orgPublicId}`),
  getActiveOrgs: () => API.get(`master/org`),
  switchOrg: (orgId) => API.post(`master/org/switch`, { orgId }),
};

export default OrgService;
