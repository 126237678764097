export const UserType = {
  ADMIN: 'ADMIN',
  UNSET: 'UNSET',
}

const TypeIdToUser = {
  [UserType.ADMIN]: [1, 2],
}

export const convertIdToType = (typeId) => {
  let currUserType = UserType.UNSET;

  Object.entries(TypeIdToUser).forEach(([userType, typeIds]) => {
    if (typeIds.includes(typeId)) { currUserType = userType }
  })

  return currUserType;
}
