// libs
import { useQueryParam, NumberParam } from 'use-query-params';
import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

// deps
import OrganisationService from '../../../../../../service/organisation-service';

// styles
import './style.css';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useRecoilValue } from 'recoil';
import { currentOrg } from '../../../../../../store';
const CardWrap = styled.div``;

const TargetsGraph = ({ locationIds }) => {
    const navigate = useNavigate();
    const [reorder, setReorder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useQueryParam('id', NumberParam);
    const level = !!id ? 'h2' : 'h1';

    // categories
    const [categories, setCategories] = useState();
    const [categoryIds, setCategoryIds] = useState();

    // orders
    const [ordered, setOrdered] = useState([]);
    const [orderedCost, setOrderedCost] = useState([]);

    // units
    const [unitsOnHand, setUnitsOnHand] = useState([]);
    const [unitsOnHandTarget, setUnitsOnHandTarget] = useState([]);

    // cost
    const [costOnHand, setCostOnHand] = useState([]);
    const [costOnHandTarget, setCostOnHandTarget] = useState([]);

    // colors
    const [targetColor, setTargetColor] = useState([]);
    const [targetHoverColor, setTargetHoverColor] = useState([]);

    const [selectedTab, setSelectedTab] = useState(
      localStorage.getItem('selectedCategoryTarget') === 'units' ? 'units' : 'cost');

    // auth
    const org = useRecoilValue(currentOrg);
    const orgId = org?.public_id;

    const sort = (data) => {
        let sorted = [];

        if (selectedTab === 'units') {
            sorted = data.sort(function(a, b) {
                return ((a.target_on_hand > b.target_on_hand) ? -1 :
                  ((a.target_on_hand === b.target_on_hand) ? 0 : 1));
            });
        } else {
            sorted = data.sort(function(a, b) {
                return (((a.target_on_hand * a.auc) > (b.target_on_hand * b.auc)) ? -1 :
                  (((a.target_on_hand * a.auc ) === (b.target_on_hand * b.auc)) ? 0 : 1));
            });
        }

        return sorted;
    }

    const filter = (data, h2_id, level) => {
        const _filter = level === 'h1' ?
          item => item.h3_id === null && item.h2_id !== null :
          item => item;

        return (data ?? []).filter(_filter);
    }

    // data fetching
    const fetchReorderRecommendation = async (h2_id) => {
        setLoading(true);

        try {
            if (locationIds.length === 1 && locationIds.includes(-1))
                locationIds = [];

            const { data: reorderRecommendation } = await OrganisationService
              .dashboard(orgId)
              .newReorderRecommendation(locationIds, h2_id, level);

            return reorderRecommendation;
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    // handlers
    const categoryFiltered = (e) => {
        if (e.length === 0) return;
        const index = e && e[0]?.index;
        const h2_id = categoryIds[index];
        if (h2_id === id) return;
        navigate(`?id=${h2_id}`);
    }

    const handleSwitchChange = (event, newValue) => {
        localStorage.setItem('selectedCategoryTarget', newValue);
        setSelectedTab(newValue);
    }

    const format = (h2_id) => {
        const filtered = filter(reorder || [], h2_id, level);
        const sorted = sort(filtered);

        const categoryIds = sorted.map(item => level === 'h1' ? item.h2_id : item.h3_id);
        const categories = sorted.map(item => level === 'h1' ? item.h2_name : item.h3_name);

        // metrics
        const orders = sorted.map(o => o.order_qty || 0);
        const ordersCost = sorted.map(item => item.order_qty === null ? 0 :
          Math.trunc(item.order_qty * item.auc));

        // on_hand
        const units_on_hand = sorted.map(m => m.on_hand);
        const units_on_hand_target = sorted.map(t => t.target_on_hand);

        // on_hand cost
        const onHandCost = sorted.map(item => Math.trunc(item.cost_on_hand));
        const onHandCostTargets = sorted.map(item => Math.trunc(item.target_on_hand * item.auc));

        const targetColors = unitsOnHand.map((t, i) => t > unitsOnHand[i] ?
          'rgba(100, 100, 100, 1)' : 'rgba(200, 200, 200, 1)');
        const targetHoverColors = unitsOnHand.map((t, i) => t > unitsOnHand[i] ?
          'rgba(200, 200, 200, 1)' : 'rgba(240, 240, 240, 1)');

        // units
        setUnitsOnHand(units_on_hand);
        setUnitsOnHandTarget(units_on_hand_target);

        // cost
        setCostOnHand(onHandCost);
        setCostOnHandTarget(onHandCostTargets);

        // order
        setOrdered(orders);
        setOrderedCost(ordersCost);

        // categories
        setCategories(categories);
        setCategoryIds(categoryIds);

        // colors
        setTargetColor(targetColors);
        setTargetHoverColor(targetHoverColors);
    }

    useEffect(() => {
        const loadReorder = async () => {
            const reorder = await fetchReorderRecommendation(id);
            setReorder(reorder);
        }

        loadReorder().then();
    }, [id, locationIds]);

    useEffect(() => {
        format(id);
    }, [reorder, selectedTab]);

    // chart config
    const options = {
        tooltips: {
            enabled: true,
            mode: 'single'
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: 'black',
                }
            },
            y: {
                beginAtZero: true,
                stacked: true,
                ticks: {
                    color: 'black',
                    maxTicksLimit: 6,
                    callback: function (value) {
                        return selectedTab === 'units' ? value.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : `${value.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                    },
                },
                title: {
                    display: true,
                    text: selectedTab === 'units' ? 'Units' : 'Cost',
                    color: 'black'
                }
            },
        },
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    font: {
                        size: 14,
                    },
                    color: 'black',
                    padding: 40,
                    usePointStyle: true,
                }
            },
            font: {
                size: 24,
            },
            color: 'black',
            tooltip: {
                mode: 'nearest',
                callbacks: {
                    label: function (tooltipItems, data) {
                        return selectedTab === 'units' ? `  ${tooltipItems.formattedValue}` : `  ${tooltipItems.formattedValue}`;
                    }
                }
            }
        },
    };

    // bar chart data
    const data = {
        labels: categories,
        datasets: [
            {
                label: 'Target',
                type: 'scatter',
                data: selectedTab === 'units' ? unitsOnHandTarget : costOnHandTarget,
                radius: 30,
                pointHoverRadius: 30,
                borderWidth: 4,
                pointStyle: 'line',
                pointHoverBorderWidth: 4,
                pointHoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                pointHoverBorderColor: targetHoverColor,
                borderColor: targetColor,
                backgroundColor: ['rgba(100, 100, 100, 1)'],
            },
            {
                label: 'On Hand',
                data: selectedTab === 'units' ? unitsOnHand : costOnHand,
                maxBarThickness: 60,
                backgroundColor: ['rgba(24, 76, 82, 1)'],
            },
            {
                label: 'Ordered',
                data: selectedTab === 'units' ? ordered : orderedCost,
                maxBarThickness: 60,
                backgroundColor: ['rgba(133, 197, 202, 1)'],
                borderRadius: 2,
            },
        ]
    };

    return (
      <CardWrap>
          <Card sx={{ boxShadow: 'none', border: 'solid 1px #eee' }}>
              <CardContent>
                  <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                      {!loading && (<Box flex={1}>
                          <Bar
                            type="bar"
                            getElementAtEvent={categoryFiltered}
                            data={data}
                            height={150}
                            width={300}
                            options={options}
                          />
                      </Box>)
                      }
                      {loading && <CircularProgress />}
                  </Box>
              </CardContent>
              <FormControlLabel
                label=""
                control={
                    <Tabs
                      value={selectedTab}
                      onChange={handleSwitchChange}
                      aria-label="tabs to switch between cost and units"
                      TabIndicatorProps={{
                          style: {
                              display: "none",
                          },
                      }}
                    >
                        <Tab value="cost" label="Cost" />
                        <Tab value="units" label="Units" />
                    </Tabs>
                }
              />
          </Card>
      </CardWrap>
    )
}

export default TargetsGraph;
