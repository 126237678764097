export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const processPaths = (files, initialPath) => {
  const itemsMap = {};

  files.forEach(file => {
    const isDir = file.id.endsWith('/');

    let parentId;
    let name;

    if (isDir) {
      // For directories, find the second-to-last slash
      const lastSlashIndex = file.id.lastIndexOf('/', file.id.length - 2);
      parentId = lastSlashIndex === -1 ? null : file.id.substring(0, lastSlashIndex + 1);
      name = file.id.split('/').filter(Boolean).pop();
    } else {
      // For files, find the last slash
      const lastSlashIndex = file.id.lastIndexOf('/');
      parentId = lastSlashIndex === -1 ? null : file.id.substring(0, lastSlashIndex + 1);
      name = file.id.substring(lastSlashIndex + 1);
    }

    itemsMap[file.id] = {
      ...file,
      name,
      isDir,
      parentId,
    };
  });

  let itemsArray = Object.values(itemsMap);

  itemsArray.sort((a, b) => {
    if (a.isDir && !b.isDir) return -1;
    if (!a.isDir && b.isDir) return 1;
    return a.name.localeCompare(b.name);
  });

  return itemsArray;
};


