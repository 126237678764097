import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import NavMenu from '@/components/NavMenu/NavMenu';
import { useRecoilValue } from 'recoil';
import { currentOrg } from '../../../../store';

const ExportPo = () => {
  const { state } = useLocation();
  const pot = state?.pot;
  const org = useRecoilValue(currentOrg);

  const navigate = useNavigate();
  const buttons = [{
    icon: 'edit',
    title: 'edit',
    description: 'edit',
    action: () => navigate(`/account/orders/${pot.po_number}/edit`, { pot }),
  }, {
    icon: 'share',
    title: 'share',
    description: 'share',
    action: () => {
    },
  }, {
    icon: 'departure_board',
    title: 'track',
    description: 'track',
    action: () => navigate(`/account/orders`),
  }];

  return (
    <div>
      <div className="clearfix">
        <div className="float-left">
          <div>
            <h2>EXPORT PURCHASE ORDER</h2>
          </div>
        </div>
        <NavMenu buttons={buttons} />
      </div>
      <Card className="card-shadow" style={{ marginTop: '20px' }}>
        <CardContent />
      </Card>
    </div>
  );
};

export default ExportPo;
