export const GENERAL_SPACING = '15px';

export const sectionCommonStyle = {
  addButton: {
    color: 'white !important'
  },
  submitButton: {
    color: 'white !important',
    padding: "10px 0px !important",
  },
  actions: {
    width: '100%',
    display: 'flex',
    height: '47px',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '10px 20px 0px 20px',
  },
  activeFilter: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'end',
    borderTop: '1px solid #cbcbcb',
    width: '100%',
    padding: "10px 0",
    justifyContent: "space-between"
  },
  table: {
    flex: 1,
    margin: '20px',
    background: 'white',
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#ffffff',
      borderColor: '#cbcbcb',
      borderWidth: 2,
    },
    '& .MuiDataGrid-row:nth-child(2n)': {
      backgroundColor: '#f9f9f9',
    },
    '& .MuiDataGrid-row:nth-child(2n + 1)': {
      backgroundColor: 'white',
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: 'white',
    },
    '& .MuiDataGrid-row.inactive': {
      color: 'gray',
      fontStyle: 'italic'
    }
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: GENERAL_SPACING,
  },
  inputsRow: {
    display: 'flex',
    columnGap: GENERAL_SPACING,
    rowGap: GENERAL_SPACING,
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: '110px',
    paddingBottom: GENERAL_SPACING,
  },
  error: {
    color: '#d94c4c',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: GENERAL_SPACING
  }
}

export const customSelectStyle = {
  fontSize: 'inherit',
  '& .MuiSelectUnstyled-popper': { zIndex: 2 },
  '& button': {
    fontSize: 'inherit !Important',
    borderRadius: '5px',
    height: '43px',
    color: 'currentColor',
    padding: '8.5px 14px',
    minHeight: 'unset'
  },
  '& ul': {
    height: '120px'
  }
};
