import React, { useState } from 'react';
import { useStyles } from './styles';
import PulseLoader from '../../../../../components/PulseLoader/index'
import classNames from 'classnames';

const Widget = ({ content, name }) => {
	const classes = useStyles();
	const [loading, setIsLoading] = useState(false)

	return (
		<div className={classNames(classes.widgetContainer, `${name}-widget`)}>
			{loading &&
				(<div className={classNames(classes.loadingContainer, `${name}-loading`)}>
					<PulseLoader loop />
				</div>)}
			{React.cloneElement(content,
				{
					onStartLoading: () => setIsLoading(true),
					onEndLoading: () => setIsLoading(false)
				})}
		</div>
	);
};

export default Widget
