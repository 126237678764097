import React from "react";
import { useStyles } from "./style";
import { Filters } from "./filters";
import CustomizedCheckbox from "@/components/CheckBox/CheckBox";

const FiltersControl = ({ filters, onFiltersChange }) => {
  const classes = useStyles();

  const handleFilterClick = (filter) => {
    if (filters.includes(filter)) {
      onFiltersChange(filters.filter((f) => f !== filter));
    } else {
      onFiltersChange([...filters, filter]);
    }
  };

  return (
    <div className={classes.container}>
      <span>Show Items</span>
      <div>
        <CustomizedCheckbox
          checked={filters.includes(Filters.RECOMMENDED)}
          color="#4da1aa"
          label={<span>Recommended</span>}
          onChange={(e) => {
            handleFilterClick(Filters.RECOMMENDED);
          }}
        />
      </div>
      <div>
        <CustomizedCheckbox
          checked={filters.includes(Filters.ORDERED)}
          color="#4da1aa"
          label="Ordered"
          onChange={(e) => {
            handleFilterClick(Filters.ORDERED);
          }}
        />
      </div>
      <div>
        <CustomizedCheckbox
          checked={filters.includes(Filters.ALL)}
          color="#4da1aa"
          label="All"
          onChange={(e) => {
            handleFilterClick(Filters.ALL);
          }}
        />
      </div>
    </div>
  );
};

export default FiltersControl;
