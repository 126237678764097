import React, { useState } from "react";
import { useSnackbar } from "notistack";

// recoil
import { useRecoilValue } from 'recoil';
import { settingsState , settingsDraftState } from "@/store/admin-panel/admin-panel.store";

// styles
import { useStyles } from "./style";

// services
import SettingsService from "@/service/org/setting.service";

// icons
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// components
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

// settings sections components
import FinanceSettings from "./finance";
import ReorderSettings from "./reorder";
import ForecastSettings from "./forecast";

const Settings = ({ onRefetch, onProcessing }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const copySettings = useRecoilValue(settingsDraftState);
  const originalSettings = useRecoilValue(settingsState);

  const saveSettings = async () => {
    onProcessing();
    try {
      setIsSaving(true);
      await SettingsService.updateSettings({ settings: {
        ...copySettings,
          "Default Tax Rate": copySettings?.["Default Tax Rate"] / 100
        } });
      enqueueSnackbar("Settings have updated successfully", {
        variant: 'success'
      })
      onRefetch();
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      enqueueSnackbar("Failed update settings", {
        variant: 'error'
      })
    }
  }

  const canSave = () => {
    return JSON.stringify(originalSettings) !== JSON.stringify(copySettings);
  };

  return (
    <Box className={classes.settingsContainer} sx={{ width: '100%' }}>
      <Tooltip title="Save settings" placement="left" style={{ pointerEvents: canSave() ? 'all' : 'none' }}>
        <div className={classes.saveIcon}>
          <SaveIcon
            fontSize="large"
            htmlColor={canSave() ? "#4da1aa" : "gray"}
            onClick={saveSettings}
          />
          <span style={{ color: canSave() ? '#4da1aa' : 'gray' }}>Save</span>
        </div>
      </Tooltip>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="forecast"
        >
          <span className={classes.heading}>Forecast Settings</span>
        </AccordionSummary>
            <AccordionDetails>
              <ForecastSettings />
            </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="settings"
        >
          <span className={classes.heading}>Finance Settings</span>
        </AccordionSummary>
          <AccordionDetails>
            <FinanceSettings />
          </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="reorder"
        >
          <span className={classes.heading}>Reorder Settings</span>
        </AccordionSummary>
            <AccordionDetails>
              <ReorderSettings />
            </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default Settings;
