import { makeStyles } from "@mui/styles";
import { sectionCommonStyle } from "../../shared/sections-common-style";

export const useStyles = makeStyles((theme) => ({
  ...sectionCommonStyle,
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  selectContainer: {
    flex: 1,
    '& span': {
      color: 'currentColor',
      fontSize: '13px'
    }
  },
  row: {
    flexDirection: 'row',
    height: 'unset'
  }
}));
