import { makeStyles } from "@mui/styles";
import styled from 'styled-components';

export const FieldsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#5b5b5b",
  },
  formContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "304px",
  },
  loadingWrap: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9,
    flexDirection: 'column',
  }
}));

