import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { ACCESS_TOKEN_KEY } from '../store';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/v2/`,
});

API.CancelToken = axios.CancelToken;
API.isCancel = axios.isCancel;

const hasTokenExpired = (exp) => Date.now() / 1000 > exp;

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    try {
      if (token) {
        const decodedToken = jwtDecode(token)

        if (!hasTokenExpired(decodedToken.exp)) {
          config.headers['Authorization'] = `Bearer ${token}`;
        } else {
          if (!window.location.href.includes('logout')) {
            window.location.href = "/logout";
          }
        }
      }
    } catch (e) {
      if (!window.location.href.includes('logout')) {
      } window.location.href = "/logout"
    }

    return config
  },
  (error) => Promise.reject(error)
)

API.interceptors.response.use(
  res => {
    return res
  },
  err => {
    if (err.response.status === 401 || err.response.status === 403)
      window.location.href = "/logout"
    else if (err.message === "Network Error")
      throw err
    return Promise.reject(err);
  }
)

export default API;
