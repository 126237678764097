import React from "react";

// styles
import { useStyles } from "./style";

// mui
import EditIcon from "@mui/icons-material/Edit";

// components
import NumberEditableText from "@/components/NumberEditableText/NumberEditableText";

const OpenToBuy = ({
  totalBudget,
  onOrder,
  openToBuyAmount,
  onEditBudget = () => { },
}) => {

  // state
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.amountRow}>
        <span className={classes.amountContainer}>
          <NumberEditableText
            displayType="text"
            align="right"
            className={classes.amount}
            value={totalBudget}
            onChange={() => { }}
            prefix="$"
            decimals={0}
          />
        </span>
        <span
          className={classes.amountTextContainer}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Total budget
          <EditIcon
            className={classes.editIcon}
            fontSize="small"
            htmlColor="#4da1aa"
            onClick={onEditBudget}
          />
        </span>
      </div>
      <div className={classes.amountRow}>
        <span className={classes.amountContainer}>
          <NumberEditableText
            displayType="text"
            align="right"
            className={classes.amount}
            value={onOrder}
            decimals={0}
            prefix="$"
          />
        </span>
        <span className={classes.amountTextContainer}>On Order</span>
      </div>
      <div className={classes.divider} />
      <div className={classes.amountRow}>
        <span className={classes.amountContainer}>
          <NumberEditableText
            displayType="text"
            align="right"
            className={classes.openToBuy}
            decimals={0}
            value={openToBuyAmount}
            prefix="$"
          />
        </span>
        <span className={classes.amountTextContainer}>Open to buy</span>
      </div>
    </div>
  );
};

export default OpenToBuy;
