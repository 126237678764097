import React, { useEffect, useState } from "react";
import { useStyles } from "./style";

// components
import CustomizedCheckbox from "@/components/CheckBox/CheckBox";

// mui
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";


const CategoriesHierarchy = ({
  categories = [],
  selectedCategories,
  onSelectCategory = () => { },
  onSelectAll = () => { },
  onDeselectAll = () => { },
}) => {

  // state
  const classes = useStyles();
  const [isAllSelected, setIsAllSelected] = useState(categories.length === selectedCategories.length)
  const totalCategoriesAmount = categories.reduce((sum, curr) => sum += curr.children ? curr.children.length : 0, 0) + categories.length

  // effects
  useEffect(() => {
    setIsAllSelected(totalCategoriesAmount === selectedCategories.length)
  }, [selectedCategories])

  // handlers
  const selectionForAllCategories = (select = true) => {
    setIsAllSelected(select)
    if (select) {
      onSelectAll();
    } else {
      onDeselectAll();
    }
  }

  const TreeViewItemLabel = ({ id, name }) => {
    return (
      <div style={{ padding: "3px 0px", display: "flex", alignItems: "center" }} onClick={(e) => e.stopPropagation()}>
        <CustomizedCheckbox
          checked={selectedCategories.includes(id)}
          color="#4da1aa"
          onChange={(e) => {
            onSelectCategory(id);
          }}
        />
        <span
          style={{ marginLeft: "4px" }}
          onClick={(e) => {
            onSelectCategory(id);
          }}
        >
          {name}
        </span>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {categories.length === 0 ? (
        <CircularProgress />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <Typography
              component="h1"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Choose Categories
            </Typography>
            <Button
              className={classes.selectAllButton}
              onClick={() => selectionForAllCategories(!isAllSelected)}
              variant="text"
            >
              {isAllSelected ? 'Deselect All' : 'Select All'}
            </Button>
          </div>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
              height: 450,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#bfbfbf",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#8d8d8d",
              },
            }}
          >
            {categories.map((parent) => (
              <TreeItem
                key={parent.id}
                nodeId={parent.id.toString()}
                label={TreeViewItemLabel(parent)}
              >
                {(parent.children ? parent.children : []).map((child) => (
                  <TreeItem
                    key={child.id}
                    nodeId={child.id.toString()}
                    label={TreeViewItemLabel(child)}
                  >
                    {(child.children ? child.children : []).map((subChild) => (
                      <TreeItem
                        key={subChild.id}
                        nodeId={subChild.id.toString()}
                        label={TreeViewItemLabel(subChild)}
                      />
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeView>
        </>
      )}
    </div>
  );
};

export default CategoriesHierarchy;
