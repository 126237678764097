import { uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader";
import OrganisationService from "../../../service/organisation-service";
import { sortAlphabetically } from "../../../utils/arrays";
import ExportItemsDialog from "./export";
import GroupByDialog from "./group-by-dialog";
import ColumnsDialog from "./columns-dialog";
import { getStoredGroupByFields, storeGroupByFields } from "./group-by-dialog/utils/local-storage-service";
import AdvancedFiltersControl from "./locations-categories";
import OrdersList from "./grid";
import { filterItems } from "./grid/utils/filter-items";
import { getAggregatedGroupedRows, groupItemsBy } from "./grid/utils/group-by";
import {
  convertOrderItemsToHierarchyArray
} from "./grid/utils/items-hirearchy";
import { useStyles } from "./style";
import { ITEM_PLANNER_HIDDEN_COLUMNS_NAMES } from "./utils/columns";
import { useRecoilState, useRecoilValue } from 'recoil';
import { reorderItemsState } from "@/store";
import { getGroupedFieldsValues, GROUP_BY_FIELDS } from './group-by-dialog/utils/constants';
import React, { useState, useEffect, useMemo } from 'react';
import useUserData from '../../../utils/hooks/useUserData';
import PageTitle from '../../../components/PageTitle';
import { locations as locationsState } from '../../../store/common.store';
import AllocationService from '../../../service/org/allocation.service';

export const ALL_VENDORS_OPTION = { value: -1, label: "All Vendors" };
export const ALL_BRANDS_OPTION = { value: -1, label: "All Brands" };
export const ALL_LOCATIONS_OPTION = { id: -1, name: "All Locations" };

const Allocation = ({
}) => {
  const { org_public_id: orgId } = useUserData();
  const locations = useRecoilValue(locationsState);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  //------------- Items --------------
  const [items, setItems] = useState();
  const [loadingItems, setLoadingItems] = useState(true);

  //------- Saving and controls -------
  const [updating, setUpdating] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [showGroupByDialog, setShowGroupByDialog] = useState(false);
  const [showColumnsDialog, setColumnsDialog] = useState(false);
  const [convertedItems, setConvertedItems] = useRecoilState(reorderItemsState);

  //------------ Filters --------------
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(ALL_VENDORS_OPTION);

  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(ALL_BRANDS_OPTION);

  const [selectedLocationId, setSelectedLocationId] = useState(
    ALL_LOCATIONS_OPTION.id
  );

  const fetchReorderItems = async () => {
    if (!orgId) return;
    setLoadingItems(true);
    const { data: items } = await AllocationService.getAllocation();
    setItems(items);
    setLoadingItems(false);
  }

  const fetchBrands = async () => {
    if (!orgId) return;
    const { data: brands } = await OrganisationService.brands.list(orgId);
    const sortedBrands = sortAlphabetically(brands, 'name');
    setBrands(sortedBrands);
  };

  //----------------------------------

  const fetchVendors = async () => {
    const {
      data: { dist },
    } = await OrganisationService.vendor.list(orgId, 10000, 1);
    const vendors = uniqBy(dist, "distributor_name");
    const sortedVendors = sortAlphabetically(vendors, 'distributor_name');
    setVendors(sortedVendors);
  };

  useEffect(async () => {
    if (!orgId) return;
    try {
      await Promise.all([
        fetchReorderItems(),
        fetchVendors(),
        fetchBrands(),
      ]);

    } catch (e) {
      enqueueSnackbar("There was an error while loading the step data.", {
        variant: "error",
      });
    }
  }, [orgId]);

  const getInitialGroupByFields = () => {
    return getStoredGroupByFields() ?? getGroupedFieldsValues()
  }

  const [groupByFields, setGroupByFields] = useState(getInitialGroupByFields())

  const locationsFilter = {
    list: locations,
    selectedId: selectedLocationId,
    onChange: (locationId) => setSelectedLocationId(locationId),
  };

  const categoriesFilter = {
    list: categories,
    selected: selectedCategories,
    onChange: setSelectedCategories,
  };

  const vendorsFilter = {
    list: vendors,
    selectedId: selectedVendor,
    onChange: setSelectedVendor,
  };

  const brandsFilter = {
    list: brands,
    selectedId: selectedBrand,
    onChange: setSelectedBrand,
  };

  //------------------------------------
  const [itemsColumns, setItemsColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(ITEM_PLANNER_HIDDEN_COLUMNS_NAMES);

  //------------------------------------
  useEffect(() => {
    if (Array.isArray(items)) {
      if (items.length > 0) {
        const converted = convertOrderItemsToHierarchyArray(items, locations);
        setConvertedItems(converted);
      } else {
        setConvertedItems([]);
      }
    }
  }, [items]);

  const filteredItems = filterItems(convertedItems, {
    categoriesIds: selectedCategories,
    locations,
    selectedLocationId,
    selectedVendorId: selectedVendor.value,
    selectedBrandId: selectedBrand.value,
  });

  const itemPlannerItems = useMemo(() => {
    const itemsRows = groupItemsBy(filteredItems, groupByFields);
    let parentsRows = getAggregatedGroupedRows(itemsRows, groupByFields.length);

    parentsRows = parentsRows.map((r) => {
      const WOS = r["Store OH - Units"] / r["Avg Weekly Sales - Units"];
      const AUC = +r["COGS"] / +r["Avg Weekly Sales - Units"];

      const WarehouseOHCost = r["Warehouse OH - Units"] * AUC;
      const inTransitCost = r["In Transit - Units"] * AUC;
      const StoreOHCost = r["Store OH - Units"] * AUC;
      const TargetStoreOHCost = r["Target Store OH - Units"] * AUC;
      const StoreNeedCost = r["Store Need - Units"] * AUC;
      const SuggestedAllocationCost = r["Suggested Allocation - Units"] * AUC;
      const avgWeeklySalesCost = r["Avg Weekly Sales - Units"] * AUC;
      const shortfallCost = r["Shortfall - Units"] * AUC;

      const AUV = r["Retail $"] / r["Avg Weekly Sales - Units"];
      const GMPercent = r["Gross Margin $"] / r["Retail $"]
      const avgWeeklyCogs = r["Ave Weekly Unit Sales"] & AUC;
      const WGMROI = (r["Gross Margin $"] * r["Avg Weekly Sales - Units"]) / StoreOHCost;

      return {
        ...r,
        AUC,
        AUV,
        "Gross Margin %": GMPercent,
        "Shortfall - Cost": shortfallCost,
        "Ave Weekly COGS": avgWeeklyCogs,
        WOS,
        'Warehouse OH - Cost': WarehouseOHCost,
        'In Transit - Cost': inTransitCost,
        'Store OH - Cost': StoreOHCost,
        'Target Store OH - Cost': TargetStoreOHCost,
        'Store Need - Cost': StoreNeedCost,
        'Suggested Allocation - Cost': SuggestedAllocationCost,
        'Ave Weekly Sales - Cost': avgWeeklySalesCost,
        WGMROI
      }
    });

    return [...itemsRows, ...parentsRows]
  }, [filteredItems, groupByFields])

  const allHiddenColumns = [...hiddenColumns, ...groupByFields]

  return (
    <div className={classes.container}>
      <div className={classes.topRow} style={{
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 20,
        width: '100%'}}>
      <PageTitle>
        Allocation
      </PageTitle>
      </div>
      <div className={classes.header}>
        <AdvancedFiltersControl
          locations={locationsFilter}
          categories={categoriesFilter}
          vendors={vendorsFilter}
          brands={brandsFilter}
        />
        <GroupByDialog
          reportName="itemPlanner-groupByFields"
          groupByFieldsMap={GROUP_BY_FIELDS}
          groupByFieldsValues={getGroupedFieldsValues}
          show={showGroupByDialog}
          onChangeGroupByFields={(metrics) => {
            setGroupByFields(metrics);
            storeGroupByFields(metrics)
          }}
          onCloseDialog={() => {
            setShowGroupByDialog(false)
          }}
          groupByFields={groupByFields}
        />
        <ColumnsDialog
          show={showColumnsDialog}
          onChangeColumns={(metrics) => {
            setItemsColumns(metrics);
          }}
          onCloseDialog={() => {
            setColumnsDialog(false)
          }}
          columns={itemsColumns}
          onChangeHiddenColumns={setHiddenColumns}
          hiddenColumns={allHiddenColumns}
        />
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          position: "relative",
        }}
      >
        {updating && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              flex: 1,
              zIndex: 9999,
              opacity: 0.9,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Loader />
            <b>Saving and updating recommendations...</b>
          </div>
        )}
        <OrdersList
          filteredItems={itemPlannerItems}
          categories={categories}
          groupByFields={groupByFields}
          columns={itemsColumns}
          hiddenColumns={allHiddenColumns}
          loading={loadingItems}
          onGroupBy={() => setShowGroupByDialog(true)}
          onColumns={() => setColumnsDialog(true)}
          onExport={() => setShowExportDialog(true)}
          setItemsColumns={setItemsColumns}
        />
      </div>
      <ExportItemsDialog
        show={showExportDialog}
        items={convertedItems}
        filteredItems={itemPlannerItems}
        onCloseDialog={() => setShowExportDialog(false)}
      />
    </div>
  );
};

export default Allocation;
