import { useMemo } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  BrowserRouter,
} from "react-router-dom";
import ProtectedLayouts from "./protectedRoute";
import { QueryParamProvider } from "use-query-params";

// Auth
import LogOut from "../pages/authentication/Logout";
import Login from "../pages/authentication/login/Login";
import PageNotFound from "../pages/misc/page-not-found";

// Profile
import Profile from "../pages/account/profile"
import ProfileEditor from "../pages/account/profile/editor";
import ProfilePasswordForm from "../pages/account/profile/change-password";

import Dashboard from "../pages/account/dashboard";
import ROIBooster from "../pages/account/roi-booster";
import Forecast from "../pages/account/forecast/forecast";

import TargetsGraph from "../pages/account/orders/purchase-order/views/TargetsGraph";
import ProductBoosterDetails from "../pages/account/roi-booster/product-booster-details";

import AdminPanel from "../pages/account/admin-panel";
import SwitchOrganization from "../pages/authentication/switch-org";
import ChangePassword from "../pages/authentication/change-password/ChangePassword";

import Looker from "../pages/account/looker"

// production
import Production from "../pages/account/production/production";
import BomDetail from '../pages/account/production/boms/BomDetail';
import MaterialDependencies from '../pages/account/production/materials/MaterialDependencies';
import FinishedGoodsDependencies
  from '../pages/account/production/finished-goods/FinishedGoodsDependencies';

// Reorder Pro

import ReorderPro from "../pages/account/reorder-pro/overview";
import ReorderProSwitch from "../pages/account/reorder-pro/route-switcher";
import ReorderProStepper from "../pages/account/reorder-pro/stepper";

// purchase orders
import ExportPo from "../pages/account/orders/purchase-order/export";
import PurchaseOrderList from "../pages/account/orders/purchase-order/views/PurchaseOrderList/PurchaseOrderList";
import PurchaseOrderEdit from "../pages/account/orders/purchase-order/views/PurchaseOrderEdit/PurchaseOrderEdit";
import PurchaseOrderCreate from "../pages/account/orders/purchase-order/views/PurchaseOrderCreate/PurchaseOrderCreate";

// reports
import Reports from "../pages/account/reports";
import ReOrdersReport from "../pages/account/reports/re-orders";
import TopMoversReport from "../pages/account/reports/top-movers";
import LocationRecap from "../pages/account/reports/recap/location-recap";
import CategoryRecap from "../pages/account/reports/recap/category-recap";


import useUserData from '../utils/hooks/useUserData';
import Allocation from '../pages/account/allocation';
import ForgotPassword from '../pages/authentication/forgot-password/ForgotPassword';
import SetPassword from '../pages/authentication/set-password/SetPassword';
import FileBrowser from '../pages/account/reports/file-browser';

// Adapter for react-router-dom v6 only
const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

const HomeRedirect = () => {
  const userData = useUserData();
  const token = localStorage.getItem("access-token");
  return token ? <Navigate to={`account/dashboard`} /> : <Navigate to={`login`} />
}

const AppRouter = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={RouteAdapter}>
        <Routes>
          <Route path="/">
            <Route path="" exact element={<HomeRedirect />} />
            <Route path="login" exact element={<Login />} />
            <Route path="/auth/password/:resetRequestId/reset" exact element={<ChangePassword />} />
            <Route path="set-password" exact element={< SetPassword />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route path="logout" exact element={<LogOut />} />
            <Route path="switch-organization" exact element={<SwitchOrganization />} />
          </Route>
          <Route path="/account" element={<ProtectedLayouts />}>
            <Route path="dashboard" exact element={<Dashboard />} />
            <Route path="admin" exact element={<AdminPanel />} />
            <Route path="reports">
              <Route path="" exact element={<Reports />} />
              <Route path="re-orders" exact element={<ReOrdersReport />} />
              <Route path="top-movers" exact element={<TopMoversReport />} />
              <Route path="files" exact element={<FileBrowser />} />
              <Route path="recap/category-recap" exact element={<CategoryRecap />} />
              <Route path="recap/location-recap" exact element={<LocationRecap />} />
              <Route path="category-targets" exact element={<TargetsGraph />} />
            </Route>
            <Route path="looker">
              <Route path=":moduleId" exact element={<Looker />} />
            </Route>
            <Route path="reorder-pro">
              <Route path="" exact element={<ReorderProSwitch />} />
              <Route path="overview" exact element={<ReorderPro />} />
              <Route path="setup" exact element={<ReorderProStepper />} />
            </Route>
            <Route path="allocation" exact element={<Allocation />} />
            <Route path="targets/category" exact element={<TargetsGraph />} />
            <Route path="roi-booster">
              <Route path="" exact element={<ROIBooster />} />
              <Route path="product-booster-info/:id" exact element={<ProductBoosterDetails />} />
            </Route>
            <Route path="forecast" exact element={<Forecast />} />
            <Route path="production">
              <Route path="" exact element={<Production />} />
              <Route path="bom" element={<BomDetail />} />
              <Route path="materials/:id/dependencies"
                     element={<MaterialDependencies />} />
              <Route path="finished-goods/:id/dependencies"
                     element={<FinishedGoodsDependencies />} />
            </Route>
            <Route path="orders">
              <Route path="" exact element={<PurchaseOrderList />} />
              <Route path="edit" exact element={<PurchaseOrderEdit />} />
              <Route path="add" exact element={<PurchaseOrderCreate />} />
              <Route path=":poNumber/export" exact element={<ExportPo />} />
            </Route>
            <Route path="profile">
              <Route path="" exact element={<Profile />} />
              <Route path="edit" exact element={<ProfileEditor />} />
              <Route path="change-password" exact element={<ProfilePasswordForm />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
