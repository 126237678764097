import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import React  from 'react';
import Stack from '@mui/material/Stack';

// data
import { useRecoilValue } from 'recoil';

// mui
import Box from '@mui/material/Box';
import PageTitle from '../../../../components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { materialsState } from '../production';
import { mrpForecastDataState } from '../production';
import { addCommas } from '../../../../utils/textFormatter';

export const MaterialDependencies = () => {
  // find the current material to display its description
  let { id } = useParams();
  const materials = useRecoilValue(materialsState);
  const material = materials?.find(material => material['Material ID'] === id);

  const mrpForecastData = useRecoilValue(mrpForecastDataState);
  const materialForecast = mrpForecastData.filter(d => d?.['Product ID'] === id);

  // dependent finished goods
  const dependentFinishedGoods = [...new Set(materialForecast.map(m => m?.['Parent ID']))];
  const dependentFinishedGoodsForecasts = dependentFinishedGoods.map(productId => ({
    "Product ID": productId,
    "Product Description": mrpForecastData?.find(mf => mf?.['Product ID'] === productId)?.['Product Description'],
    rows: mrpForecastData?.filter(d => d?.['Product ID'] === productId)
      ?.map(finishedGood => ({
        ...finishedGood,
        id: `${finishedGood?.['Product ID']}_${finishedGood?.['Metric']}`
      }))
  }));

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  let columns = [{
    field: 'id',
    headerName: 'ID',
    id: row => `${row?.['Product ID']}_${row?.['Metric']}`,
    width: 120,
    hide: true
  }, {
    field: 'Metric',
    headerName: '',
    width: 130,
    renderCell: (params) => <div style={{ fontWeight: 'bold' }}>
      {params.value}
    </div>
  }];

  // hardcoded weeks
  const availableWeeks = [
    '11/1',
    '11/8',
    '1/15',
    '11/22',
    '11/29',
    '12/6',
    '12/13',
    '12/20',
    '12/27',
    '1/3',
    '1/10',
    '1/17',
    '1/24'
  ];

  // build weeks 1-32
  const weekColumns = availableWeeks.map((w, i) => ({
    field: `Week ${i + 1}`,
    headerName: w,
    width: 65,
    headerAlign: 'right',
    renderCell: (params) => <div style={{
      width: '100%',
      textAlign: 'right'
    }}>
      <span style={{ fontSize: '12px' }}>
          { addCommas(params.value) }
      </span>
    </div>
  }));

  columns = [...columns, ...weekColumns];

  const materialForecastFormatted = materialForecast?.map(m => ({
    ...m,
    id: `${m?.['Product ID']}_${m?.['Metric']}`
  }));

  return <Stack style={{
    padding: '25px',
    flex: 1
  }}>
    <Box display="flex"
         justifyContent="space-between"
         sx={{
           position: 'relative',
           marginBottom: '25px'
         }}>
      <Box>
        <Box sx={{
          position: 'absolute',
          right: 20,
          display: 'flex',
          columnGap: '30px'
        }}>
          <a
            onClick={goBack}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              fontSize: '15px',
            }}>
            <i className='material-icons navmenu-icon'>{'arrow_back'}</i>
            <span className='d-block'>Back</span>
          </a>
          <a
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              fontSize: '15px',
            }}>
            <i className='material-icons navmenu-icon'>{'save'}</i>
            <span className='d-block'>Save</span>
          </a>
          <a style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'get_app'}</i>
            <span className='d-block'>Export</span>
          </a>
          <a style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'restore'}</i>
            <span className='d-block'>Reset</span>
          </a>
        </Box>
        <PageTitle>
          Production
        </PageTitle>
        <div style={{ marginTop: '25px', marginBottom: '20px' }}>
          <span style={{
            color: 'rgb(99,99,99)',
            fontSize: '20px',
            fontWeight: ' bold'
          }}>
          Material Dependencies View
          </span>
        </div>
      </Box>
    </Box>
    <Box sx={{
      height: '280px',
      paddingLeft: '30px'
    }}>
      <div style={{ marginBottom: '10px' }}>
        <span style={{ fontWeight: 'bold' }}>
          Raw Material: {material?.['Material Description']}
        </span>
      </div>
      <DataGrid
        hideFooter
        compact={true}
        rows={materialForecastFormatted}
        columns={columns}
        density="compact"
      />
    </Box>
    <br />
    <br />
    <br />
    <Box sx={{ paddingLeft: '30px', marginBottom: '60px' }}>
      <div style={{ marginBottom: '40px' }}>
      <span style={{
        color: 'gray',
        fontWeight: 'bold',
        fontSize: '13px',
        marginBottom: '10px'
      }}>
        Is needed for production of the following finished goods:
      </span>
      </div>
      {dependentFinishedGoodsForecasts.map(finishedGood => <Box sx={{
        height: '280px',
      }}>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>{finishedGood?.['Product Description']}</span>
        </div>
        <DataGrid
          hideFooter
          compact={true}
          rows={finishedGood.rows}
          columns={columns}
          density="compact"
        />
      </Box>)}
    </Box>
  </Stack>
}

export default MaterialDependencies;
