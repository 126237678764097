// libs
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

// deps
import './style.css';
import Box from '@mui/material/Box';

const QuarterRange = ({
  display = {
    startingYear: 2022,
    endingYear: new Date().getFullYear()
  },
  startingQuarter,
  endingQuarter,
  granularity,
  actionHandler,
  closeModal
}) => {
  const [modalOpen, showModal] = useState(true);

  // start quarter
  const [startRangeQuarter, setStartRangeQuarter] = useState(startingQuarter.quarter);
  const [startRangeYear, setStartRangeYear] = useState(startingQuarter.year);

  // end quarter
  const [endRangeQuarter, setEndRangeQuarter] = useState(endingQuarter.quarter);
  const [endRangeYear, setEndRangeYear] = useState(endingQuarter.year);

  const [hoverValue, setHoverValue] = useState(0);
  const [rangeSelectionInProgress, setRangeSelectionInProgress] = useState(false);

  let date1 = new Date();
  let date2 = new Date();

  const allowableQuartersLimit = {
    week_id: 1,
    month_id: 3,
    quarter_id: 13
  };

  const setStart = (quarter, year) => {
    setStartRangeQuarter(quarter);
    setStartRangeYear(year);
  };

  const setEnd = (quarter, year) => {
    setEndRangeQuarter(quarter);
    setEndRangeYear(year);
  };

  const toString = (quarter, year) => (quarter === 0 || year === 0 ? '' : `Q${quarter} ${year}`);

  const makeRange = (quarter, year) => {
    const nextState = !rangeSelectionInProgress;

    if (nextState) {
      setStart(quarter, year);
      setEnd(0, 0);
    } else {
      if (year < startRangeYear) {
        setStart(quarter, year);
        setEnd(startRangeQuarter, startRangeYear);
      } else if (year === startRangeYear) {
        if (quarter >= startRangeQuarter) {
          setEnd(quarter, year);
        } else {
          setStart(quarter, year);
          setEnd(startRangeQuarter, startRangeYear);
        }
      } else if (year > startRangeYear) {
        setEnd(quarter, year);
      }
    }

    setRangeSelectionInProgress(nextState);
  };

  const withinAcceptableLimit = (() => {
    date1.setDate(1);
    date1.setMonth(startRangeQuarter * 3 - 1);
    date1.setFullYear(startRangeYear);

    date2.setDate(1);
    date2.setMonth(endRangeQuarter * 3 - 1);
    date2.setFullYear(endRangeYear);

    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const numberOfQuarters = Math.ceil(timeDiff / (1000 * 86400 * 30 * 3));

    return !rangeSelectionInProgress && numberOfQuarters <= allowableQuartersLimit[granularity];
  })();

  const doHighlight = (value) => {
    const startValue = startRangeYear * 10 + startRangeQuarter;
    const endValue = endRangeYear * 10 + endRangeQuarter;
    const valueInRange = startValue <= value && value <= endValue;

    if (rangeSelectionInProgress) {
      if (hoverValue <= startValue && hoverValue <= value && value <= startValue) {
        return (
          'highlight-hover' +
          (value === hoverValue ? ' highlight-start' : '') +
          (value === startValue ? ' highlight-end' : '')
        );
      } else if (hoverValue >= startValue && startValue <= value && value <= hoverValue)
        return (
          'highlight-hover' +
          (value === startValue ? ' highlight-start' : '') +
          (value === hoverValue ? ' highlight-end' : '')
        );
    } else if (!rangeSelectionInProgress && valueInRange) {
      return (
        'highlight' +
        (value === startValue ? ' highlight-start' : '') +
        (value === endValue ? ' highlight-end' : '')
      );
    } else return '';
  };

  const doClose = () => {
    showModal(false);
    closeModal();
  };

  const doAction = () => {
    actionHandler(`${startRangeYear}_Q${startRangeQuarter}`, `${endRangeYear}_Q${endRangeQuarter}`);
    doClose();
  };

  return (
    <Modal
      id="quarter-range-selector-modal"
      open={modalOpen}
      backdrop={'static'}
      keyboard={true}
      style={{
        borderRadius: '8px!important',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 50%)'
      }}>
      <Box
        sx={{
          backgroundColor: 'white',
          margin: 'auto',
          width: '320px',
          borderRadius: '4px'
        }}>
        <div
          style={{
            backgroundColor: '#19d1dd',
            color: 'white',
            padding: '10px',
            borderRadius: '4px 4px 0 0'
          }}>
          <div
            style={{
              fontSize: '11px',
              letterSpacing: '2.6px'
            }}>
            SELECT QUARTERS
          </div>
          <div style={{ fontSize: '31px' }}>
            Q{startRangeQuarter} {startRangeYear} - Q{endRangeQuarter} {endRangeYear}
          </div>
        </div>
        <div style={{ padding: '10px' }}>
          <table id="quarter-range-selector-table" style={{ width: '100%' }}>
            <tbody>
              {[...Array(display.endingYear - display.startingYear + 1)].map((_, index) => (
                <>
                  <tr key={`${index}_year`}>
                    <td>
                      <div style={{ marginTop: '15px' }}>{display.startingYear + index}</div>
                    </td>
                  </tr>
                  <tr key={`${index}_qrtrs`}>
                    <td
                      onClick={() => makeRange(1, display.startingYear + index)}
                      onMouseOver={() => setHoverValue((display.startingYear + index) * 10 + 1)}
                      className={doHighlight((display.startingYear + index) * 10 + 1)}>
                      Q1
                    </td>
                    <td
                      onClick={() => makeRange(2, display.startingYear + index)}
                      onMouseOver={() => setHoverValue((display.startingYear + index) * 10 + 2)}
                      className={doHighlight((display.startingYear + index) * 10 + 2)}>
                      Q2
                    </td>
                    <td
                      onClick={() => makeRange(3, display.startingYear + index)}
                      onMouseOver={() => setHoverValue((display.startingYear + index) * 10 + 3)}
                      className={doHighlight((display.startingYear + index) * 10 + 3)}>
                      Q3
                    </td>
                    <td
                      onClick={() => makeRange(4, display.startingYear + index)}
                      onMouseOver={() => setHoverValue((display.startingYear + index) * 10 + 4)}
                      className={doHighlight((display.startingYear + index) * 10 + 4)}>
                      Q4
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          {rangeSelectionInProgress && (
            <div className="text-muted pl-3 pr-3 mt-3">
              <small>
                Range selection in progress. Select another/same quarter to end quarter
                range-selection.
              </small>
            </div>
          )}
          {!rangeSelectionInProgress && !withinAcceptableLimit && (
            <div className="text-danger pl-3 pr-3 mt-3">
              <small>
                You cannot select more than {allowableQuartersLimit[granularity]}{' '}
                {allowableQuartersLimit[granularity] > 1 ? 'quarters' : 'quarter'}
              </small>
            </div>
          )}
        </div>
        <div className="text-right" style={{ padding: '10px 0' }}>
          <Button
            onClick={doClose}
            style={{
              backgroundColor: 'inherit',
              border: 'none',
              color: '#3C3C3C'
            }}>
            CANCEL
          </Button>
          <Button
            disabled={rangeSelectionInProgress || !withinAcceptableLimit}
            onClick={doAction}
            style={{
              backgroundColor: 'inherit',
              border: 'none',
              color: '#3C3C3C'
            }}>
            OK
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default QuarterRange;
