import React, { useState } from 'react';
import QuarterRange from '../../quarters';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import { FilterLabel, Section, Title } from '../style';
import { formatQuarter } from '../../../../../../../utils/textFormatter';
import CustomSelect from '../../../../../../../components/CustomSelect';
import { atom, useRecoilState } from 'recoil';
import { BootstrapInput } from './style';

export const timeState = atom({
  key: 'forecast_time_options',
  default: {
    options: [
      {
        key: 'week_id',
        value: 'Weekly'
      },
      {
        key: 'month_id',
        value: 'Monthly'
      }
    ],
    startQuarter: null,
    endQuarter: null,
    granularity: 'week_id'
  }
});

const TimeOptions = () => {
  // state
  const [quarterRangeVisible, setQuarterRangeVisible] = useState(false);
  const [{ options, startQuarter, endQuarter, granularity }, setTimeOptions] = useRecoilState(timeState);

  const updateQuarters = (startQuarter, endQuarter) =>
    setTimeOptions((prev) => ({
      ...prev,
      startQuarter,
      endQuarter
    }));

  const updateGranularity = (granularity) =>
    setTimeOptions((prev) => ({
      ...prev,
      granularity
    }));

  return (
    <Section>
      <Title>Time Options</Title>
      <form>
        <FormGroup
          sx={{
            marginBottom: '1rem'
          }}>
          <FormControl variant="standard">
            <FilterLabel>Date</FilterLabel>
            <BootstrapInput
              defaultValue={`${formatQuarter(startQuarter).display} - ${
                formatQuarter(endQuarter).display
              }`}
              id="bootstrap-input"
              style={{ marginTop: '4px' }}
              onClick={() => setQuarterRangeVisible(true)}
            />
          </FormControl>
          {quarterRangeVisible && (
            <QuarterRange
              startingQuarter={formatQuarter(startQuarter)}
              endingQuarter={formatQuarter(endQuarter)}
              granularity={granularity}
              actionHandler={updateQuarters}
              closeModal={() => setQuarterRangeVisible(false)}
            />
          )}
        </FormGroup>
        <FormGroup style={{ marginBottom: '0px' }}>
          <FilterLabel>Show in</FilterLabel>
          <CustomSelect
            value={granularity || ''}
            options={options}
            onChange={updateGranularity}
            style={{
              width: '100%',
              marginTop: '4px'
            }}
          />
        </FormGroup>
      </form>
    </Section>
  );
};

export default TimeOptions;
