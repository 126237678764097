import React, { useState } from 'react';
import { AlertTriangle, Info, AlertOctagon } from 'react-feather';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import {
  ResetActions,
  ResetConfirmTitle
} from '../../forecast/forecast/widgets/toolbar/style';

// styles
export const ResetMessage = styled.div`
  padding: 15px;
`;

export const Title = styled.div`
  background: rgb(122, 214, 224);
  border-radius: 5px 5px 0 0;
  color: white;
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
`;

export const Actions = styled.div`
  padding: 15px;
`;

export const ConfirmButton = styled.span`
  background: tomato;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
`;

export const CancelButton = styled.span`
  background: #b4b3b3;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
`;

const MessageBox = ({ type, title, iconify = false, message, actionHandler, closeModal }) => {
  const [modalOpen, showModal] = useState(true);

  const doClose = () => {
    showModal(false);
    closeModal();
  };
  const doAction = () => {
    actionHandler();
    doClose();
  };

  const icon = (() => {
    switch (type.toLowerCase()) {
      case 'action':
      case 'info':
        return <Info size={40} color="#19d1dd" />;
      case 'warning':
        return <AlertTriangle size={40} color="#ff9d3d" />;
      case 'danger':
      case 'critical':
        return <AlertOctagon size={40} color="#cc4125" />;
      default:
        return null;
    }
  })();

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999
      }}
      open={modalOpen}
      onClose={() => showModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200
      }}>
      <Fade in={modalOpen}>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '5px',
            zIndex: 10,
            boxShadow: 24
          }}>
          <ResetConfirmTitle>
            <span className={`text-${type}`}>{title}</span>
          </ResetConfirmTitle>
          <ResetMessage>
            <div className="text-center">
              {iconify && <div style={{ marginBottom: '15px' }}>{icon}</div>}
              {message}
            </div>
          </ResetMessage>
          <ResetActions>
            <ConfirmButton style={{ marginRight: '15px' }} onClick={doAction}>
              Yes
            </ConfirmButton>
            <CancelButton onClick={doClose}>No</CancelButton>
          </ResetActions>
        </Box>
      </Fade>
    </Modal>
  );
};

export default MessageBox;
