import { UserType, convertIdToType } from "@/enums/user-type"
import { useRecoilValue } from "recoil";
import { currentUser } from "../../../store";

const UserTypeGuard = ({ type, admin = false, children }) => {
    const user = useRecoilValue(currentUser);

    const userType = convertIdToType(+user?.user_type_id);

    const hasPermission = type ?
        (+user?.user_type_id === type) : (admin ?
            userType === UserType.ADMIN :
            true)

    return <>{hasPermission && children}</>
}

export default UserTypeGuard;
