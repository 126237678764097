import { useRecoilState } from 'recoil';
import productService from '../../../../service/org/product.service';

export const useGetFinishedGood = (state) => {
  const [finishedGoods, setFinishedGoods] = useRecoilState(state);
  const refresh = async () => {
    try {
      const { data: finishedGoods } = await productService.getFinishedGoods();
      setFinishedGoods((finishedGoods ?? []).map(finishedGood => ({
        ...finishedGood,
        finished_good_id: finishedGood.id
      })));
    } catch (e) {
      console.log(e);
    }
  };

  return { finishedGoods, refresh };
};
