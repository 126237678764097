import styled from 'styled-components';


export default styled.div`
  display: flex;
  height: 38px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  min-width: 75px;
  &:hover {
    background-color: #fafafa;
    border: solid 1px gray;
  }
`;

export const OKButton = styled.div`
  background: #109185;
  display: inline-block;
  opacity: 1;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
`;

