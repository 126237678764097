import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    paddingBottom: "2vh",
    alignItems: "center",
    flexDirection: "column",
  },
  wrapper: {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#bfbfbf",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#8d8d8d",
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
