import React, { useEffect, useState } from "react";
import { useStyles } from "./style";

import { useSnackbar } from "notistack";

import VendorService from "@/service/org/vendor.service";
import { getError } from "@/utils/server-error-types";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const emptyFormData = {
  name: { display: 'Vendor Name', value: "", hasError: false, required: true },
  contactName: { display: 'Contact Person', value: "", hasError: false },
  phone: { display: 'Phone', value: "", hasError: false },
  address: { display: 'Address 1', value: "", hasError: false },
  secondAddress: { display: 'Address 2', value: "", hasError: false },
  email: { display: 'Email', value: "", hasError: false },
  state: { display: 'State', value: "", hasError: false },
  city: { display: 'City', value: "", hasError: false },
  zip: { display: 'ZIP Code', value: "", hasError: false },
  website: { display: 'Website', value: "", hasError: false },
}

const CreateVendorDialog = ({
  show,
  onCloseDialog,
  onCreate,
  onUpdate,
  vendor
}) => {
  const classes = useStyles();

  const initialFormData = {
    name: { display: 'Vendor Name', value: vendor ? vendor.distributor_name : "", hasError: false, required: true },
    contactName: { display: 'Contact Person', value: vendor ? vendor.contact_name : "", hasError: false },
    phone: { display: 'Phone', value: vendor ? vendor.phone_number : "", hasError: false },
    address: { display: 'Address 1', value: vendor ? vendor.address : "", hasError: false },
    secondAddress: { display: 'Address 2', value: vendor ? vendor.address2 : "", hasError: false },
    email: { display: 'Email', value: vendor ? vendor.email : "", hasError: false },
    state: { display: 'State', value: vendor ? vendor.state : "", hasError: false },
    city: { display: 'City', value: vendor ? vendor.city : "", hasError: false },
    zip: { display: 'ZIP Code', value: vendor ? vendor.zip : "", hasError: false },
    website: { display: 'Website', value: vendor ? vendor.website : "", hasError: false },
  }

  const [formData, updateFormData] = useState({ ...initialFormData });
  const [formError, setFormError] = useState("");
  const [loading, toggleLoading] = useState(false);

  useEffect(() => {
    updateFormData(initialFormData)
  }, [vendor])

  const { enqueueSnackbar } = useSnackbar();

  const handleFormInputChange = (event) => {
    const newValue = event.target.value;
    updateFormData({
      ...formData,
      [event.target.name]: {
        ...formData[event.target.name],
        value: newValue
      }
    })
  }

  const validateField = (formInput, value) => {
    const validateMap = {
      name: (val) => {
        if (val === "") {
          return "Name is a required field";
        } else {
          return ""
        }
      },
    }
    if (validateMap[formInput]) return validateMap[formInput](value);
    return "";
  }

  const validate = () => {
    let formDataCopy = { ...formData };
    let foundErrors = false;

    Object.keys(formData).forEach(inputKey => {
      const fieldValidate = (validateField(inputKey, formData[inputKey].value))
      if (fieldValidate !== "") {
        if (!foundErrors) {
          setFormError(fieldValidate);
          foundErrors = true;
        }
        formDataCopy[inputKey].hasError = true;
      } else {
        formDataCopy[inputKey].hasError = false;
      }
    })

    if (!foundErrors) {
      setFormError("")
      handleFormSubmission(formDataCopy)
    }

    updateFormData({ ...formDataCopy })
  }

  const handleFormSubmission = async (data) => {
    toggleLoading(true);
    try {
      const newData = {
        distributor_name: formData.name.value,
        contact_name: formData.contactName.value,
        phone_number: formData.phone.value,
        address: formData.address.value,
        address2: formData.secondAddress.value,
        email: formData.email.value,
        state: formData.state.value,
        city: formData.city.value,
        zip: formData.zip.value,
        website: formData.website.value,
      };
      if (vendor) {
        await VendorService.updateVendor(vendor.id, newData);
        onUpdate();
        enqueueSnackbar("Vendor updated successfully", {
          variant: 'success'
        });
      } else {
        await VendorService.createVendor(newData)
        onCreate();
        enqueueSnackbar("Vendor created successfully", {
          variant: 'success'
        });
      }

      closeDialog();
    } catch (err) {
      toggleLoading(false);
      const { response } = err;
      const errorMessage = getError(response, `Failed to ${vendor ? 'update' : 'create'} vendor`);
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  }


  const closeDialog = () => {
    updateFormData({ ...emptyFormData });
    setFormError("");
    toggleLoading(false);
    onCloseDialog();
  };

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          width: "517px",
        },
      }}
    >
      <DialogContent className={classes.wrapper}>
        <Box className={classes.form}>
          <Typography
            component="h2"
            sx={{
              lineHeight: "1",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {vendor ? 'Update vendor details' : 'Add vendor'}
          </Typography>

          <div className={classes.inputsContainer}>
            <div className={classes.inputsRow}>
              <TextField
                error={formData.name.hasError}
                value={formData.name.value}
                label={formData.name.display}
                required={formData.name.required}
                name="name"
                onChange={handleFormInputChange}
                size="small"
                color='primary'
              />
              <TextField
                error={formData.contactName.hasError}
                value={formData.contactName.value}
                label={formData.contactName.display}
                required={formData.contactName.required}
                name="contactName"
                onChange={handleFormInputChange}
                size="small"
                color='primary'
              />
            </div>
            <div
              className={classes.inputsRow}

            >
              <TextField
                error={formData.phone.hasError}
                value={formData.phone.value}
                label={formData.phone.display}
                required={formData.phone.required}
                name="phone"
                onChange={handleFormInputChange}
                size="small"
                color='primary'

              />
              <TextField
                error={formData.email.hasError}
                value={formData.email.value}
                label={formData.email.display}
                required={formData.email.required}
                name="email"
                onChange={handleFormInputChange}
                size="small"
                color='primary'

              />
              <TextField
                error={formData.address.hasError}
                value={formData.address.value}
                label={formData.address.display}
                required={formData.address.required}
                name="address"
                onChange={handleFormInputChange}
                size="small"
                color='primary'

              />
              <TextField
                error={formData.secondAddress.hasError}
                value={formData.secondAddress.value}
                label={formData.secondAddress.display}
                required={formData.secondAddress.required}
                name="secondAddress"
                onChange={handleFormInputChange}
                size="small"
                color='primary'

              />
            </div>
            <div
              className={classes.inputsRow}

            >
              <TextField
                error={formData.state.hasError}
                value={formData.state.value}
                label={formData.state.display}
                required={formData.state.required}
                name="state"
                onChange={handleFormInputChange}
                size="small"
                color='primary'

              />
              <TextField
                error={formData.zip.hasError}
                value={formData.zip.value}
                label={formData.zip.display}
                required={formData.zip.required}
                name="zip"
                onChange={handleFormInputChange}
                size="small"
                color='primary'

              />
              <TextField
                error={formData.city.hasError}
                value={formData.city.value}
                label={formData.city.display}
                required={formData.city.required}
                name="city"
                onChange={handleFormInputChange}
                size="small"
                color='primary'

              />
              <TextField
                error={formData.website.hasError}
                value={formData.website.value}
                label={formData.website.display}
                required={formData.website.required}
                name="website"
                onChange={handleFormInputChange}
                size="small"
                color='primary'

              />
            </div>
          </div>
          {formError &&
            <div style={{ marginTop: '5px', marginBottom: '15px' }}>
              <span className={classes.error}>{formError}</span>
            </div>
          }
          <LoadingButton
            loading={loading}
            onClick={validate}
            variant="contained"
            className={classes.submitButton}
          >
            {vendor ? 'Update' : 'Add'}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateVendorDialog;
