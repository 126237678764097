import React, { useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import { FilterLabel, Section, Title } from '../style';
import CustomSelect from '../../../../../../../components/CustomSelect';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  locations as locationsState,
  districts as districtsState
} from '../../../../../../../store/common.store';
import { locationState, districtState } from '../../../forecast.store';
import { useSearchParams } from "react-router-dom";

export default () => {
  const locations = useRecoilValue(locationsState);
  const [location, setLocation] = useRecoilState(locationState);

  const districts = useRecoilValue(districtsState);
  const [district, setDistrict] = useRecoilState(districtState);

  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredLocations, setFilteredLocations] = useState([]);

  // Update location and district from URL search params on load
  useEffect(() => {
    const locationId = searchParams.get('locId');
    const districtId = searchParams.get('districtId');

    // Wait for districts to load before setting from query param
    if (districts.length > 0 && districtId) {
      setDistrict(parseInt(districtId, 10));
    }

    if (locationId) {
      setLocation(parseInt(locationId, 10));
    }
  }, [districts]);

  const updateLocation = (locationId) => {
    setLocation(locationId);
    searchParams.set('locId', locationId);
    setSearchParams(searchParams);
  };

  const updateDistrict = (districtId) => {
    setDistrict(districtId);
    setLocation(-1);
    searchParams.set('districtId', districtId);
    searchParams.set('locId', '-1');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (district === -1) {
      setFilteredLocations(locations);
    } else {
      const filtered = locations.filter(location => location.district_id === district);
      setFilteredLocations(filtered);
    }
  }, [locations, district]);

  let locationOptions = filteredLocations.map(location => ({
    key: location.id,
    value: location.location_name
  }));

  if (district !== -1) {
    locationOptions = [{ key: -1, value: 'All Locations' }, ...locationOptions];
  }

  return (
    <Section>
      <Title>Location Options</Title>
      <form>
        <FormGroup sx={{ marginBottom: '1rem' }}>
          <FilterLabel>District</FilterLabel>
          <CustomSelect
            value={district}
            options={districts.map(district => ({
              key: district.district_id,
              value: district.district_name
            }))}
            onChange={(districtId) => updateDistrict(districtId)}
            style={{ width: '100%', marginTop: '4px' }}
          />
        </FormGroup>
        <FormGroup style={{ marginBottom: '0px' }}>
          <FilterLabel>Location</FilterLabel>
          <CustomSelect
            value={location}
            options={locationOptions}
            onChange={(locationId) => updateLocation(locationId)}
            style={{ width: '100%', marginTop: '4px' }}
          />
        </FormGroup>
      </form>
    </Section>
  );
};
