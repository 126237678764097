export const blue = "#4da1aa";

export const commonStyles = {
  controlButton: {
    width: "fit-content",
    color: "white",
    backgroundColor: blue,
    "&:hover": {
      backgroundColor: "#447e85",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "75%",
    direction: "rtl",
    marginTop: "30px",
  },
};
