import React from "react";
import { BpCheckedIcon, BpCheckbox } from "./styles";

const CustomizedCheckbox = ({
  checked = false,
  onChange,
  name = "",
  color = "black",
  disabled = false,
  ...rest
}) => {
  return (
    <BpCheckbox
      checkedIcon={<BpCheckedIcon sx={{ backgroundColor: color }} />}
      checked={checked}
      name={name}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
  );
};

export default CustomizedCheckbox;
