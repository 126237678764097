import styled from 'styled-components';

export const Wrap = styled.div`
  min-width: 100px;
  position: relative;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 10px;
  color: #495057;
  *:focus {
    outline: none;
  }
  &:hover {
    background: #f1f1f1;
    border: solid 1px gray;
  }
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export const Opener = styled.div`
  display: flex;
  height: 100%;
  padding: 0 10px;
`;

export const DatesWrap = styled.div`
    display: flex;
    margin-left: 10px;
`;

export const Date = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const Spacer = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectWrap = styled.div`
  width: 350px;
  height: 300px;
  background-color: white;
  border: solid 1px #ced4da;
  border-radius: 5px;
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 999;
  display: flex;
  padding: 15px;
  flex-direction: column;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  border-right: solid 1px #f1f1f1;
  padding-right: 15px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 15px;
  flex: 1;
`;

export const MainWrap = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const Option = styled.div`
  display: flex;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 5px;
  min-width: 100px;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const FilterLabel = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: #b6b5b5;
  margin-bottom: 10px;
`;

export const StartWrap = styled.div`
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const Value = styled.div`
  background-color: #f1f1f1;
  border: solid 1px transparent;
  border-radius: 5px;
  padding: 5px 6px;
  margin: 4px 0;
  font-size: 13px;
  &:hover {
    border: solid 1px gray;
  }
  &:active {
    background: #fafafa;
  }
`;

export const Text = styled.div`
  padding-left: 10px;
`;
