export const updateElement = (array, newElement, callBackFindCondition) => {
  const elementIndex = array.findIndex(callBackFindCondition);
  if (elementIndex === -1) {
    return array;
  }
  const copy = [...array];
  copy[elementIndex] = newElement;
  return copy;
};

export const sum = (arr) => {
  return arr.reduce((summ, curr) => summ + curr, 0);
};

export const maxDiff = (arr) => {
  let diff = 0;
  if (arr?.length) diff = arr?.length ? Math.max(...arr) - Math.min(...arr) : 0;
  return diff;
};

export const sortAlphabetically = (arr, field) => {
  return arr.sort((a, b) => {
    const first = field ? a[field].toLowerCase() : a.toLowerCase();
    const second = field ? b[field].toLowerCase() : b.toLowerCase();
    if (first < second) { return -1; }
    if (first > second) { return 1; }
    return 0;
  })
}
