import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  action: {
    cursor: 'pointer',
    marginRight: '5px',
    '&:last-of-type': {
      marginRight: '0'
    }
  }
}));
