const INPUT_TYPE_ERROR = 'input-type-error';

export const getError = (response, defaultError = "Unhandled Error") => {
  if (!response) {
    return "Network Error"
  }
  // In case the error is request schema type error
  if (response?.data?.error?.type === INPUT_TYPE_ERROR) {
    return response.data.error.message;
  }
  if (response?.data?.error?.message) {
    return response.data.error.message;
  }
  return defaultError;
}