import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";

// styles
import { useStyles } from "./style";

// recoil
import { useRecoilValue } from "recoil";

// services
import UserService from "@/service/master/user.service";

// store
import { usersState, userTypesState } from "@/store/admin-panel/admin-panel.store";

// components
import Actions from "../shared/actions";
import CreateUserDialog from "./create-user-dialog";
import CustomizedCheckbox from "@/components/CheckBox/CheckBox";

// mui
import Tooltip from "@mui/material/Tooltip";
import FormGroup from '@mui/material/FormGroup';
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Chip } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteConfirmation from '../../../../components/DeleteConfirmation';

const Users = ({ onRefetch, onProcessing }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const users = useRecoilValue(usersState);
  const userTypes = useRecoilValue(userTypesState);

  const [selectedUser, setSelectedUser] = useState();
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [showActivatedOnly, setShowActivatedOnly] = useState(false);
  const [showUpdateCreatePopup, setShowUpdateCreatePopup] = useState(false);

  const deleteUser = async (user) => {
    setSelectedUser(user);
    setDeletePopupOpen(true);
  }

  const onDeleteConfirmed = async () => {
    try {
      onProcessing();
      await UserService.deleteUser(selectedUser.user_id);
      enqueueSnackbar('User deleted successfully', {
        variant: 'success'
      });
      onRefetch();
    } catch (err) {
      enqueueSnackbar('Delete user failed', {
        variant: 'error'
      });
    }
  };

  const USERS_COLUMNS = [
    {
      field: 'first_name',
      headerName: 'First name',
      width: 150,
    },
    {
      field: 'last_name',
      headerName: 'Last name',
      width: 150,
    },
    {
      field: 'user_type_id',
      headerName: 'Role',
      width: 120,
      valueGetter: ({ row }) =>
        userTypes?.find(userType => userType.user_type_id === row.user_type_id)?.user_type_name || ""
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 350,
      renderCell: ({ row }) => {
        return <div>
          {row.email}
          {row.reset_pass === 1 &&
          <Tooltip title="Instructions email sent" placement="top">
            <Chip className={classes.pendingChip} label="pending" color="primary" />
          </Tooltip>
          }
        </div>
      }
    },
    {
      field: 'actions',
      type: 'actions',
      renderCell: ({ row }) => {
        return <Actions
          onDelete={() => deleteUser(row)}
          onEdit={() => {
            setSelectedUser(row);
            setShowUpdateCreatePopup(true);
          }}
        />
      }
    }
  ];

  const filteredUsers = useMemo(() =>
      showActivatedOnly ? users : users.filter(user => +user.status_id === 1)
    , [users, showActivatedOnly])

  return (
    <Box className={classes.usersContainer} sx={{ width: '100%' }}>
      <div className={classes.actions}>
        <Button className={classes.addButton} variant="contained"
                onClick={() => setShowUpdateCreatePopup(true)}>
          <AddOutlined sx={{ marginRight: '8px' }} />
          Create user
        </Button>
      </div>
      <DataGridPro
        data-testid="admin_users_table"
        className={classes.table}
        loading={users.length === 0}
        rows={filteredUsers}
        columns={USERS_COLUMNS}
        getRowId={(row) => row.user_id}
        getRowClassName={(params) => +params.row.status_id !== 1 && 'inactive'}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        hideFooterSelectedRowCount
        components={{
          Footer: () => (
            <div className={classes.activeFilter}>
              <FormGroup>
                <FormControlLabel control={<CustomizedCheckbox
                  checked={showActivatedOnly}
                  color="#19d1dd"
                  onChange={() => {
                    setShowActivatedOnly(currActivity => !currActivity);
                  }} />}
                                  label={<span
                                    style={{ marginLeft: '2px' }}>Show inactive users</span>} />
              </FormGroup>
            </div>),
        }}
      />
      <CreateUserDialog
        show={showUpdateCreatePopup}
        onCloseDialog={() => {
          setShowUpdateCreatePopup(false);
          setSelectedUser(undefined)
        }}
        onCreate={onRefetch}
        onUpdate={onRefetch}
        user={selectedUser}
        userTypes={userTypes}
      />
      <DeleteConfirmation
        title={`Delete user`}
        content={`Are you sure you want to delete ${selectedUser?.first_name} ${selectedUser?.last_name}?`}
        onClose={() => {
          setDeletePopupOpen(false);
          setTimeout(() => setSelectedUser(null), 1000);
        }}
        onDelete={onDeleteConfirmed}
        open={deletePopupOpen} />
    </Box>
  )
}

export default Users;
