import { cashNullFormatter, decimalNullFormatter, numberNullFormatter } from "../../../../../../../utils/mui-data-grid/formatters";

export const ITEM_PLANNER_COLUMNS = {
    'Location': {
        field: 'Location Name'
    },
    'Location ID': {
        field: 'Location ID',
        hide: true
    },
    'Category': {
        field: 'Category'
    },
    'Category ID': {
        field: 'Category ID',
        hide: true
    },
    'Brand': {
        field: 'Brand Name'
    },
    'Brand ID': {
        field: 'Brand ID',
        hide: true
    },
    'Vendor': {
        field: 'Vendor Name'
    },
    'Vendor ID': {
        field: 'Vendor ID',
        hide: true
    },
    'Product Name': {
        field: 'Product Name'
    },
    'Sub Category': {
        field: 'Sub Category'
    },
    'Sub Category ID': {
        field: 'Sub Category ID',
        hide: true
    },
    'Default Cost': {
        field: 'Default Cost',
        formatter: numberNullFormatter
    },
    'Default Price': {
        field: 'Default Price',
        formatter: numberNullFormatter
    },
    'Tax Rate': {
        field: 'Tax Rate',
        formatter: numberNullFormatter
    },
    'AUV': {
        field: 'AUV',
        formatter: numberNullFormatter
    },
    'New Out': {
        field: 'New Out',
    },
    'Avg Weekly Unit Sales': {
        field: 'Avg Weekly Sales',
        formatter: numberNullFormatter
    },
    'Curr Units On Hand': {
        field: 'Current On Hand',
        formatter: numberNullFormatter
    },
    'Reorder Target On Hand': {
        field: 'Future Target On Hand',
        formatter: numberNullFormatter
    },
    'Expected Lead Time Unit Sales': {
        field: 'Lead Time Sales',
        formatter: numberNullFormatter
    },
    'Raw Reco': {
        field: 'Raw Reco',
        formatter: numberNullFormatter
    },
    'Case Rounded Reco': {
        field: 'Case Rounded Reco',
        formatter: numberNullFormatter
    },
    'Final Reco': {
        field: 'Final Reco',
        formatter: numberNullFormatter
    },
    'Weeks Of Supply': {
        field: 'WOS',
        formatter: decimalNullFormatter
    },
    'WGMROI': {
        field: 'WGMROI',
        formatter: decimalNullFormatter
    },
    'AUC': {
        field: 'AUC',
        formatter: ({ value }) => cashNullFormatter({ value }, 2)
    },
    'Cases to Order': {
        field: 'Cases to Order',
        formatter: numberNullFormatter
    },
    'Case Pack Size': {
        field: 'Case Pack Size',
        formatter: numberNullFormatter
    },
    'Total Order - Units': {
        field: 'Total Order - Units',
        formatter: numberNullFormatter
    },
    'Total Order - Cost': {
        field: 'Total Order - Cost',
        formatter: cashNullFormatter
    },
    'Reorder Notes': {
        field: 'Reorder Notes',
    },
    'Is On Order': {
        field: 'IsOnOrder'
    },
    'Is Hidden': {
        field: 'IsHide'
    },
    'Is Out Of Stock': {
        field: 'isOutOfStock'
    }
}

export const ITEM_PLANNER_HIDDEN_COLUMNS_NAMES =
    Object.entries(ITEM_PLANNER_COLUMNS)
        .reduce((hidden, [fieldName, field]) => {
            if (field.hide) { return [...hidden, fieldName] }
            return hidden;
        }, [])
