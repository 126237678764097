import { makeStyles } from "@mui/styles";
import { commonStyles } from "../../../style";

// in pixels
export const ITEM_ROW_HEIGHT = 30;

export const customCellsStyle = {
  dataGrid: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  targetCell: {
    backgroundColor: "#cdf2eb",
    color: "#3c7379",
    fontWeight: "bold",
  },
  mainCategory: {
    fontWeight: "bold",
  },
};

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: "4vh",
    paddingBottom: "8vh",
    alignItems: "center",
    flexDirection: "column",
  },
  selectWrapper: {
    margin: "10px 10px 10px 0px",
  },
  gridWrapper: {
    flex: 1,
    width: "70%",
  },
  grandTargetInput: {
    backgroundColor: "transparent",
    color: customCellsStyle.targetCell.color,
    width: "-webkit-fill-available",
    cursor: "pointer",
  },
  grandTargetInputOnScroll: {
    paddingLeft: '20px'
  },
  loadingWrapper: {
    display: "flex",
    alignItems: "center",
  },
  loadingText: {
    fontSize: "15px",
    color: "#777777",
  },
  ...customCellsStyle,
  ...commonStyles,
}));
