import { useState } from 'react';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { timeState } from '../pages/account/forecast/forecast/widgets/toolbar/time-options';
import OrgService from '../service/master/org.service';
import UserService from '../service/master/user.service';
import DistrictService from '../service/org/district.service';
import OrganisationService from '../service/organisation-service';
import {
  accessToken as accessTokenState,
  currentOrg,
  currentOrgId,
  currentUser, districts,
  locations,
  orgView,
  productTypes
} from '../store';
import Layout from './layout-wrapper/layout';
import { useDispatch } from '../context/store';
import { SUBSCRIPTIONS_ROUTES } from './store/initial-routes-subscriptions';
import { userTypesState } from '../store/admin-panel/admin-panel.store';
import LoadingScreen from '../pages/misc/loading-screen/LoadingScreen';

const ProtectedLayouts = () => {
  // state
  const [loading, setLoading] = useState(true);

  const accessToken = useRecoilValue(accessTokenState);
  if (!accessToken) {
    localStorage.clear();
    return <Navigate to="/login" />;
  }

  const orgId = useRecoilValue(currentOrgId);
  const navigate = useNavigate();
  const location = useLocation();
  const setTime = useSetRecoilState(timeState);
  const setOrgView = useSetRecoilState(orgView);
  const setLocations = useSetRecoilState(locations);
  const setDistricts = useSetRecoilState(districts);
  const setCurrentOrg = useSetRecoilState(currentOrg);
  const setCurrentUser = useSetRecoilState(currentUser);
  const setProductTypes = useSetRecoilState(productTypes);
  const setUserTypes = useSetRecoilState(userTypesState);

  // handlers
  const fetchOrg = async () => {
    try {
      const org = await OrgService.getCurrentOrg();
      setCurrentOrg(org.data);
    } catch (error) {
      localStorage.clear();
      navigate('/login');
    }
  };

  const fetchUser = async () => {
    const userTypeId = localStorage.getItem("userTypeId");
    const user = await UserService.getUser(userTypeId);
    setCurrentUser(user.data);
    window.user_permissions = user.data?.userPermissions;
    return user.data;
  };

  const fetchUserTypes = async () => {
    const userTypes = await UserService.getUserTypes();
    setUserTypes(userTypes?.data ?? []);
  };

  const fetchView = async () => {
    const { data: org } = await OrganisationService.org.getInfo(orgId);
    setOrgView(org);

    // initialize start,end quarters
    setTime((prev) => ({
      ...prev,
      startQuarter: org?.dateInfo?.[0]?.YearQuarter,
      endQuarter: org?.dateInfo?.[0]?.YearQuarter
    }));
  };

  const fetchProductTypes = async () => {
    const { data } = await OrganisationService.forecast(orgId)
      .productTypes();
    setProductTypes(data);
  };

  const fetchDistricts = async () => {
    const ALL_DISTRICTS = {
      district_id: -1,
      district_name: 'All Districts'
    };

    const { data } = await DistrictService.getDistricts();
    if (data) {
      const sortedDistricts = [ALL_DISTRICTS, ...data].sort((a, b) => a.district_name.localeCompare(b.district_name))
      setDistricts(sortedDistricts);
    }
    return data;
  }

  const fetchLocations = async () => {
    const ALL_LOCATIONS = {
      id: -1,
      location_name: 'All Locations'
    };
    const { data } = await OrganisationService.location.list(orgId, 100);
    if (data.loc) {
      const sortedLocations = data.loc.sort((a, b) => a.location_name.localeCompare(b.location_name))
      setLocations([
        ALL_LOCATIONS,
        ...sortedLocations
      ]);
    }
  };

  const dispatch = useDispatch();
  const layoutDispatch = (obj) => dispatch({ target: "layout", ...obj });

  const toShowRouteBySubscriptionId = (subscriptions, id) => {
    return subscriptions?.length === 0
      ? false
      : subscriptions?.find((subscription) => subscription.id === id)?.value ===
      "true" ?? false;
  };

  const fetchSubscriptions = async () => {
    const settingsService = OrganisationService.settings(orgId);
    const { data: orgSettings } = await settingsService.list();

    // Get only the subscribed routes to show
    const newRoutes = { ...SUBSCRIPTIONS_ROUTES };

    Object.values(newRoutes)
      .forEach((route) => {
        route.show = toShowRouteBySubscriptionId(
          orgSettings.orgSettingList,
          route.id
        );
      });

    layoutDispatch({
      type: "setSubscriptionsRoutes",
      payload: newRoutes,
    });
  }

  const fetchAll = async () => {
    const [user] = await Promise.all([
      fetchUser(),
      fetchUserTypes(),
      fetchView(),
      fetchDistricts(),
      fetchLocations(),
      fetchProductTypes(),
      fetchSubscriptions()]);

    const bottomNavModules = ['Admin', 'Support'];

    let modules = user
      ?.userPermissions
      ?.filter(m => !bottomNavModules.includes(m.name)) ?? [];

    const showReportsInNav = user?.orgSettings
      ?.find(s => s.setting_key === 'show_reports_in_navigation')
      ?.setting_value
      ?.toLowerCase() === 'true';

    if (!showReportsInNav)
      modules = modules.filter(m => m.module_type !== 'report');

    modules.sort((a, b) => {
      if (a.navigation_order === null) return 1;
      if (b.navigation_order === null) return -1;
      return a.navigation_order - b.navigation_order || a.name.localeCompare(b.name);
    });


    // if we're logging in, navigate based on the modules logic (first module)
    // otherwise, go directly to the url. This is to support both login navigation logic
    // and a page refresh.

    const { loggingIn } = location.state ?? {};

    if (loggingIn) {
      if (modules?.[0]?.link?.length > 0)
        navigate(`/account/${modules[0].link}`);
      else
        navigate(`/account`);
    }

    setLoading(false);

  };

  useEffect(() => {
    // In case user is not logged in
    fetchOrg()
      .then();
  }, [accessToken]);

  useEffect(() => {
    if (orgId) {
      fetchAll()
        .then();
    }
  }, [orgId]);

  return loading ?
     <LoadingScreen text="" /> : <Layout>
      <Outlet />
    </Layout>;
}

export default ProtectedLayouts;
