import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "fit-content",
    flexDirection: "column",
    height: "fit-content",
    border: "1.5px solid #e8e8e8",
    borderRadius: "20px",
    padding: "10px 25px 8px 15px",
    marginLeft: "10px",
    marginRight: "10px",
    fontSize: "14px",
  },
  filterText: {
    cursor: "pointer",
    paddingLeft: "10px",
  },
}));
