export const convertTreeToConfigCategories = (
  oldConfig,
  tree
) => {
  const newConfig = { ...oldConfig.categoryTargets };

  Object.keys(newConfig).forEach(([locationId]) => {
    const locationData = tree[locationId];

    newConfig[locationId] = {
      children: []
    }

    const children = Object.entries(locationData.categories);

    children.forEach(([categoryName, category]) => {
      const subChildren = Object.entries(category.subCategories);

      newConfig[locationId].children = [
        ...newConfig[locationId].children,
        {
          unit_sales: category.unit_sales,
          on_hand: category.on_hand,
          on_order: category.on_order,
          weeks_on_hand: category.woh,
          target: category.target,
          name: categoryName,
          auc: category.auc,
          categoryId: category.categoryId,
          children: subChildren.map(([subCategoryName, subCategory]) => ({
            unit_sales: subCategory.unit_sales,
            on_hand: subCategory.on_hand,
            weeks_on_hand: subCategory.woh,
            target: subCategory.target,
            name: subCategoryName,
            auc: subCategory.auc,
            categoryId: subCategory.categoryId,
          })),
        },
      ];
    });
  });

  return newConfig;
};
