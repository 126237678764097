import { makeStyles } from '@mui/styles';
import { sectionCommonStyle } from '../shared/sections-common-style';

export const useStyles = makeStyles((theme) => ({
  ...sectionCommonStyle,
  actions: {
    ...sectionCommonStyle.actions,
    justifyContent: 'start',
  },
  locationsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
}));
