// libs
import Card from '@mui/material/Card';
import { Bar } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// deps
import OrganisationService from "../../../../../service/organisation-service";

// styles
import { CardHeaderStyle, CardFooterStyle } from '../../../../../components/Card/card';
import { useRecoilValue } from 'recoil';
import { currentOrg } from '../../../../../store';

const TargetsGraph = ({ locationIds, onStartLoading = () => { }, onEndLoading = () => { } }) => {
	// state
	const [reorder, setReorder] = useState([]);
	const org = useRecoilValue(currentOrg);
	const orgId = org?.public_id;

	// categories
	const [categories, setCategories] = useState();
	const [categoryIds, setCategoryIds] = useState();

	// orders
	const [ordered, setOrdered] = useState([]);
	const [orderedCost, setOrderedCost] = useState([]);

	// units
	const [unitsOnHand, setUnitsOnHand] = useState([]);
	const [unitsOnHandTarget, setUnitsOnHandTarget] = useState([]);

	// cost
	const [costOnHand, setCostOnHand] = useState([]);
	const [costOnHandTarget, setCostOnHandTarget] = useState([]);

	// colors
	const [targetColor, setTargetColor] = useState([]);
	const [targetHoverColor, setTargetHoverColor] = useState([]);

	const [selectedTab, setSelectedTab] = useState(
		localStorage.getItem('selectedCategoryTargetWidget') === 'units' ? 'units' : 'cost');

	// handlers
	const handleSwitchChange = (event, newValue) => {
		localStorage.setItem('selectedCategoryTargetWidget', newValue);
		setSelectedTab(newValue);
	}

	// effects
	useEffect(() => {
		const loadReorder = async () => {
			const { data: reorder } = await OrganisationService
				.dashboard(orgId)
				.newReorderRecommendation(locationIds, null, 'h1');
			setReorder(reorder);
		}

		loadReorder().then(() => onEndLoading());
	}, []);

	useEffect(() => {
		format();
	}, [reorder, selectedTab]);

	// effects
	useEffect(() => {
		onStartLoading();
	}, []);


	const sort = (data) => {
		let sorted = [];

		if (selectedTab === 'units') {
			sorted = data.sort(function(a, b) {
				return ((a.target_on_hand > b.target_on_hand) ? -1 :
					((a.target_on_hand === b.target_on_hand) ? 0 : 1));
			});
		} else {
			sorted = data.sort(function(a, b) {
				return (((a.target_on_hand * a.auc) > (b.target_on_hand * b.auc)) ? -1 :
					(((a.target_on_hand * a.auc ) === (b.target_on_hand * b.auc)) ? 0 : 1));
			});
		}

		return sorted;
	}

	const filter = (data, h2_id) => {
		const _filter = item => item.h3_id === null && item.h2_id !== null;
		return (data ?? []).filter(_filter);
	}

	const format = () => {
		const filtered = filter(reorder || [], null, 'h1');
		const sorted = sort(filtered)?.slice(0,5);


		const categoryIds = sorted.map(item => item.h2_id);
		const categories = sorted.map(item => item.h2_name);

		// metrics
		const orders = sorted.map(o => o.order_qty || 0);
		const ordersCost = sorted.map(item => item.order_qty === null ? 0 :
			Math.trunc(item.order_qty * item.auc));

		// on_hand
		const units_on_hand = sorted.map(m => m.on_hand);
		const units_on_hand_target = sorted.map(t => t.target_on_hand);

		// on_hand cost
		const onHandCost = sorted.map(item => Math.trunc(item.cost_on_hand));
		const onHandCostTargets = sorted.map(item => Math.trunc(item.target_on_hand * item.auc));

		const targetColors = unitsOnHand.map((t, i) => t > unitsOnHand[i] ?
			'rgba(100, 100, 100, 1)' : 'rgba(200, 200, 200, 1)');
		const targetHoverColors = unitsOnHand.map((t, i) => t > unitsOnHand[i] ?
			'rgba(200, 200, 200, 1)' : 'rgba(240, 240, 240, 1)');

		// units
		setUnitsOnHand(units_on_hand);
		setUnitsOnHandTarget(units_on_hand_target);

		// cost
		setCostOnHand(onHandCost);
		setCostOnHandTarget(onHandCostTargets);

		// order
		setOrdered(orders);
		setOrderedCost(ordersCost);

		// categories
		setCategories(categories);
		setCategoryIds(categoryIds);

		// colors
		setTargetColor(targetColors);
		setTargetHoverColor(targetHoverColors);
	}

	// data fetching
	const options= {
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
				},
				ticks: {
					color: 'black',
				}
			},
			y: {
				beginAtZero: true,
				stacked: true,
				ticks: {
					color: 'black',
					maxTicksLimit: 6,
					callback: function(value) {
						return selectedTab === 'units' ? value.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : `${value.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
					},
				},
				title: {
					display: true,
					text: selectedTab === 'units' ? 'Units' : 'Cost',
					color: 'black'
				}
			},
		},
		plugins: {
			legend: {
				position: "bottom",
				labels: {
					font: {
						size: 14,
					},
					color: 'black',
					padding: 40,
					usePointStyle: true,
				}
			},
			font: {
				size: 24,
			},
			color: 'black',
			tooltip: {
				mode: 'nearest',
				callbacks: {
					label: function(tooltipItems, data) {
						return selectedTab === 'units' ? `  ${tooltipItems.formattedValue}` : `  ${tooltipItems.formattedValue}`;
					}
				}
			}
		},
		reponsive: false,
		maintainAspectRatio: false
	};

	const data = {
		labels: categories,
		datasets: [
			{
				label: 'Target',
				type: 'scatter',
				data: selectedTab === 'units' ? unitsOnHandTarget : costOnHandTarget,
				radius: 30,
				pointHoverRadius: 30,
				borderWidth: 4,
				pointStyle: 'line',
				pointHoverBorderWidth: 4,
				pointHoverBackgroundColor: 'rgba(200, 200, 200, 1)',
				pointHoverBorderColor: targetHoverColor,
				borderColor: targetColor,
				backgroundColor: ['rgba(100, 100, 100, 1)'],
			},
			{
				label: 'On Hand',
				data: selectedTab === 'units' ? unitsOnHand : costOnHand,
				maxBarThickness: 60,
				backgroundColor: ['rgba(24, 76, 82, 1)'],
			},
			{
				label: 'Ordered',
				data: selectedTab === 'units' ? ordered : orderedCost,
				maxBarThickness: 60,
				backgroundColor: ['rgba(133, 197, 202, 1)'],
				borderRadius: 2,
			},
		]
	};

	return (
		<Card variant="outlined" sx={{
			width: '100%',
		}}>
			<CardHeader title={
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<CardHeaderStyle>
						Category Targets
					</CardHeaderStyle>
					<ToggleButtonGroup
						color="primary"
						value={selectedTab}
						exclusive
						aria-label="tabs to switch between cost and units"
						onChange={handleSwitchChange}
					>
						<ToggleButton style={{ padding: '3px 7px' }} value="cost">Cost</ToggleButton>
						<ToggleButton style={{ padding: '3px 7px' }} value="units">Units</ToggleButton>
					</ToggleButtonGroup>
				</div>
			} />
			<CardContent>
				<div className={reorder.length !== 0 ? 'categories-data' : undefined}>
					<div>
						<Bar
							data={data}
							height={240}
							width="auto"
							options={options}
						/>
					</div>
				</div>
			</CardContent>
			<CardActions>
				<CardFooterStyle>
					<NavLink to="../targets/category" style={{ position: 'absolute', bottom: '10px', right: '10px', }}>
						VIEW CATEGORY TARGETS</NavLink>
				</CardFooterStyle>
			</CardActions>
		</Card>
	)
}

export default TargetsGraph;
