
export const ALL_LOCATIONS_OPTION = { id: -1, value: 'All Locations', name: "All Locations" };

export const TARGETS_MODE = {
    units: "units",
    cost: "cost",
};

export const GROUP_BY = {
    category: 'category',
    location: 'location',
};

export const UNITS_COLUMNS = {
    unit_sales: "unit_sales",
    on_hand: "on_hand",
    on_order: "on_order",
    woh: "woh",
    target: "target",
}

export const COST_COLUMNS = {
    cost_sales: "cost_sales",
    cost_on_hand: "cost_on_hand",
    on_order_cost: "on_order_cost",
    cost_woh: "cost_woh",
    target_cost_on_hand: "target_cost_on_hand",
}
