import { makeStyles } from "@mui/styles";
import { customCellsStyle } from "../style";

export const useStyles = makeStyles((theme) => ({
  total: {
    width: "200px",
    ...customCellsStyle.mainCategory,
    textAlign: "left",
    padding: "10px",
  },
  aggregateCell: {
    flex: 3,
    width: 0,
    userSelect: "none",
    textAlign: "right",
    padding: "10px 10px",
    "& .numeric-format-input": {
      color: "black",
    },
    "&:last-of-type": {
      ...customCellsStyle.targetCell,
    },
    ...customCellsStyle.mainCategory,
  },
}));
