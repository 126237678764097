import React, { useEffect } from "react";
import { useResetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "../../context/store";
import AuthService from "../../service/auth-service";
import { currentOrg as currentOrgState } from "../../store";

const LogOut = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const resetCurrentOrg = useResetRecoilState(currentOrgState);
    localStorage.clear()

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        sleep(1000).then(() => {
            AuthService.logout();
            dispatch({ target: "logout" });
            dispatch({ target: "layout", ...{ type: 'reset' } });

            // TODO: reset whole store
            resetCurrentOrg();

            navigate("/");
        })
    }, [])

    return <></>
}

export default LogOut
