import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    flexDirection: "column",
    "& > span": {
      fontSize: "12px",
      color: "#717171",
    },
    marginRight: '5px',
  },
  boostrapWrapper: {
    "& .MuiInputBase-input": {
      backgroundColor: "white !important",
    },
  },
}));
