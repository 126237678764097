import { Box, Typography } from "@mui/material";
import errorImg from "../../../assets/errors/error.png";

const ErrorFallback = () => {
	return (
		<Box
			role="alert"
			display="flex"
			flexDirection="column"
			padding="25px"
			width="420px"
			margin="100px auto"
		>
			<Box display="flex" justifyContent="center" marginBottom="25px">
				<img src={errorImg} alt="Something went wrong" />
			</Box>
			<Typography
				sx={{
					fontSize: "24px",
					fontWeight: 600,
					marginBottom: "20px",
					display: "flex",
					justifyContent: "center",
				}}
			>
				Oops, something went wrong.
			</Typography>
			<Box sx={{ marginBottom: "10px", color: "#454545" }}>
				<span>
					We're having a little trouble loading this right now. We've already
					alerted our support team, but if this issue persists, please reach out
					to&nbsp;
					<a
						style={{ textDecoration: "underline" }}
						href="https://support.growthsayer.com/submit-a-ticket/"
					>
						support
					</a>
					.
				</span>
			</Box>
		</Box>
	);
}

export default ErrorFallback;