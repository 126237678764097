import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

// recoil
import { BomsState, materialsState } from '../production';
import { useRecoilState, useRecoilValue } from 'recoil';

// hooks
import { useFetchBoms } from './hooks';

// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

// mui icons
import { DeleteOutline, EditRoad } from '@mui/icons-material';

// components
import { useSnackbar } from 'notistack';
import PageTitle from '../../../../components/PageTitle';

// services
import bomService from '../../../../service/org/bom.service';

export const BomDetail = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar }  = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [bomId, setBomId] = useQueryParam('id', NumberParam);
  const bomHook = useFetchBoms(BomsState);

  useEffect(async () => {
    if (!bomId) {
      setCurrentBom(prev => ({
        ...prev,
        dirty: true
      }));
    } else {
      const { data: bom } = await bomService.getBom(bomId);
      setCurrentBom(bom);
      setMaterialList(bom.items);
    }
  }, []);

  // all boms
  const [boms, setBoms] = useRecoilState(BomsState);

  // a list of all raw materials to choose from when adding
  const materials = useRecoilValue(materialsState);

  // current material being added/edited
  const [selectedMaterial, setSelectedMaterial] = useState({});

  // current bom
  const [currentBom, setCurrentBom] = useState();

  // current bom's material list
  const [materialList, setMaterialList] = useState([]);

  // handlers
  const goBack = () => {
    navigate('/account/production?tab=2');
  };

  const editMaterial = (material) => {
    if (material.new) {
      setSelectedMaterial({
        new: true
      })
    } else {
      const materialCopy = materialList?.find(m => m['product_id'] === material['product_id']);
      if (materialCopy) {
        setSelectedMaterial(
          JSON.parse(
            JSON.stringify(
              materialCopy
            )
          )
        );
      }
    }
    setIsOpen(true);
  };

  const deleteMaterial = (material) => {
      setMaterialList(materialList
          .map(m => m['product_id'] === material['product_id']
          ? { ...m, deleted: true}
          : m
      ));
  };

  const updateMaterial = (field, value) => {
    setSelectedMaterial(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const updateBom = (field, value) => {
    setCurrentBom(prev => ({
      ...prev,
      [field]: value
    }))
  };

  const apiCreateNewBom = async () => {
    delete currentBom.dirty;
    const newBom = {
      "case_rounding": 0,
      "in_use": true,
      "min_production_qty" : 0,
      "production_lead_time" : 0,
      "items": materialList,
      ...currentBom,
      "bom_id": currentBom?.['bom_id']
    }
    setCurrentBom(newBom);

    // send to api
    await bomService.createBom(newBom);

    setBoms(prev => [
      ...prev,
      newBom
    ]);
  };

  const apiUpdateBom = async () => {
    const bomWithItems = {
      ...currentBom,
      items: materialList
    }
    const bomList = boms.map(b => b['bom_id'] === bomId ? bomWithItems : b);
    setBoms(bomList)
    await bomService.updateBom(bomWithItems.id, bomWithItems);
  };


  const saveBOM  = async () => {
    if (currentBom.dirty) {
      await apiCreateNewBom();
    } else {
      await apiUpdateBom();
    }

    enqueueSnackbar("BOM saved.", {
      variant: 'success'
    });

    await bomHook.refresh();
  };

  const resetBom = () => {
    const originalBom = boms?.find(b => b['bom_id'] === bomId);
    setCurrentBom(originalBom);

    const origMaterialList = data?.filter(b => b['bom_id'] === bomId);
    setMaterialList(origMaterialList);
  };

  const updateMaterials = async () => {
    if (!selectedMaterial) return;
    setIsOpen(false);

    if (selectedMaterial.new) {
      const newMaterial = materials?.find(m => m['product_id'] === selectedMaterial['product_id']);
      setMaterialList(prev => [
        ...prev,
        {
          id: crypto.randomUUID(),
          new: true,
          unit_of_measurement: newMaterial.unit_of_measurement,
          case_rounding: newMaterial.case_rounding,
          distributor_name: newMaterial.distributor_name,
          category: newMaterial.category,
          sub_category: newMaterial.sub_category,
          moq: newMaterial.moq,
          lead_time: newMaterial.lead_time,
          product_id: newMaterial.id,
          product_name: newMaterial.product_name,
          bom_id: currentBom?.bom_id,
          qty: selectedMaterial.qty,
          override_cost: selectedMaterial.override_cost,
          override_extra_purchase: selectedMaterial.override_extra_purchase
        }
      ]);
    } else {
      const newMaterialList = materialList.map(m => m.id === selectedMaterial.id ? ({
        ...m,
        qty: selectedMaterial.qty,
        override_cost: selectedMaterial.override_cost,
        override_extra_purchase: selectedMaterial.override_extra_purchase
      }) : m);
      setMaterialList(newMaterialList);
    }
    setSelectedMaterial(null);
  };

  const columns = [
    {
      field: 'product_id',
      headerName: 'Material ID',
      width: 80
    },
    {
      field: 'product_name',
      headerName: 'Material Description',
      width: 240,
    },
    {
      field: 'Type',
      headerName: 'Type',
      width: 80,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 80,
    },
    {
      field: 'sub_category',
      headerName: 'Sub Category',
      width: 100,
    },
    {
      field: 'distributor_name',
      headerName: 'Vendor',
      width: 90,
    },
    {
      field: 'qty',
      headerName: 'Qty',
      width: 50
    },
    {
      field: 'unit_of_measurement',
      headerName: 'UOM',
      width: 80
    },
    {
      field: 'extra_purchase',
      headerName: 'Extra Purchase',
      width: 80,
      renderCell: (params) => {
        return <span>
          {params.row.override_extra_purchase ?? params.row.extra_purchase}
        </span>
      }
    },
    {
      field: 'moq',
      headerName: 'MOQ',
      width: 50
    },
    {
      field: 'lead_time',
      headerName: 'Lead Time',
      width: 70
    },
    {
      field: 'case_rounding',
      headerName: 'Case Rounding',
      width: 80
    },
    {
      field: 'override_cost',
      headerName: 'Extra Cost',
      width: 50
    },
    {
      field: 'actions',
      width: 200,
      headerName: 'Actions',
      renderCell: (params) => {
        return <div style={{ color: '#868686', display: 'flex', columnGap: '5px', cursor: 'pointer' }}>
          <EditRoad  onClick={() => {
            editMaterial(params.row)
          }} />
          <DeleteOutline onClick={() => deleteMaterial(params.row)} />
        </div>
      }
    }
  ];

  return <Stack style={{ padding: '25px', flex: 1 }}>
    <Box display="flex"
         justifyContent="space-between"
         sx={{
            position: 'relative',
            marginBottom: '20px'
          }}>
      <Box>
        <Box sx={{ position: 'absolute', right: 20, display: 'flex', columnGap: '30px' }}>
          <a onClick={goBack} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'arrow_back'}</i>
            <span className='d-block'>Back</span>
          </a>
          <a
            onClick={saveBOM}
            style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'save'}</i>
            <span className='d-block'>Save</span>
          </a>
          <a style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'get_app'}</i>
            <span className='d-block'>Export</span>
          </a>
          <a onClick={resetBom} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'restore'}</i>
            <span className='d-block'>Reset</span>
          </a>
        </Box>
        <PageTitle>
          Production
        </PageTitle>
        <div style={{ marginTop: '25px' }}>
          <span style={{ color: 'rgb(99,99,99)', fontSize: '20px', fontWeight:' bold' }}>
          Bill of Materials
          </span>
        </div>
      </Box>
    </Box>
    <Box style={{ marginBottom: '20px', marginTop: '20px' }}>
      <div style={{ display: 'flex', columnGap: '35px' }}>
        <TextField label="BOM Description" variant="outlined"
                   value={currentBom?.['description'] ?? ''}
                   size="small"
                   style={{ width: '350px' }}
                   onChange={(e) => {
                     updateBom('description', e.target.value)
                   }}/>
        <FormControlLabel
          control={<Checkbox
            checked={!!currentBom?.['in_use']}
            onChange={(e, checked) => {
              updateBom('in_use', checked)
            }}
          />}
          label="In Use"
          labelPlacement="end"
        />
      </div>
    </Box>
    <Box style={{ marginBottom: '30px', marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', columnGap: '20px' }}>
        <TextField label="Prod Lead Time" variant="outlined"
                   value={currentBom?.['production_lead_time'] ?? 0}
                   size="small"
                   style={{ width: '120px' }}
                   onChange={(e) => {
                     updateBom('production_lead_time', e.target.value)
                   }}/>
        <TextField label="Min Prod Qty" variant="outlined"
                   value={currentBom?.['min_production_qty'] ?? 0}
                   size="small"
                   style={{ width: '100px' }}
                   onChange={(e) => {
                     updateBom('min_production_qty', e.target.value)
                   }}/>
        <TextField label="Unit of Meas" variant="outlined"
                   value={currentBom?.['unit_of_measurement'] ?? 0}
                   size="small"
                   style={{ width: '100px' }}
                   onChange={(e) => {
                     updateBom('unit_of_measurement', e.target.value)
                   }}/>
        <TextField label="Case Round" variant="outlined"
                   value={currentBom?.['case_rounding'] ?? 0}
                   size="small"
                   style={{ width: '100px' }}
                   onChange={(e) => {
                     updateBom('case_rounding', e.target.value)
                   }}/>
      </div>
      <div>
        <div
          onClick={() => {
            editMaterial({
              new: true
            });
          }}>
          <a style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '13px',
          }}>
            <i className='material-icons navmenu-icon'>{'add_circle_outline'}</i>
            <span className='d-block'>Add Material</span>
          </a>
        </div>
      </div>
    </Box>
    <DataGrid
      compact={true}
      rows={materialList?.filter(m => !m.deleted)}
      columns={columns}
      density="compact"
      hideFooter
      autoHeight={true}
      headerHeight={90}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1,
        }
      }}
    />
    <div
      onClick={() => editMaterial(null)}
      style={{
        position: 'absolute',
        top: 0,
        right: 25
      }}>
      <a style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '13px',
      }}>
        <i className='material-icons navmenu-icon'>{'add_circle_outline'}</i>
        <span className='d-block'>New Material</span>
      </a>
    </div>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}>
      <div style={{
        backgroundColor: 'white',
        zIndex: 999,
        borderRadius: '2px',
        padding: '35px 30px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '30px'
      }}>
        { selectedMaterial?.new && <Autocomplete
          size="small"
          disablePortal
          id="location"
          onChange={(_, newValue) => {
            setSelectedMaterial(prev => ( {
              ...prev,
              product_id: newValue.id,
            }));
          }}
          options={materials?.map(m => ({
            ...m,
            value: m['product_id'],
            label: m['product_name']
          }))}
          renderInput={(params) => (
            <TextField
              sx={{ width: "400px" }}
              {...params}
              label="Select Material"
            />
          )}
        /> }
        {
          !selectedMaterial?.new && <span>
          { selectedMaterial?.product_name }
          </span>
        }
        <TextField label="Quantity"
                   size="small"
                   value={selectedMaterial?.qty ?? 0}
                   onChange={(e) => {
                     updateMaterial('qty', +e.target.value);
                   }} />
        <TextField label="Extra Purchase"
                   size="small"
                   value={selectedMaterial?.override_extra_purchase ?? 0}
                   onChange={(e) => {
                     updateMaterial('override_extra_purchase', +e.target.value);
                   }} />
        <TextField label="Extra Cost"
                   size="small"
                   value={selectedMaterial?.override_cost ?? 0}
                   onChange={(e) => {
                     updateMaterial('override_cost', +e.target.value);
                   }} />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={updateMaterials}
            disabled={!(selectedMaterial?.qty > 0 && !!selectedMaterial?.['product_id'])}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  </Stack>
}

export default BomDetail;
