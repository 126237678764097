// deps
import React, { useEffect, useState } from "react";

// libs
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';


const ClassSelector = ({ serviceProvider, onSelect }) => {
	const [menu, setMenu] = useState({});
	const [loading, setLoading] = useState(true);

	// handlers
	const selectNode = (h2, h3) => {
		onSelect(h2, h3)
	};

	// data fetching
	const fetchClassSelector = async () => {
		setLoading(true);

		try {
			const { data } = await serviceProvider.productTypes();
			const products = data.hierarchy.children[0];
			setMenu(products);
		} catch (e) {

		} finally {
			setLoading(false)
		}
	};

	// life cycle
	useEffect(() => {
		fetchClassSelector()
			.then()
	}, []);

	return <div>
		{loading && <CircularProgress />}
		{!loading && <TreeView
			aria-label="Class Selector"
			defaultCollapseIcon={<ExpandMoreIcon />}
			defaultExpandIcon={<ChevronRightIcon />}
			sx={{
				flexGrow: 1,
				maxWidth: 400,
				overflowY: 'auto'
			}}
		>
			<TreeItem nodeId={menu.id} label={menu.name}>
				{
					menu?.children?.map(child => {
						return <TreeItem nodeId={child?.id} label={child?.name}>
							{
								child?.children?.map(grandChild => {
									return <TreeItem nodeId={grandChild?.id} onClick={() => selectNode(child, grandChild)}
										label={<span>
											{grandChild?.name}
										</span>
										} />
								})
							}
						</TreeItem>
					})
				}
			</TreeItem>
		</TreeView>
		}
	</div>
}

export default ClassSelector
