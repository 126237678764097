import API from "../api"

const VendorService = {
  getVendors: () => API.get(`org/vendor`),
  updateVendor: (vendorId, newValues) => API.put(`org/vendor/${vendorId}`, newValues),
  deleteVendor: (vendorId) => API.delete(`org/vendor/${vendorId}`),
  createVendor: (vendor) => API.post(`org/vendor`, vendor),
};

export default VendorService;
