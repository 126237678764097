import { atom } from 'recoil';

export const optimizedPercentage = atom({
  key: 'roi_booster_optimized_percentage',
  default: "0",
});

export const totalValue = atom({
  key: 'roi_booster_total_value',
  default: 0,
});

export const rowCount = atom({
  key: 'roi_booster_row_count',
  default: 25,
});

export const totalRecordCount = atom({
  key: 'roi_booster_total_record_count',
  default: 0,
});

export const boosters = atom({
  key: 'roi_booster_boosters',
  default: [],
  set: ({ set }, filteredBoosters) => {
    set(boosters, filteredBoosters);
    set(totalRecordCount, filteredBoosters.length);
  },
});

export const currentPageNumber = atom({
  key: 'roi_booster_current_page_number',
  default: 1,
});




