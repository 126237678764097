import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, NavLink } from "react-router-dom";

// services
import AuthService from "../../../service/master/auth.service";
import OrganisationService from "../../../service/organisation-service";

// styles
import { useStyles } from "./style";
import { FieldsWrap } from "./style.js";
import { ChevronRight } from "react-feather";
import logo from "../../../assets/gs-vertical-logo-on-light-bg-cropped.png";


// mui
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";

const ForgotPassword = () => {
  const classes = useStyles();
  const [successfullySent, setSuccessfullySent] = useState(false);

  const [formData, updateFormData] = useState({
    email: "",
  });

  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);

  const updateField = (evt) => {
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleFormSubmission = async (evt) => {
    evt.preventDefault();
    if (!formData.email?.length) return;

    try {
      setSending(true);
      const res = await AuthService.forgotPassword(formData.email);
      setSuccessfullySent(true);
    } catch (e) {
      const { response } = e;
      const message = response?.data?.error?.message || "Unknown error";
      enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    } finally {
      setSending(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <form onSubmit={handleFormSubmission}>
          <Card
            className="text-center"
            style={{
              border: "0px",
              borderRadius: "8px",
              width: "330px",
              boxShadow: "rgb(17, 17, 24) 0px 0px 40px 4px",
            }}
          >
            <CardHeader
              style={{
                backgroundColor: "red",
                background: "#eaeaea",
              }}
              title={<img src={logo} alt="GS Logo" />}
            />
            <CardContent>
              {!successfullySent && <div
                style={{
                  backgroundColor: "inherit",
                  border: "0px",
                  borderRadius: "8px 8px 0 0",
                  fontSize: "20px",
                  marginBottom: "1.2rem",
                }}
              >
                Forgot Password
              </div> }
              { !successfullySent && <FieldsWrap>
                <FormGroup>
                  <div>
                    <TextField
                      type="text"
                      name="email"
                      size="small"
                      label="Email"
                      onChange={updateField}
                      style={{
                        borderColor: "#f1f1f1",
                        width: "100%",
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div>
                  </div>
                </FormGroup>
              </FieldsWrap> }
              { successfullySent && <div>
                <span>
                  Success! Your password reset link has been sent to your email.
                </span>
              </div> }
            </CardContent>
            { !successfullySent && <CardActions
              style={{
                backgroundColor: "inherit",
                borderRadius: "0 0 8px 8px",
                padding: "0px",
              }}
            >
              <TextField
                id="do-login"
                name="do-login"
                className="d-none"
                type="submit"
                disabled={sending}
              />
              <FormLabel
                htmlFor="do-login"
                className={`theme-button text-center btn btn-block ${sending ? "disabled" : ""
                  }`}
                style={{
                  borderRadius: "0 0 8px 8px",
                  color: "white",
                  marginBottom: "0px",
                  padding: "25px 19px",
                }}
              >
                {sending ? (
                  <>
                    <CircularProgress size="sm" style={{ marginBottom: "2px" }} />{" "}
                    SENDING RESET LINK
                  </>
                ) : (
                  <>
                    SEND RESET LINK
                    <ChevronRight size={18} style={{ marginTop: "-3px" }} />
                  </>
                )}
              </FormLabel>
            </CardActions> }
          </Card>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
