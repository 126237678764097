import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  mainWrap: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '25px'
  },
}));
