
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export const DRAWER_WIDTH = 230;

export const openedMixin = (theme) => ({
    width: DRAWER_WIDTH,
    background: "#F4F4F4",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});
export const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    background: "#F4F4F4",
    width: `calc(${theme.spacing(7)} - 4px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} - 4px)`,
    },
});


export const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    boxShadow: "1px 0 13px -5px rgb(0 0 0 / 50%)",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
