import API from "../api"

const ProductService = {
  getProducts: () => API.get(`org/product`),
  getFinishedGoods: () => API.get(`org/product/finished-goods`),
  updateProduct: (productId, newValues) => API.put(`org/product/${productId}`, newValues),
  deleteProduct: (productId) => API.delete(`org/product/${productId}`),
  createProduct: (product) => API.post(`org/product`, product),
};

export default ProductService;
