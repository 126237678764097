import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../assets/lotties/growthsayer_loader.json';

const Loader = () => {
    const lottieDefaultOptions = {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <div style={{
            background: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
        >
            <Lottie
                options={lottieDefaultOptions}
                animationData={animationData}
                style={{
                  width: 200,
                  height: 200
                }}
                loop={true}
                isClickToPauseDisabled
            />
        </div>
    )
}

export default Loader
