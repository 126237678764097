// libs
import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import './style.css';

// deps
import { useRecoilState, useRecoilValue } from 'recoil';

// atoms
import { currentClassIds, pathState } from '../../forecast.store';
import { productTypes as productTypesState } from '../../../../../../store';
import Collapse from "@mui/material/Collapse";
import { ChevronRight } from "react-feather";

const getLeafNodeIds = (node) => {
  let leafNodeIds = [];
  if (node?.children?.length === 0) {
    // If the node has no children, it is a leaf node
    leafNodeIds.push(node.id);
  } else {
    // If the node has children, recursively get the leaf node ids for each child
    for (const child of (node?.children ?? [])) {
      leafNodeIds = leafNodeIds.concat(getLeafNodeIds(child));
    }
  }
  return leafNodeIds;
}

const buildPath = (raw, nodeId) => {
  if (!nodeId) return [null];

  let currentNodeId = nodeId;
  const path = [currentNodeId];
  while (currentNodeId !== null) {
    const node = raw.find(m => m.id === currentNodeId);
    path.push(node.parent_id);
    currentNodeId = node.parent_id;
  }
  return path.reverse();
};

function RecursiveTree({ node }) {
  const [open, setOpen] = useState(!node?.id || node.id === 1);
  const [classId, setClassId] = useRecoilState(currentClassIds);
  const [path, setPath] = useRecoilState(pathState);
  const classMenu = useRecoilValue(productTypesState);

  const onEntering = () => {
    setClassId({
      classId: getLeafNodeIds(node),
      hierarchyName: node?.name,
      parentId: node?.parent_id
    });

    const newPath = buildPath(classMenu.raw, node.id);
    setPath(newPath);
  };

  const isHighlighted = path?.indexOf(node?.id) > -1;

  return (
    <>
      <div
        style={{
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500,
          fontSize: '16px'
        }}
        onClick={() => setOpen(!open)}>
        { node?.children?.length > 0 && <span>
            {open ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
          </span>
        }
        <span style={{
          marginLeft: node?.children?.length === 0 ? '20px' : 0,
          fontStyle: isHighlighted ? 'italic' : 'normal',
          color: isHighlighted ? 'rgb(21, 165, 143)' : ''
        }}>
          {node?.name}
          </span>
      </div>
      <Collapse in={open} style={{marginLeft: '10px'}} onEntering={onEntering} onExiting={onEntering}>
        {node?.children?.map(child => (
          <RecursiveTree key={child?.id}
                         node={child} />
        ))}
      </Collapse>
    </>
  );
}

const ClassMenu = () => {
  const classMenu = useRecoilValue(productTypesState);
  const [classId, setClassId] = useRecoilState(currentClassIds);

  useEffect(() => {
    setClassId({
      classId: getLeafNodeIds(classMenu.hierarchy),
      hierarchyName: 'All',
      parentId: null
    });
  }, [classMenu]);

  return (
    <div className="categories_menu" style={{ cursor: 'pointer' }}>
      <RecursiveTree node={classMenu.hierarchy} />
    </div>
  );
};

export default ClassMenu;
