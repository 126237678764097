import API from "../api"

const AuthService = {
  login: (loginData) => API.post(`master/auth/signin`, loginData),
  setPassword: (newPassword) => API.post(`master/auth/set-password`, { newPassword }),
  forgotPassword: (email) => API.post(`master/auth/forgot-password`, { email }),
  validateResetPasswordRequest: (resetRequestId) => API.post(`master/auth/validate-reset-request`, { resetRequestId }),
  resetPassword: (resetRequestId, newPassword) => API.post(`master/auth/reset-password`, { resetRequestId, newPassword })
};

export default AuthService;
