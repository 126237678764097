import { atom, selector } from 'recoil';
import { LAST_VISITED_STEP_KEY } from './local-storage-keys';

export const lastVisitedStep = atom({
  key: 'reorder_pro_last_visited_step',
  default: localStorage.getItem(LAST_VISITED_STEP_KEY) ?
    Number(localStorage.getItem(LAST_VISITED_STEP_KEY))
    : 0
});

export const lastVisitedStepState = selector({
  key: 'last_visited_step',
  get: ({ get }) => get(lastVisitedStep),
  set: ({ set }, newVisitedStep) => {
    localStorage.setItem(LAST_VISITED_STEP_KEY, newVisitedStep)
    set(lastVisitedStep, newVisitedStep)
  },
});

// Item Planner
export const reorderItemsState = atom({
  key: 'reorder_pro_items',
  default: []
});





