import { ITEM_PLANNER_COLUMNS } from "../../utils/item-planner-columns";

export const GROUP_BY_FIELDS = [
  {
    value: ITEM_PLANNER_COLUMNS['Location'].field,
    display: 'Location',
    visible: true
  },
  {
    value: ITEM_PLANNER_COLUMNS['Category'].field,
    display: 'Category',
    visible: true
  },
  {
    value: ITEM_PLANNER_COLUMNS['Vendor'].field,
    display: 'Vendor',
    visible: false
  },
  {
    value: ITEM_PLANNER_COLUMNS['Brand'].field,
    display: 'Brand',
    visible: false
  },
  {
    value: ITEM_PLANNER_COLUMNS['Product Name'].field,
    display: 'Product Name',
    visible: false
  }
]

export const getGroupedFieldsValues = (fields = GROUP_BY_FIELDS) => {
  return fields.reduce((grouped, field) => {
    if (field.visible) {
      return grouped.concat(field.value)
    }
    return grouped
  }, [])
}