// libs
import Skeleton from '@mui/material/Skeleton';
import Currency from 'react-currency-input-field';
import React, { useEffect } from 'react';

import './style.css';

import { useRecoilState, useRecoilValue } from 'recoil';
import { Accordion, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import { unitComparisonState } from '../toolbar/unit-comparison';
import { metrics } from '../../../../reports/recap/metric-groups';
import { toPercent } from '../../../../../../utils/textFormatter';

import {
  outputs,
  draftState,
  changesState,
  columnsState,
  fetchingState,
  forecastState,
  versionsState,
  selectedVersionsState,
  metricsDisplayedState
} from '../../forecast.store';
import { timeState } from '../toolbar/time-options';

const metricFormats = {};
(metrics || []).map((metric) => {
  metricFormats[metric.value] = {
    format: metric.format,
    precision: metric.precision
  };
});

const TableSkeleton = () => {
  return Array.from({ length: 15 }).map((a, i) => <Skeleton key={`skeleton_${i}`} height={30} />);
};

const ForecastTable = () => {
  const { granularity } = useRecoilValue(timeState);
  const forecast = useRecoilValue(forecastState);
  const versions = useRecoilValue(versionsState);
  const [draft, setDraft] = useRecoilState(draftState);
  const fetching = useRecoilValue(fetchingState);
  const metricGroups = useRecoilValue(metricsDisplayedState);
  const [columns, setColumns] = useRecoilState(columnsState);
  const [changes, setChanges] = useRecoilState(changesState);
  const metricsDisplayed = useRecoilValue(metricsDisplayedState);
  const selectedVersions = useRecoilValue(selectedVersionsState);
  const unitComparison = useRecoilValue(unitComparisonState);

  const mainVersion = versions?.find(v => v.key === selectedVersions.main);
  const compareVersion = versions?.find(v => v.key === selectedVersions.compare);

  useEffect(() => {
    if (forecast?.data) {
      setDraft(formatData(forecast.data, 0, forecast.columns.length));
      setColumns(forecast.columns);
    }
  }, [forecast]);

  const canEditCell = (timeKey, granularity) => {
    if (granularity === 'week_id') return timeKey >= forecast?.currentWeekId;
    if (granularity === 'month_id') return timeKey > forecast?.currentMonthId;
    return false;
  }

  const TableHeader = () => (
    <>
      <thead>
        <tr>
          <th colSpan="2" />
          {columns.map((column, i) => {
            return <th key={`th_${column?.value}`} onMouseEnter={() => hoverColumn(i)}>{column?.display}</th>;
          })}
        </tr>
      </thead>
    </>
  );

  const clearHover = () => {
    for (let i = 0; i < 14; i += 1) {
      const cellsToReset = document.getElementsByClassName(`hover_group_${i}`);
      Array.prototype.forEach.call(cellsToReset, (cell) => {
        cell.classList.remove('hoverMe');
      });
    }
  };

  const hoverColumn = (colIndex) => {
    clearHover();
    // hover column
    const cellsToHover = document.getElementsByClassName(
      `hover_group_${colIndex}`
    );
    Array.prototype.forEach.call(cellsToHover, (cell) => {
      cell.classList.add('hoverMe');
    });
  };

  const zeroCoalesce = (number) => (Number.isNaN(number) ? 0 : number);

  const handleKeyUp = (e, metric, weekIdIndex) => {
    if (e.key === 'Enter') {
      e.target.blur();
    } else if (e.key === 'Escape') {
      const copy = JSON.parse(JSON.stringify(draft));
      copy[weekIdIndex].main[metric.value] = forecast.data?.[weekIdIndex]?.main[metric?.value];
      setDraft(copy);
    }
  };

  const formatData = (data, startTimeKey, endTimeKey) => {
    // reevaluate table
    const copy = JSON.parse(JSON.stringify(data));

    for (let c = startTimeKey; c < endTimeKey; c++) {
      const timePeriod = copy[c];

      // unit_sales = revenue / auv
      timePeriod.main.unit_sales = zeroCoalesce(
        timePeriod.main?.auv === 0 ? 0 : timePeriod.main?.revenue / timePeriod?.main?.auv
      );

      timePeriod.main.receipts = zeroCoalesce(
        !timePeriod.main.auc_inventory ? 0 : timePeriod.main.cost_receipts / timePeriod.main.auc_inventory
      );

      // $gm = revenue - cost_sales
      timePeriod.main.gross_margin = zeroCoalesce(
        timePeriod.main.revenue - timePeriod.main.auc_sales * timePeriod.main.unit_sales
      );

      // gm% = (revenue - cost_sales) / revenue
      timePeriod.main.gross_margin_pct = zeroCoalesce(
        (timePeriod.main.revenue - timePeriod.main.auc_sales * timePeriod.main.unit_sales) / timePeriod.main.revenue
      );

      // // on_hand = previous week's on_hand + receipts - unit_sales
      for (let i = c; i < columns.length; i++) {
        const currTimePeriod = copy[i];
        const prevTimePeriod = copy[i - 1];
        if (!currTimePeriod || !prevTimePeriod) continue;
        const newValue = prevTimePeriod.main.on_hand + currTimePeriod.main.receipts - currTimePeriod.main.unit_sales;
        currTimePeriod.main.on_hand = zeroCoalesce(newValue);
      }

      // on_hand (cost) = auc_inventory * on_hand
      timePeriod.main.cost_on_hand = zeroCoalesce(timePeriod.main.auc_inventory * timePeriod.main.on_hand);

      // weeks_on_hand (cost) = auc_inventory * unit sales
      timePeriod.main.cost_weeks_on_hand = zeroCoalesce(
        timePeriod.main.cost_on_hand / (timePeriod.main.auc_inventory * timePeriod.main.unit_sales)
      );
    }
    return copy;
  };

  const onChange = (newValue, metric, weekIdIndex) => {
    const copy = JSON.parse(JSON.stringify(draft));
    const week = copy[weekIdIndex];

    // update value in input
    week.main[metric?.value] = zeroCoalesce(newValue);
    setDraft(copy);
  };

  const onBlur = (newValue, metric, weekIdIndex) => {
    const copy = JSON.parse(JSON.stringify(draft));

    // revert to original value if the cell is left empty and blurred out of.
    if (newValue === '') {
      copy[weekIdIndex].main[metric.value] = forecast.data?.[weekIdIndex]?.main[metric?.value];
    }

    const copyModified = formatData(copy, weekIdIndex, weekIdIndex + 1);

    // recalculate table
    setDraft(copyModified);
  };

  useEffect(() => {
    calculateChanges();
  }, [draft]);

  const calculateChanges = () => {
    // changes are always made to the main version, you cannot submit to the compare version.
    let changesDetected = [];

    for (let m = 0; m < metricGroups?.length; m++) {
      // skip output fields, we only track changes for input fields (editable boxes)
      if (outputs.indexOf(metricGroups[m].value) > -1) continue;
      for (let r = 0; r < draft?.length; r++) {
        // convenience
        const timeKey = draft[r] ?? data[r][granularity];

        // also skip historical weeks, edits can only be made to future forecasts
        // if (timeKey < forecast?.currentMonthId) continue;

        const forecastOriginalValue = Number(forecast?.data[r].main[metricGroups[m].value]);
        const draftValue = Number(draft[r].main[metricGroups[m].value]);

        // key changes by week and metric
        const key = `${metricGroups[m].value}_${granularity}`;

        if (forecastOriginalValue !== draftValue) {
          setChanges(changes.filter((c) => c.key === key));
          changesDetected.push({
            key,
            metric: metricGroups[m],
            oldValue: forecastOriginalValue,
            newValue: draftValue,
            timeKey,
            main: draft[r]?.main,
            [timeKey]: columns?.find((c) => c.value === timeKey)?.display
          });
        }
      }
    }

    setChanges(changesDetected);
  };

  const renderTable = () => {
    return (
      <div style={{ padding: '5px 15px', overflow: 'auto' }} onMouseLeave={clearHover}>
        <table id="forecast-table" data-testid="forecast_table" className="text-right" style={{ fontSize: '12px' }}>
          <TableHeader />
          <tbody>
            {metricsDisplayed
              .filter((m) => m.visible)
              .map((metricDisplayed, index) => {
                const metric = metrics.find((m) => m?.value === metricDisplayed?.value);

                if (!metric) return;

                return (
                  <React.Fragment key={`tr_fragment${metric.value}`}>
                    <tr key={`${index}_main`}>
                      <th
                        key={`main_header_${metric.display}`}
                        rowSpan={
                          2 +
                          (unitComparison.percentage ? 1 : 0) +
                          (unitComparison.absolute ? 1 : 0)
                        }
                        className="text-left row-header">
                        {metric.display}
                      </th>
                      <td
                        key={`main_version_${metric.value}`}
                        className="text-left text-muted second-heading">
                        {mainVersion.value}
                      </td>
                      {draft?.map((timePeriod, idx) => {
                        if (
                          canEditCell(timePeriod[granularity], granularity) &&
                          outputs.indexOf(metric.value) === -1 &&
                          mainVersion.key === 'WP'
                        ) {
                          return (
                            <td
                              onMouseEnter={() => hoverColumn(idx)}
                              className={`cell hover_group_${idx}`}
                              key={`edit_${metric.value}_${columns[idx]?.value}`}>
                              <Currency
                                prefix="$"
                                key={`input_${metric.value}_${columns[idx]?.value}`}
                                className="curr"
                                onValueChange={(number, name) => {
                                  onChange(number, metric, idx);
                                }}
                                onBlur={(e) => {
                                  onBlur(e.target.value, metric, idx);
                                }}
                                onKeyUp={(e) => handleKeyUp(e, metric, idx)}
                                value={timePeriod.main[metric.value]}
                              />
                            </td>
                          );
                        } else {
                          return (
                            <td
                              onMouseEnter={() => hoverColumn(idx)}
                              key={`main_${metric.value}_${columns[idx]?.value}`}
                              className={`cell readonly hover_group_${idx}`}>
                              {metricFormats[metric.value].format(timePeriod.main[metric.value])}
                            </td>
                          );
                        }
                      })}
                    </tr>
                    <tr
                      key={`${index}_compare`}
                      style={
                        !unitComparison.percentage && !unitComparison.absolute
                          ? { borderBottom: 'solid 1px black' }
                          : {}
                      }>
                      <td
                        key={`compare_version_${metric.value}`}
                        className="text-left text-muted second-heading">
                        {compareVersion.value}
                      </td>
                      {draft?.map((timePeriod, c) => {
                        return (
                          <td
                            onMouseEnter={() => hoverColumn(c)}
                            key={`compare_${metric.value}_${c}`}
                            className={`cell readonly hover_group_${c}`}>
                            <div>
                              {metricFormats[metric.value].format(timePeriod.compare[metric.value])}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                    {unitComparison.percentage && (
                      <tr
                        key={`${index}_diff_pct`}
                        style={
                          unitComparison.percentage && !unitComparison.absolute
                            ? { borderBottom: 'solid 1px black' }
                            : {}
                        }>
                        <td
                          key={`diff_pct_${metric.value}`}
                          className="text-left text-muted second-heading">
                          % Difference
                        </td>
                        {draft?.map((timePeriod, t) => {
                          const main = timePeriod.main[metric.value];
                          const compare = timePeriod.compare[metric.value];

                          return (
                            <td
                              onMouseEnter={() => hoverColumn(t)}
                              className={`cell readonly hover_group_${t}`}
                              key={`diff_pct_${metric.value}_${t}`}>
                              {toPercent(
                                !compare ? 0 : (main - compare) / Math.abs(compare),
                                1,
                                100
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                    {unitComparison.absolute && (
                      <tr
                        key={`${index}_diff_abs`}
                        style={
                          unitComparison.percentage ? { borderBottom: 'solid 1px black' } : {}
                        }>
                        <td
                          key={`diff_abs_${metric.value}`}
                          className="text-left text-muted second-heading">
                          Amount Diff.
                        </td>
                        {draft?.map((timePeriod, t) => {
                          const main = timePeriod?.main?.[metric.value]
                          const compare = timePeriod?.compare?.[metric.value]
                          const diff = zeroCoalesce(main - compare);

                          return (
                            <td
                              className={`cell readonly hover_group_${t}`}
                              key={`diff_abs_${metric.value}_${t}`}>
                              {metricFormats[metric.value].format(diff)}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="widget">
      <Accordion sx={{ boxShadow: 'none' }} defaultExpanded={true}>
        <AccordionSummary
          sx={{
            textTransform: 'uppercase',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
          expandIcon={<ExpandMoreIcon />}>
          FORECAST TABLE
        </AccordionSummary>
        <AccordionDetails>
          {fetching ? (
            <TableSkeleton />
          ) : draft?.length == 0 ? (
            <span className="d-block text-center p-3">There are no records to display</span>
          ) : (
            renderTable()
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ForecastTable;
