// libs
import React from 'react';

// deps
import { useStyles, MinusSquare, PlusSquare, StyledTreeItem } from './styles';
import TreeView from '@mui/lab/TreeView';
import GrandTotal from './GrandTotals';
import LevelOne from './LevelOne';
import LevelTwo from './LevelTwo';
import Headers from './Headers';

const DataGrid = ({
    metricGroups,
    data,
    levelOneNameField,
    levelTwoNameField,
    MainText,
    CompareText,
    VarianceText,
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const children = ((data && data.children) || []).sort((a, b) => {
        const cat1 = a[levelOneNameField].toUpperCase();
        const cat2 = b[levelOneNameField].toUpperCase();
        return (cat1 < cat2) ? -1 : (cat1 > cat2) ? 1 : 0;
    });

    return <TreeView
        className={classes.root}
        defaultExpanded={[]}
        expanded={expanded}
        onNodeToggle={handleToggle}
        defaultCollapseIcon={<MinusSquare/>}
        defaultExpandIcon={<PlusSquare/>}
        defaultEndIcon={null}
    >
        <StyledTreeItem nodeId="headers" label={<Headers
            MainText={MainText}
            CompareText={CompareText}
            VarianceText={VarianceText}
            fields={metricGroups}/>}/>
        {
            children.map((l2, i) => {
                return <StyledTreeItem nodeId={`ti_${i}`}
                                       label={<LevelOne
                                           levelName={levelOneNameField}
                                           fields={metricGroups}
                                                        data={l2}/>}>
                    {
                        (l2.children || [])
                        .map((l3, p) =>  {
                            return <LevelTwo key={`l2k_${p}_${l3.name}`}
                                                   levelName={levelTwoNameField}
                                                   fields={metricGroups}
                                                   data={l3} /> })
                    }
                    <LevelTwo
                        key={`l2_total_${i}_${l2.name}`}
                        levelName={levelTwoNameField}
                        fields={metricGroups}
                        isTotal={true}
                        data={{
                            ...l2,
                            name: `${l2[levelOneNameField]} - Total`
                        }}
                        styles={{
                            backgroundColor: '#f2fdfb',
                            fontWeight: 'bold',
                            marginBottom: '15px'
                        }}/>
                </StyledTreeItem>;
            })
        }
        <StyledTreeItem nodeId="grand_totals"
                        label={<GrandTotal fields={metricGroups} data={data} />} />
    </TreeView>
};

export default DataGrid;
