import React, { useEffect, useState } from "react";
import { useStyles } from "./style";

import { useSnackbar } from "notistack";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useRecoilValue } from "recoil";
import CustomSelect from "@/components/CustomSelect";
import LocationService from "@/service/org/location.service";
import { getError } from "@/utils/server-error-types";

const emptyFormData = {
  externalId: { display: 'External ID', value: undefined, hasError: false },
  address: { display: 'Address', value: "", hasError: false, required: true },
  state: { display: 'State', value: "", hasError: false, required: true },
  zipCode: { display: 'ZIP Code', value: "", hasError: false, required: true },
  name: { display: 'Name', value: "", hasError: false, required: true },
  city: { display: 'City', value: "", hasError: false, required: true },
}

const CreateLocationDialog = ({
  show,
  onCloseDialog,
  onCreate,
  onUpdate,
  location
}) => {
  const classes = useStyles();

  const initialFormData = {
    externalId: { display: 'External ID', value: location ? location.ext_location_id : undefined, hasError: false },
    address: { display: 'Address', value: location ? location.address : "", hasError: false, required: true },
    state: { display: 'State', value: location ? location.state : "", hasError: false, required: true },
    zipCode: { display: 'ZIP Code', value: location ? location.zip_code : "", hasError: false, required: true },
    name: { display: 'Name', value: location ? location.location_name : "", hasError: false, required: true },
    city: { display: 'City', value: location ? location.city : "", hasError: false, required: true },
  }

  const [formData, updateFormData] = useState({ ...initialFormData });
  const [formError, setFormError] = useState("");
  const [loading, toggleLoading] = useState(false);


  useEffect(() => {
    updateFormData(initialFormData)
  }, [location])

  const { enqueueSnackbar } = useSnackbar();

  const handleFormInputChange = (event) => {
    const newValue = event.target.value;
    updateFormData({
      ...formData,
      [event.target.name]: {
        ...formData[event.target.name],
        value: newValue
      }
    })
  }

  const validateField = (formInput, value) => {
    const validateMap = {
      address: (val) => {
        if (val === "") {
          return "Address is a required field";
        } else {
          return ""
        }
      },
      state: (val) => {
        if (val === "") {
          return "State is a required field";
        } else {
          return ""
        }
      },
      zipCode: (val) => {
        if (val === "") {
          return "ZIP code is a required field";
        } else {
          return ""
        }
      },
      name: (val) => {
        if (val === "") {
          return "Name is a required field";
        } else {
          return ""
        }
      },
      city: (val) => {
        if (val === "") {
          return "City is a required field";
        } else {
          return ""
        }
      }
    }
    if (validateMap[formInput]) return validateMap[formInput](value);
    return "";
  }

  const validate = () => {
    let formDataCopy = { ...formData };
    let foundErrors = false;

    Object.keys(formData).forEach(inputKey => {
      const fieldValidate = (validateField(inputKey, formData[inputKey].value))
      if (fieldValidate !== "") {
        if (!foundErrors) {
          setFormError(fieldValidate);
          foundErrors = true;
        }
        formDataCopy[inputKey].hasError = true;
      } else {
        formDataCopy[inputKey].hasError = false;
      }
    })

    if (!foundErrors) {
      setFormError("")
      handleFormSubmission(formDataCopy)
    }

    updateFormData({ ...formDataCopy })
  }

  const handleFormSubmission = async (data) => {
    toggleLoading(true);
    try {
      const newData = {
        ext_location_id: formData.externalId.value,
        address: formData.address.value,
        state: formData.state.value,
        zip_code: formData.zipCode.value,
        location_name: formData.name.value,
        city: formData.city.value
      }
      if (location) {
        await LocationService.updateLocation(location.id, newData);
        onUpdate();
        enqueueSnackbar("Location updated successfully", {
          variant: 'success'
        });
      } else {
        await LocationService.createLocation(newData)
        onCreate();
        enqueueSnackbar("Location created successfully", {
          variant: 'success'
        });
      }

      closeDialog();
    } catch (err) {
      toggleLoading(false);
      const { response } = err;
      const errorMessage = getError(response, `Failed to ${location ? 'update' : 'create'} location`);
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  }


  const closeDialog = () => {
    updateFormData({ ...emptyFormData });
    setFormError("");
    toggleLoading(false);
    onCloseDialog();
  };

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          width: "500px",
        },
      }}
    >
      <DialogContent className={classes.wrapper}>
        <Box className={classes.form}>
          <Typography
            component="h2"
            sx={{
              lineHeight: "1",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {location ? 'Update location details' : 'Add location'}
          </Typography>
          <div className={classes.inputsContainer}>
            <TextField
              error={formData.name.hasError}
              value={formData.name.value}
              label={formData.name.display}
              required={formData.name.required}
              name="name"
              onChange={handleFormInputChange}
              size="small"
              color='primary'
              sx={{
                '& input': {
                  padding: '8.5px 10px',
                },
              }}
            />
            <TextField
              error={formData.externalId.hasError}
              value={formData.externalId.value}
              label={formData.externalId.display}
              required={formData.externalId.required}
              name="externalId"
              onChange={handleFormInputChange}
              size="small"
              color='primary'
              sx={{
                '& input': {
                  padding: '8.5px 10px',
                },
              }}
            />
            <TextField
              error={formData.address.hasError}
              value={formData.address.value}
              label={formData.address.display}
              required={formData.address.required}
              name="address"
              onChange={handleFormInputChange}
              size="small"
              color='primary'
              sx={{
                '& input': {
                  padding: '8.5px 10px',
                },
              }}
            />
            <TextField
              error={formData.state.hasError}
              value={formData.state.value}
              label={formData.state.display}
              required={formData.state.required}
              name="state"
              onChange={handleFormInputChange}
              size="small"
              color='primary'
              sx={{
                '& input': {
                  padding: '8.5px 10px',
                },
              }}
            />
            <TextField
              error={formData.zipCode.hasError}
              value={formData.zipCode.value}
              label={formData.zipCode.display}
              required={formData.zipCode.required}
              name="zipCode"
              onChange={handleFormInputChange}
              size="small"
              color='primary'
              sx={{
                '& input': {
                  padding: '8.5px 10px',
                },
              }}
            />

            <TextField
              error={formData.city.hasError}
              value={formData.city.value}
              label={formData.city.display}
              required={formData.city.required}
              name="city"
              onChange={handleFormInputChange}
              size="small"
              color='primary'
              sx={{
                '& input': {
                  padding: '8.5px 10px',
                },
              }}
            />
          </div>
          {formError &&
            <div style={{ marginBottom: '10px' }}>
              <span className={classes.error}>{formError}</span>
            </div>
          }
          <LoadingButton
            loading={loading}
            onClick={validate}
            variant="contained"
            className={classes.submitButton}
          >
            {location ? 'Update' : 'Add'}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateLocationDialog;
