// an usage exmaple:
// ----------------
// values: [{amount:5, name: 'abc'},{amount:6, name:'cde'}]
// aggregatedkeys: ['amount']
// output:
// { amount : 11 }
export const aggregateValues = (
  values,
  aggregatedkeys,
  initalAggregateValue = 0
) => {
  const aggregate = aggregatedkeys.reduce(
    (aggregateMap, key) => (
      (aggregateMap[key] = initalAggregateValue), aggregateMap
    ),
    {}
  );
  values.forEach((value) => {
    Object.keys(aggregate).forEach((key) => {
      if (!aggregate[key]) {
        aggregate[key] = 0;
      }
      const v = value[key];
      if (Number.isFinite(+v)) {
        aggregate[key] += +v || 0;
      }
    });
  });
  return aggregate;
};
