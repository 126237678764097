import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';

// data
import { useRecoilState, useRecoilValue } from 'recoil';

// mui
import Box from '@mui/material/Box';

// state
import { finishedGoodsState } from './';
import { mrpForecastDataState } from '../production';

// misc
import PageTitle from '../../../../components/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { addCommas } from '../../../../utils/textFormatter';

export const FinishedGoodsDependencies = () => {
  // find the current material to display its description
  let { id } = useParams();
  const finishedGoods = useRecoilValue(finishedGoodsState);
  const finishedGood = finishedGoods?.find(product => product['Product ID'] === id);
  const currentWeekIdx = 5;

  const [mrpForecastData, setMrpForecastData] = useRecoilState(mrpForecastDataState);
  const finishedGoodForecast = mrpForecastData.filter(d => d?.['Product ID'] === id);

  const navigate = useNavigate();

  // dependent finished goods
  const dependentMaterials = [
    ...new Set(mrpForecastData
          .filter(m => m?.['Parent ID'] === id)
          .map(m => m?.['Product ID'])
    )
  ];

  const goBack = () => {
    navigate(-1);
  };

  const dependentMaterialsForecasts = dependentMaterials.map((materialId, i) => ({
    "Product ID": materialId,
    "Product Description": mrpForecastData?.find(mf => mf?.['Product ID'] === materialId)?.['Product Description'],
    rows: mrpForecastData?.filter(d => d?.['Product ID'] === materialId)
      ?.map(material => ({
        ...material,
        id: `${material?.['Product ID']}_${material?.['Metric']}_${i}`
      }))
  }));

  let columns = [{
    field: 'id',
    headerName: 'ID',
    id: (row, i) => `${row?.['Product ID']}_${row?.['Metric']}_${i}`,
    width: 120,
    hide: true
  }, {
    field: 'Metric',
    headerName: '',
    width: 130,
    renderCell: (params) => <div style={{ fontWeight: 'bold' }}>
      {params.value}
    </div>
  }];

  // hardcoded weeks
  const availableWeeks = [
    '11/1',
    '11/8',
    '11/15',
    '11/22',
    '11/29',
    '12/6',
    '12/13',
    '12/20',
    '12/27',
    '1/3',
    '1/10',
    '1/17',
    '1/24'
  ];


  const findValue = (weekIndex, metric, productId) => {
    const metricRow = dependentMaterialsForecasts
      ?.find(p => p['Product ID'] === productId)
      ?.rows
      ?.find(row => row['Metric'] === metric);

    return metricRow?.[`Week ${weekIndex}`]
  };

  // build weeks 1-32
  const weekColumns = availableWeeks.map((w, i) => ({
    field: `Week ${i + 1}`,
    headerName: w,
    width: 65,
    headerAlign: 'right',
    cellClassName: (params) => {
      let cellBackground = '';

      if (params.row['Metric'] === 'OH' &&
        findValue(i + 1, 'Need', params.row['Product ID']) > params.value)
        cellBackground = 'red';
       else if (params.row['Metric'] === 'Receipts')
        cellBackground = 'green';

      return `cell ${cellBackground}`
    },
    editable: true,
    resizable: true,
    renderCell: (params) => {
      return <div style={{
        width: '100%',
        textAlign: 'right',
      }}>
      <span style={{ fontSize: '12px' }}>
          {addCommas(params.value)}
      </span>
      </div>
    }
  }));

  columns = [...columns, ...weekColumns];


  const finishedGoodForecastFormatted = finishedGoodForecast?.map((m, i) => ({
    ...m,
    id: `${m?.['Product ID']}_${m?.['Metric']}_${i}}`
  }))

  const calculate = (productId, field, metric, value) => {
    if (metric !== 'Receipts') return;

    let all = JSON.parse(JSON.stringify(mrpForecastData));
    let data = all.filter(d => d['Product ID'] === productId);

    const currentRow = data.find(m => m['Metric'] === metric);
    currentRow[field] = +value;

    const oh = data.find(d => d['Metric'] === 'OH');
    const targetOH = data.find(d => d['Metric'] === 'Target OH');
    const receipts = data.find(d => d['Metric'] === 'Receipts');
    const consumption = data.find(d => d['Metric'] === 'Consumption');
    const need = data.find(d => d['Metric'] === 'Need');

    for (let i = 1; i <= 12; i++) {
      oh[`Week ${i}`] = (oh?.[`Week ${i-1}`] ?? 0)  + (receipts?.[`Week ${i}`] ?? 0) - (consumption?.[`Week ${i}`] ?? 0);
      need[`Week ${i}`] = (targetOH?.[`Week ${i}`] ?? 0) - (oh?.[`Week ${i}`] ?? 0);
    }

    return all;
  };

  const handleRowEditCommit = React.useCallback(
    ({id, field, value}) => {
      const [productId, metric] = id.split('_');
      const newData = calculate(productId, field, metric, value);
      setMrpForecastData(newData);
      }, []
  );

  return <Stack style={{
    padding: '25px',
    flex: 1
  }}>
    <Box display="flex"
         justifyContent="space-between"
         sx={{
           position: 'relative',
           marginBottom: '25px'
         }}>
      <Box>
        <Box sx={{
          position: 'absolute',
          right: 20,
          display: 'flex',
          columnGap: '30px'
        }}>
          <a onClick={goBack} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'arrow_back'}</i>
            <span className='d-block'>Back</span>
          </a>
          <a
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer',
              fontSize: '15px',
            }}>
            <i className='material-icons navmenu-icon'>{'save'}</i>
            <span className='d-block'>Save</span>
          </a>
          <a style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'get_app'}</i>
            <span className='d-block'>Export</span>
          </a>
          <a style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '15px',
          }}>
            <i className='material-icons navmenu-icon'>{'restore'}</i>
            <span className='d-block'>Reset</span>
          </a>
        </Box>
        <PageTitle>
          Production
        </PageTitle>
        <div style={{ marginTop: '25px', marginBottom: '20px' }}>
          <span style={{
            color: 'rgb(99,99,99)',
            fontSize: '20px',
            fontWeight: ' bold'
          }}>
          Product Dependencies View
          </span>
        </div>
      </Box>
    </Box>
    <Box sx={{
      height: '280px',
      paddingLeft: '30px'
    }}>
      <div style={{ marginBottom: '10px' }}>
        <span style={{ fontWeight: 'bold' }}>
          Finished Good: {finishedGood?.['Product Description']}
        </span>
      </div>
        <DataGrid
          hideFooter
          compact={true}
          rows={finishedGoodForecastFormatted}
          columns={columns}
          density="compact"
        />
    </Box>
    <br />
    <br />
    <br />
    <Box sx={{ paddingLeft: '30px', marginBottom: '60px' }}>
      <div style={{ marginBottom: '40px' }}>
      <span style={{
        color: 'gray',
        fontWeight: 'bold',
        fontSize: '13px',
        marginBottom: '10px'
      }}>
        Is dependent on the following raw materials and packaging:
      </span>
      </div>
      {dependentMaterialsForecasts.map(material => <Box sx={{
        height: '280px',
        marginBottom: '60px'
      }}>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>{material?.['Product Description']}</span>
        </div>
        <Box sx={{
          height: '280px',
          '& .cell.red': {
            backgroundColor: '#ffd1d1',
          },
          '& .cell.green': {
            backgroundColor: '#AED9E5',
          },
        }}>
          <DataGrid
            hideFooter
            compact={true}
            rows={material.rows}
            onCellEditCommit={handleRowEditCommit}
            columns={columns}
            density="compact"
          />
        </Box>
      </Box>)}
    </Box>
  </Stack>
}

export default FinishedGoodsDependencies;
