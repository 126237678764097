import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { useNavigate } from "react-router-dom";
import useAuthGuard from "../../utils/hooks/useAuthGuard";

const PageNotFound = () => {
  document.title = "Page Not Found";
  const navigate = useNavigate();
  const { guard: loggedIn, homeRoute } = useAuthGuard();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Card
        className="card-shadow text-center"
        style={{
          border: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "350px",
        }}
      >
        <CardContent
          style={{
            backgroundColor: "#fafafa",
            padding: "50px",
          }}
        >
          <div
            style={{
              fontSize: "10em",
              fontWeight: "600",
              lineHeight: "normal",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                paddingLeft: "36px",
                whiteSpace: "nowrap",
              }}
            >
              <span style={{ color: "#50e7d3" }}>4</span>
              <span
                style={{
                  color: "#444",
                  position: "relative",
                  left: "-20px",
                }}
              >
                0
              </span>
              <span
                style={{
                  color: "#50e7d3",
                  position: "relative",
                  left: "-40px",
                }}
              >
                4
              </span>
            </span>
          </div>
          <span
            style={{
              color: "#444",
              fontSize: "1.5em",
              fontWeight: "bold",
              letterSpacing: "4px",
            }}
          >
            NOT FOUND
          </span>
          <div
            style={{
              color: "#444",
              fontSize: "1.2em",
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "30px",
              textTransform: "capitalize",
            }}
          >
            The page you are attempting to reach is currently not available.
            This may be because the page does not exist or has been moved.
          </div>
          <Button
            onClick={() => navigate(homeRoute)}
            className="pt-2 pb-2 pr-3 pl-3"
            style={{
              backgroundColor: "#50e7d3",
              border: "none",
              borderRadius: "22px",
              color: "white",
              fontSize: "18px",
              marginBottom: "20px",
              textTransform: "uppercase",
            }}
          >
            Back to {loggedIn ? `Dashboard` : `Login Page`}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default PageNotFound;
