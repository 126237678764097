import { atom } from 'recoil';

// Settings Section
export const settingsState = atom({
  key: 'org_settings',
  default: undefined
});

export const settingsDraftState = atom({
  key: 'org_settings_draft',
  default: undefined
});

// Users Section
export const usersState = atom({
  key: 'org_users',
  default: []
});

export const userTypesState = atom({
  key: 'user_types',
  default: []
});

// Locations Section
export const locationsState = atom({
  key: 'org_locations',
  default: []
});

// Vendors Section
export const vendorsState = atom({
  key: 'org_vendors',
  default: []
});

// Products Section
export const productsState = atom({
  key: 'org_products',
  default: []
});

export const brandsState = atom({
  key: 'org_brands',
  default: []
});

export const productTypesState = atom({
  key: 'org_product_types',
  default: {},
});






