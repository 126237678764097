import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "430px",
    flexWrap: "wrap",
    "& > div": {
      marginRight: "10px",
    },
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    "& > span": {
      fontSize: "12px",
      color: "#717171",
    },
  },
  boostrapWrapper: {
    "& .MuiInputBase-input": {
      backgroundColor: "white !important",
    },
  },
}));
