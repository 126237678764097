import { uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { stepsComponents } from "../..";
import Loader from "../../../../../../components/Loader";
import OrganisationService from "../../../../../../service/organisation-service";
import WorkbenchService from "../../../../../../service/workbench-service";
import { sortAlphabetically } from "../../../../../../utils/arrays";
import { ALL_LOCATIONS_OPTION } from "../category-targets/utils/constants";
import Actions from "./actions";
import ExportItemsDialog from "./export-items-dialog";
import FiltersControl from "./filters-control";
import { Filters } from "./filters-control/filters";
import GroupByDialog from "./group-by-dialog";
import ColumnsDialog from "./columns-dialog";
import { getGroupedFieldsValues } from "./group-by-dialog/utils/constants";
import { getStoredGroupByFields, storeGroupByFields } from "./group-by-dialog/utils/local-storage-service";
import AdvancedFiltersControl from "./locations-categories";
import OpenToBuy from "./open-to-buy";
import OrdersList from "./orders-list";
import { filterByCategories, filterItems } from './orders-list/utils/filter-items';
import { getAggregatedGroupedRows, groupItemsBy } from "./orders-list/utils/group-by";
import {
  calculateTotalCost,
  convertOrderItemsToHirerarchyArray
} from "./orders-list/utils/items-hirearchy";
import { useStyles } from "./style";
import { duplicateExistingItem } from "./utils/reactive-calculate-changes";
import { ITEM_PLANNER_HIDDEN_COLUMNS_NAMES } from "./utils/item-planner-columns";
import { useRecoilState } from "recoil";
import { reorderItemsState } from "@/store";

export const ALL_VENDORS_OPTION = { value: -1, label: "All Vendors" };
export const ALL_BRANDS_OPTION = { value: -1, label: "All Brands" };

const ItemPlanner = ({
  config,
  locations,
  gotoStep,
  orgId,
  onUpdate = () => { },
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const pingActiveInterval = useRef(null);
  //------------- Items --------------
  const [items, setItems] = useState();
  const [loadingItems, setLoadingItems] = useState(true);

  //------- Saving and controls -------
  const [updating, setUpdating] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [showGroupByDialog, setShowGroupByDialog] = useState(false);
  const [showColumnsDialog, setColumnsDialog] = useState(false);
  const [convertedItems, setConvertedItems] = useRecoilState(reorderItemsState);
  const [changesLog, setChangesLog] = useState([]);

  //------------ Filters --------------
  const [filters, setFilters] = useState([
    Filters.RECOMMENDED,
    Filters.ORDERED,
  ]);

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(ALL_VENDORS_OPTION);

  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(ALL_BRANDS_OPTION);

  const [selectedLocationId, setSelectedLocationId] = useState(
    ALL_LOCATIONS_OPTION.id
  );

  const getInitialGroupByFields = () => {
    return getStoredGroupByFields() ?? getGroupedFieldsValues();
  }
  const [groupByFields, setGroupByFields] = useState(getInitialGroupByFields);

  const locationsFilter = {
    list: locations,
    selectedId: selectedLocationId,
    onChange: (locationId) => setSelectedLocationId(locationId),
  };

  const categoriesFilter = {
    list: categories,
    selected: selectedCategories,
    onChange: setSelectedCategories,
  };

  const vendorsFilter = {
    list: vendors,
    selectedId: selectedVendor,
    onChange: setSelectedVendor,
  };

  const brandsFilter = {
    list: brands,
    selectedId: selectedBrand,
    onChange: setSelectedBrand,
  };

  //------------------------------------
  const [itemsColumns, setItemsColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(ITEM_PLANNER_HIDDEN_COLUMNS_NAMES);

  //------------------------------------
  useEffect(() => {
    if (Array.isArray(items)) {
      if (items.length > 0) {
        const converted = convertOrderItemsToHirerarchyArray(items, locations);
        setConvertedItems(converted);
      } else {
        setConvertedItems([]);
      }
    }
  }, [items]);

  const filteredItems = filterItems(convertedItems, {
    categoriesIds: selectedCategories,
    locations,
    filters,
    selectedLocationId,
    selectedVendorId: selectedVendor.value,
    selectedBrandId: selectedBrand.value,
  });

  const itemPlannerItems = useMemo(() => {
    const itemsRows = groupItemsBy(filteredItems, groupByFields);
    const parentsRows = getAggregatedGroupedRows(itemsRows, groupByFields.length)
    return [...itemsRows, ...parentsRows]
  }, [filteredItems, groupByFields])

  //----------- Data Fetching -----------
  const fetchOrders = async () => {
    try {
      const reorderItems = await WorkbenchService.updateReorder(orgId, {
        changes: {
          flagChanges: [],
          openToBuy: openToBuyAmount,
        },
      });
      setItems(reorderItems.data);
    } catch (err) {
      setItems([]);
      enqueueSnackbar("Failed to import items", {
        variant: "error",
      });
    } finally {
      setTimeout(() => {
        setLoadingItems(false);
      }, 2000);
    }
  };

  const fetchCategories = async () => {
    const productsTypes = await OrganisationService.forecast(
      orgId
    )
      .productTypes();
    const newCategories = productsTypes?.data?.children ?? [];
    const sortedCategories = sortAlphabetically(newCategories, 'name');
    setCategories(sortedCategories);
  };

  const fetchVendors = async () => {
    const {
      data: { dist },
    } = await OrganisationService.vendor.list(orgId, 10000, 1);
    const vendors = uniqBy(dist, "distributor_name");
    const sortedVendors = sortAlphabetically(vendors, 'distributor_name');
    setVendors(sortedVendors);
  };

  const fetchBrands = async () => {
    const { data: brands } = await OrganisationService.brands.list(orgId);
    const sortedBrands = sortAlphabetically(brands, 'name');
    setBrands(sortedBrands);
  };

  //----------------------------------

  useEffect(async () => {
    try {
      await Promise.all([
        fetchOrders(),
        fetchCategories(),
        fetchVendors(),
        fetchBrands(),
      ]);
      // TODO: take care of removing old intervals
      pingActiveInterval.current = setInterval(() => {
        WorkbenchService.pingActiveReorder(orgId);
      }, 30000);
    } catch (e) {
      console.log(444444, e);
      enqueueSnackbar("There was an error while loading the step data.", {
        variant: "error",
      });
    }
  }, []);

  const duplicateItem = (item) => {
    const newItem = duplicateExistingItem(convertedItems, item);
    const insertionIndex =
      convertedItems.findIndex(
        (convertedItem) => convertedItem.uid === item.uid
      ) + 1;
    const newConvertedItems = [
      ...convertedItems.slice(0, insertionIndex),
      newItem,
      ...convertedItems.slice(insertionIndex),
    ];

    // TODO: call add item api
    setConvertedItems(newConvertedItems);
  };

  const totalOnOrder = useMemo(() => {
    return convertedItems.reduce((sum, currItem) => {
      return currItem.IsOnOrder ? sum + calculateTotalCost(currItem) : sum;
    }, 0);
  }, [convertedItems]);

  const totalBudget = useMemo(
    () =>
      Object.values(config.budget).reduce(
        (count, location) => count + location.budget,
        0
      ),
    [config]
  );

  const addChangesLog = (changes) => {
    setChangesLog(oldChanges => ([...oldChanges, ...changes]));
  };

  const openToBuyAmount = useMemo(() => {
    return totalBudget - totalOnOrder;
  }, [totalBudget, totalOnOrder]);

  const saveChanges = async () => {
    setUpdating(true);

    try {
      const updatedItems = await WorkbenchService.updateReorder(orgId, {
        changes: {
          flagChanges: changesLog,
          openToBuy: openToBuyAmount,
        },
      });

      if (updatedItems.data) {
        setItems(updatedItems.data);
        enqueueSnackbar("Saved successfully.", { variant: "success" });
      }

      // clear the changes log after send all changes
      setChangesLog([]);
      onUpdate();
    } catch (e) {
      console.log(e.message);
    } finally {
      setUpdating(false);
    }
  };

  const allHiddenColumns = [...hiddenColumns, ...groupByFields]

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <AdvancedFiltersControl
          locations={locationsFilter}
          categories={categoriesFilter}
          vendors={vendorsFilter}
          brands={brandsFilter}
        />
        <FiltersControl
          filters={filters}
          onFiltersChange={(newFilters) => setFilters(newFilters)}
        />
        <GroupByDialog
          show={showGroupByDialog}
          onChangeGrupeByFields={(metrics) => {
            setGroupByFields(metrics);
            storeGroupByFields(metrics)
          }}
          onCloseDialog={() => {
            setShowGroupByDialog(false)
          }}
          groupByFields={groupByFields}
        />
        <ColumnsDialog
          show={showColumnsDialog}
          onChangeColumns={(metrics) => {
            setItemsColumns(metrics);
          }}
          onCloseDialog={() => {
            setColumnsDialog(false)
          }}
          columns={itemsColumns}
          onChangeHiddenColumns={setHiddenColumns}
          hiddenColumns={allHiddenColumns}
        />
        <Actions
          autoSave={autoSave}
          onAutoSaveChange={(toAutoSave) => {
            setAutoSave(toAutoSave);
          }}
          hasNewChanges={changesLog.length > 0}
          onSave={saveChanges}
          onTarget={() => gotoStep(stepsComponents.CATEGORIES_TARGETS)}
        />
        <OpenToBuy
          onOrder={totalOnOrder}
          totalBudget={totalBudget}
          openToBuyAmount={openToBuyAmount}
          onEditBudget={() => gotoStep(stepsComponents.SET_BUDGET)}
        />
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          position: "relative",
        }}
      >
        {updating && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              flex: 1,
              zIndex: 9999,
              opacity: 0.9,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Loader />
            <b>Saving and updating recommendations...</b>
          </div>
        )}
        <OrdersList
          filteredItems={itemPlannerItems}
          categories={categories}
          groupByFields={groupByFields}
          columns={itemsColumns}
          hiddenColumns={allHiddenColumns}
          loading={loadingItems}
          onGroupBy={() => setShowGroupByDialog(true)}
          onColumns={() => setColumnsDialog(true)}
          onExport={() => setShowExportDialog(true)}
          onDuplicateItem={duplicateItem}
          setItemsColumns={setItemsColumns}
          addChangesLog={addChangesLog}
        />
      </div>
      <ExportItemsDialog
        show={showExportDialog}
        items={convertedItems}
        filteredItems={itemPlannerItems}
        onCloseDialog={() => setShowExportDialog(false)}
      />
    </div>
  );
};

export default ItemPlanner;
