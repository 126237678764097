import React from 'react';
import Typography from '@mui/material/Typography';

const PageTitle = ({ children, typographyProps }) => {
  return <Typography component="h2" sx={{
    lineHeight: "1",
    marginBottom: "0px",
    fontSize: "2rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  }}
    {...typographyProps}>
    {children}
  </Typography>
};

export default PageTitle;
