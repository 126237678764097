import { ALL_BRANDS_OPTION, ALL_VENDORS_OPTION } from "../../index";

export const filterByCategories = (item, categoriesIds) => {
  return (
    categoriesIds.includes(+item["Category ID"]) &&
    categoriesIds.includes(+item["Sub Category ID"])
  );
};

const filterByVendor = (item, selectedVendorId) => {
  if (selectedVendorId === ALL_VENDORS_OPTION.value) return true;
  return item["Vendor ID"] === selectedVendorId;
};

const filterByBrand = (item, selectedBrandId) => {
  if (selectedBrandId === ALL_BRANDS_OPTION.value) return true;
  return item["Brand ID"] === selectedBrandId;
};

const filterByLocation = (item, selectedlocationName) => {
  return item.path[0] === selectedlocationName;
};

export const filterItems = (
  items,
  {
    categoriesIds,
    locations,
    selectedLocationId,
    selectedVendorId,
    selectedBrandId,
  }
) => {
  const selectedLocation = locations?.find(
    (location) => location.id === selectedLocationId
  );

  return items.filter((item) => {
    if (selectedLocation) {
      if (!filterByLocation(item, selectedLocation.name)) {
        return false;
      }
    }

    if (!filterByVendor(item, selectedVendorId)) {
      return false;
    }

    if (!filterByBrand(item, selectedBrandId)) {
      return false;
    }

    return true;
    // return filterByCategories(item, categoriesIds);
  });
};
