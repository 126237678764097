import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    width: "100%",
  },
  actionsWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  onOrder: {
    backgroundColor: "#E2F3F4",
  },
  noBudgetOrHide: {
    color: '#6B6A69',
    // fontStyle: 'italic',
    '& .MuiDataGrid-cellContent': {
      width: '100%'
    }
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      fontWeight: 500
    }
  },
  onOrderText: {
    fontWeight: "bold",
    marginLeft: "7px",
    color: "#00949f",
  },
  onOrderIcon: {
    color: "#00949f",
  },
  activeIcon: {
    color: "#22BECF",
  },
  editableCell: {
    backgroundColor: "#8ecfd46b",
    borderRight: "2px solid white",
    borderTop: "2px solid white",
  },
  expandAll: {
    color: '#8ecfd4',
    height: '18px',
    width: '18px',
    border: '1px solid #8ecfd4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '30%',
    marginLeft: '7px',
    cursor: 'pointer',
    '& svg': {
      height: '15px',
      width: '15px'
    }
  },
}));
