import React from "react";
import styled from "styled-components";
import { Cell, CellGroup, CellGroupWrap, LevelHeader } from "../styles";
import { toPercent } from "../../../utils/textFormatter";

const Wrap = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 1px;
  font-weight: bold;
`;

const GrandTotal = ({ data, fields }) => {
  return (
    data && (
      <Wrap>
        <LevelHeader>Grand Total</LevelHeader>
        <CellGroupWrap>
          {fields
            .filter((f) => !!f.visible)
            .map((field, i) => {
              const actual = data[field.value];
              const actualFormatted = field.format(actual);
              const forecast = data[`fcst_${field.value}`];
              const forecastFormatted = field.format(forecast);
              const variance =
                forecast === 0 ? 0 : (actual - forecast) / Math.abs(forecast);

              return (
                <CellGroup
                  key={`mg1_${i}`}
                  style={{
                    backgroundColor: "#c0fff5",
                  }}
                >
                  <Cell style>{actualFormatted}</Cell>
                  <Cell>{forecastFormatted}</Cell>
                  <Cell>{toPercent(variance, 0, 100)}</Cell>
                  <span className="testing-grand-actual" style={{display: 'none'}}>{actual}</span>
                </CellGroup>
              );
            })}
        </CellGroupWrap>
      </Wrap>
    )
  );
};

export default GrandTotal;
