import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useMemo, useState } from "react";
import CustomSelectComponent from "../../../../../../components/CustomSelect";
import NumberEditableText from "../../../../../../components/NumberEditableText/NumberEditableText";
import WorkBenchService from "../../../../../../service/workbench-service";
import { aggregateValues } from "../../../../../../utils/aggregate";
import { cashNullFormatter, decimalNullFormatter, numberNullFormatter } from "../../../../../../utils/mui-data-grid/formatters";
import { useDataGridScroll } from "../../../../../../utils/mui-data-grid/useDataGridScroll";
import { commonStyles } from "../../../style";
import "./data-grid.css";
import FooterAggregateData from "./footer-aggregate-data";
import { ITEM_ROW_HEIGHT, useStyles } from "./style";
import {
  convertTreeToConfigCategories
} from "./utils/categories-hirearchy";
import {
  ALL_LOCATIONS_OPTION, COST_COLUMNS, GROUP_BY, TARGETS_MODE, UNITS_COLUMNS
} from "./utils/constants";
import { calculateParentAvg, calculateWoh } from "./utils/reactive-tree";
import { addCostToTree, combineRows, convertToTree, onFieldChange, onFieldChangeAllLocations, treeToRows } from "./utils/reactive-tree";
import useUserData from '../../../../../../utils/hooks/useUserData';

const CategoryTargets = ({
  config,
  locations,
  loadingConfig,
  onDone = async () => { },
  onBack = () => { },
}) => {
  const classes = useStyles();
  const hasScroll = useDataGridScroll();
  const categoryTargets = useMemo(() => config.categoryTargets || {}, [config]);
  const [saving, toggleSaving] = useState(false);
  const { org_public_id } = useUserData();

  //------------------------------------
  // Locations
  const [selectedLocationId, setSelectedLocationId] = useState(
    ALL_LOCATIONS_OPTION.id
  );

  const locationsOptions = useMemo(() => {
    return [ALL_LOCATIONS_OPTION, ...locations].map((location, index) => ({
      key: location.id,
      value: location.name,
    }));
  }, [locations]);

  //------------------------------------
  // Modes and filters
  const filterBy = useMemo(() => {
    if (+selectedLocationId === ALL_LOCATIONS_OPTION.id) {
      return GROUP_BY.category
    } else {
      return GROUP_BY.location
    }
  }, [selectedLocationId, locations])
  const [targetsMode, setTargetsMode] = useState(TARGETS_MODE.cost);

  //------------------------------------
  // Data grid rows
  const [originalRows, setOriginalRows] = useState([]);
  const [originalTree, setOriginalTree] = useState({});

  const displayedRows = useMemo(() => {
    // All Locations are selected
    if (filterBy === GROUP_BY.category) {
      return combineRows(originalRows);
    } else if (filterBy === GROUP_BY.location) {
      return originalRows.filter(row => +row.locationId === +selectedLocationId);
    }
    return []
  }, [originalRows, selectedLocationId])

  //------------------------------------
  // Rows generate
  const convertToRows = (initialTree) => {
    const tree = convertToTree(initialTree || categoryTargets,
      locations);
    const treeWithCost = addCostToTree(tree);
    const treeRows = treeToRows(treeWithCost);
    setOriginalTree(treeWithCost);
    setOriginalRows(treeRows);
  }

  const calculateRecursiveValues = (tree) => {
    setOriginalTree(tree);
    const treeWithCost = addCostToTree(tree);
    const treeRows = treeToRows(treeWithCost);
    setOriginalRows(treeRows);
  }

  useEffect(() => {
    // convert categories from config file to hierarchy array
    // for the dataGridPro rows
    convertToRows();
  }, [categoryTargets]);

  //------------------------------------
  const categoryTargetsColumns = useMemo(() => {
    if (targetsMode === TARGETS_MODE.cost) return Object.values(COST_COLUMNS)
    else if (targetsMode === TARGETS_MODE.units) return Object.values(UNITS_COLUMNS)
    return []
  }, [targetsMode])

  const rowsAggregate = () => {
    const BIGGEST_LEVEL = 1;
    const topLevelItems = displayedRows.filter(item => item.path.length === BIGGEST_LEVEL);
    return Object.values(aggregateValues(topLevelItems, categoryTargetsColumns))
  }

  const totalTargetElement = () => {
    return +selectedLocationId === ALL_LOCATIONS_OPTION.id
      ? undefined
      : (value) => (
        <NumberEditableText
          align="right"
          className={classes.grandTargetInput}
          value={value}
          onChange={(newValue) => {

          }}
        />
      );
  };
  //------------------------------------

  const handleFieldChange = ({ value, row }, field) => {
    let newTree = {};
    if (filterBy === GROUP_BY.category) {
      newTree = onFieldChangeAllLocations(originalTree, { value, row }, field);
    } else if (filterBy === GROUP_BY.location) {
      newTree = onFieldChange(originalTree, { value, row }, field);
    }
    calculateRecursiveValues(newTree);
    return { ...row, [field]: value };
  }

  const handleTargetsMode = (_, newMode) => {
    if (newMode !== null) {
      setTargetsMode(newMode);
    }
  };

  const units_columns = [
    {
      field: "unit_sales",
      headerName: "Unit Sales",
      type: "number",
      flex: 1,
      sortable: false,
    },
    {
      field: "on_hand",
      headerName: "On Hand",
      type: "number",
      flex: 1,
      sortable: false,
    },
    {
      field: "on_order",
      headerName: "On Order",
      type: "number",
      flex: 1,
      sortable: false,
      valueFormatter: numberNullFormatter
    },
    {
      field: "woh",
      headerName: "WOH",
      type: "number",
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => {
        if (row.path.length === 2) return calculateWoh(row)
        return calculateParentAvg(originalRows,
          row,
          calculateWoh,
          filterBy === GROUP_BY.category);
      },
      valueFormatter: decimalNullFormatter
    },
    {
      field: "target",
      headerName: "Target",
      type: "number",
      cellClassName: classes.targetCell,
      flex: 1,
      editable: true,
      sortable: false,
      valueFormatter: numberNullFormatter,
      valueSetter: (params) => {
        return handleFieldChange(params, 'target')
      },
    },
  ];

  const cost_columns = [
    {
      field: "cost_sales",
      headerName: "Cost Sales",
      flex: 1,
      type: "number",
      sortable: false,
      valueFormatter: cashNullFormatter,
    },
    {
      field: "cost_on_hand",
      headerName: "Cost On Hand",
      flex: 1,
      type: "number",
      sortable: false,
      valueFormatter: cashNullFormatter,
    },
    {
      field: "on_order_cost",
      headerName: "On Order",
      flex: 1,
      type: "number",
      sortable: false,
      valueFormatter: cashNullFormatter,
    },
    {
      field: "cost_woh",
      headerName: "WOH",
      flex: 1,
      type: "number",
      sortable: false,
      valueFormatter: decimalNullFormatter,
      valueGetter: ({ row }) => {
        if (row.path.length === 2) return calculateWoh(row)
        return calculateParentAvg(originalRows,
          row,
          calculateWoh,
          filterBy === GROUP_BY.category);
      },
    },
    {
      field: "target_cost_on_hand",
      headerName: "Target Cost on Hand",
      type: "number",
      cellClassName: classes.targetCell,
      flex: 1,
      editable: true,
      sortable: false,
      valueFormatter: cashNullFormatter,
      valueSetter: (params) => {
        const deltaCost = params.value - params.row.target_cost_on_hand;
        let auc = params.row.auc;
        if (params.row.path.length === 1) {
          auc = params.row.target_cost_on_hand / params.row.target;
        }
        const calculatedDeltaUnits = (auc === 0 ? 0 : Math.floor(deltaCost / auc))

        return handleFieldChange({ value: params.row.target + calculatedDeltaUnits, row: params.row }, 'target')
      },
    },
  ];

  // ----------------------------------------
  // Data grid configs
  const getTreeDataPath = (row) => row.path;

  const getRowClassName = ({ row }) => {
    if (row.path) {
      return row.path.length > 1 ? classes.subCaregory : classes.mainCategory;
    }
    return "";
  };
  // ----------------------------------------
  // save all changes back to the config
  const setCategoriesTargets = async () => {
    const newCategories = convertTreeToConfigCategories(
      config,
      originalTree
    );
    const newConfig = {
      ...config,
      categoryTargets: newCategories,
    };
    toggleSaving(true);
    await onDone(newConfig);

    // run the engine for the first time
    await WorkBenchService.updateReorder(org_public_id, []);

    toggleSaving(false);
  };

  return (
    <div className={classes.container}>
      <Typography
        component="h2"
        sx={{
          fontSize: "30px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Here's our recommended targets by category
      </Typography>
      <Typography
        component="span"
        sx={{
          fontSize: "24px",
          fontWeight: 200,
          textAlign: "center",
        }}
      >
        Adjust the targets to your desired levels
      </Typography>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          width: "70%",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div className={classes.selectWrapper}>
            <CustomSelectComponent
              options={locationsOptions}
              style={{ width: "200px" }}
              value={selectedLocationId}
              onChange={(newLocation) => setSelectedLocationId(newLocation)}
            />
          </div>
          {loadingConfig && (
            <div className={classes.loadingWrapper}>
              <CircularProgress
                color="primary"
                sx={{
                  width: "15px !important",
                  height: "15px !important",
                  marginRight: "8px",
                }}
              />
              <span className={classes.loadingText}>Refreshing targets..</span>
            </div>
          )}
        </div>
        <ToggleButtonGroup
          color="primary"
          value={targetsMode}
          exclusive
          aria-label="tabs to switch between cost and units"
          onChange={handleTargetsMode}
        >
          <ToggleButton style={{ padding: "3px 7px" }} value="cost">
            Cost
          </ToggleButton>
          <ToggleButton style={{ padding: "3px 7px" }} value="units">
            Units
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.gridWrapper}>
        <DataGridPro
          treeData
          loading={displayedRows.length === 0}
          experimentalFeatures={{ newEditingApi: true }}
          rows={displayedRows}
          columns={targetsMode === TARGETS_MODE.units ? units_columns : cost_columns}
          rowHeight={ITEM_ROW_HEIGHT}
          disableColumnMenu
          disableColumnResize
          disableColumnReorder
          groupingColDef={{
            hideDescendantCount: true,
          }}
          components={{
            Footer: FooterAggregateData,
          }}
          componentsProps={{
            footer: {
              values: rowsAggregate(),
              lastElement: totalTargetElement(),
              mode: targetsMode,
              lastElementStyle: {
                ...(hasScroll && {
                  paddingLeft: '17px',
                  paddingRight: '20px'
                })
              }
            },
          }}
          getTreeDataPath={getTreeDataPath}
          getRowClassName={getRowClassName}
        />
      </div>
      <div className={classes.actions}>
        <LoadingButton
          variant="contained"
          sx={{ ...commonStyles.controlButton }}
          onClick={setCategoriesTargets}
          loading={saving}
        >
          Confirm Targets
        </LoadingButton>
        <Button
          variant="contained"
          sx={{ ...commonStyles.controlButton }}
          onClick={onBack}
        >
          Set Budget
        </Button>
      </div>
    </div>
  );
};

export default CategoryTargets;
