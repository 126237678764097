import API from "../api"

const BomService = {
  getBoms: () => API.get(`org/boms`),
  getBom: (bomId) => API.get(`org/boms/${bomId}`),
  createBom: (bom) => API.post(`org/boms`, bom),
  updateBom: (bomId, newValues) => API.put(`org/boms/${bomId}`, newValues),
  updateLineItem: (bomId, lineItemId, newValues) => API.put(`org/boms/${bomId}/items/${lineItemId}`, newValues),
  deleteBom: (bomId) => API.delete(`org/boms/${bomId}`),
};

export default BomService;
