import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@mui/styles';
import { TreeView, TreeItem } from '@mui/lab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';

const Wrap = styled.div`
  background: white;
  max-width: 100vw;
  max-height: 100%;
  position: fixed;
  min-width: 300px;
  padding: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  *:focus {
    outline: none;
  }
`;


const filterTree = (tree, leafIds) => {
    if (!tree) return [];
    const filteredTree = [];

    for (const node of tree) {
        if (leafIds.includes(node.id)) {
            filteredTree.push(node);
        }

        if (node.children) {
            const filteredChildren = filterTree(node.children, leafIds);
            if (filteredChildren.length > 0) {
                node.children = filteredChildren;
            } else {
                node.children = [];
            }
        }
    }

    return filteredTree;
};

const ProductTypeFilter = ({ value, categories = [], onChange, map, filter = [] }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    filterTree(categories, filter);
    categories = categories?.filter(c => c?.children.length > 0);

    const handleNodeSelect = (event, nodeId) => {
        setSelected({
            id: nodeId,
            name: map[nodeId],
        });
    };

    const handleSelect = () => {
        onChange(selected);
        setModalOpen(false);
    }

    const handleClear = () => {
        setSelected(null);
        onChange(null);
    }

    const theme = createTheme({
        props: {
            MuiButtonBase: {
                disableRipple: true
            }
        }
    });

    const useStyles = makeStyles({
        root: {
            height: 240,
            maxWidth: 400,
            maxHeight: 600,
            overflowY: 'auto'
        },
    });
    const classes = useStyles();

    return <ThemeProvider theme={theme}>
        <div style={{
            display: 'inline-block',
            border: '1px solid #ced4da',
            borderRadius: '0.25rem'
        }}
        >
            <Chip
                label={value ? (<span>
                  <span>Product type: </span>
                  <span style={{ color: '#109185' }}>{map[value]}</span>
              </span>)
                    : 'All categories'}
                aria-controls="filter-value"
                aria-haspopup="true"
                icon={<TuneIcon/>}
                style={{
                    background: 'white',
                    color: '#495057',
                }}
                onClick={() => {
                    setModalOpen(true);
                }}
            />
            <Modal open={modalOpen} onClose={() => {
                setModalOpen(false);
            }}>
                <Wrap style={{
                    display: 'inline-block',
                    border: '1px solid #ced4da',
                    borderRadius: '0.25rem',
                }}>
                    <TreeView
                        onNodeSelect={handleNodeSelect}
                        className={classes.root}
                        defaultCollapseIcon={<ExpandMoreIcon/>}
                        defaultExpandIcon={<ChevronRightIcon/>}
                    >
                        {(categories || []).map((category, i) => {
                            return <TreeItem key={'ti_' + category?.id?.toString()}
                                             nodeId={category?.id?.toString()}
                                             label={category.name}>
                                {(category.children || []).map((h3, j) => {
                                    return <TreeItem key={'ti2_' + j} nodeId={h3?.id?.toString()} label={h3.name}/>
                                })}
                            </TreeItem>
                        })}
                    </TreeView>
                    <Button
                        onClick={handleSelect}
                        style={{
                            backgroundColor: '#109185',
                            color: 'white',
                            marginTop: '20px'
                        }}>
                        Select
                    </Button>
                </Wrap>
            </Modal>
            {value && <IconButton style={{
                marginRight: '5px'
            }} size="small" aria-label="delete" onClick={handleClear}>
                <ClearIcon fontSize="small"/>
            </IconButton>}
        </div>
    </ThemeProvider>;
}

export default ProductTypeFilter;
