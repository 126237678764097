// store
import { useRecoilState } from 'recoil';
import { settingsDraftState } from "@/store/admin-panel/admin-panel.store";


// styles
import { useStyles } from "./style";

// components
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

const ReorderSettings = () => {
  const classes = useStyles();
  const [copySettings, setCopySettings] = useRecoilState(settingsDraftState);

  const onFieldChange = (field, value) => {
    const newSettings = copySettings.map(setting => {
      if (setting.setting_key === field) {
        return {
          ...setting,
          setting_value: value
        }
      }
      return setting;
    });

    setCopySettings(newSettings);
  };

  if (!copySettings) {
    return (<div>
      <CircularProgress
        color="primary"
        sx={{
          width: "15px !important",
          height: "15px !important",
          marginRight: "8px",
        }}
      />
      Loading...
    </div>)
  }

  return (
    <>
      <div className={classes.input}>
        <span className={classes.label}>Reorder Lead Time Weeks</span>
        <OutlinedInput
          value={copySettings?.find(s => s.setting_key === 'reorder_lead_time_weeks')?.setting_value}
          type="text"
          onChange={(e) => onFieldChange('reorder_lead_time_weeks', e.target.value)}
          endAdornment={<InputAdornment position="end">weeks</InputAdornment>}
          sx={{ width: '105px' }}
          className={classes.textInput}
        />
      </div>
      <div className={classes.input}>
        <span className={classes.label}>Active Item Weeks</span>
        <OutlinedInput
          value={copySettings?.find(s => s.setting_key === 'active_item_weeks')?.setting_value}
          type="text"
          onChange={(e) => onFieldChange('active_item_weeks', e.target.value)}
          endAdornment={<InputAdornment position="end">weeks</InputAdornment>}
          sx={{ width: '105px' }}
          className={classes.textInput}
        />
      </div>
      <div className={classes.input}>
        <span className={classes.label}>Active Item Sales Threshold</span>
        <OutlinedInput
          value={copySettings?.find(s => s.setting_key === 'active_item_sales_threshold')?.setting_value}
          type="text"
          onChange={(e) => onFieldChange('active_item_sales_threshold', e.target.value)}
          endAdornment={<InputAdornment position="end">units</InputAdornment>}
          sx={{ width: '105px' }}
          className={classes.textInput}
        />
      </div>
    </>
  )
}

export default ReorderSettings;
