import { range } from "lodash";
import { ITEM_PLANNER_COLUMNS } from "../../utils/item-planner-columns";

export const groupItemsBy = (items, groupFields) => {
    return items.reduce((groupedItems, item) => {
        if (item.path.length === 3) {
            const newPath = []
            groupFields.forEach((field) => { newPath.push(item[field]) })
            newPath.push(item.path[2])
            return [...groupedItems, { ...item, path: newPath }]
        }
        return groupedItems;
    }, [])
}

const sum = (amount) => {
    return amount
}

const avg = (amount, count) => {
    return amount / count
}

const GROUP_AGGREGATIONS = {
    [ITEM_PLANNER_COLUMNS["Avg Weekly Unit Sales"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Curr Units On Hand"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Reorder Target On Hand"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Expected Lead Time Unit Sales"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Raw Reco"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Case Rounded Reco"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Final Reco"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Weeks Of Supply"].field]: avg,
    [ITEM_PLANNER_COLUMNS["WGMROI"].field]: avg,
    [ITEM_PLANNER_COLUMNS["AUC"].field]: avg,
    [ITEM_PLANNER_COLUMNS["Cases to Order"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Case Pack Size"].field]: avg,
    [ITEM_PLANNER_COLUMNS["Total Order - Units"].field]: sum,
    [ITEM_PLANNER_COLUMNS["Total Order - Cost"].field]: sum,
}

export const getAggregatedGroupedRows = (items, productLevel) => {
    const SPLITTING_SIGN = "/"
    const levels = {};
    items.forEach(item => {
        range(0, productLevel).forEach((index) => {
            const path = item.path.slice(0, index + 1).join(SPLITTING_SIGN);
            if (!levels[path]) {
                levels[path] = []
            }
            levels[path] = [...levels[path], item];
        })
    })

    const rows = [];
    let uid = -1000;

    Object.entries(levels).forEach(([levelPath, children]) => {
        const path = levelPath.split(SPLITTING_SIGN);
        const row = {
            path,
            uid: uid++
        };

        // Set all aggregations fields to 0
        const aggregation = Object.keys(GROUP_AGGREGATIONS)
            .reduce((aggObj, field) =>
                Object.assign(aggObj, { [field]: 0 }), {})

        children.forEach(child => {
            Object.keys(aggregation).forEach(field => {
                aggregation[field] +=
                    Number.isFinite(+child[field]) ? +child[field] : 0
            })
        })

        Object.keys(aggregation).forEach(field => {
            aggregation[field] = GROUP_AGGREGATIONS[field](aggregation[field], children.length)
            row[field] = aggregation[field]
        })

        rows.push(row)
    })

    return rows;
}