import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Lottie from 'lottie-react';
import animationData from './pulse_loader.json'

const PulseLoader = ({ loop = false }) => {
    const lottieDefaultOptions = {
        loop: loop,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    return (
        <TransitionGroup
            className="fadein"
        >
            <CSSTransition
                className="fadein"
                key={1}
                timeout={300}
            >
                <div style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                >
                    <Lottie
                        animationData={animationData}
                        options={lottieDefaultOptions}
                        style={{
                            width: 250,
                            height: 250,
                            cursor: 'default'
                        }}
                        isClickToPauseDisabled
                    />
                </div>
            </CSSTransition>
        </TransitionGroup>
    )
};

export default PulseLoader;
