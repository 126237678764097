import { addCommas } from "../textFormatter";

export const numberNullFormatter = ({ value }, decimals = 0) => {
    const converted = Number(value);
    if (Number.isNaN(converted)) return null;
    return `${addCommas(Number(converted), decimals)}`;
}

export const precentsNullFormatter = ({ value }, decimals = 0) => {
    const converted = Number(value);
    if (Number.isNaN(converted)) return null;
    return `${addCommas(Number(converted), decimals)}%`;
}

export const decimalNullFormatter = ({ value }, decimals = 1) => {
    const converted = Number(value);
    if (Number.isNaN(converted)) return null;
    return converted.toFixed(decimals);
}

export const cashNullFormatter = ({ value }, decimals = 0) => {
    const converted = Number(value);
    if (Number.isNaN(converted)) return null;
    return `$${addCommas(Number(converted), decimals)}`;
}