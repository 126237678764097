// libs
import { ExportToCsv } from 'export-to-csv';
import React, { useEffect, useState } from 'react';

// deps
import OrganisationService from '../../../../../service/organisation-service';
import { metrics } from '../metric-groups';

// mui
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import TuneIcon from '@mui/icons-material/Tune';
import GetAppIcon from '@mui/icons-material/GetApp';

// styles
import { Wrapper, Actions, useStyles } from './styles';

// components
import Button from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';
import DataGrid from '../../../../../components/DataGrid';
import Sorter from '../../../../../components/DataGrid/Sorter';
import PageTitle from '../../../../../components/PageTitle';
import Filter from '../../../orders/purchase-order/components/Filter';
import WeekMonthPicker from '../../../../../components/WeekMonthPicker';

// store
import { useRecoilValue } from 'recoil';
import { currentOrg, districts as districtsState } from '../../../../../store';
import CustomSelect from '../../../../../components/CustomSelect';

const CategoryRecap = () => {
  const REPORT_NAME = 'category_recap_metric_order';

  const org = useRecoilValue(currentOrg);
  const orgId = org?.org_id;

  const classes = useStyles();
  const [raw, setRaw] = useState();
  const [data, setData] = useState();
  const [range, setRange] = useState();
  const [mode, setMode] = useState('');
  const [locationId, setLocationId] = useState(-1);
  const [locations, setLocations] = useState([]);
  const [metricGroups, setMetricGroups] = useState(metrics);
  const [isLoading, setIsLoading] = useState(true);
  const [isCustomizeFieldsModalOpen, setIsCustomizeFieldsModalOpen] = useState(false);
  const districts = useRecoilValue(districtsState);
  const [district, setDistrict] = useState(-1);

  useEffect(() => {
    const orderMap = JSON.parse(localStorage.getItem(REPORT_NAME));
    if (!orderMap) return;
    let ordered = [
      ...metricGroups.map((m) => ({
        ...m,
        visible: false
      }))
    ];
    const visible = [];
    orderMap.map((metric) => {
      const index = ordered.findIndex((o) => o.value === metric);
      const m = ordered.splice(index, 1);
      visible.push({
        ...m[0],
        visible: true
      });
    });
    ordered = ordered.sort((a, b) => {
      const cat1 = a?.display?.toUpperCase();
      const cat2 = b?.display?.toUpperCase();
      return cat1 < cat2 ? -1 : cat1 > cat2 ? 1 : 0;
    });
    setMetricGroups([...visible, ...ordered]);
  }, []);

  const loadLocationData = async () => {
    if (!orgId) return;
    try {
      setIsLoading(true);
      const { data: locations } = await OrganisationService.location.list(orgId, 100000);
      locations && setLocations(locations.loc || []);
    } catch (e) {
      console.log('Cannot get locations.');
      setLocations([]);
    }
  };

  useEffect(() => {
    loadLocationData().then();
  }, [orgId]);

  const filteredLocations = district === -1 ? locations : locations.filter((l) => district === -1 || l.district_id === district);
  console.log(district, locations);
  useEffect(() => {
    if (district === -1) {
      setLocationId(-1);
    }
  }, [district]);

  const loadRecapData = async () => {
    if (!orgId || locations.length === 0) return;
    try {
      setIsLoading(true);

      const locationIds = district === -1 ? locations.map((l) => l.id)
        : locationId === -1 ? locations
          .filter((l) => l.district_id === district)
          .map((l) => l.id) : [locationId];

      const { data } = await OrganisationService.reports.categoryRecap(
        orgId,
        locationId,
        range,
        mode,
        locationIds
      );

      const {
        categoryRecap,
        raw
      } = data;
      setData(categoryRecap);
      setRaw(raw);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadRecapData().then();
  }, [orgId, locationId, range, locations, district]);

  const onDownload = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      filename: 'category-recap',
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(raw);
  };

  const reset = () => {
    setRange(null);
    setMode('');
  };

  const onDateSelected = (value, mode) => {
    if (mode === 'week') {
      setRange(value.week_id);
      setMode(mode);
    } else {
      setRange([value.start_month, value.end_month]);
      setMode(mode);
    }
  };

  return (
    <Wrapper>
      <PageTitle>Category Recap Report</PageTitle>
      <Actions>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <CustomSelect
            value={district}
            options={districts.map(district => ({
              key: district.district_id,
              value: district.district_name
            }))}
            onChange={setDistrict}
            style={{ width: '200px', marginTop: '4px' }}
          />
          <Filter
            value={locationId}
            defaultLabel="All Locations"
            mainLabel="Location: "
            options={[{
              value: -1,
              label: 'All Locations'
            }, ...(filteredLocations || []).map((loc) => ({
              value: loc.id,
              label: loc.location_name
            }))]}
            onChange={(newVal) => {
              setLocationId(newVal);
            }}
          />
          <div>
            <WeekMonthPicker onDateSelected={onDateSelected} reset={reset} />
          </div>
          <Button
            style={{ padding: '0 40px', borderRadius: '10px', height: '32px' }}
            onClick={() => setIsCustomizeFieldsModalOpen(true)}>
            <TuneIcon />
            <span style={{ padding: '0 7px' }}>Metrics</span>
          </Button>
          <Modal
            className={classes.modalRoot}
            open={isCustomizeFieldsModalOpen}
            onClose={() => setIsCustomizeFieldsModalOpen(false)}
            closeAfterTransition>
            <Fade>
              <>
                <Sorter
                  metricGroups={metricGroups}
                  reportName={REPORT_NAME}
                  setMetricGroups={setMetricGroups}
                  onClose={() => setIsCustomizeFieldsModalOpen(false)}
                />
              </>
            </Fade>
          </Modal>
        </div>
        <div>
          <Button
            style={{ padding: '0 15px', borderRadius: '10px', height: '32px' }}
            onClick={onDownload}>
            <GetAppIcon />
            <span style={{ marginLeft: '5px' }}>Download</span>
          </Button>
        </div>
      </Actions>
      {isLoading && <Loader />}
      {!isLoading && data &&  (
        <DataGrid
          data={data}
          levelOneNameField="parent_name"
          levelTwoNameField="name"
          MainText="Actuals"
          CompareText="Forecast"
          VarianceText="Var %"
          metricGroups={metricGroups}
        />
      )}
      { !isLoading && !data && (
        <div style={{  marginTop: '20px' }}>
          <h6>No data found</h6>
        </div>
      )}
    </Wrapper>
  );
};

export default CategoryRecap;
