import API from "../api"

const LocationService = {
  getLocations: () => API.get(`org/location`),
  updateLocation: (locationId, newValues) => API.put(`org/location/${locationId}`, newValues),
  deleteLocation: (locationId) => API.delete(`org/location/${locationId}`),
  createLocation: (location) => API.post(`org/location`, location),
};

export default LocationService;
