import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { Section, Title, UnitComparisonLabel } from '../style';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomizedCheckbox from '../../../../../../../components/CheckBox/CheckBox';
import React from 'react';

export const unitComparisonState = atom({
  key: 'forecast_unit_comparison',
  default: {
    percentage: true,
    absolute: false
  }
});

export default () => {
  const [unitComparison] = useRecoilState(unitComparisonState);
  const setUnitComparison = useSetRecoilState(unitComparisonState);

  const handleChange = (event) => {
    setUnitComparison((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked
    }));
  };

  return (
    <Section>
      <Title>Unit Comparison</Title>
      <FormControl
        style={{
          padding: '0 10px'
        }}
        component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <CustomizedCheckbox
                color="#6c757d"
                name="percentage"
                checked={unitComparison.percentage}
                onChange={handleChange}
              />
            }
            label={<UnitComparisonLabel>Compare %</UnitComparisonLabel>}
          />
          <FormControlLabel
            control={
              <CustomizedCheckbox
                color="#6c757d"
                name="absolute"
                checked={unitComparison.absolute}
                onChange={handleChange}
              />
            }
            label={<UnitComparisonLabel>Compare Amount</UnitComparisonLabel>}
          />
        </FormGroup>
      </FormControl>
    </Section>
  );
};
