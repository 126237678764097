import styled from 'styled-components';
import Badge from '@mui/material/Badge';

const Container = styled.div``;
const FieldLabel = styled.label`
  font-size: 13px;
  font-weight: bold;
  margin: 0 !important;
`;

const Cell = styled.div`
  display: flex;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightAlignedCell = styled.div`
  display: flex;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: flex-end;
`;

const CenteredCell = styled.div`
  display: flex;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.i`color: #00A9A7`;

const Row = styled.div`
`

const CenterCol = styled.div`
  padding: 0 50px;
  min-width: 500px;
`;

const FieldContainer = styled.div`
  width: 200px;
  margin-right: 20px;
`;

const StatusBadge = styled(Badge)`
  padding: 5px 15px;
  font-size: 14px;
`

const Figure = styled.div`
  display: flex;
  margin-bottom: 7px;
`;

const FigureLabel = styled.div`
  width: 100px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
`

const FigureValue = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const ExportModalHeader = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
`;

const ExportOption = styled.div`
  border-radius: 5px;
  border: solid 1px #bfbebe;
  cursor: pointer;
  padding: 10px;
  margin: 20px 0;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(107, 255, 255, 0.26);
  }

  span {
    margin-left: 10px;
  }
`;

const OKButton = styled.span`
  background: #109185;
  opacity: 1;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
`;


const DeleteButton = styled.span`
  background: tomato;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
`;

const CancelButton = styled.span`
  background: #b4b3b3;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
`;

const FiltersContainer = styled.div`
  display: inline-block;
  margin-top: 15px;
  padding-left: 20px;
  font-weight: bold;
`;


export {
    Cell,
    Icon,
    FieldLabel,
    Container,
    Row,
    CenterCol,
    FieldContainer,
    StatusBadge,
    Figure,
    FigureLabel,
    FigureValue,
    RightAlignedCell,
    CenteredCell,
    ExportModalHeader,
    ExportOption,
    DeleteButton,
    CancelButton,
    OKButton,
    FiltersContainer
}
