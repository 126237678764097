import styled from 'styled-components';

import {
    Button as MaterialButton,
    TextField as MaterialInput,
    FormLabel as MaterialLabel,
    Select as MaterialSelect
} from '@mui/material';

export const Title = styled.h6`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 1rem;
`;

export const Section = styled.div`
  border-bottom: 1px solid white;
  padding: 15px;
`;

export const Button = styled(MaterialButton)`
  color: white;
  margin: 0 6px 0 0;
  font-size: 13px;
  padding: 2px 10px;
  cursor: pointer;
`;

export const VersionSelectionWrap = styled.div`
    display: flex;
`;

export const FilterLabel = styled(MaterialLabel)`
  color: #666 !important;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
`;

export const ResetMessage = styled.div`
  padding: 15px;
`;

export const ResetConfirmTitle = styled.div`
  background: rgb(122, 214, 224);
  border-radius: 5px 5px 0 0;
  color: white;
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
`;

export const TableContainer = styled.div`
  padding: 0 10px;
  margin: 0 0 20px 0;
`;

export const SimpleTable = styled.table`
  font-size: 13px;
  margin: 0 0 10px 0;
`;

export const Th = styled.th`
  border: solid 1px #f1f1f1;
  padding: 4px 8px;
`;

export const Td = styled.td`
  border: solid 1px #f1f1f1;
  padding: 4px 8px;
`;

export const ResetActions = styled.div`
    padding: 15px;
`;

export const ConfirmButton = styled.span`
  background: tomato;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
`;

export const CancelButton = styled.span`
  background: #b4b3b3;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
`;

export const Input = styled(MaterialInput)`
  cursor: pointer;
  border-radius: 10px;
  padding: 1px 10px 0 35px;
  font-weight: 600;
  font-size: 12px;
  color: rgba(41, 41, 41, 0.87);
  letter-spacing: 0.5px;
  background: #fff url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="rgba(41, 41, 41, 0.87)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>') no-repeat left .75rem center;
`;

export const CustomInput = styled(MaterialSelect)`
    border-radius: 10px;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    color: rgba(41, 41, 41, 0.87);
    letter-spacing: 0.5px;
    background: white;
`;


export const UnitComparisonLabel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
`;


export const ModalMessage = styled.div`
  padding: 10px;
`;

export const ModalTitle = styled.div`
  background: rgb(122, 214, 224);
  border-radius: 5px 5px 0 0;
  color: white;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const ModalActions = styled.div`
    padding: 20px;
`;

export const SubmitButton = styled.span`
  background: #109185;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;
`;

