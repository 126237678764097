export const getRandomAvailableUID = (existingItems) => {
    const uids = existingItems.map((item) => item.uid);
    let randomUID = 100 + Math.round(Math.random() * 3000);
    while (uids.includes(randomUID)) {
        randomUID = 100 + Math.round(Math.random() * 3000);
    }
    return randomUID;
};

export const duplicateExistingItem = (allItems, itemToDuplicate) => {
    const randomuid = getRandomAvailableUID(allItems);
    const newPath = [...itemToDuplicate.path];
    newPath[2] = randomuid;

    const newItem = {
        ...itemToDuplicate,
        uid: randomuid,
        path: newPath,
        "Item ID": undefined,
        isDummy: true,
    };
    return newItem;
};
