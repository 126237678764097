import { ALLOCATION_COLUMNS } from "../../utils/columns";

export const GROUP_BY_FIELDS = [
  {
    value: ALLOCATION_COLUMNS["Warehouse"].field,
    display: "Warehouse",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Vendor ID"].field,
    display: "Vendor ID",
    visible: false,

  },
  {
    value: ALLOCATION_COLUMNS["Vendor"].field,
    display: "Vendor",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Brand ID"].field,
    display: "Brand ID",
    visible: false,

  },
  {
    value: ALLOCATION_COLUMNS["Brand"].field,
    display: "Brand",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Product ID"].field,
    display: "Product ID",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Product Name"].field,
    display: "Product Name",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Product Type ID"].field,
    display: "Product Type ID",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Product Type"].field,
    display: "Product Type",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Product Category ID"].field,
    display: "Product Category ID",
    visible: false,

  },
  {
    value: ALLOCATION_COLUMNS["Category"].field,
    display: "Category",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Sub Category ID"].field,
    display: "Sub Category ID",
    visible: false,

  },
  {
    value: ALLOCATION_COLUMNS["Sub Category"].field,
    display: "Sub Category",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Location ID"].field,
    display: "Location ID",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Location Name"].field,
    display: "Location Name",
    visible: false,
  },
  {
    value: ALLOCATION_COLUMNS["Location Type"].field,
    display: "Location Type",
    visible: false
  },
]

export const getGroupedFieldsValues = (fields = GROUP_BY_FIELDS) => {
  return fields.reduce((grouped, field) => {
    if (field.visible) {
      return grouped.concat(field.value)
    }
    return grouped
  }, [])
}
