import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  error: {
    color: '#d94c4c',
  },
  form: {
    marginTop: '20vh',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '200px',
    border: '1px solid #e1e1e1',
    borderRadius: '20px',
    padding: '40px'
  }
}));
