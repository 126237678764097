import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	adminPanel: {
		display: 'flex',
		flexDirection: 'column',
		padding: '20px 20px 0px 20px',
	},
	inside: {
		background: '#f0f0f0',
		paddingBottom: '20px',
	},
	panel: {
		height: '80vh',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	content: {
		display: 'flex',
		flex: 1,
		overflow: 'auto',
		"&::-webkit-scrollbar": {
			width: "8px",
		},
		"&::-webkit-scrollbar-track": {
			background: "#f1f1f1",
		},
		"&::-webkit-scrollbar-thumb": {
			background: "#bfbfbf",
		},
		"&::-webkit-scrollbar-thumb:hover": {
			background: "#8d8d8d",
		},
	},
	nav: {
		height: '20px',
		display: 'flex',
		alignItems: 'center'
	},
	navItem: {
		width: '100px',
		textTransform: 'uppercase'
	},
	tabs: {
		height: '60px',
		margin: '10px 20px',
		'& .MuiTabs-flexContainer': {
			height: '100%',
			borderBottom: '2px solid #dddddd'
		},
		'& .MuiTabs-indicator': {
			backgroundColor: theme.palette.gsDarkGreen.main,
		},
		'& .Mui-selected': {
			color: `${theme.palette.gsDarkGreen.main} !important`,
			fontWeight: 'bold',
		},
	},
	tab: {
		width: '100px',
	},
	loadingOverlay: {
		position: 'absolute',
		width: '100%',
		height: '-webkit-fill-available',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 10,
		backgroundColor: '#ddddd',
		opacity: 0.7,
	},
}));
