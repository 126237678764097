// libs
import Router from './routing/router';
import React from 'react';
import { theme } from "../src/theme/theme";
import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { ThemeProvider } from '@mui/material/styles';

// deps
import { Provider } from './context/store';

// styles
import './App.css';

const useStyles = makeStyles(() => ({
  // this is breaking the file browser component, even if you try other solutions.
  // variantSuccess: { backgroundColor: '#19d1dd' },
  // variantError: { backgroundColor: '#e84748 !important' }
}));

const App = () => {
  const classes = useStyles();
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY);

  return (
    <ThemeProvider theme={theme}>
      <Provider>
        <SnackbarProvider classes={classes} maxSnack={3} anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}>
          <Router />
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
