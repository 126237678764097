import { useMemo } from "react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

// styles
import { useStyles } from "./style";

// store
import { useRecoilState, useRecoilValue } from "recoil";
import {
  brandsState,
  productsState,
  vendorsState,
  productTypesState
} from "@/store/admin-panel/admin-panel.store";

import {
  selectedBrandFilterState,
  selectedVendorFilterState,
  selectedCategoriesFilterState,
} from "@/store/admin-panel/products.store";

// components

import ProductsFilters from "./filters";
import Actions from "../shared/actions";
import CreateProductDialog from "./create-product-dialog";
import { filterProducts } from "./utils/filter-products";
import CustomizedCheckbox from "@/components/CheckBox/CheckBox";

// mui
import Pagination from '@mui/material/Pagination';
import { DataGridPro,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector } from "@mui/x-data-grid-pro";
import { Box, FormGroup, FormControlLabel } from "@mui/material";

// services
import ProductService from "@/service/org/product.service";

// utils
import {
  cashNullFormatter,
  precentsNullFormatter
} from "../../../../utils/mui-data-grid/formatters";
import DeleteConfirmation from '../../../../components/DeleteConfirmation';

const Products = ({ onRefetch, onProcessing }) => {
  // state
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const brands = useRecoilValue(brandsState);
  const vendors = useRecoilValue(vendorsState);
  const products = useRecoilValue(productsState);
  const categories = useRecoilValue(productTypesState);
  const [pageSize, setPageSize] = useState(100);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const selectedBrandFilter = useRecoilValue(selectedBrandFilterState);
  const selectedVendorFilter = useRecoilValue(selectedVendorFilterState);
  const [selectedCategories, setSelectedCategories] = useRecoilState(selectedCategoriesFilterState);

  const [selectedProduct, setSelectedProduct] = useState();
  const [showInactive, setShowInactive] = useState(false);
  const [showUpdateCreatePopup, setShowUpdateCreatePopup] = useState(false);

  // handlers
  const setAllCategoriesAsSelected = () => {
    const categoriesWithSelection = {};
    Object.entries(categories).forEach(([parentId, parent]) => {
      const childrenWithSelection = {};

      Object.entries(parent.children ?? {}).forEach(([childId, child]) => {
        childrenWithSelection[childId] = {
          ...child,
          selected: true,
        }
      });

      categoriesWithSelection[parentId] = {
        ...parent,
        children: childrenWithSelection,
        selected: true,
      };
    })
    setSelectedCategories(categoriesWithSelection);
  }

  useEffect(() => {
    setAllCategoriesAsSelected();
  }, []);

  const deleteProduct = (product) => {
    setSelectedProduct(product);
    setDeletePopupOpen(true);
  };

  const onDeleteConfirmed = async () => {
    try {
      onProcessing();
      await ProductService.deleteProduct(selectedProduct.id);
      enqueueSnackbar('Product Deleted Successfully', {
        variant: 'success'
      });
      onRefetch();
    } catch (err) {
      enqueueSnackbar('Delete product failed', {
        variant: 'error'
      });
    }
  }

  const PRODUCTS_COLUMNS = [
    {
      field: 'product_name',
      headerName: 'Product Name',
      width: 200,
    },
    {
      field: 'distributor_id',
      headerName: 'Vendor',
      width: 120,
      valueGetter: ({ row }) =>
        vendors.find(vendor => vendor.id === row.distributor_id)?.distributor_name || ""
    },
    {
      field: 'brand_id',
      headerName: 'Brand',
      width: 120,
      valueGetter: ({ row }) => brands.find(brand => brand.id === row.brand_id)?.name || ""
    },
    {
      field: 'Status',
      type: 'status',
      renderCell: ({ row }) => {
        return <span>
          { +row.status_id === 1 ? "Active" : "Inactive"}
        </span>
      }
    },
    {
      field: 'category_id',
      headerName: 'Category',
      width: 150,
      valueGetter: ({ row }) => row.category.name
    },
    {
      field: 'sub_category_id',
      headerName: 'Sub Category',
      width: 150,
      valueGetter: ({ row }) => row.subCategory.name
    },
    {
      field: 'default_cost',
      headerName: 'Default Cost',
      width: 120,
      valueFormatter: (v) => cashNullFormatter(v, 1),
    },
    {
      field: 'default_price',
      headerName: 'Default Price',
      width: 120,
      valueFormatter: (v) => cashNullFormatter(v, 1),
    },
    {
      field: 'case_rounding',
      headerName: 'Case Rounding',
      width: 120,
    },
    {
      field: 'unit_of_measurement',
      headerName: 'Unit of Measurement',
      width: 120,
      valueGetter: ({ row }) => row.unit_of_measurement || 'units'
    },
    {
      field: 'tax_rate',
      headerName: 'Tax Rate',
      width: 120,
      valueFormatter: precentsNullFormatter,
    },
    {
      field: 'actions',
      type: 'actions',
      renderCell: ({ row }) => {
        return <Actions
          onDelete={() => deleteProduct(row)}
          onEdit={() => {
            setSelectedProduct(row);
            setShowUpdateCreatePopup(true);
          }}
        />
      }
    }
  ];

  const filteredProducts = useMemo(() =>
    filterProducts(products, {
      selectedVendor: selectedVendorFilter,
      selectedBrand: selectedBrandFilter,
      selectedCategories: selectedCategories,
      showInactive,
    })
    , [products,
      selectedVendorFilter,
      selectedBrandFilter,
      selectedCategories,
      showInactive]);

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  return (
    <Box className={classes.locationsContainer} sx={{ width: '100%' }}>
      <div className={classes.actions}>
        <ProductsFilters
          vendors={vendors}
          brands={brands}
          products={products}
          categories={selectedCategories}
        />
      </div>
      <DataGridPro
        className={classes.table}
        loading={products.length === 0}
        rows={filteredProducts}
        columns={PRODUCTS_COLUMNS}
        getRowClassName={(params) => +params.row.status_id !== 1 && 'inactive'}
        getRowId={(row) => row.masking_id}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        components={{
          Footer: () => (
            <div className={classes.activeFilter}>
              <FormGroup>
                <FormControlLabel control={<CustomizedCheckbox
                  checked={showInactive}
                  color="#19d1dd"
                  onChange={() => {
                    setShowInactive(currActivity => !currActivity);
                  }}
                />} label={<span style={{ marginLeft: '2px' }}>Show inactive products</span>} />
              </FormGroup>
              <CustomPagination />
            </div>),
        }}
      />
      <CreateProductDialog
        show={showUpdateCreatePopup}
        onCloseDialog={() => {
          setShowUpdateCreatePopup(false);
          setSelectedProduct(undefined)
        }}
        onCreate={onRefetch}
        onUpdate={onRefetch}
        product={selectedProduct}
      />
      <DeleteConfirmation
        title={`Delete product`}
        content={`Are you sure you want to delete ${selectedProduct?.product_name}?`}
        onClose={() => {
          setDeletePopupOpen(false);
          setTimeout(() => setSelectedProduct(null), 1000);
        }}
        onDelete={onDeleteConfirmed}
        open={deletePopupOpen} />
    </Box>
  )
}

export default Products
