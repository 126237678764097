const key = 'reorder-pro-group-by-fields';

const storeGroupByFields = (fields) => {
  return localStorage.setItem(key, JSON.stringify(fields));
  set
}

const getStoredGroupByFields = () => {
  return JSON.parse(localStorage.getItem(key));
}

export {
  storeGroupByFields,
  getStoredGroupByFields
}