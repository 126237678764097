import React, { useState, useEffect } from "react";
import Sorter from "../../../../components/DataGrid/Sorter";
import { useStyles } from "./style";

// mui
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";

const getHiddenColumns = (columns) => {
  return columns.reduce((all, column) => {
    if (!column.visible) {
      return all.concat(column.field)
    }
    return all
  }, [])
}

const ColumnsDialog = ({
  show,
  columns,
  hiddenColumns,
  onChangeHiddenColumns,
  onChangeColumns,
  onCloseDialog,
}) => {
  const classes = useStyles();

  const [metrics, setMetrics] = useState([]);

  const initMetrics = (hiddenColumns) => {
    const currentMetrics = columns.map((column) => {
      if (hiddenColumns.includes(column.field)) {
        return { ...column, visible: false, display: column.headerName, value: column.field }
      }
      return { ...column, visible: true, display: column.headerName, value: column.field }
    }).sort((a, b) => hiddenColumns.indexOf(a.value) - hiddenColumns.indexOf(b.value));
    setMetrics(currentMetrics)
  }

  useEffect(() => {
    initMetrics(hiddenColumns);
  }, [hiddenColumns])

  const cleanAndClose = () => {
    initMetrics(hiddenColumns)
    onCloseDialog();
  }

  const closeDialog = () => {
    onChangeHiddenColumns(getHiddenColumns(metrics));
    onChangeColumns(metrics)
    cleanAndClose()
  }

  return (
    <Dialog
      open={show}
      onClose={cleanAndClose}
      PaperProps={{
        sx: {
          width: "350px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }} className={classes.wrapper}>
        <Sorter
          metricGroups={metrics}
          setMetricGroups={setMetrics}
          cacheInLocalStorage={false}
          headerComponent={
            <div className={classes.header}>
              <Typography
                component="h1"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Columns
              </Typography>
            </div>}
          bodyStyle={{
            padding: '0px 20px 15px 20px'
          }}
        />
        <div className={classes.actions}>
          <Button
            onClick={closeDialog}
            variant="contained"
            color="primary"
            sx={{
              flex: 1,
              padding: "10px 0px",
              color: 'white'
            }}
          >
            Set Columns
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ColumnsDialog;
