const commonStyle = {
  input: {
    marginBottom: '10px',
    width: '300px',
  },
  label: {
    display: 'block',
    fontSize: '14px',
    fontWeight: '500',
  },
  headingLabel: {
    fontSize: '17px',
    fontWeight: 'bold'
  },
  textInput: {
    margin: '5px 0',
    width: '70px',
    fontSize: '13px !Important',
    boxSizing: 'border-box',
    minHeight: '32px',
    background: '#fff',
    borderRadius: '0.75em !Important',
    textAlign: 'left',
    lineHeight: '1.5 !Important',
    '& legend': {
      width: 'unset !Important'
    },
    '& > input': {
      padding: '5px 5px 5px 9px ',
    },
    input: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    },
  },
};

export default commonStyle;
