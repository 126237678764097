import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  icon: {
    height: 'inherit'
  },
  toolbarContainer: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '6px',
    '& .MuiButton-root': {
      color: '#212121'
    }
  },
  toolbarButton: {
    marginRight: '7px !important',
    fontWeight: '400 !important'
  }
}));
