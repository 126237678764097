import { cloneDeep } from "lodash";
import { aggregateValues } from "../../../../../../../utils/aggregate";
import { splitProportionally } from "../../../../../../../utils/splitProportianlly";

// Input: categoryTargets config format
// Output: Tree object of the hierarchy
export const convertToTree = (categoryTargets, locations) => {
  const hierarchy = {}

  Object.entries(categoryTargets).forEach(([locationId, locationData]) => {

    const location = locations.find(location => +location.id === +locationId);

    if (location) {
      hierarchy[location.id] = {
        name: location?.name || "unknown location",
      }

      const categories = {}

      locationData.children.forEach(category => {
        const copyCategory = { ...category };

        const subCategories = {};
        if (copyCategory.children && copyCategory.children.length > 0) {
          copyCategory.children.forEach(subCategory => {
            subCategories[subCategory.name] = {
              ...subCategory,
              locationId: locationId
            }
          })
        }

        delete copyCategory.children;
        categories[copyCategory.name] = {
          ...copyCategory,
          subCategories,
          locationId: locationId
        }
      })

      hierarchy[location.id].categories = categories;
    }
  })

  return hierarchy;
}

// Input: Tree object of the hierarchy
// Output: Tree with costs calculated fields (cost_on_hand, cost_woh...)
export const addCostToTree = (tree) => {
  const cTree = cloneDeep(tree);

  Object.entries(cTree).forEach(([locationId, location]) => {

    Object.entries(location.categories).forEach(([categoryName, category]) => {
      const subCategories = category.subCategories;
      Object.entries(subCategories).forEach(([subCategoryName, subCategory]) => {
        const costSales = calculateCostSales(subCategory);
        const costOnHand = calculateCostOnHand(subCategory);
        const targetCostOnHand = calculateTargetCostOnHand(subCategory);
        subCategory.cost_sales = costSales
        subCategory.cost_on_hand = costOnHand;
        subCategory.cost_woh = costSales > 0 ?
          costOnHand /
          costSales : 0;
        subCategory.target_cost_on_hand = targetCostOnHand;
      })

      const categoryCostFields = aggregateValues(Object.values(subCategories),
        ['cost_sales', 'cost_on_hand', 'cost_woh', 'target_cost_on_hand']);

      Object.entries(categoryCostFields).forEach(([field, value]) => {
        category[field] = value
      })
    })
  })

  return cTree;
}

// Input: Tree with costs calculated fields
// Output: Data grid's format rows of the full hierarchy
export const treeToRows = (tree) => {
  const rows = [];
  let serialId = 0;

  Object.entries(tree).forEach(([locationId, location]) => {

    Object.entries(location.categories).forEach(([categoryName, category]) => {

      const copyCategory = { ...category };
      delete copyCategory.subCategories;

      rows.push({
        path: [categoryName],
        locationId: locationId,
        id: serialId++,
        ...copyCategory
      })

      Object.entries(category.subCategories).forEach(([subCategoryName, subCategory]) => {
        rows.push({
          path: [categoryName, subCategoryName],
          locationId: locationId,
          id: serialId++,
          ...subCategory
        })
      })

    })


  })

  return rows;
}

// Input: Data grid's format rows of the full hierarchy
// Output: rows groupoed by category, all locations together
export const combineRows = (rows) => {
  const SPLIT_SIGN = ':';
  const combinedRows = [];

  const categories = {};

  rows.forEach(row => {
    // row is subCategory
    if (row.path.length === 2) {
      const categorySubCategoryKey = row.path[0] + SPLIT_SIGN + row.path[1];
      const categoryKey = row.path[0];

      const copyRow = { ...row };
      delete copyRow.path;

      if (!categories[categorySubCategoryKey]) {
        categories[categorySubCategoryKey] = {};
      }

      if (!categories[categoryKey]) {
        categories[categoryKey] = {};
      }

      Object.entries(copyRow).forEach(([field, value]) => {
        categories[categorySubCategoryKey][field] = (categories[categorySubCategoryKey][field] || 0) + value
        categories[categoryKey][field] = (categories[categoryKey][field] || 0) + value
      })
    }
  })

  let serialId = 0;
  Object.entries(categories).forEach(([key, aggregation]) => {
    // In case the key is for category only
    if (key.split(SPLIT_SIGN).length === 1) {
      combinedRows.push({
        path: [key],
        ...aggregation,
        id: serialId++,
      })
    } else {
      // In case the key is for a sub category in category
      const names = key.split(SPLIT_SIGN);

      combinedRows.push({
        path: [names[0], names[1]],
        ...aggregation,
        id: serialId++,
      })
    }
  })
  return combinedRows;
}

// Populate the field change to parents / children
export const onFieldChange = (tree, { value, row }, field) => {
  const delta = value - row[field];
  const cTree = cloneDeep(tree);

  if (delta !== 0) {
    const relaventLocation = row.locationId;
    const relaventCategory = row.path[0];

    if (cTree[relaventLocation]) {

      cTree[relaventLocation].categories[relaventCategory][field] += delta;

      // Change is in the category level, requires chlidren update
      if (row.path.length === 1) {
        const children = Object.values(cTree[relaventLocation].categories[relaventCategory].subCategories)
        const childrenValues = children.map(child => child[field])
        const deltas = splitProportionally(delta, childrenValues);

        children.forEach((child, index) => {
          child[field] += deltas[index]
        })
      } else {
        // Change is in the subcateogry level, requires sub category update
        const relaventSubCategory = cTree[relaventLocation].categories[relaventCategory].subCategories[row.path[1]];
        relaventSubCategory[field] += delta;
      }
    }
  }

  return cTree;
}

// Populate the field change to parents / children when All Locations mode is on
export const onFieldChangeAllLocations = (tree, { value, row }, field) => {
  const delta = value - row[field];
  let cTree = cloneDeep(tree);

  if (delta !== 0) {
    const relaventCategoryName = row.path[0];

    const relaventCategories = [];

    // Go through all locations
    Object.entries(cTree).forEach(([locationId, location]) => {
      // For every category
      Object.entries(location.categories).forEach(([categoryName, category]) => {
        if (categoryName === relaventCategoryName) {
          relaventCategories.push(category);
        }
      })
    })

    const categoriesValues = relaventCategories.map(category => category[field])
    const deltas = splitProportionally(delta, categoriesValues);

    relaventCategories.forEach((category, index) => {
      cTree = onFieldChange(
        cTree,
        {
          value: deltas[index] + category[field],
          row: {
            path: [category.name],
            locationId: category.locationId,
            [field]: category[field]
          }
        },
        field)
    })
  }

  return cTree;
}

// ----------------------------------------------
const calculateCostSales = (row) => (+row.auc || 0) * (+row.unit_sales || 0);
const calculateCostOnHand = (row) => {
  return (+row.auc || 0) * (+row.on_hand || 0)
};
const calculateTargetCostOnHand = (row) => {
  return (+row.auc || 0) * (+row.target || 0);
};
export const calculateWoh = (row) => {
  return row.unit_sales > 0 ? row.target / row.unit_sales : 0;
};
export const calculateParentAvg = (rows, row, getValue, allLocations = false) => {
  const children = rows.filter(dataRow => dataRow.path.length === 2 &&
    (allLocations ? true : dataRow.locationId === row.locationId) &&
    dataRow.path[0] === row.path[0])
  if (children.length === 0) return 0;
  const avg = children.reduce((sum, curr) => sum += getValue(curr), 0) / children.length
  return avg;
}
