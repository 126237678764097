import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useStyles } from "./style";

// utils
import { sortAlphabetically } from '@/utils/arrays';
import useUserData from '@/utils/hooks/useUserData';
import { uniqBy } from 'lodash';

// services
import UserService from '@/service/master/user.service';
import SettingService from '@/service/org/setting.service';
import OrganisationService from '@/service/organisation-service';
import {
	brandsState,
	locationsState,
	productsState,
	settingsState,
	usersState,
	userTypesState,
	vendorsState,
	productTypesState,
} from '@/store/admin-panel/admin-panel.store';
import { useRecoilState, useSetRecoilState } from 'recoil';

// components
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';

// tabs components
import Locations from './locations';
import Products from './products';
import Settings from './settings';
import Users from './users';
import Vendors from './vendors';

// tabs services
import LocationService from '@/service/org/location.service';
import ProductService from '@/service/org/product.service';
import VendorService from '@/service/org/vendor.service';
import ProductTypeService from "@/service/org/product-type.service";
import { settingsDraftState } from '../../../store/admin-panel/admin-panel.store';

const AdminPanel = () => {
	const classes = useStyles()
	const userData = useUserData();

	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	// Sections data
	const [settings, setSettings] = useRecoilState(settingsState);
	const setSettingsDraft = useSetRecoilState(settingsDraftState);
	const setUsers = useSetRecoilState(usersState);
	const setUserTypes = useSetRecoilState(userTypesState);
	const setLocations = useSetRecoilState(locationsState);
	const setVendors = useSetRecoilState(vendorsState);
	const setProducts = useSetRecoilState(productsState);
	const setBrands = useSetRecoilState(brandsState);
	const setProductTypes = useSetRecoilState(productTypesState);

	// Fetching Sections data
	const fetchSettings = async () => {
		setLoading(true);
		try {
			const settings = await SettingService.getSettings();
			settings.data["Default Tax Rate"] = parseFloat(settings.data["Default Tax Rate"]) * 100;
			setSettings(settings.data);
			setSettingsDraft(settings.data);
		} catch (err) {
			enqueueSnackbar("Failed to fetch settings", {
				variant: 'error'
			})
		} finally {
			setLoading(false)
		}
	}

	const fetchUsers = async () => {
		setLoading(true);
		try {
			const users = await UserService.getOrgUsers();
			setUsers(users.data)
		} catch (err) {
			enqueueSnackbar("Failed to fetch users", {
				variant: 'error'
			})
		} finally {
			setLoading(false)
		}
	}

	const fetchUserTypes = async () => {
		setLoading(true);
		try {
			const userTypes = await UserService.getUserTypes();
			setUserTypes(userTypes.data);
		} catch (err) {
			enqueueSnackbar("Failed to fetch user types", {
				variant: 'error'
			});
		} finally {
			setLoading(false);
		}
	};

	const fetchLocations = async () => {
		setLoading(true);
		try {
			const locations = await LocationService.getLocations();
			setLocations(locations.data)
		} catch (err) {
			enqueueSnackbar("Failed to fetch locations", {
				variant: 'error'
			})
		} finally {
			setLoading(false)
		}
	};

	const fetchVendors = async () => {
		setLoading(true);
		try {
			const vendors = await VendorService.getVendors();
			const uniqVendors = uniqBy(vendors.data, "distributor_name");
			const sortedVendors = sortAlphabetically(uniqVendors, 'distributor_name');
			setVendors(sortedVendors);
		} catch (err) {
			enqueueSnackbar("Failed to fetch vendors", {
				variant: 'error'
			})
		} finally {
			setLoading(false)
		}
	}

	const fetchProducts = async () => {
		setLoading(true);
		try {
			const products = await ProductService.getProducts();
			setProducts(products.data);
		} catch (err) {
			enqueueSnackbar("Failed to fetch products", {
				variant: 'error'
			})
		} finally {
			setLoading(false)
		}
	}

	const fetchBrands = async () => {
		setLoading(true);
		const brands = await OrganisationService.brands.list(userData.org_public_id);
		const sortedBrands = sortAlphabetically(brands.data, 'name');
		setBrands(sortedBrands)
		setLoading(false)
	}

	const fetchProductTypes = async () => {
		setLoading(true);
		const productTypes = await ProductTypeService.getProductTypesHierarchy();
		setProductTypes(productTypes.data);
	}

	const fetchSection = {
		users: fetchUsers,
		userTypes: fetchUserTypes,
		locations: fetchLocations,
		settings: fetchSettings,
		vendors: fetchVendors,
		products: fetchProducts,
		brands: fetchBrands,
		productTypes: fetchProductTypes,
	}

	// Section to show handling
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const tabs = [
		{
			display: 'Settings',
			component: <Settings onRefetch={fetchSection.settings} onProcessing={() => setLoading(true)} />,
		},
		{
			display: 'Users',
			component: <Users onRefetch={fetchSection.users} onProcessing={() => setLoading(true)} />,
		},
		{
			display: 'Locations',
			component: <Locations onRefetch={fetchSection.locations} onProcessing={() => setLoading(true)} />,
		},
		{
			display: 'Vendors',
			component: <Vendors onRefetch={fetchSection.vendors} onProcessing={() => setLoading(true)} />,
		},
		{
			display: 'Products',
			component: <Products onRefetch={fetchSection.products} onProcessing={() => setLoading(true)} />,
		},
	];

	const currentTab = tabs[selectedTabIndex].component;

	const LoadingOverlay = () => <div className={classes.loadingOverlay}>
		<CircularProgress />
	</div>

	useEffect(() => {
		if (!settings) {
			setLoading(true);
		}
		// Fetch all Sections data
		Object.values(fetchSection).forEach(fetchFunc => fetchFunc());
	}, [])

	return (
		<div className={classes.adminPanel}>
			<Typography component="h2" sx={{
				lineHeight: "1",
				fontSize: "1.8rem",
				fontWeight: "bold",
				textTransform: "uppercase",
				padding: '0px 0px 13px 0px',
			}}>
				Admin
			</Typography>
			<div className={classes.inside}>
				<div className={classes.panel}>
					<Tabs
						className={classes.tabs}
						value={selectedTabIndex}
						onChange={(_, newTabIndex) => { setSelectedTabIndex(newTabIndex) }}
						aria-label="Admin Pages"
					>
						{
							tabs.map((tab) => (
								<Tab key={tab.display} className={classes.tab} label={tab.display} />
							))
						}

					</Tabs>
					<div className={classes.content} style={{
						...(loading && { pointerEvents: 'none' })
					}}>
						{loading && <LoadingOverlay />}
						{!loading && currentTab}
					</div>
				</div>
			</div>
		</div >
	);
};

export default AdminPanel;
