import { useRecoilState } from 'recoil';

// styles
import { useStyles } from "./style";
import classNames from "classnames";

// store
import { settingsDraftState } from "@/store/admin-panel/admin-panel.store";

// components
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

// mui
import CircularProgress from "@mui/material/CircularProgress";

const FinanceSettings = () => {
  const classes = useStyles();
  const [copySettings, setCopySettings] = useRecoilState(settingsDraftState);


  // handlers
  const onChange = (event) => {
    const intValue = parseInt(event.target.value, 10);

    if (intValue > 100) return
    if (intValue < 0) return

    let newValue = isNaN(intValue) ? '' : (intValue / 100);
    const newSetting = copySettings.map(setting => {
      if (setting.setting_key === 'default_tax_rate') {
        return {
          ...setting,
          setting_value: newValue
        }
      }
      return setting;
    });

    setCopySettings(newSetting);
  };

  if (!copySettings) {
    return (<div>
      <CircularProgress
        color="primary"
        sx={{
          width: "15px !important",
          height: "15px !important",
          marginRight: "8px",
        }}
      />
      Loading...
    </div>)
  }

  return (
    <>
      <div className={classNames(classes.input, classes.sliderInput)}>
        <span className={classes.label}>Default Tax Rate</span>
        <OutlinedInput
          type="text"
          onChange={onChange}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          value={Math.trunc(+(copySettings?.find(s => s.setting_key === 'default_tax_rate')?.setting_value ?? 0) * 100)}
          size="small"
          className={classes.textInput}
        />
      </div>
    </>
  )
}

export default FinanceSettings;
