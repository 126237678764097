const initialState = {
    doFetch: true,
    boosters: [],
    optimizedPercentage: "0",
    totalValue: 0,
    rowCount: 25,
    totalRecordCount: 0,
    currentPageNumber: 1
};

const reducer = (state, action) => {
    switch (action.type) {
        case "fetch": return { ...state, doFetch: true }
        case "noFetch": return { ...state, doFetch: false }
        case "updateBoosterList": return { ...state, boosters: action.payload }
        case "updateTotalValue": return { ...state, totalValue: action.payload }
        case "updateOptimizedPercentage": return { ...state, optimizedPercentage: action.payload }
        case "addBooster": return {
            ...state,
            doFetch: true,
            currentPageNumber: 1
        }
        case "updateBooster": return {
            ...state,
            boosters: state.boosters.map(booster => booster.loc_public_id === action.payload.loc_public_id ? action.payload : booster)
        }
        case "removeBooster": {
            const newBoosterList = state.boosters.filter(booster => booster.id !== action.payload.id)
            if (newBoosterList.length === 0) {
                if (state.currentPageNumber === 1)
                    return { ...initialState }
                else
                    return { ...state, currentPageNumber: state.currentPageNumber - 1, doFetch: true }
            }
            else
                return {
                    ...state,
                    boosters: newBoosterList,
                    totalRecordCount: state.totalRecordCount - 1
                }
        }
        case "updateRowCount": return { ...state, rowCount: action.payload }
        case "updateTotalRecordCount": return { ...state, totalRecordCount: action.payload }
        case "updateCurrentPageNumber": return { ...state, currentPageNumber: action.payload }
        case "reset": return initialState
        default: return state
    }
}

export { initialState, reducer } 