import { makeStyles, withStyles } from '@mui/styles';
import TreeItem from '@mui/lab/TreeItem';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring/web.cjs';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';

export const StyledTreeItem = withStyles(() => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 23,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

export const MinusSquare = props => {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
};

export const PlusSquare = props => {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
};

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: 35,
        overflowX: 'scroll'
}}));


export const CellGroupWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  &:hover {
    background: #f1f1f1;
  }
`;

export const CellGroup = styled.div`
  display: flex;
  margin: 0 10px;
`;

export const Cell = styled.div`
  flex: 1;
  width: 75px;
  text-align: right;
  padding: 2px 4px;
  font-size: 12px;
  border: solid 1px #e0e4eb;
`;

export const ColGroupTitle = styled.div`
  text-align: center;
  background-color: #e0e4eb;
  padding: 2px 0;
`;

export const LevelHeader = styled.div`
  width: 200px;
  min-width: 200px;
  padding: 2px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: sticky;
  background: rgb(224, 228, 235);
  box-shadow: rgb(136 136 136) 3px 0 5px -2px;
  left: 0;
`;

export const LevelHeader2 = styled.div`
  width: 200px;
  min-width: 200px;
  padding: 2px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: sticky;
  background: white;
  box-shadow: rgb(136 136 136) 3px 0 5px -2px;
  left: 0;
`;

export function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)'
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    in: PropTypes.bool,
};
