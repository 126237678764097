import React from "react";
import styled from "styled-components";
import { Cell, CellGroup, CellGroupWrap, LevelHeader } from "../styles";
import { toPercent } from "../../../utils/textFormatter";

const Wrap = styled.div`
  background-color: white;
  display: flex;
  font-size: 14px;
  margin-bottom: 1px;
  font-weight: bold;
`;

const LevelOne = ({ data, fields, levelName }) => (
  <Wrap>
    <LevelHeader>{data[levelName]}</LevelHeader>
    <CellGroupWrap>
      {fields
        .filter((f) => !!f.visible)
        .map((field, i) => {
          const main = data[field.value];
          const mainFormatted = field.format(data[field.value]);
          const compare = data[`fcst_${field.value}`];
          const compareFormatted = field.format(data[`fcst_${field.value}`]);
          const variance =
            compare === 0 ? 0 : (main - compare) / Math.abs(compare);

          return (
            <CellGroup key={`mg1_${i}`}>
              <Cell>{main === "Infinity" ? "∞" : mainFormatted}</Cell>
              <Cell>{compare === "Infinity" ? "∞" : compareFormatted}</Cell>
              <Cell>
                {variance === Infinity ? "%" : toPercent(variance, 0, 100)}
              </Cell>
            </CellGroup>
          );
        })}
    </CellGroupWrap>
  </Wrap>
);

export default LevelOne;
