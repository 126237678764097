import React from 'react';
import SalesWidget from "./widgets/sales";
import Widget from "./widgets/widget/index";
import TargetGraph from "./widgets/category-targets";
import ROIBoosterWidget from "./widgets/roi-booster";
import PageTitle from "../../../components/PageTitle";
import FiveWeeksForecastWidget from "./widgets/five-week-forecast";
import OrganisationService from '../../../service/organisation-service';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useRecoilValue } from 'recoil';
import { currentOrgId } from '../../../store';

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	border: 'none',
	boxShadow: 'none',
}));

function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button onClick={resetErrorBoundary}>Try again</button>
		</div>
	)
}

const myErrorHandler = (error, info) => {
	// Do something with the error
	// E.g. log to an error logging client here
}

const Dashboard = () => {
	window.eventBus.dispatch("sidebarCollapse", false);
	const orgId = useRecoilValue(currentOrgId);
	const serviceProvider = OrganisationService.dashboard(orgId);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', padding: '25px', rowGap: '25px' }}>
			<PageTitle>Dashboard</PageTitle>
			<Box sx={{
				width: '100%',
				display: 'grid',
				gridTemplateColumns: 'repeat(2, 1fr)',
				gridTemplateRows: '360px 380px',
				gridGap: '15px',
				['@media (max-width:1115px)']: {
					gridTemplateColumns: 'repeat(1, 1fr)',
				}
			}}>
				<Widget
					name="roi-booster"
					content={<ROIBoosterWidget endpoint={serviceProvider.roiBooster} />}
				/>
				<Widget
					name="sales"
					content={<SalesWidget endpoint={serviceProvider.sales} />}
				/>
				<Widget
					name="target-graph"
					content={<TargetGraph />}
				/>
				<Widget
					name="forecast"
					content={<FiveWeeksForecastWidget endpoint={serviceProvider.fiveWeeksForecast} />}
				/>
			</Box>
		</div>
	);
};

export default Dashboard
