import { useSetRecoilState } from 'recoil';
import bomService from '../../../../service/org/bom.service';
export const useFetchBoms = (state) => {
  const setBoms = useSetRecoilState(state);
  const refresh = async () => {
    try {
      const { data: apiBoms } = await bomService.getBoms();
      setBoms((apiBoms ?? []).map(bom => ({
        ...bom,
        bom_id: bom.id
      })));
    } catch (e) {
      console.log(e);
    }
  };

  return { refresh };
};
