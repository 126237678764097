import { useEffect, useState } from "react";
import { whenStyleChanges } from "../detect-changes";

const DG_VIRTUAL_SCROLL_CONTAINER_CLASS = 'MuiDataGrid-virtualScroller'
const DG_VIRTUAL_SCROLL_CONTENT_CLASS = 'MuiDataGrid-virtualScrollerContent'

export const useDataGridScroll = () => {
    const [hasScroll, setHasScroll] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            const scrollableContainer = document.getElementsByClassName(DG_VIRTUAL_SCROLL_CONTAINER_CLASS)[0];
            const scrollableContent = document.getElementsByClassName(DG_VIRTUAL_SCROLL_CONTENT_CLASS)[0];

            // If Data grid has rendered
            if (scrollableContent) {
                whenStyleChanges(scrollableContent, (change) => {
                    // Check if the content height is bigger
                    // than the container height, it means
                    // a scroll bar is shown
                    const isScrollShown =
                        change.target.style.height
                        > scrollableContainer.style.height;
                    setHasScroll(isScrollShown)
                    if (isScrollShown) {
                        if (!hasScroll)
                            setHasScroll(true)
                    } else {
                        if (hasScroll)
                            setHasScroll(false)
                    }
                })
            }
        }, 2000);
    }, [])

    return hasScroll;
}