import React from "react"

const ProgressRing = ({ stroke, radius, progress, style }) => {

    const __progress = (() => {
        let p = parseFloat(progress)

        if (!Number.isFinite(p))
            p = 0

        return {
            value: p,
            stroke: p > 0 ? "#17e7d3" : "lightgray",
            strokeDashOffset: p > 0 ? p : 100
        }
    })()

    const diameter = radius * 2;
    const normalisedRadius = radius - stroke * 0.8;
    const circumference = 2 * Math.PI * normalisedRadius;
    const strokeDashOffset = circumference * (1 - __progress.strokeDashOffset / 100);

    return (
        <div style={{ position: "relative", ...style }}>
            <svg height={diameter} width={diameter}
                style={{
                    transform: "rotate(-90deg)",
                    filter: "drop-shadow( -2px 0px 2px rgba(0, 0, 0, 0.3))"
                }}>
                <circle
                    stroke={__progress.stroke}
                    strokeDasharray={`${circumference} ${circumference}`}
                    style={{ strokeDashoffset: `${strokeDashOffset}` }}
                    strokeWidth={stroke}
                    fill="transparent"
                    r={normalisedRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>
            <div style={{ position: "absolute", left: "50%", top: "53%", transform: "translate(-49%, -50%)" }}>
                <div className="text-center" style={{ lineHeight: "16px" }}>
                    <b style={{ color: "#4f4f4f", fontSize: "29px" }}>{__progress.value}%</b>
                    <br />
                    <b style={{ color: "#8d8d8d", fontSize: "12px" }}>Optimized</b>
                </div>
            </div>
        </div>
    )
}

export default ProgressRing