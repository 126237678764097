import jwtDecode from 'jwt-decode';
import { useRecoilValue } from 'recoil';
import {
	accessToken as accessTokenState,
	ACCESS_TOKEN_KEY,
	currentUser as currentUserState
} from '../../store';

const useAuthGuard = () => {
	const accessToken = useRecoilValue(accessTokenState);
	const currentUser = useRecoilValue(currentUserState);

	// Check if the token has expired, prevents AccountLayoutRoute wrap from displaying if the token has expired
	let token = localStorage.getItem(ACCESS_TOKEN_KEY);
	const hasTokenExpired = (exp) => {
		return Date.now() / 1000 > exp;
	};
	let tokenexpired = false;

	if (token !== null) {
		const decodedToken = jwtDecode(token)
		if (!hasTokenExpired(decodedToken.exp)) {
			tokenexpired = false;
		} else {
			tokenexpired = true;
			localStorage.clear();
		}
	}

	const guardClauses = [
		accessToken !== null ? true : false
	];

	const guardUp = guardClauses.every(clause => clause === true);

	return {
		guard: guardUp && !tokenexpired,
		homeRoute: guardUp ?
			`/account/dashboard`
			: "/"
	}
}

export default useAuthGuard
