import API from "./service";

const AuthService = {
    login: (dataObj) => API.post(process.env.REACT_APP_USER_PROFILE === "superadmin" ?
        "signin" : "organization/user/signin",
        dataObj),
    logout: () => {
        localStorage.clear()
    },
    forgotPassword: (dataObj) => API.post("forget-password", dataObj),
    setPassword: (dataObj) => API.post("set-password", dataObj),
    resetPassword: (dataObj) => API.post("reset-password", dataObj),
    getUserInfo: () => JSON.parse(localStorage.getItem("userInfo")),
    setUserInfo: (userInfo) => localStorage.setItem("userInfo", JSON.stringify(userInfo)),
    profile: {
        getInfo: (userPublicId) => API.post("organization/user/edit", { user_public_id: userPublicId }),
        update: (dataObj) => API.post("organization/user/update-profile", dataObj),
        updatePicture: (dataObj) => API.post("organization/user/update-profile-pic", dataObj),
        deletePicture: () => API.post("organization/user/delete-profile-pic"),
        updatePassword: (dataObj) => API.post("change-password", dataObj)
    }
}

export default AuthService;
