import { ALL_VENDORS_OPTION, ALL_BRANDS_OPTION } from "@/store/admin-panel/products.store";

export const filterProducts = (products, {
  selectedVendor,
  selectedBrand,
  selectedCategories,
  showInactive,
}) => {
  return products.filter(product => {
    if (!showInactive && (+(product.status_id) !== 1)) return false;
    if (selectedVendor.value !== ALL_VENDORS_OPTION.value &&
      product.distributor_id !== selectedVendor.value) return false;
    if (selectedBrand.value !== ALL_BRANDS_OPTION.value &&
      product.brand_id !== selectedBrand.value) return false;
    if (!(selectedCategories[product.category.id]?.children[product.subCategory.id]?.selected)) return false;
    return true;
  });
};
