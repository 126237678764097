import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useStyles } from "./style";

// components
import CustomizedCheckbox from "@/components/CheckBox/CheckBox";

// mui
import { Box, Button } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import { AddOutlined } from "@mui/icons-material";
import {
  DataGridPro, gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';
import Pagination from '@mui/material/Pagination';
import FormControlLabel from '@mui/material/FormControlLabel';

// store
import { locationsState } from "@/store/admin-panel/admin-panel.store";
import { useRecoilValue } from "recoil";

// services
import LocationService from "../../../../service/org/location.service";

// components
import Actions from "../shared/actions";
import CreateLocationDialog from "./create-location-dialog";
import DeleteConfirmation from '../../../../components/DeleteConfirmation';

const Locations = ({ onRefetch, onProcessing }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const locations = useRecoilValue(locationsState);
  const [pageSize, setPageSize] = useState(100);
  const [selectedLocation, setSelectedLocation] = useState();
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [showActivatedOnly, setShowActivatedOnly] = useState(false);
  const [showUpdateCreatePopup, setShowUpdateCreatePopup] = useState(false);

  const deleteLocation = async (location) => {
    setSelectedLocation(location);
    setDeletePopupOpen(true);
  };

  const onDeleteConfirmed = async () => {
    try {
      onProcessing();
      await LocationService.deleteLocation(selectedLocation.id);
      enqueueSnackbar('Location Deleted Successfully', {
        variant: 'success'
      });
      onRefetch();
    } catch (err) {
      enqueueSnackbar('Delete location failed', {
        variant: 'error'
      });
    }
  }

  const LOCATIONS_COLUMNS = [
    {
      field: 'location_name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'ext_location_id',
      headerName: 'External ID',
      width: 150,
    },
    {
      field: 'Status',
      type: 'status',
      renderCell: ({ row }) => {
        return <span>
          { +row.status_id === 1 ? "Active" : "Inactive"}
        </span>
      }
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 150,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 120,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 90,
    },
    {
      field: 'zip_code',
      headerName: 'Zip',
      width: 90,
    },
    {
      field: 'actions',
      type: 'actions',
      renderCell: ({ row }) => {
        return <Actions
          onDelete={() => deleteLocation(row)}
          onEdit={() => {
            setSelectedLocation(row);
            setShowUpdateCreatePopup(true);
          }}
        />
      }
    }
  ];

  const filteredLocations = useMemo(() =>
    showActivatedOnly ? locations : locations.filter(location => +location.status_id === 1)
    , [locations, showActivatedOnly]);


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }


  return (
    <Box className={classes.locationsContainer} sx={{ width: '100%' }}>
      <div className={classes.actions}>
        <Button className={classes.addButton} variant="contained" onClick={() => setShowUpdateCreatePopup(true)}>
          <AddOutlined sx={{ marginRight: '8px' }} />
          Add Location
        </Button>
      </div>
        <DataGridPro
          className={classes.table}
          loading={locations.length === 0}
          getRowClassName={(params) => +params.row.status_id !== 1 && 'inactive'}
          rows={filteredLocations}
          columns={LOCATIONS_COLUMNS}
          getRowId={(row) => row.id}
          initialState={{ pinnedColumns: { right: ['actions'] } }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          components={{
            Footer: () => (
              <div className={classes.activeFilter}>
                <FormGroup>
                  <FormControlLabel control={<CustomizedCheckbox
                    checked={showActivatedOnly}
                    color="#19d1dd"
                    onChange={() => {
                      setShowActivatedOnly(currActivity => !currActivity);
                    }}
                  />} label={<span style={{ marginLeft: '2px' }}>Show inactive locations</span>} />
                </FormGroup>
                <CustomPagination />
              </div>
            ),
          }}
        />
      <CreateLocationDialog
        show={showUpdateCreatePopup}
        onCloseDialog={() => {
          setShowUpdateCreatePopup(false);
          setSelectedLocation(undefined)
        }}
        onCreate={onRefetch}
        onUpdate={onRefetch}
        location={selectedLocation}
      />
      <DeleteConfirmation
        title={`Delete location`}
        content={`Are you sure you want to delete ${selectedLocation?.location_name}?`}
        onClose={() => {
          setDeletePopupOpen(false);
          setTimeout(() => setSelectedLocation(null), 1000);
        }}
        onDelete={onDeleteConfirmed}
        open={deletePopupOpen} />
    </Box>
  )
}

export default Locations
