import React, { useState } from 'react';

// recoil
import { useRecoilState, useRecoilValue } from 'recoil';

// UI
import FormGroup from '@mui/material/FormGroup';
import CircularProgress from '@mui/material/CircularProgress';
import useForecastExport from '../../export/export-forecast';
import CustomSelect from '../../../../../../../components/CustomSelect';
import {
  Td,
  Th,
  Title,
  Button,
  Section,
  CancelButton,
  ConfirmButton,
  FilterLabel,
  ModalTitle,
  ModalActions,
  ModalMessage,
  SimpleTable,
  SubmitButton,
  TableContainer,
  VersionSelectionWrap
} from '../style';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';

// services
import OrganisationService from '../../../../../../../service/organisation-service';

// global store
import { currentOrgId } from '../../../../../../../store';

// forecast store
import {
  changesState,
  locationState,
  versionsState,
  forecastState,
  currentClassIds,
  submittingChanges,
  selectedVersionsState
} from '../../../forecast.store';

const selectStyle = {
  width: '100%',
  marginTop: '4px'
};

const Versions = ({ refresh }) => {
  // state
  const orgId = useRecoilValue(currentOrgId);
  const versions = useRecoilValue(versionsState);
  const changes = useRecoilValue(changesState);
  const locationId = useRecoilValue(locationState);
  const classIds = useRecoilValue(currentClassIds);
  const [forecast, setForecast] = useRecoilState(forecastState);
  const [submitting, setSubmitting] = useRecoilState(submittingChanges);
  const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
  const [changesModalVisible, setChangesModalVisible] = useState(false);
  const [{ main, compare }, setSelectedVersions] = useRecoilState(selectedVersionsState);

  // export
  const { exportForecast } = useForecastExport();

  // api
  const forecastAPI = OrganisationService.forecast(orgId);

  const updateMainVersion = (version) => {
    setSelectedVersions((prev) => ({
      ...prev,
      main: version
    }));
  };

  const updateCompareVersion = (version) => {
    setSelectedVersions((prev) => ({
      ...prev,
      compare: version
    }));
  };

  const versionSelectStyle = {
    backgroundColor: '#6c757d',
    borderColor: '#6c757d'
  };

  const reset = () => {
    setResetConfirmOpen(false);
    setForecast(JSON.parse(JSON.stringify(forecast)));
  };

  const onSubmit = async () => {
    try {
      setChangesModalVisible(false);
      setSubmitting(true);
      const forecastVersionId = forecast?.mainVersionId;
      await forecastAPI.forecastAdjustments({
        classIds,
        locationId,
        changes,
        forecastVersionId
      });
      refresh();
    } catch (e) {
      console.log(e.message);
    } finally {
      setSubmitting(false);
      setChangesModalVisible(false);
    }
  };

  const onSubmitPreview = () => {
    setChangesModalVisible(true);
  };

  // local components
  const ResetConfirmation = React.forwardRef((props, ref) => (
    <Modal
      {...props}
      ref={ref}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={resetConfirmOpen}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999
      }}
      onClose={() => setResetConfirmOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200
      }}>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '5px',
          zIndex: 99999
        }}>
        <ModalTitle>Confirm reset</ModalTitle>
        <ModalMessage>Are you sure you want to reset? all your changes will be lost.</ModalMessage>
        <ModalActions>
          <ConfirmButton style={{ marginRight: '15px' }} onClick={reset}>
            Reset
          </ConfirmButton>
          <CancelButton onClick={() => setResetConfirmOpen(false)}>Cancel</CancelButton>
        </ModalActions>
      </div>
    </Modal>
  ));

  const ChangesPreview = React.forwardRef((props, ref) => (
    <Modal
      {...props}
      ref={ref}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={changesModalVisible}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClose={() => setChangesModalVisible(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200
      }}>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '5px',
          zIndex: 99999
        }}>
        <ModalTitle>Review changes</ModalTitle>
        <ModalMessage>Are you sure you want to submit the following changes?</ModalMessage>
        <div style={{ marginTop: '10px' }}>
          <TableContainer>
            <SimpleTable sx={{ minWidth: 650 }} aria-label="Forecast draft changes">
              <thead>
                <tr>
                  <Th>Metric</Th>
                  <Th align="right">Week</Th>
                  <Th align="right">Old Value</Th>
                  <Th align="right">New Value</Th>
                </tr>
              </thead>
              <tbody>
                {changes.map((change) => {
                  return (
                    <tr key={`${JSON.stringify(change)}`}>
                      <Td align="right">{change?.metric?.display}</Td>
                      <Td align="right">{change?.week}</Td>
                      <Td align="right">{change?.oldValue}</Td>
                      <Td align="right">{change?.newValue}</Td>
                      <Td align="right">
                        {change?.newValue - change?.oldValue > 0 ? (
                          <span style={{ color: 'green' }}>&uarr;</span>
                        ) : (
                          <span style={{ color: 'tomato' }}>&darr;</span>
                        )}
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </SimpleTable>
          </TableContainer>
        </div>
        <ModalActions>
          <SubmitButton
            style={{ marginRight: '15px', backgroundColor: '#7ad6e0' }}
            onClick={onSubmit}>
            Submit
          </SubmitButton>
          <CancelButton onClick={() => setChangesModalVisible(false)}>Cancel</CancelButton>
        </ModalActions>
      </div>
    </Modal>
  ));

  return (
    <>
      <Section>
        <Title>Forecast Options</Title>
        <div>
          <FormGroup sx={{ marginBottom: '1rem' }}>
            <FilterLabel>Main Version</FilterLabel>
            <CustomSelect
              value={main}
              options={versions}
              onChange={updateMainVersion}
              style={selectStyle}
            />
          </FormGroup>
          <FormGroup sx={{ marginBottom: '1rem' }}>
            <FilterLabel>Compare Version</FilterLabel>
            <CustomSelect
              value={compare}
              options={versions}
              onChange={updateCompareVersion}
              style={selectStyle}
            />
          </FormGroup>
          <VersionSelectionWrap>
            <Button
              variant="contained"
              disabled={!(changes?.length > 0)}
              onClick={() => setResetConfirmOpen(true)}
              sx={versionSelectStyle}>
              Reset
            </Button>
            <Button onClick={exportForecast} variant="contained" sx={versionSelectStyle}>
              Export
            </Button>
            <Button
              onClick={onSubmitPreview}
              disabled={!(changes?.length > 0)}
              variant="contained"
              sx={versionSelectStyle}>
              Submit
            </Button>
          </VersionSelectionWrap>
        </div>
      </Section>
      <ResetConfirmation />
      <ChangesPreview />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Versions;
