import { useRecoilState, useSetRecoilState } from 'recoil';
import React, { useEffect, useMemo, useState } from "react";

// store
import { lastVisitedStepState } from "@/store";
import { drawerOpenState } from '../../../../store';

// components
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { StepLabel } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import PulseLoader from "@/components/PulseLoader/index";

// deps
import { isEmpty } from "lodash";
import { useStyles } from "./style";
import { useSnackbar } from "notistack";

// steps
import SetBudget from "./steps/set-budget/index";
import ItemPlanner from "./steps/item-planner/index";
import CategoryTargets from "./steps/category-targets/index";

// utils
import { sortAlphabetically } from "@/utils/arrays";
import WorkbenchService from "@/service/workbench-service";
import useUserData from "../../../../utils/hooks/useUserData";
import PageTitle from '../../../../components/PageTitle';

export const stepsComponents = {
  SET_BUDGET: 0,
  CATEGORIES_TARGETS: 1,
  ITEM_PLANNER: 2,
};

const steps = [
  { display: "Set budget", component: SetBudget },
  { display: "Adjust category targets", component: CategoryTargets },
  { display: "Plan orders", component: ItemPlanner },
];

const ReorderProStepper = ({ }) => {
  // state
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { org_public_id: orgId } = useUserData();
  const setDrawerOpen = useSetRecoilState(drawerOpenState);
  const [activeStep, setActiveStep] = useRecoilState(lastVisitedStepState);
  const [workbenchConfig, setWorkbenchConfig] = useState({});
  const [loadingConfig, setLoadingConfig] = useState(false);

  // effects
  useEffect(async () => {
    if (!orgId) return;
    setDrawerOpen(false);
    const data = await fetchConfig(orgId);
    const isWorkbenchNew = data.initial;
    setWorkbenchConfig(data);
    if (isWorkbenchNew) {
      setActiveStep(stepsComponents.SET_BUDGET);
    } else {
      const stepKey = Object.keys(stepsComponents)
        .find(
          (component) => stepsComponents[component] === activeStep
        );
      setActiveStep(stepsComponents[stepKey]);
    }

  }, [orgId]);

  const locations = useMemo(() => {
    if (!workbenchConfig.budget) return []
    const locationsFromConfig = Object.entries(workbenchConfig.budget).reduce(
      (locationsArr, [locationId, locationData]) => [
        ...locationsArr,
        { id: locationId, ...locationData },
      ], []);
    return sortAlphabetically(locationsFromConfig, 'name')
  },
    [workbenchConfig]
  );

  // handlers
  const fetchConfig = async (publicOrgId) => {
    const { data } = await WorkbenchService.getConfig(publicOrgId);
    return data;
  };

  const loading = useMemo(() => {
    return isEmpty(workbenchConfig);
  }, [workbenchConfig]);

  const updateConfig = async (newConfig) => {
    await WorkbenchService.updateConfig(orgId, newConfig);
  };

  const onDoneEditConfig = async (newConfig) => {
    try {
      await updateConfig(newConfig);
      setWorkbenchConfig(newConfig);
      handleNext();
    } catch (err) {
      enqueueSnackbar("An error has occurred", { variant: "error" });
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const currentStepComponent = useMemo(() => {
    const StepComponent = steps[activeStep].component;

    return (
      <StepComponent
        config={workbenchConfig}
        onDone={onDoneEditConfig}
        onBack={handleBack}
        loadingConfig={loadingConfig}
        onUpdate={async () => {
          setLoadingConfig(true);
          const data = await fetchConfig(orgId);
          setLoadingConfig(false);
          setWorkbenchConfig(data);
        }}
        gotoStep={(step) => setActiveStep(step)}
        orgId={orgId}
        locations={locations}
      />
    );
  }, [activeStep, workbenchConfig]);

  const showStepper = () => {
    return activeStep !== stepsComponents.ITEM_PLANNER;
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <PulseLoader loop />
        </div>
      ) : (
        <Box className={classes.container}>
          <div
            className={classes.topRow}
            style={{
              marginBottom:
                activeStep === stepsComponents.ITEM_PLANNER ? 0 : "5vh",
            }}
          >
            <PageTitle>
              Reorder Pro
            </PageTitle>
          </div>
          {showStepper() && (
            <div style={{ width: "60%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((step, index) => (
                  <Step key={step.display}>
                    <StepLabel
                      onClick={handleStep(index)}
                      sx={{
                        "& .MuiStepIcon-text": {
                          fill: "white",
                        },
                      }}
                    >
                      {step.display}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          )}
          <div className={classes.stepContainer}>{currentStepComponent}</div>
        </Box>
      )}
    </>
  );
};

export default ReorderProStepper;
