// libs
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { NavLink } from "react-router-dom";

// deps
import { formatDollar } from "../../../../../utils/textFormatter";

// styles
import CardActions from "@mui/material/CardActions";
import { useRecoilValue } from "recoil";
import {
  CardFooterStyle, CardHeaderStyle
} from "../../../../../components/Card/card";
import { currentOrgId } from "../../../../../store";

const FiveWeeksForecast = ({
  endpoint: api,
  classId = "",
  onStartLoading = () => { },
  onEndLoading = () => { },
}) => {
  const orgId = useRecoilValue(currentOrgId);

  const [forecastData, updateForecastData] = useState({
    values: [],
    dates: [],
  });

  const isDefault = forecastData.values.length === 0 &&
    forecastData.dates.length === 0;

  const [loading, setLoading] = useState(false);

  const init = () => {
    updateForecastData({
      values: [],
      dates: [],
    });
    setLoading(true);
    onStartLoading();
  };

  useEffect(() => {
    init();
    if (orgId) {
      api(classId)
        .then((res) => {
          if (res.data.errors) {
          } else {
            updateForecastData(res.data.data);
          }
        })
        .catch((err) => {
          // TBD: show snackbar
        })
        .finally(() => {
          setLoading(false);
          onEndLoading();
        });
    }
  }, [classId, orgId]);

  const chartData = (() => {
    return {
      xAxis:
        forecastData.dates.length > 0 ? ["", ...forecastData.dates, ""] : [],
      yAxis:
        forecastData.values.length > 0
          ? [
            null,
            ...forecastData.values.map((value) => Number(value).toFixed(2)),
            null,
          ]
          : [],
    };
  })();

  const forecastOptions = {
    chart: {
      id: "five-weeks-forecast-chart",
      fontFamily: "Roboto",
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    colors: ["#19d1dd", "lightgray"],
    grid: {
      show: true,
      position: "back",
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    labels: chartData.xAxis,
    noData: { text: loading ? "" : "No Data Available!" },
    legend: { position: "top" },
    stroke: { curve: "smooth" },
    yaxis: [
      {
        labels: {
          formatter: function (val) {
            const salesFigure = Math.round(val);
            const formattedFigure = formatDollar(salesFigure);
            return salesFigure >= 0
              ? `${formattedFigure}`
              : `-${Math.abs(salesFigure)}`;
          },
        },
      },
    ],
  };

  const forecastSeries = [
    {
      name: "Revenue",
      data: chartData.yAxis,
    },
  ];

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <CardHeader
        title={<CardHeaderStyle>Five Week Forecast</CardHeaderStyle>}
      />
      <CardContent className={isDefault ? undefined : 'forecast-data'}>
        <Chart options={forecastOptions} series={forecastSeries} height={250} />
      </CardContent>
      <CardActions>
        <CardFooterStyle>
          <NavLink
            to="../forecast"
            style={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            VIEW FORECAST
          </NavLink>
        </CardFooterStyle>
      </CardActions>
    </Card>
  );
};

export default FiveWeeksForecast;
