import { makeStyles } from "@mui/styles";
import { commonStyles, blue } from "../../../style";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: "4vh",
    paddingBottom: "8vh",
    alignItems: "center",
    flexDirection: "column",
  },
  totalBudget: {
    fontSize: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  totalBudgetInput: {
    fontSize: "40px",
    maxWidth: "270px",
  },
  setBudgetWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  locationsWrapper: {
    marginTop: "15px",
    fontFamily: "Montserrat",
    maxHeight: "250px",
    overflow: "auto",
    paddingRight: "5px",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#bfbfbf",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#8d8d8d",
    },
  },
  budget: {
    fontWeight: 900,
    color: blue,
  },
  darkenText: {
    color: "#474747de",
  },
  location: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
    borderBottom: "1px solid #f4f4f4",
  },
  ...commonStyles,
}));
