import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Button,
  TextField
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { currentOrgId, currentUser } from '../../../../store';
import PageTitle from '../../../../components/PageTitle';
import OrganisationService from '../../../../service/organisation-service';
import { useStyles } from "./styles";
import { processPaths } from '../../../../utils/utils';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Spinner from '../../../../components/Spinner';
import PulseLoader from '../../../../components/PulseLoader';
import Loader from '../../../../components/Loader';

const FileBrowser = () => {
  const user = useRecoilValue(currentUser);
  const orgId = useRecoilValue(currentOrgId);
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [basePath, setBasePath] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPath, setCurrentPath] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
  };

  const fetchAndFormatFiles = async (path = []) =>  {
    let files = await loadData(path);

    // ensure it ends with a slash
    let basePath = user?.orgSettings.find(s => s.setting_key === 'path')?.setting_value || '';
    basePath = basePath.endsWith('/') ? basePath : `${basePath}/`;
    const formattedFiles = processPaths(files, `${basePath}/${path.join('/')}`);

    setBasePath(`${basePath}`);
    setFiles(formattedFiles);
  }

  const loadData = async (path) => {
    try {
      setLoading(true);
      const { data } = await OrganisationService.reports.fileBrowser(path);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!orgId) return;
    if (!user?.orgSettings.find(s => s.setting_key === 'path')?.setting_value) {
      setLoading(false);
      return;
    }

    fetchAndFormatFiles();
  }, [user, orgId]);

  const handleFileClick = (file) => {
    if (file.isDir) {
      setCurrentPath([...currentPath, file.name]);
    }
  };

  const handleBackClick = () => {
    setCurrentPath(currentPath.slice(0, -1));
  };

  const handleDownloadFiles = async () => {
    try {
      setDownloading(true);
      const paths = files
        .filter(file => selectedFiles.includes(file.id))
        .map(file => file.id);

      await OrganisationService.reports.fileBrowserGetSignedUrls(paths);
    } catch (e) {
      console.log(e);
    } finally {
      setDownloading(false);
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 280,
      renderCell: (params) => (
        <div onClick={() => handleFileClick(params.row)} style={{ cursor: 'pointer', textDecorationLine: params.row.isDir ? 'underline' : null }}>
          <Box display="flex" alignItems="center" gap={1}>
            { params.row.isDir && <FolderIcon style={{ fontSize: 21 }} /> }
            { !params.row.isDir && <InsertDriveFileIcon style={{ fontSize: 21 }} /> }
            {params.row.name}
          </Box>
        </div>
      )
    },
    { field: 'size', headerName: 'Size', width: 110 },
    { field: 'timeCreated', headerName: 'Created', width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      }
    },
    { field: 'updated', headerName: 'Last Modified', width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      }}
  ];

  let fileBrowserSettings = user
    ?.userPermissions
    ?.find(m => m.module_id === 31);

  let title = "";
  if (!fileBrowserSettings)
    title = "File Browser Report";
  else if (fileBrowserSettings?.name_override?.length > 0)
    title = fileBrowserSettings?.name_override;
  else
    title = fileBrowserSettings?.name;

  let pathFilter = `${basePath}${currentPath.join('/')}`;
  if (!pathFilter.endsWith('/')) pathFilter += '/';

  let filteredFiles = files
    .filter(file => file.parentId === pathFilter)
    .filter(file => file.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);

  return (
    <Box className={classes.mainWrap}>
      <PageTitle>
        { title }
      </PageTitle>
      <div style={{ height: 400, width: '100%' }}>
        {loading && <CircularProgress />}
        {!loading && (
          <>
            <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
              <ArrowBackIcon onClick={handleBackClick} style={{ cursor: 'pointer', color: currentPath.length === 0 ? 'gray' : 'black' }} disabled={currentPath.length === 0} />
              <span>
                <a href="javascript:void(0)" onClick={() => setCurrentPath([])} style={{
                  color: 'black',
                  'textDecoration': 'underline'
                }}>/</a>
                {currentPath.map(pathSegment => {
                  return <>
                    <a href="javascript:void(0)"
                       onClick={() => setCurrentPath(currentPath.slice(0, currentPath.indexOf(pathSegment) + 1))}
                       style={{
                      color: 'black',
                      'textDecoration': 'underline'
                    }}>{pathSegment}/</a>
                  </>;
                })}
              </span>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems="center" marginBottom={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <TextField
                  label="Search"
                  size="small"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  variant="outlined"
                />
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Button onClick={handleDownloadFiles}
                        disabled={selectedFiles.length === 0 || downloading}>
                  Download
                </Button>
              </Box>
            </Box>
            <Modal
              keepMounted
              disableBackdropClick
              open={downloading}
              onClose={() => setDownloading(false)}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style}>
                <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                  Downloading Files
                </Typography>
                <div style={{ padding: '20px 0' }}>
                  <CircularProgress size={16} /> &nbsp;
                  This file is being downloaded Please wait...
                </div>
              </Box>
            </Modal>
            <DataGridPro
              rows={filteredFiles}
              columns={columns}
              pageSize={5}
              checkboxSelection
              onSelectionModelChange={(ids) => setSelectedFiles(ids)}
              selectionModel={selectedFiles}
              disableSelectionOnClick
              autoHeight
            />
          </>
        )}
      </div>
    </Box>
  );
};

export default FileBrowser;
