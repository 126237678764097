import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';

// data
import { useRecoilState } from 'recoil';

// services
import materialService from "../../../../service/org/material.service";

// mui
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import ClassSelector from '../../../../components/ClassSelector';
import { useNavigate } from 'react-router-dom';
import { DeleteOutline, EditRoad } from '@mui/icons-material';

// state
import { materialsState } from "../production";
import DeleteConfirmation from '../../../../components/DeleteConfirmation';

export const Materials = ({ vendors, forecastAPI }) => {
  // state
  const [isOpen, setIsOpen] = useState(false);
  const [materials, setMaterials] = useRecoilState(materialsState);
  const [selectedMaterial, setSelectedMaterial] = useState({});
  const navigate = useNavigate();

  // handlers
  const editMaterial = (material) => {
    if (!material) {
      setSelectedMaterial({
        dirty: true,
        "product_name": "",
        "product_id": "",
        "Type": "",
        "Category": "",
        "class_id": null,
        "distributor_id": "",
        "lead_time": 0,
        "moq": 0,
        "case_rounding": 0,
        "cost_default": 0
      });
    } else {
      console.log(materials?.find(m => m.id === material.id));
      setSelectedMaterial(materials?.find(m => m.id === material.id));
    }

    setIsOpen(true);
  };

  const deleteMaterial = (material) => {
    setMaterials(materials.filter(m => m.id !== material.id));
  }

  const updateField = (field, value) => {
    setSelectedMaterial(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const save = async () => {
    if (selectedMaterial.dirty) {
      setMaterials([
        ...materials,
        selectedMaterial
      ]);
      // save new
      await materialService.createMaterial(selectedMaterial);
    } else {
      setMaterials(materials.map(m => {
        return (m['product_id'] === selectedMaterial['product_id']) ? selectedMaterial : m
      }));
    }
    setIsOpen(false);
  };

  let formatted = materials.map((d, i) => {
    return {
      ...d,
      id: d['product_id']
    }
  });

  const handleEvent = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    navigate(`../materials/${params.row.id}/dependencies`);
  };


  const columns = [
    {
      field: 'product_id',
      headerName: 'Material ID',
    },
    {
      field: 'product_name',
      headerName: 'Material Description',
      width: 100,
    },
    {
      field: 'Type',
      headerName: 'Type',
      width: 90,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 80,
    },
    {
      field: 'sub_category',
      headerName: 'Sub Category',
      width: 100,
    },
    {
      field: 'distributor_name',
      headerName: 'Vendor',
      width: 90,
    },
    {
      field: 'lead_time',
      headerName: 'Lead Time',
      width: 80,
    },
    {
      field: 'MOQ',
      headerName: 'MOQ',
      width: 80,
    },
    {
      field: 'case_rounding',
      headerName: 'Case Rounding',
      width: 80,
    },
    {
      field: 'default_cost',
      headerName: 'AUC',
      width: 80,
    },
    {
      field: 'actions',
      width: 200,
      headerName: 'Actions',
      renderCell: (params) => <div style={{ color: '#868686', display: 'flex', columnGap: '5px', cursor: 'pointer' }}>
        <EditRoad onClick={
          (e) => {
            editMaterial(params.row);
            e.stopPropagation();
          }
        } />
        <DeleteOutline  onClick={(e) => {
          deleteMaterial(params.row);
          e.stopPropagation();
        }
        } />
      </div>
    }
  ];

  console.log(selectedMaterial);

  return <>
    <DataGrid
      compact={true}
      rows={formatted}
      onRowClick={handleEvent}
      columns={columns}
      getRowId={row => row.product_id}
      hideFooter
      density="compact"
      autoHeight={true}
      headerHeight={90}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1,
        }
      }}
    />
    <div
      onClick={() => editMaterial(null)}
      style={{
      position: 'absolute',
      top: 10,
      right: 25
    }}>
      <a style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '13px',
      }}>
        <i className='material-icons navmenu-icon'>{'add_circle_outline'}</i>
        <span className='d-block'>New Material</span>
      </a>
    </div>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}>
      <>
        <div style={{
          width: '350px',
          backgroundColor: 'white',
          zIndex: 999,
          borderRadius: '2px',
          padding: '20px 25px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px'
        }}>
          <div>
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Edit Material</span>
          </div>

          <TextField id="material-id" label="Material Id" variant="standard"
                     value={selectedMaterial.id}
                     onChange={(e) => updateField('product_id', e.target.value)} />

          <TextField id="material-desc" label="Material Description" variant="standard"
                     value={selectedMaterial.product_name}
                     onChange={(e) => updateField('product_name', e.target.value)}/>

          <TextField id="material-type" label="Type" variant="standard"
                     value={selectedMaterial['Type']}
                     onChange={(e) => updateField('Type', e.target.value)}/>

          <ClassSelector onSelect={(h2, h3) => {
            // updateField('Category', h2.name);
            // updateField('Sub-Category', h3.name);
            updateField('class_id', h2.id)
          }} serviceProvider={forecastAPI} key={1} />

          <Autocomplete
            size="small"
            disablePortal
            id="vendor_name"
            onChange={(_, newValue) => {
              updateField('distributor_id', newValue?.id);
            }}
            options={(vendors ?? []).map((v) => ({
              ...v,
              value: v.id,
              label: v.distributor_name,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Which Vendor is this for?"
              />
            )}
          />

          <div style={{ display: 'flex', gap: 35 }}>
            <TextField id="material-moq" label="MOQ" variant="standard"
                       value={selectedMaterial['MOQ']}
                       type="number"
                       onChange={(e) => updateField('MOQ', e.target.value)}/>

            <TextField id="material-case-rounding" label="Case Rounding" variant="standard"
                       value={selectedMaterial.case_rounding}
                       type="number"
                       onChange={(e) => updateField('case_rounding', e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', gap: 35 }}>
            <TextField id="material-auc" label="AUC" variant="standard"
                       type="number"
                       value={selectedMaterial.auc}
                       onChange={(e) => updateField('AUC', e.target.value)}/>
            <TextField id="material-lead-time" label="Lead Time" variant="standard"
                       value={selectedMaterial.lead_time}
                       type="number"
                       onChange={(e) => updateField('Lead Time', e.target.value)}/>
          </div>

          <div style={{ marginTop: '20x', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={save}>
              Save
            </Button>
          </div>
        </div>
      </>
    </Modal>
  </>
}

export default Materials;
