import React, { useEffect, useState } from 'react';
import {
    Wrap,
    Opener,
    DatesWrap,
    Label,
    SelectWrap,
    Left,
    Option,
    FilterLabel,
    Right,
    MainWrap,
    StartWrap,
    Value,
    Date,
    Text
} from './styles';
import TodayIcon from '@mui/icons-material/Today';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import OrganisationService from '../../service/organisation-service';
import AuthService from '../../service/auth-service';
import { useRecoilValue } from 'recoil';
import { currentOrg } from '../../store';
const { dates } = OrganisationService;
const userInfo = AuthService.getUserInfo();

const WeekMonthPicker = ({ onDateSelected, reset }) => {
    const org = useRecoilValue(currentOrg)
    const orgId = org?.org_id;

    const [months, setMonths] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [mode, setMode] = useState('week');
    const [nextMode, setNextMode] = useState('week');
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(null);
    const toggle = () => {
        setOpen(!open);
    };

    const onSelect = (value) => {
        setCurrent(value);
        setOpen(false);
        setMode(nextMode);
        onDateSelected(value, nextMode);
    };

    useEffect(() => {
        const getMonths = async () => {
            if (!orgId) return;
            const { data } = await dates.list(orgId);

            // weeks filter
            const { weeks = [] } = data;
            const td_actual_available_weeks = data?.td_actual_available_weeks?.map(w => w.week_id);
            const filteredWeeks = weeks.filter(w => td_actual_available_weeks.includes(w?.week_id));
            setWeeks(filteredWeeks);

            // months filter
            const { months = [] } = data;
            const td_actual_available_months = data?.td_actual_available_months?.map(m => `${m.year}_${m.month}`);
            const filteredMonths = months.filter(m => td_actual_available_months.includes(`${m.year}_${m.month}`));
            setMonths(filteredMonths);
        }
        getMonths().then();
    }, [orgId]);

    useEffect(() => {
        if (current === null)
            setOpen(false);
    }, [current]);

    return <Wrap>
        <Opener onClick={toggle}>
            <Label>
                <TodayIcon />
                <Text>
                    {mode === 'week' && <span>Week</span>}
                    {mode === 'month' && <span>Month</span>}
                </Text>
            </Label>
            <DatesWrap>
                {
                    current && <Date>{
                        (mode === 'week') ?
                            `${current.week_start} - ${current.week_end}`
                            : `${current.month_formatted}`}
                    </Date>
                }
            </DatesWrap>
            {current && <IconButton style={{
                marginLeft: '10px'
            }} size="small" aria-label="delete" onClick={() => {
                reset();
                setCurrent(null);
            }}>
                <ClearIcon fontSize="small" />
            </IconButton>}
        </Opener>
        {open && <SelectWrap>
            <MainWrap>
                <Left>
                    <FilterLabel>filter by</FilterLabel>
                    <Option onClick={() => setNextMode('week')} style={{ fontWeight: nextMode === 'week' ? 'bold' : 'normal' }}>
                        Week
                    </Option>
                    <Option onClick={() => setNextMode('month')} style={{ fontWeight: nextMode === 'month' ? 'bold' : 'normal' }}>
                        Month
                    </Option>
                </Left>
                <Right>
                    <StartWrap>
                        {
                            (nextMode === 'week') && weeks.map(week => {
                                return <Value onClick={() => onSelect(week)}>
                                    {week.week_start} - {week.week_end}
                                </Value>
                            })
                        }
                        {
                            (nextMode === 'month') && months.map(month => {
                                return <Value onClick={() => onSelect(month)}>
                                    {month.month_formatted}
                                </Value>
                            })
                        }
                    </StartWrap>
                </Right>
            </MainWrap>
        </SelectWrap>}
    </Wrap>
};

export default WeekMonthPicker;
