import OfflineBolt from "@mui/icons-material/OfflineBolt";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from "recoil";
import MessageBox from "./components/MessageBox";
import OrganisationService from "../../../service/organisation-service";
import { currentOrgId } from "../../../store";
import { boosters as boostersState } from "../../../store/roi-booster/roi-booster.store";
import { formatDollar } from "../../../utils/textFormatter";
import CircularProgress from '@mui/material/CircularProgress';
import PageTitle from '../../../components/PageTitle';

const MultiSkeleton = () => (
  <>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </>
);

const ProductBoosterDetails = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let { id } = useParams();

  const orgId = useRecoilValue(currentOrgId);
  const serviceProvider = OrganisationService.forecast(orgId).roiBooster;

  const [boosters, setBoosters] = useRecoilState(boostersState);
  const [dismissModalOpen, setDismissModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [booster, updateBooster] = useState(
    state ? state.productBoosterDetails : {}
  );

  const dismissBooster = (toDismissBooster) => {
    const filteredBoosters = boosters.filter(booster => booster.id !== toDismissBooster.id);
    setBoosters(filteredBoosters);
  }

  const handleDismiss = () => {
    serviceProvider
      .dismiss(id)
      .then((res) => {
        if (res.data.errors) {
          return
        }
        // TBD: show snackbar
        dismissBooster(booster);
        navigate(-1);

      })
      .catch()
      .finally();
  };

  const closeDismissModal = () => setDismissModalOpen(false);

  const getColour = (priority) => {
    if (priority === "Action") {
      return "#19d1dd";
    } else if (priority === "Warning") {
      return "#FF9D3D";
    } else if (priority === "Critical") {
      return "#cc4125";
    }
  };

  const loadBoosters = async () => {
    try {
      setLoading(true);
      const res = await serviceProvider.read(id)
      if (res.data.errors) {
        return
      }

      const data = res.data;
      updateBooster({
        ...booster,
        ...data.reorderList[0],
        date: data.date,
        onHand: data.on_hand,
        sales: data.sale,
      });
    } catch (e) {

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBoosters().then();
  }, [id]);

  if (loading) {
    return (
      <Box sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      }}>
        <CircularProgress />
      </Box>
    );
  }

  const secondLineStyle = {
    fontSize: "14px",
    fontWeight: "400",
    color: 'gray',
    marginBottom: '45px',
    height: '45px',
    fontStyle: 'italic'
  };

  return (
    <>
      <Stack container style={{ padding: "25px 30px", gap: '25px', maxWidth: '1200px' }}>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <OfflineBolt
            style={{
              fill: getColour(booster.priority),
              height: "36px",
              width: "36px",
            }}
          />
          <PageTitle>
            ROI Boosters
          </PageTitle>
        </Box>
        <Box sx={{ display: 'flex', gap: '100px', marginBottom: '20px' }}>
          <Box display="flex" sx={{ flex: 1 }}>
            <Stack sx={{ marginLeft: "15px" }}>
              <Box
                sx={{
                  color: getColour(booster.priority),
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {booster.head_line}
              </Box>
            </Stack>
          </Box>
          <Box display="flex" sx={{ flex: 1 }}>
            <div>
              <span
                style={{
                  display: 'block',
                  fontSize: '20px',
                  fontWeight: '500'
                }}
              >
                {booster.deadline_description}
              </span>
              <span
                style={{
                  color: 'rgba(68,68,68,0.87)',
                  fontSize: '14px',
                  fontWeight: 'bold'
                }}
              >
                {booster.summary}
              </span>
            </div>
          </Box>
          <Box sx={{ padding: '10px' }} display='flex' alignItems='flex-end' flexDirection='column'>
            <div className='big-dollar' style={{ fontSize: '44px' }}>
              {formatDollar(booster.value)}
            </div>
            <span
              style={{
                color: '#8D8D8D',
                fontWeight: 'bold',
                fontSize: '20px'
              }}
            >
                Booster Size
              </span>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '25px' }}>
          <Card sx={{ height: "400px", backgroundColor: "#e6f3f2", flex: 1, padding: '10px 25px' }}>
            <CardContent>
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Summary
                </Typography>
                <Typography sx={secondLineStyle}>
                  This is Growthsayer's assessment of the problem.
                </Typography>
                <div style={{ fontSize: "17px", lineHeight: 1.7 }}>
                  {loading ? <MultiSkeleton /> : booster.extended_summary}
                </div>
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ height: "400px", backgroundColor: "#e6f3f2", flex: 1, padding: '10px 25px' }}>
            <CardContent>
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Recommended Actions
                </Typography>
                <Typography sx={secondLineStyle}>
                  This is Growthsayer's recommendation to action on this opportunity while there's still time.
                </Typography>
                <div style={{ fontSize: "17px", lineHeight: 1.75, fontWeight: 400 }}>
                  {loading ? <MultiSkeleton /> : booster.recommended_actions}
                </div>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <div
            style={{
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            <Button
              style={{  backgroundColor: '#00A9A7', color: 'white' }}
              onClick={() => setDismissModalOpen(true)}
              variant="contained">DISMISS</Button>
          </div>
          {dismissModalOpen && (
            <MessageBox
              type="info"
              title="Need your confirmation"
              message="Are you sure you want to dismiss this action?"
              actionHandler={handleDismiss}
              closeModal={closeDismissModal}
            />
          )}
        </Box>
      </Stack>
    </>
  );
};

export default ProductBoosterDetails;
