import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	widgetContainer: { position: 'relative', flex: 1, display: 'flex', overflow: 'hidden' },
	loadingContainer: {
		position: 'absolute',
		zIndex: 2,
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}
}));
