import { atom } from 'recoil';

export const purchaseOrders = atom({
  key: 'purchase-orders',
  default: []
});

export const fetching = atom({
  key: 'purchase-order-fetching',
  default: false
});
