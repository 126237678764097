import { makeStyles } from '@mui/styles';
import { sectionCommonStyle } from '../shared/sections-common-style';

export const useStyles = makeStyles((theme) => ({
  ...sectionCommonStyle,
  settingsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 0px 20px',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'unset'
    },
    '& .MuiAccordionSummary-content': {
      margin: '10px 0 0 0 !important',
    },
    '& .MuiAccordion-root': {
      border: '1px solid #e3e3e3;',
    },
    '& .MuiAccordion-root.Mui-expanded-default': {
      marginTop: '10px'
    },
    '& .Mui-expanded-default .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
    '& .Mui-expanded-default:before': {
      display: 'none'
    },
    '& .Mui-expanded-default .MuiAccordionSummary-root': {
      minHeight: '48px'
    },
    '& .MuiSlider-root': {
      color: '#4da1aa',
      height: '6px',
      margin: 0,
    },
    '& .MuiSlider-valueLabelOpen': {
      backgroundColor: 'unset',
      color: 'inherit',
      fontSize: '14px',
      top: '-7px'
    }
  },
  actions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '10px 0px 0px 0px',
    marginBottom: '20px'
  },
  heading: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  loadingSettingSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '20px',
    alignItems: 'center'
  },
  saveIcon: {
    position: 'absolute',
    cursor: 'pointer',
    outline: 'none',
    top: '12px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      fontSize: '13px',
    },
  },
}));
