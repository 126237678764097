import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Montserrat",
    flex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  amountRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
  },
  divider: {
    width: "260px",
    borderTop: "2px solid #e8e8e8",
    margin: "7px 0px",
  },
  amountContainer: {
    flex: 1,
    textAlign: "right",
    paddingRight: "5px",
  },
  amountTextContainer: {
    width: "135px",
  },
  amount: {
    fontSize: "20px",
    width: "100%",
    color: "#6e6e6e !Important",
  },
  editIcon: {
    cursor: "pointer ",
  },
  openToBuy: {
    fontSize: "28px",
    width: "100%",
  },
}));
