// libs
import Box from '@mui/material/Box';
import Chart from 'react-apexcharts';
import Skeleton from '@mui/material/Skeleton';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState, useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';

// deps
import { withStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import { metrics as metricGroups } from '../../../../reports/recap/metric-groups';

// styles
import './style.css';
import { useRecoilState, useRecoilValue } from 'recoil';

// forecast store
import {
  fetchingState,
  columnsState,
  draftState,
  versionsState,
  selectedVersionsState
} from '../../forecast.store';

Array.prototype.last = function () {
  return this[this.length - 1];
};

const CogButton = withStyles({
  root: {
    color: '#19d1dd',
    padding: 0,
    marginLeft: '10px'
  }
})(IconButton);

const TableSkeleton = () => {
  return Array.from({ length: 7 }).map((a, i) => <Skeleton key={`skeleton_${i}`} height={30} />);
};

const ForecastTrend = () => {
  // state
  const anchorPosRef = useRef();
  const columns = useRecoilValue(columnsState);
  const versions = useRecoilValue(versionsState);
  const fetching = useRecoilValue(fetchingState);
  const [draft, setDraft] = useRecoilState(draftState);
  const [options, setOptions] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataSeries, setDataSeries] = useState([]);
  const selectedVersions = useRecoilValue(selectedVersionsState);
  const mainVersion = versions?.find((v) => v.key === selectedVersions.main);
  const compareVersion = versions?.find((v) => v.key === selectedVersions.compare);
  const [selectedMetric, setSelectedMetric] = useState({
    value: 'revenue',
    display: 'Revenue'
  });

  // vars
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // handlers
  const updateSeries = () => {
    const main = (draft || []).map((week, i) => {
      return {
        x: columns[i]?.display,
        y: week.main[selectedMetric?.value]
      };
    });

    const compare = (draft || []).map((week, i) => {
      return {
        x: columns[i]?.display,
        y: week.compare[selectedMetric?.value]
      };
    });

    const events = {
      mouseMove: function (event, chartContext, config) {
        // reset all columns
        columns.map((col, i) => {
          const cellsToReset = document.getElementsByClassName(`hover_group_${i}`);
          Array.prototype.forEach.call(cellsToReset, (cell) => {
            cell.classList.remove('hoverMe');
          });
        });

        // hover column
        const cellsToHover = document.getElementsByClassName(
          `hover_group_${config.dataPointIndex}`
        );
        Array.prototype.forEach.call(cellsToHover, (cell) => {
          cell.classList.add('hoverMe');
        });
      }
    };

    const xAxis = {
      type: 'categories',
      tickAmount: columns?.length,
      showDuplicates: true,
      labels: {
        formatter: (val) => {
          return val;
        }
      }
    };

    const yAxis =  {
      forceNiceScale: true,
      min: 0,
      max: selectedMetric.value === 'gross_margin_pct' ? 1 : undefined,
      labels: {
        formatter: function (val) {
          const metricGroup = metricGroups.find((g) => g.value === selectedMetric.value);
          return metricGroup && metricGroup.format(val);
        }
      }
    };

    const newOptions = {
      chart: {
        id: 'forecast-chart',
        fontFamily: 'Roboto',
        toolbar: { show: false },
        zoom: { enabled: false },
        events
      },
      colors: ['lightgrey', '#19d1dd'],
      grid: {
        show: true,
        position: 'back',
        xaxis: { lines: { show: false } },
        yaxis: { lines: { show: true } }
      },
      stroke: {
        width: [5, 5],
        curve: 'smooth',
        dashArray: [15, 0]
      },
      labels: columns?.map((c) => c.display) || [],
      noData: { text: fetching || draft?.length === 0 ? 'Loading...' : 'No Data' },
      legend: { position: 'bottom' },
      yaxis: yAxis,
      xaxis: xAxis
    };

    setOptions(newOptions);

    setDataSeries([
      {
        name: mainVersion.value,
        data: main
      },
      {
        name: compareVersion.value,
        data: compare
      }
    ]);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(anchorPosRef.current);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  // effects
  useEffect(() => {
    updateSeries();
  }, []);

  useEffect(() => {
    updateSeries();
  }, [selectedMetric, draft]);

  const mouseOut = () => {
      for (let i = 0; i < 14; i += 1) {
        const cellsToReset = document.getElementsByClassName(`hover_group_${i}`);
        Array.prototype.forEach.call(cellsToReset, (cell) => {
          cell.classList.remove('hoverMe');
        });
      }
  };

  return (
    <Box
      display="flex"
      onMouseLeave={mouseOut}
      flexDirection="column"
      sx={{
        border: '1px solid lightgray',
        borderRadius: '8px',
        padding: '1%',
        marginBottom: '25px'
      }}>
      <Accordion sx={{ boxShadow: 'none' }} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontSize: '14px',
                fontWeight: 'bold'
              }}>
              {selectedMetric?.display}
            </Typography>
            <div ref={anchorPosRef} />
            <CogButton disabled={draft?.length === 0} onClick={handleClick}>
              <WbIncandescentIcon />
            </CogButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={(e) => handleClose(e)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}>
              <Box sx={{ p: 2 }}>
                {(metricGroups || []).map((m, i) => {
                  return (
                    <div
                      key={`metric_selection_${i}`}
                      className="metric-menu-item"
                      onClick={(e) => {
                        setSelectedMetric(m);
                        handleClose(e);
                      }}>
                      {m.display}
                    </div>
                  );
                })}
              </Box>
            </Popover>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {fetching && <TableSkeleton />}
          {!fetching && !!draft && (
            <Chart options={options || {}} series={dataSeries || []} height={350} />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ForecastTrend;
