import { Actions, ACTIONS_FIELD_NAMES } from "./actions";
import { Filters } from "../../filters-control/filters";
import { ALL_BRANDS_OPTION, ALL_VENDORS_OPTION } from "../..";

export const filterByCategories = (item, categoriesIds) => {
  return categoriesIds.length === 0 || (
    categoriesIds.includes(+item["Category ID"]) &&
    categoriesIds.includes(+item["Sub Category ID"])
  );
};

const filterByFlag = (item, filter) => {
  if (filter === Filters.ALL) {
    return true;
  }
  if (filter === Filters.ORDERED) {
    return item[ACTIONS_FIELD_NAMES[Actions.OnOrder]];
  }
  if (filter === Filters.RECOMMENDED) {
    if (typeof item.IsInBudget === "boolean") {
      return item.IsInBudget;
    }
    return item.IsInBudget === "true";
  }
  return true;
};

const filterByVendor = (item, selectedVendorId) => {
  if (selectedVendorId === ALL_VENDORS_OPTION.value) return true;
  return item["Vendor ID"] === selectedVendorId;
};

const filterByBrand = (item, selectedBrandId) => {
  if (selectedBrandId === ALL_BRANDS_OPTION.value) return true;
  return item["Brand ID"] === selectedBrandId;
};

const filterByLocation = (item, selectedlocationName) => {
  return item.path[0] === selectedlocationName;
};

export const filterItems = (
  items,
  {
    categoriesIds,
    locations,
    selectedLocationId,
    filters,
    selectedVendorId,
    selectedBrandId,
  }
) => {
  const selectedLocation = locations.find(
    (location) => location.id === selectedLocationId
  );

  return items.filter((item) => {
    if (selectedLocation) {
      if (!filterByLocation(item, selectedLocation.name)) {
        return false;
      }
    }

    if (!filterByVendor(item, selectedVendorId)) {
      return false;
    }

    if (!filterByBrand(item, selectedBrandId)) {
      return false;
    }

    const isPassFilters = filters.some((filter) => filterByFlag(item, filter));
    return isPassFilters && filterByCategories(item, categoriesIds);
  });
};
