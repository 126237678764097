import API from "../api"

const UserService = {
  getUser: (userTypeId = '') => API.get(`master/user/me?userTypeId=${userTypeId}`),
  getUserTypes: (db_name) => API.get(`org/user-types?dbName=${db_name ?? ''}`),
  getOrgUsers: () => API.get(`master/user`),
  updateUser: (userId, newValues) => API.put(`master/user/${userId}`, newValues),
  deleteUser: (userId) => API.delete(`master/user/${userId}`),
  createUser: (user) => API.post(`master/user`, user),
};

export default UserService;
