import { useSetRecoilState } from 'recoil';
import materialService from '../../../../service/org/material.service';

export const useFetchMaterials = (state) => {
  const setMaterials = useSetRecoilState(state);

  const refresh  = async () => {
    try {
      const { data: apiMaterials } = await materialService.getMaterials();
      setMaterials((apiMaterials ?? []).map(m => ({
        ...m,
        product_id: m.id
      })));
    } catch (e) {
      console.log(e);
    }
  };

  return { refresh };
}
