// libs
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AlertTriangle, AlertOctagon, Info } from "react-feather";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";

// deps
import { formatDollar } from "../../../../../utils/textFormatter";
import ProgressRing from "../../../../../components/ProgressRing";

// styles
import {
  CardHeaderStyle,
  CardFooterStyle,
} from "../../../../../components/Card/card";
import { OpportunitiesAmount, Alerts, AlertAmount } from "./styles";
import { useRecoilValue } from "recoil";
import { currentOrgId } from "../../../../../store";

const ROIBooster = ({
  endpoint: api,
  onStartLoading = () => { },
  onEndLoading = () => { },
}) => {
  const [loading, setLoading] = useState(false);
  const [roiBoosterData, updateROIBoosterData] = useState({
    roiPriority: [
      {
        TotalValueWarning: "0",
        TotalValueCritical: "0",
        TotalValueAction: "0",
      },
    ],
  });

  const isDefault = roiBoosterData.roiPriority[0].TotalValueWarning === "0"
    && roiBoosterData.roiPriority[0].TotalValueCritical === "0"
    && roiBoosterData.roiPriority[0].TotalValueAction === "0"

  const orgId = useRecoilValue(currentOrgId);

  const loadBoosterData = async () => {
    try {
      const { data } = await api();
      if (data.errors) {
        // TBD: show snackbar
        return;
      }
      updateROIBoosterData(data);
    } catch (e) {
      // TBD: show snackbar
    } finally {
      setLoading(false);
      onEndLoading();
    }
  };

  useEffect(() => {
    setLoading(true);
    onStartLoading();
  }, []);

  useEffect(() => {
    if (orgId) {
      loadBoosterData().then();
    }
  }, [orgId]);

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader title={<CardHeaderStyle>ROI Booster</CardHeaderStyle>} />
      <CardContent sx={{ flex: 1 }} className={isDefault ? undefined: 'booster-data'}>
        {!loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Box>
              <ProgressRing
                stroke={30}
                radius={90}
                progress={roiBoosterData.optimized_percentage}
              />
            </Box>
            <Box>
              <OpportunitiesAmount>
                <span className="amount" data-testid="opportunities_amount">
                  {formatDollar(roiBoosterData.total_value)}
                </span>
                <span className="label">In Opportunities</span>
              </OpportunitiesAmount>
              <Alerts>
                <div className="clearfix">
                  <span className="float-left text-danger">
                    <AlertOctagon />
                    &nbsp;&nbsp;Critical
                  </span>
                  <AlertAmount className="float-right">
                    {formatDollar(
                      roiBoosterData.roiPriority[0].TotalValueCritical
                    )}
                  </AlertAmount>
                </div>
                <div className="clearfix">
                  <span className="float-left text-warning">
                    <AlertTriangle /> &nbsp;&nbsp;Warning
                  </span>
                  <AlertAmount className="float-right">
                    {formatDollar(
                      roiBoosterData.roiPriority[0].TotalValueWarning
                    )}
                  </AlertAmount>
                </div>
                <div className="clearfix">
                  <span className="float-left theme-text-color">
                    <Info />
                    &nbsp;&nbsp;Action
                  </span>
                  <AlertAmount className="float-right">
                    {formatDollar(
                      roiBoosterData.roiPriority[0].TotalValueAction
                    )}
                  </AlertAmount>
                </div>
              </Alerts>
            </Box>
          </Box>
        )}
      </CardContent>
      <CardActions>
        <CardFooterStyle>
          <NavLink
            to="../roi-booster"
            style={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            VIEW ALL BOOSTERS
          </NavLink>
        </CardFooterStyle>
      </CardActions>
    </Card>
  );
};

export default ROIBooster;
