import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from '../../../assets/gs-logo.png';
import AuthService from "../../../service/master/auth.service";
import { useStyles } from "./style";

const ChangePassword = () => {
  const classes = useStyles();

  const params = useParams();
  const { resetRequestId } = params;

  const navigate = useNavigate();

  const validateResetRequest = async () => {
    try {
      const { data: { valid }} = await AuthService.validateResetPasswordRequest(resetRequestId);
      if (!valid)
        navigate('/login')
    } catch (e) {
      navigate('/login')
    }
  };

  useEffect(() => {
    validateResetRequest().then();
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const initialFormData = {
    newPassword: { display: 'New Password', value: "", hasError: false },
    confirmedPassword: { display: 'Confirm Password', value: "", hasError: false },
  }

  const [formData, updateFormData] = useState({ ...initialFormData });
  const [formError, setFormError] = useState("");
  const [loading, toggleLoading] = useState(false);

  const handleFormInputChange = (event) => {
    const newValue = event.target.value;
    updateFormData({
      ...formData,
      [event.target.name]: {
        ...formData[event.target.name],
        value: newValue
      }
    })
  }

  const validateField = (formInput, value) => {
    const validateMap = {
      newPassword: (val) => {
        if (val === "") {
          return "Please enter a new password";
        } else if (val.length > 0 && val.length <= 3) {
          return "Password must be at least 3 characters"
        } else {
          return ""
        }
      },
      confirmedPassword: (val) => {
        if (val === "") {
          return "Please enter the new password again";
        } else if (val !== formData.newPassword.value) {
          return "Passwords are different. Please re-enter the password"
        } else {
          return ""
        }
      }
    }
    return validateMap[formInput](value)
  }

  const validate = () => {
    let formDataCopy = { ...formData };
    let foundErrors = false;

    Object.keys(formData).forEach(inputKey => {
      const fieldValidate = (validateField(inputKey, formData[inputKey].value))
      if (fieldValidate !== "") {
        if (!foundErrors) {
          setFormError(fieldValidate);
          foundErrors = true;
        }
        formDataCopy[inputKey].hasError = true;
      } else {
        formDataCopy[inputKey].hasError = false;
      }
    })

    if (!foundErrors) {
      setFormError("")
      handleFormSubmission(formDataCopy)
    }
    updateFormData({ ...formDataCopy })
  }

  const handleFormSubmission = async (data) => {
    toggleLoading(true);
    try {
      await AuthService.resetPassword(resetRequestId, data.newPassword.value);
      enqueueSnackbar('Password has changed', { variant: 'success' });
      // clear form
      updateFormData({ ...initialFormData })
      navigate(`/account/dashboard`);
    } catch (err) {
      setFormError('An error has occured, please try again');
    } finally {
      toggleLoading(false);
    }
  }

  return (<div
      className={classes.wrapper}
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <img className={classes.logo} src={logo} alt="GS Logo" />
      <span className={classes.title}>Create a new password</span>
      <div className={classes.form}>
        <TextField
          error={formData.newPassword.hasError}
          value={formData.newPassword.value}
          label={formData.newPassword.display}
          name="newPassword"
          onChange={handleFormInputChange}
          type="password"
          size="small"
          color='primary'
          sx={{
            marginBottom: '15px'
          }}
        />
        <TextField
          error={formData.confirmedPassword.hasError}
          value={formData.confirmedPassword.value}
          label={formData.confirmedPassword.display}
          name="confirmedPassword"
          onChange={handleFormInputChange}
          type="password"
          size="small"
          color='primary'
          sx={{
            marginBottom: '15px'
          }}
        />
        <div>
          <span className={classes.error}>{formError}</span>
        </div>
        <LoadingButton
          loading={loading}
          onClick={validate}
          variant='outlined'
          color='primary'
          startIcon={<LockOutlinedIcon />}
          sx={{
            marginTop: '15px',
            padding: '10px 0px',
          }}>
          Change Password
        </LoadingButton>
      </div>
      <span className={classes.backToLogin} onClick={() => navigate('/login')}>Back to login</span>
    </div>
  )
}

export default ChangePassword;
