export const Actions = {
  OnOrder: "OnOrder",
  Duplicate: "Duplicate",
  Hide: "Visibility",
  OutOfStock: "OutOfStock",
  AUC: "AUC",
  OrderCases: "OrderCases",
  CasePackSize: "CasePackSize",
  SubCategoryID: "SubCategoryID",
  SubCategory: "SubCategory",
  ProductName: "ProductName",
  TotalOrderUnits: "TotalOrderUnits",
  TotalOrderCost: "TotalOrderCost",
};

export const ACTIONS_FIELD_NAMES = {
  [Actions.OnOrder]: "IsOnOrder",
  [Actions.Hide]: "IsHide",
  [Actions.OutOfStock]: "IsOutOfStock",
  [Actions.AUC]: "AUC",
  [Actions.OrderCases]: "Cases to Order",
  [Actions.CasePackSize]: "Case Pack Size",
  [Actions.SubCategoryID]: "Sub Category ID",
  [Actions.SubCategory]: "Sub Category",
  [Actions.ProductName]: "Product Name",
  [Actions.TotalOrderUnits]: "Total Order - Units",
  [Actions.TotalOrderCost]: "Total Order - Cost",
};

export const ACTIONS_AFFECT_ALL = {
  [Actions.OnOrder]: false,
  [Actions.Hide]: true,
  [Actions.OutOfStock]: true,
  [Actions.AUC]: false,
  [Actions.OrderCases]: false,
  [Actions.CasePackSize]: false,
  [Actions.SubCategoryID]: false,
  [Actions.SubCategory]: false,
  [Actions.ProductName]: false,
  [Actions.TotalOrderUnits]: false,
  [Actions.TotalOrderCost]: false,
};
