import { atom } from 'recoil';

export const ALL_VENDORS_OPTION = {
  value: -1,
  label: 'All Vendors',
};

export const ALL_BRANDS_OPTION = {
  value: -1,
  label: 'All Brands',
};

export const ALL_PRODUCTS_OPTION = {
  value: -1,
  label: 'All Products',
};

// filters
export const selectedVendorFilterState = atom({
  key: 'admin_panel_products_vendors_filter',
  default: ALL_VENDORS_OPTION,
});

export const selectedBrandFilterState = atom({
  key: 'admin_panel_products_brands_filter',
  default: ALL_BRANDS_OPTION,
});

export const selectedCategoriesFilterState = atom({
  key: 'admin_panel_products_categories_filter',
  default: {},
});

export const selectedProductFilterState = atom({
  key: 'admin_panel_products_product_filter',
  default: ALL_PRODUCTS_OPTION,
});







