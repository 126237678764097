import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PulseLoader from "../../../../components/PulseLoader/index";
import WorkbenchService from "../../../../service/workbench-service";
import useUserData from '../../../../utils/hooks/useUserData';
import {
  toShowOverview
} from "../utils/localstorageHandler";
import { useStyles } from "./style";

const ReorderProSwitch = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { org_public_id } = useUserData();

  const showOverview = async () => {
    try {
      const showOverviewFromLocalStorage = toShowOverview();
      const { data } = await WorkbenchService.isExist(org_public_id);

      // In case have active workbench, skip overview
      if (data.exists) {
        return false;
      }
      // Never started the engine, overview depends on local storage
      return showOverviewFromLocalStorage;
    } catch (err) {
      return true;
    }
  }

  const navigateToOverviewOrLastStep = async () => {
    const toOverview = await showOverview()
    if (toOverview) {
      navigate(`../../reorder-pro/overview`);
    } else {
      navigate(`../setup`);
    }
  }

  useEffect(() => {
    navigateToOverviewOrLastStep()
  }, []);

  return (
    <div className={classes.container}>
      <PulseLoader loop />
    </div>
  );
};

export default ReorderProSwitch;
