const REORDER_PRO_ID = 8;
const PRODUCTION_ID = 18;

export const SUBSCRIPTIONS_ROUTES = {
    reorderPro: {
        id: REORDER_PRO_ID,
        show: false,
    },
    production: {
        id: PRODUCTION_ID,
        show: false
    }
};
