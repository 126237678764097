// libs
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useMemo } from 'react';

// store
import { useRecoilState, useRecoilValue } from 'recoil';
import { purchaseOrders as purchaseOrdersState } from '../../../../../../store/purchase-orders/purchase-orders.store'

// services
import OrganisationService from '../../../../../../service/organisation-service';

// components
import NavMenu from '@/components/NavMenu/NavMenu';
import DataTable from 'react-data-table-component';
import StatusSelect from '../../components/StatusSelect';
import ArrivalDatePicker from '../../components/ArrivalDatePicker';
import PageTitle from '../../../../../../components/PageTitle';

// styles
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { currentOrg } from '../../../../../../store';
import { addCommas } from '../../../../../../utils/textFormatter';

const PurchaseOrderList = () => {
  // state
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState(0);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const org = useRecoilValue(currentOrg);
  const orgId = org?.org_id;

  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);

  // Recoil
  const [purchaseOrderList, setPurchaseOrderList] = useRecoilState(purchaseOrdersState);

  // data fetching
  const fetchData = async (pageNumber, rowCount_Optional) => {
    if (!orgId) return;
    setLoading(true);
    try {
      const { data } = await OrganisationService.po
        .list(orgId, rowCount_Optional, pageNumber);

      setPurchaseOrderList(data.purchase_orders);
    } catch (e) {
      enqueueSnackbar('Load orders failed', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // handlers
  const onChangeStatus = (record, status) => {
    setPurchaseOrders([...purchaseOrders.filter(order => order.po_number !== record.po_number)]);
    OrganisationService.po.update(orgId, record.po_number, { status });
  };

  const onChangeExpArrivalDate = (record, date) => {
    const index = purchaseOrders.findIndex(
      (order) => order.po_number === record.po_number,
    );
    purchaseOrders.splice(index, 1, {
      ...record,
      expected_arrival_date: date,
    });
    setPurchaseOrders([...purchaseOrders]);
    OrganisationService.po.update(orgId, record.po_number, {
      expected_arrival_date: date,
    });
    setCalendarOpen(false);
  };

  const setPosition = (recordId) => (e) => {
    setDropdownOpen(!dropdownOpen);
    setPositionX(e.pageX);
    setPositionY(e.pageY);
    setSelectedRecordId(recordId);
    setCalendarOpen(false);
  };

  const setCalendarPosition = (recordId) => (e) => {
    if (e.target.className === 'arrive-date-picker') {
      setPositionX(e.pageX);
      setPositionY(e.pageY);
      setSelectedRecordId(recordId);
      setCalendarOpen(true);
      setDropdownOpen(false);
    }
  };

  const handleEditPO = (row) => {
    navigate(`edit?po=${row.po_number}`);
  };

  const columns = useMemo(() => [
    {
      name: 'PO#',
      selector: row => row.po_number,
      cell: record => {
        return (<div style={{ paddingLeft: '10px' }}>
          {record.po_number}
        </div>)
      },
      sortable: true,
    },
    {
      name: 'Vendor',
      selector: row => row.vendor,
      sortable: true,
    },
    {
      name: 'Location',
      selector: row => row.location,
      sortable: true,
    },
    {
      name: 'Units',
      selector: row => addCommas(row.units),
      right: true,
      sortable: true,
    },
    {
      name: 'Value',
      selector: row => row.total_cost,
      right: true,
      sortable: true,
      cell: (record) => <div>{new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(record.total_cost.toFixed(0))
        .replace(/\D00(?=\D*$)/, '')}</div>,
    },
    {
      name: 'Status',
      selector: row => row.status,
      center: true,
      cell: (record) => (
        <div onClick={setPosition(record.po_number)}>
          <StatusSelect key={`select_${record.po_number}`} status={record.status}
            onStatusChange={(newStatus) => {
              onChangeStatus(record, newStatus);
            }}
          />
        </div>
      ),
      ignoreRowClick: true,
      sortable: true,
    },
    {
      name: 'Exp. Arrival',
      selector: row => row.delivery_date,
      right: true,
      ignoreRowClick: true,
      sortable: true,
      cell: (record) => (
        <div
          onClick={setCalendarPosition(record.po_number)}
          style={{
            width: 130,
            height: 30,
            textAlign: 'right',
            paddingRight: '10px'
          }}
          className="calendar-record"
        >
          <ArrivalDatePicker
            record={record}
            onSelectDate={onChangeExpArrivalDate}
            position={{
              x: positionX,
              y: positionY
            }}
            calendarOpen={record.po_number === selectedRecordId && calendarOpen}
          />
        </div>
      ),
    },
  ]);

  const buttons = [
    {
      icon: 'stacked_bar_chart',
      title: 'targets',
      description: 'targets',
      action: () => navigate('../../targets/category'),
    },
    {
      icon: 'online_prediction',
      title: 'recommend',
      description: 'recommend',
      action: () => navigate('../../reports/re-orders'),
    },
    {
      icon: 'add_circle_outline',
      title: 'new',
      description: 'add new',
      action: () => navigate('add'),
    },
  ];

  // effects
  useEffect(() => {
    if (purchaseOrderList) {
      setPurchaseOrders(purchaseOrderList);
    }
  }, [purchaseOrderList]);

  useEffect(() => {
    fetchData(1).then();
  }, [org]);

  return (
    <Stack style={{ padding: '25px' }}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <PageTitle>
            Purchase Order Tracking
          </PageTitle>
        </Box>
        <NavMenu buttons={buttons} />
      </Box>
      <Box>
        {loading && (
          <Box display="flex" alignItems="center" flex={1} marginTop={20} justifyContent="center">
            <CircularProgress />
          </Box>)
        }
        {!loading && <Card>
          <CardContent>
            <DataTable
              data={purchaseOrders}
              columns={columns}
              noHeader
              highlightOnHover
              striped
              progressPending={loading}
              progressComponent={<CircularProgress />}
              onRowClicked={(row) => handleEditPO(row)}
            />
          </CardContent>
        </Card>}
      </Box>
    </Stack>
  );
};

export default PurchaseOrderList;
