import React from "react";
import { useStyles } from "./style";

// components
import CustomizedCheckbox from "@/components/CheckBox/CheckBox";

// mui
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { cloneDeep, isEmpty } from "lodash";

// categories should look like:
// {
//  1 : {
//        id: 1
//        name: 'prent category',
//        children : {
//          2 : {
//            id: 2,
//            name: 'sub category',
//            parentId: 1,
//            selected: true,
//          }
//        }
//    }
// }

const CategoriesHierarchyChooser = ({
  categories = {},
  setCategories,
}) => {
  const classes = useStyles();

  const selectedCategories = () => {
    const copyCategories = cloneDeep(categories);
    let allSelected = true;

    Object.entries(copyCategories).forEach(([parentId, parent]) => {
      const isAllChildrenSelected = Object.values(parent.children).every(child => child.selected);
      if (!isAllChildrenSelected) {
        allSelected = false;
      }
      copyCategories[parentId] = {
        ...parent,
        selected: isAllChildrenSelected,
      };
    });

    return {
      categoriesWithSelectedFlag: copyCategories,
      isAllSelected: allSelected,
    };
  };

  const { categoriesWithSelectedFlag, isAllSelected } = selectedCategories();

  // handlers

  const handleSelectionSubCategories = (categoriesList, parentId, categoryIds, selection = true) => {
    const copyCategories = cloneDeep(categoriesList);

    categoryIds.forEach(categoryId => {
      copyCategories[parentId].children[categoryId].selected = selection;
    })

    return copyCategories;
  }

  const handleSelectionParentCategory = (categoriesList, parentId, selection = true) => {
    const afterChildrenSelection = handleSelectionSubCategories(
      categoriesList,
      parentId,
      Object.keys(categoriesList[parentId].children),
      selection);
    afterChildrenSelection[parentId].selected = selection;
    return afterChildrenSelection;
  }

  const handleSelectionAll = (categoriesList, selection = true) => {
    let newCategories = categoriesList;

    Object.keys(categoriesList).forEach(parentId => {
      newCategories = handleSelectionParentCategory(newCategories, parentId, selection);
    });

    return newCategories;
  }

  // render

  const TreeViewItemLabel = (category) => {

    const onClickFunc = (e) => {
      // In case it's a sub category
      if (category.parentId) {
        const afterSelectionChange = handleSelectionSubCategories(categoriesWithSelectedFlag, category.parentId, [category.id], !category.selected)
        setCategories(afterSelectionChange);
      } else {
        // In case it's a parent category
        const afterSelectionChange = handleSelectionParentCategory(categoriesWithSelectedFlag, category.id, !category.selected);
        setCategories(afterSelectionChange);
      }
    }

    return (
      <div style={{
        padding: "3px 0px",
        display: "flex",
        alignItems: "center"
      }}
           onClick={(e) => e.stopPropagation()}>
        <CustomizedCheckbox
          checked={category.selected}
          color="#4da1aa"
          onChange={onClickFunc}
        />
        <span
          style={{ marginLeft: "4px" }}
          onClick={onClickFunc}
        >
          {category.name}
        </span>
      </div>
    );
  };

  const renderCategory = (parentId) => {
    const parent = categoriesWithSelectedFlag[parentId];
    const children = Object.values(parent.children ?? []);

    return <TreeItem
      key={parent.id}
      nodeId={parent.id.toString()}
      label={TreeViewItemLabel(parent)}
    >
      {children.map((child) => (
        <TreeItem
          key={child.id}
          nodeId={child.id.toString()}
          label={TreeViewItemLabel(child)}
        />
      ))}
    </TreeItem >
  }

  return (
    <div className={classes.container}>
      {isEmpty(categories) ? (
        <CircularProgress />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <Typography
              component="h1"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Choose Categories
            </Typography>
            <Button
              className={classes.selectAllButton}
              onClick={() => {
                const allSelected = handleSelectionAll(categoriesWithSelectedFlag, !isAllSelected);
                setCategories(allSelected);
              }}
              variant="text"
            >
              {isAllSelected ? 'Deselect All' : 'Select All'}
            </Button>
          </div>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
              height: 450,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#bfbfbf",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#8d8d8d",
              },
            }}
          >
            {Object.keys(categoriesWithSelectedFlag).map(renderCategory)}
          </TreeView>
        </>
      )}
    </div>
  );
};

export default CategoriesHierarchyChooser;
