import Loader from '../../../components/Loader';
import React from 'react';
import { useStyles } from "./styles.js";

const LoadingScreen = ({ text = 'Loading...' }) => {
  const classes = useStyles();

  return <div className={classes.container}>
    <div className={classes.loadingWrap}>
      <Loader />
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      </div>
    </div>
  </div>
}

export default LoadingScreen;
