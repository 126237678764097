import { makeStyles } from '@mui/styles';
import commonStyle from '../common-style';

export const useStyles = makeStyles((theme) => ({
  ...commonStyle,
  advanced: {
    boxShadow: 'none !Important',
  },
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      margin: '5px 0 25px 0'
    }
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  sliderRange: {
    margin: '6px',
    fontSize: '14px',
  },
  sliderValue: {
    fontSize: '15px',
    fontWeight: '600',
    width: '50px',
  },
}));
