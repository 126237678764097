import { makeStyles } from "@mui/styles";
import { GENERAL_SPACING, sectionCommonStyle } from "../../shared/sections-common-style";

export const useStyles = makeStyles((theme) => ({
  ...sectionCommonStyle,
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: GENERAL_SPACING,
    rowGap: GENERAL_SPACING,
    marginTop: GENERAL_SPACING,
    marginBottom: GENERAL_SPACING,
    height: '150px',
    flexWrap: 'wrap'
  },
  error: {
    color: '#d94c4c',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: GENERAL_SPACING
  }
}));
