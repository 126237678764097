import styled from "styled-components";

export const OpportunitiesAmount = styled.div`
  display: flex;
  flex-direction: column;
  
  .amount {
    color: rgb(16, 145, 133);
    display: block;
    font-weight: bolder;
    font-size: 38px;
    line-height: 1;
  }
  
  .label {
    font-weight: bolder;
    font-size: 0.99rem;
    text-transform: uppercase;
  }
`;

export const Alerts = styled.div`
  border: 1px solid lightgray;
  border-radius: 8px;
  line-height: 27px;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 15px;
  margin-top: 5px;
`;

export const AlertAmount = styled.div`
  margin-left: 10px;
`;
