import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  organizationsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  org: {
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '20px',
    marginBottom: '7px',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#eeeeee'
    },
    '&.active': {
      backgroundColor: '#d1fbfa'
    },
    '& > span': {
      fontWeight: 'bold'
    }
  },
  avatar: {
    height: "35px",
    width: "35px",
    marginRight: '15px',
    boxShadow: '2px 5px 17px -5px rgb(0 0 0 / 31%)',
  },
  noOrganizations: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    fontWeight: 'bold',
  }
}));
