import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#19d1dd'
    },
    gsAqua: {
      main: '#14dad9'
    },
    gsBlue: {
      main: '#19d1dd'
    },
    gsGreen: {
      main: '#18e7d3'
    },
    gsGray: {
      main: '#666666'
    },
    gsDarkGreen: {
      main: '#4da1aa'
    },
    gsMonochrome3: {
      main: '#8ecfd4'
    },
    gsMonochrome2: {
      main: '#2e787f'
    },
    gsMonochrome1: {
      main: '#095e65'
    },
    critical: {
      main: '#e84748'
    },
    warning: {
      main: '#ff9f43'
    },
    action: {
      main: '#19d1dd'
    },
    headlineGray: {
      main: '#444444'
    },
    lightGray: {
      main: '#f4f4f4'
    }
  }
});
