import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    padding: '20px',
    marginTop: '40px',
    borderTop: `5px solid ${theme.palette.primary.main}`,
    position: 'relative',
    boxShadow: '-4px 8px 19px -5px lightblue',
  },
  logo: {
    width: '120px',
  },
  wrapper: {
    fontFamily: "Montserrat",
    textAlign: 'center'
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '10px',
  },
  description: {
    fontSize: '18px',
    opacity: 0.7,
    width: '400px'
  },
  error: {
    color: '#d94c4c',
  },
  backToLogin: {
    position: 'absolute',
    bottom: '65px',
    fontWeight: 'bold',
    color: '#c5c5c5',
    cursor: 'pointer'
  }
}));
