import { Cell, CellGroup, CellGroupWrap, ColGroupTitle } from '../styles';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  background-color: white;
  display: flex;
  font-size: 14px;
  font-weight: bold;
`;

const LeftTopFiller = styled.div`
  width: 200px;
  min-width: 200px;
  padding: 2px 4px;
  background-color: rgb(224, 228, 235);
  position: sticky;
  left: 0;
`;

const headerStyle = {
  background: '#f2f2f2',
};

const Headers = ({
    fields = [],
    MainText,
    CompareText,
    VarianceText
}) => <Wrap>
    <LeftTopFiller />
    <CellGroupWrap>
        {
            fields.filter(g => !!g.visible).map((field, i) => {
                return <div key={`h_${field.value}`}>
                    <ColGroupTitle>{ field.display }</ColGroupTitle>
                    <CellGroup>
                        <Cell style={headerStyle}>{ MainText }</Cell>
                        <Cell style={headerStyle}>{ CompareText }</Cell>
                        <Cell style={headerStyle}>{ VarianceText }</Cell>
                    </CellGroup>
                </div>
            })
        }
    </CellGroupWrap>
</Wrap>;

export default Headers;
