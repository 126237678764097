import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    boxSizing: "border-box",
    fontSize: 13,
    minHeight: "32px",
    padding: "5px 5px 5px 9px",
    width: "100%",
    cursor: "pointer",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["IBM Plex Sans,sans-serif"].join(","),
  },
}));
export default BootstrapInput;
