import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// const
import { ITEM_PLANNER_COLUMNS } from "../utils/item-planner-columns";
import { Actions, ACTIONS_FIELD_NAMES } from "./utils/actions";

// store
import { reorderItemsState } from "@/store";
import { useRecoilState } from "recoil";

// styles
import classNames from "classnames";
import "./data-grid-overrides.css";
import { useStyles } from "./style";

// utils
import { editItems } from "./utils/edit-items";
import { getChildren } from "./utils/items-hirearchy";

// components
import { DataGridPro, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import CustomToolBar from './custom-toolbar';
import GridCellExpand from "./grid-cell-expand";

// mui
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip from "@mui/material/Tooltip";

const OrdersList = ({
  filteredItems,
  categories,
  groupByFields = [],
  columns,
  hiddenColumns,
  loading,
  setItemsColumns,
  onGroupBy,
  onColumns,
  onExport,
  onDuplicateItem,
  addChangesLog,
}) => {
  // state
  const classes = useStyles();
  const dataGridApiRef = useGridApiRef();
  const [items, setItems] = useRecoilState(reorderItemsState);
  const itemsRef = useRef();
  const productLevel = groupByFields.length;
  const [isAllExpand, setIsAllExpand] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(hiddenColumns);

  useEffect(() => {
    itemsRef.current = items;
  }, [JSON.stringify(items), items])

  // handlers
  const renderActions = useCallback((row) => {
    const onOrder = row[ACTIONS_FIELD_NAMES[Actions.OnOrder]];
    const isHide = row[ACTIONS_FIELD_NAMES[Actions.Hide]];
    const isOutOfStock = row[ACTIONS_FIELD_NAMES[Actions.OutOfStock]];

    return (
      <div className={classes.actionsWrapper}>
        <GridActionsCellItem
          icon={
            <Tooltip title="On Order" placement="bottom">
              <AssignmentTurnedInOutlinedIcon
                className={classNames(
                  "muiDataGridActionsIcon",
                  onOrder && "muiDataGridActionsActiveOnOrderIcon"
                )}
              />
            </Tooltip>
          }
          onClick={() => {
            const newItems = editItems(items, [{
              uid: row.uid,
              action: Actions.OnOrder,
              value: !onOrder,
            }]);
            setItems(newItems)
            addChangesLog([{
              location: row["Location ID"],
              itemId: row["Item ID"],
              field: "IsOnOrder",
              value: !onOrder,
            }]);
          }}
          label="On Order"
        />
        {onOrder ? (
          <span className={classes.onOrderText}>On Order</span>
        ) : (
          <>
            <GridActionsCellItem
              icon={
                <Tooltip title="Duplicate" placement="bottom">
                  <ContentCopyOutlinedIcon className="muiDataGridActionsIcon" />
                </Tooltip>
              }
              onClick={() => {
                onDuplicateItem(row);
              }}
              label="Duplicate"
            />
            <GridActionsCellItem
              icon={
                <Tooltip title="Hide Item" placement="bottom">
                  <VisibilityOutlinedIcon
                    className={classNames(
                      "muiDataGridActionsIcon",
                      isHide && "muiDataGridActionsActiveIcon"
                    )}
                  />
                </Tooltip>
              }
              onClick={() => {
                const newItems = editItems(items, [{
                  uid: row.uid,
                  action: Actions.Hide,
                  value: !isHide,
                }]);
                setItems(newItems)
                addChangesLog([{
                  location: row["Location ID"],
                  itemId: row["Item ID"],
                  field: "IsHide",
                  value: !isHide,
                }]);
              }}
              label="Hide"
            />
            <GridActionsCellItem
              icon={
                <Tooltip title="Out of Stock" placement="bottom">
                  <ProductionQuantityLimitsOutlinedIcon
                    className={classNames(
                      isOutOfStock && "muiDataGridActionsActiveIcon",
                      "muiDataGridActionsIcon"
                    )}
                  />
                </Tooltip>
              }
              onClick={() => {
                const newItems = editItems(items, [{
                  uid: row.uid,
                  action: Actions.OutOfStock,
                  value: !isOutOfStock,
                }]);
                setItems(newItems)
                addChangesLog([{
                  location: row["Location ID"],
                  itemId: row["Item ID"],
                  field: "IsOutOfStock",
                  value: !isOutOfStock,
                }]);
              }}
              label="Out of stock"
            />
          </>
        )}
      </div>
    );
  }, [items]);

  const renderParentActions = (row) => {
    const children = getChildren(filteredItems, row, productLevel, groupByFields)
    const onOrder = children.every((child) => child["IsOnOrder"]);
    const isHide = children.every((child) => child["IsHide"]);
    const isOutOfStock = children.every((child) => child["IsOutOfStock"]);

    const edit = (action, field, value) => {
      const changes = [];
      const changesLog = [];
      children.forEach((child) => {
        changes.push({
          uid: child.uid,
          action,
          value
        })
        changesLog.push({
          location: child["Location ID"],
          itemId: child["Item ID"],
          field: field,
          value: value,
        })
      });

      addChangesLog(changesLog);
      const newItems = editItems(items, changes);
      setItems(newItems)
    };

    return (
      <div className={classes.actionsWrapper}>
        <GridActionsCellItem
          icon={
            <Tooltip title="On Order" placement="bottom">
              <AssignmentTurnedInOutlinedIcon
                className={classNames(
                  "muiDataGridActionsIcon",
                  onOrder && "muiDataGridActionsActiveOnOrderIcon"
                )}
              />
            </Tooltip>
          }
          onClick={() => {
            edit(Actions.OnOrder, "IsOnOrder", !onOrder);
          }}
          label="On Order"
        />
        {onOrder ? (
          <span className={classes.onOrderText}>On Order</span>
        ) : (
          <>
            <GridActionsCellItem
              icon={
                <Tooltip title="Hide Item" placement="bottom">
                  <VisibilityOutlinedIcon
                    className={classNames(
                      "muiDataGridActionsIcon",
                      isHide && "muiDataGridActionsActiveIcon"
                    )}
                  />
                </Tooltip>
              }
              onClick={() => {
                edit(Actions.Hide, "IsHide", !isHide);
              }}
              label="Hide"
            />
            <GridActionsCellItem
              icon={
                <Tooltip title="Out of Stock" placement="bottom">
                  <ProductionQuantityLimitsOutlinedIcon
                    className={classNames(
                      isOutOfStock && "muiDataGridActionsActiveIcon",
                      "muiDataGridActionsIcon"
                    )}
                  />
                </Tooltip>
              }
              onClick={() => {
                edit(Actions.OutOfStock, "IsOutOfStock", !isOutOfStock);
              }}
              label="Out of stock"
            />
          </>
        )}
      </div>
    );
  };

  const editRowByFieldChange = useCallback((field, { value, row }) => {
    const newItems = editItems(itemsRef.current, [{
      uid: row.uid,
      action: Actions[field],
      value,
    }]);
    setItems(newItems)
    addChangesLog([{
      location: row["Location ID"],
      itemId: row["Item ID"],
      field: ACTIONS_FIELD_NAMES[field],
      value,
    }]);

    let rowRef = itemsRef.current.find((currRow) => currRow.uid === row.uid);

    return { ...rowRef, path: rowRef.path };
  }, [items, itemsRef.current]);

  const subCategoriesOptions = (mainCategoryId) => {
    const parentCategory = categories.find((cat) => cat.id === mainCategoryId);
    if (parentCategory) {
      const currSubCategories = parentCategory.children;
      const currSubOptions = currSubCategories.map((subCat) => ({
        value: subCat.id,
        label: subCat.name,
      }));
      return currSubOptions;
    } else {
      return [];
    }
  };

  const expandAllRows = (open = true) => {
    filteredItems.forEach(row => {
      // If expandable rows
      if (row.path.length <= 2) {
        dataGridApiRef.current.setRowChildrenExpansion(row.uid, open)
      }
    })
    setIsAllExpand(open)
  }

  const isProduct = (row) => row.path && row.path.length === groupByFields.length + 1;

  const editableCellClassForItems = (params) => {
    if (!isProduct(params.row)) return false;
    return Number.isFinite(params.id) ? classes.editableCell : "";
  };

  const renderCellExpand = (params) => {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  };

  const initialColumns = [
    {
      field: ITEM_PLANNER_COLUMNS["Location"].field,
      headerName: "Location",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Location ID"].field,
      headerName: "Location ID",
      sortable: false,
      hide: true
    },
    {
      field: ITEM_PLANNER_COLUMNS["Category"].field,
      headerName: "Category",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Category ID"].field,
      headerName: "Category ID",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Brand"].field,
      headerName: "Brand",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Brand ID"].field,
      headerName: "Brand ID",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Vendor"].field,
      headerName: "Vendor",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Vendor ID"].field,
      headerName: "Vendor ID",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Product Name"].field,
      headerName: "Product Name",
      sortable: false,
      editable: true,
      width: 250,
      cellClassName: editableCellClassForItems,
      valueSetter: (params) => {
        return editRowByFieldChange(Actions.ProductName, params);
      },
    },
    {
      field: ITEM_PLANNER_COLUMNS["Sub Category"].field,
      headerName: "Sub Category",
      type: "singleSelect",
      editable: true,
      sortable: false,
      cellClassName: editableCellClassForItems,
      valueOptions: ({ row }) => {
        return subCategoriesOptions(row["Category ID"]);
      },
      valueSetter: (params) => {
        const newSubCategory = subCategoriesOptions(
          params.row["Category ID"]
        ).find((subCat) => subCat.value === params.value);
        const updatedRow = editRowByFieldChange(Actions.SubCategoryID, {
          value: newSubCategory.value,
          row: params.row,
        });

        return editRowByFieldChange(Actions.SubCategory, {
          value: newSubCategory.label,
          row: updatedRow,
        });
      },
    },
    {
      field: ITEM_PLANNER_COLUMNS["Sub Category ID"].field,
      headerName: "Sub Category ID",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["AUC"].field,
      headerName: "AUC",
      sortable: true,
      editable: true,
      cellClassName: editableCellClassForItems,
      valueFormatter: ITEM_PLANNER_COLUMNS["AUC"].formatter,
      valueSetter: (params) => {
        return editRowByFieldChange(Actions.AUC, params);
      },
    },
    {
      field: ITEM_PLANNER_COLUMNS["Cases to Order"].field,
      headerName: "Cases to Order",
      sortable: true,
      editable: true,
      cellClassName: editableCellClassForItems,
      valueFormatter: ITEM_PLANNER_COLUMNS["Cases to Order"].formatter,
      valueSetter: (params) => {
        return editRowByFieldChange(Actions.OrderCases, params);
      },
    },
    {
      field: ITEM_PLANNER_COLUMNS["Case Pack Size"].field,
      headerName: "Case Pack Size",
      sortable: true,
      editable: true,
      cellClassName: editableCellClassForItems,
      valueFormatter: ITEM_PLANNER_COLUMNS["Case Pack Size"].formatter,
      valueSetter: (params) => {
        return editRowByFieldChange(Actions.CasePackSize, params);
      },
    },
    {
      field: ITEM_PLANNER_COLUMNS["Total Order - Units"].field,
      headerName: "Total Order - Units",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Total Order - Units"].formatter,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Total Order - Cost"].field,
      headerName: "Total Order - Cost",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Total Order - Cost"].formatter,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Default Cost"].field,
      headerName: "Default Cost",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Default Cost"].formatter,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Default Price"].field,
      headerName: "Default Price",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Default Price"].formatter,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Tax Rate"].field,
      headerName: "Tax Rate",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Tax Rate"].formatter,
    },
    {
      field: ITEM_PLANNER_COLUMNS["AUV"].field,
      headerName: "AUV",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["AUV"].formatter,
    },
    {
      field: ITEM_PLANNER_COLUMNS["New Out"].field,
      headerName: "New Out",
      sortable: false,
    },
    {
      field: ITEM_PLANNER_COLUMNS["Avg Weekly Unit Sales"].field,
      headerName: "Avg Weekly Unit Sales",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Avg Weekly Unit Sales"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["Curr Units On Hand"].field,
      headerName: "Curr Units On Hand",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Curr Units On Hand"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["Reorder Target On Hand"].field,
      headerName: "Reorder Target On Hand",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Reorder Target On Hand"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["Expected Lead Time Unit Sales"].field,
      headerName: "Expected Lead Time Unit Sales",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Expected Lead Time Unit Sales"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["Raw Reco"].field,
      headerName: "Raw Reco",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Raw Reco"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["Case Rounded Reco"].field,
      headerName: "Case Rounded Reco",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Case Rounded Reco"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["Final Reco"].field,
      headerName: "Final Reco",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Final Reco"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["Weeks Of Supply"].field,
      headerName: "Weeks Of Supply",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["Weeks Of Supply"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["WGMROI"].field,
      headerName: "WGMROI",
      sortable: true,
      valueFormatter: ITEM_PLANNER_COLUMNS["WGMROI"].formatter
    },
    {
      field: ITEM_PLANNER_COLUMNS["Reorder Notes"].field,
      headerName: "Reorder Notes",
      sortable: false,
      renderCell: renderCellExpand,
    },
  ]

  const actionsColumn = useMemo(() => ({
    field: "actions",
    headerName: "Actions",
    width: 150,
    renderCell: (params) => {
      if (isProduct(params.row)) {
        return renderActions(params.row);
      }
      return renderParentActions(params.row);
    },
    sortable: false,
  }), [items])

  // effects
  useEffect(() => {
    setColumnVisibilityModel(hiddenColumns.reduce((model, column) => Object.assign(model, { [column]: false }), {}))
  }, [hiddenColumns])

  useEffect(() => {
    setItemsColumns(initialColumns)
  }, []);

  const getRowClassName = ({ row }) => {
    const product = isProduct(row);
    if (product) {
      if (row[ACTIONS_FIELD_NAMES[Actions.OnOrder]]) {
        return `product-row ${classes.onOrder}`;
      } else if (!row.IsInBudget || row.IsHide) {
        return `product-row ${classes.noBudgetOrHide}`;
      }
      return `product-row`
    } else {
      // mark parent which all his childs are on order as on order too
      const children = getChildren(filteredItems, row, productLevel, groupByFields)
      if (children.every(child => child[ACTIONS_FIELD_NAMES[Actions.OnOrder]])) {
        return classes.onOrder
      }
    }
    return "";
  };

  const groupingColDef = {
    headerName: 'Group',
    width: 200 + 15 * groupByFields.length,
    hideDescendantCount: true,
    renderHeader: () => {
      return <div className={classes.groupHeader}>
        <span>Group</span>
        <Tooltip title={`${isAllExpand ? 'Collapse' : 'Expand'} Groups`}>
          <div className={classes.expandAll} onClick={() => expandAllRows(!isAllExpand)}>
            {isAllExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </Tooltip>
      </div>
    }
  }

  return (
    <div className={classNames(classes.container, "item-planner-container")}>
      <DataGridPro
        loading={loading}
        rows={filteredItems}
        columns={[...columns, actionsColumn]}
        apiRef={dataGridApiRef}
        hideFooter
        treeData={groupByFields.length !== 0}
        density="compact"
        defaultGroupingExpansionDepth={-1}
        components={{
          Toolbar: CustomToolBar,
        }}
        componentsProps={{
          toolbar: {
            actions: {
              onGroupBy,
              onColumns,
              onExport,
            }
          }
        }}
        getRowId={(row) => row.uid}
        getTreeDataPath={(row) => row.path}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
        getRowClassName={getRowClassName}
        isCellEditable={(params) => isProduct(params.row)}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
        }}
        groupingColDef={groupingColDef}
      />
    </div>
  );
};

export default OrdersList;
