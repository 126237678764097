// libs
import Button from '../../../../../components/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import React, { useEffect, useMemo, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import MuiButton from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import { ExportToCsv } from 'export-to-csv';
import Modal from '@mui/material/Modal';
import { SlideTransition } from "@/utils/transitions/transitions";

// deps
import OrganisationService from '../../../../../service/organisation-service';
import WeekMonthPicker from '../../../../../components/WeekMonthPicker';
import Sorter from '../../../../../components/DataGrid/Sorter';
import DataGrid from '../../../../../components/DataGrid';
import { metrics } from '../metric-groups';

// services
import ProductTypeService from "@/service/org/product-type.service";

// styles
import { Wrapper, Actions, useStyles } from './styles';
import Loader from '../../../../../components/Loader';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentOrg, districts as districtsState } from '../../../../../store';
import PageTitle from '../../../../../components/PageTitle';
import CustomSelect from '../../../../../components/CustomSelect';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import CategoriesHierarchyChooser from '../../../../../components/CategorySelect';
import BootstrapInput from '../../../../../components/BootstrapInput';
import { productTypesState } from '../../../../../store/admin-panel/admin-panel.store';
import { selectedCategoriesFilterState } from '../../../../../store/admin-panel/products.store';

const getSelectedIds = (categories) => {
  let selectedIds = [];
  for (const key in categories) {
    if (categories[key].children) {
      for (const childKey in categories[key].children) {
        if (categories[key].children[childKey].selected) {
          selectedIds.push(categories[key].children[childKey].id);
        }
      }
    }
  }
  return selectedIds;
};

const LocationRecap = () => {
  const REPORT_NAME = 'location_recap_metric_order';

  const org = useRecoilValue(currentOrg);
  const orgId = org?.org_id;

  const setProductTypes = useSetRecoilState(productTypesState);
  const [isCustomizeFieldsModalOpen, setIsCustomizeFieldsModalOpen] = useState(false);
  const [metricGroups, setMetricGroups] = useState(metrics);
  const [locationId, setLocationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [range, setRange] = useState(null);
  const [data, setData] = useState(null);
  const [raw, setRaw] = useState(null);
  const [mode, setMode] = useState('');
  const classes = useStyles();
  const districts = useRecoilValue(districtsState);
  const [district, setDistrict] = useState(-1);
  const [categoriesDialogOpen, setCategoriesDialogOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useRecoilState(selectedCategoriesFilterState);
  const categories = useRecoilValue(productTypesState);
  const [selectedCategoriesFilter, setSelectedCategoriesFilter] =
    useRecoilState(selectedCategoriesFilterState);

  const selectedCategoryIds = useMemo(() => getSelectedIds(selectedCategoriesFilter), [selectedCategoriesFilter]);

  useEffect(() => {
    const orderMap = JSON.parse(localStorage.getItem(REPORT_NAME));
    if (!orderMap) return;
    let ordered = [...metricGroups.map((m) => ({ ...m, visible: false }))];
    const visible = [];
    orderMap.map((metric) => {
      const index = ordered.findIndex((o) => o.value === metric);
      const m = ordered.splice(index, 1);
      visible.push({
        ...m[0],
        visible: true
      });
    });
    ordered = ordered.sort((a, b) => {
      const cat1 = a?.display?.toUpperCase();
      const cat2 = b?.display?.toUpperCase();
      return cat1 < cat2 ? -1 : cat1 > cat2 ? 1 : 0;
    });
    setMetricGroups([...visible, ...ordered]);
  }, []);

  const loadLocationData = async () => {
    try {
      setIsLoading(true);
      const { data: locations } = await OrganisationService.location.list(orgId, 100000);
      locations && setLocations(locations.loc || []);
    } catch (e) {
      console.log('Cannot get locations.');
      setLocations([]);
    }
  };

  const loadProductTypes = async () => {
    setIsLoading(true);
    const productTypes = await ProductTypeService.getProductTypesHierarchy();
    setProductTypes(productTypes.data);
  };

  const setAllCategoriesAsSelected = () => {
    const categoriesWithSelection = {};
    Object.entries(categories).forEach(([parentId, parent]) => {
      const childrenWithSelection = {};

      Object.entries(parent.children ?? {}).forEach(([childId, child]) => {
        childrenWithSelection[childId] = {
          ...child,
          selected: true,
        }
      });

      categoriesWithSelection[parentId] = {
        ...parent,
        children: childrenWithSelection,
        selected: true,
      };
    })
    setSelectedCategories(categoriesWithSelection);
  }

  useEffect(() => {
    loadLocationData().then();
    loadProductTypes().then();
  }, []);

  useEffect(() => {
    setAllCategoriesAsSelected();
  }, [categories]);

  const loadRecapData = async () => {
    try {
      if (locations.length === 0) return;
      setIsLoading(true);
      const locationIds = district === -1 ? locations.map((l) => l.id)
        : locations
          .filter((l) => l.district_id === district)
          .map((l) => l.id);

      const { data } = await OrganisationService.reports.locationRecap(orgId, range, mode, locationIds, selectedCategoryIds);
      const { locationRecap, raw } = data;
      setData(locationRecap);
      setRaw(raw);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadRecapData().then();
  }, [district, locationId, range, locations]);

  const onDateSelected = ({ start_month, end_month, week_id }, mode) => {
    if (mode === 'week') {
      setRange(week_id);
      setMode(mode);
    } else {
      setRange([start_month, end_month]);
      setMode(mode);
    }
  };

  const onDownload = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      filename: 'location-recap',
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(raw);
  };

  const reset = () => {
    setRange(null);
    setMode('');
  };

  const allCategoriesAreSelected = Object.values(selectedCategoriesFilter)
    .every(parent => parent.selected);

  const handleApplyCategories = () => {
    setCategoriesDialogOpen(false);
    loadRecapData().then();
  };

  return (
    <Wrapper>
      <PageTitle>Location Recap Report</PageTitle>
      <Actions>
        <div style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center'
        }}>
          <WeekMonthPicker onDateSelected={onDateSelected} reset={reset} />
          <Button style={{
            padding: '0 40px',
            borderRadius: '10px',
            height: '32px',
            width: '100px'
          }} onClick={() => setIsCustomizeFieldsModalOpen(true)}>
            <TuneIcon />
            <span style={{ paddingLeft: '5px' }}>Metrics</span>
          </Button>
          <CustomSelect
            value={district}
            options={districts.map(district => ({
              key: district.district_id,
              value: district.district_name
            }))}
            onChange={setDistrict}
            style={{
              width: '200px',
              marginTop: '4px'
            }}
          />
            <BootstrapInput
              value={allCategoriesAreSelected ? 'All Categories' : 'Filtered Categories'}
              readOnly
              className={classes.boostrapWrapper}
              style={{
                width: '200px',
                display: 'flex',
                alignItems: 'start'
              }}
              onClick={() => setCategoriesDialogOpen(true)}
            />
          <Modal
            className={classes.modalRoot}
            open={isCustomizeFieldsModalOpen}
            onClose={() => setIsCustomizeFieldsModalOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 200
            }}>
            <Sorter
              metricGroups={metricGroups}
              reportName={REPORT_NAME}
              setMetricGroups={setMetricGroups}
              onClose={() => setIsCustomizeFieldsModalOpen(false)}
            />
          </Modal>
        </div>
        <div style={{ display: 'flex' }}>
          <Button onClick={onDownload} style={{
            padding: '0 15px',
            borderRadius: '10px',
            height: '32px'
          }}>
            <GetAppIcon />
            <span>Download</span>
          </Button>
        </div>
      </Actions>
      {isLoading && <Loader />}
      {!isLoading && (
        <DataGrid
          data={data}
          levelOneNameField="location_name"
          levelTwoNameField="name"
          MainText="Actuals"
          CompareText="Forecast"
          VarianceText="Var %"
          metricGroups={metricGroups}
        />
      )}
      <Dialog
        open={categoriesDialogOpen}
        TransitionComponent={SlideTransition}
        onClose={() => setCategoriesDialogOpen(false)}
        PaperProps={{
          sx: {
            width: "350px",
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <CategoriesHierarchyChooser
            categories={selectedCategoriesFilter}
            setCategories={(newCategories) => {
              setSelectedCategoriesFilter(newCategories)
            }}
          />
        </DialogContent>
        <div style={{
          padding: '20px',
          textAlign: 'right'
        }}>
          <MuiButton onClick={handleApplyCategories} variant="contained" sx={{ color: 'white', width: '100%' }}>
            Apply
          </MuiButton>
        </div>
      </Dialog>
    </Wrapper>
  );
};

export default LocationRecap;
