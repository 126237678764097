import { cloneDeep, range } from "lodash";
import { aggregateValues } from "../../../../../../../../utils/aggregate";

export const convertOrderItemsToHirerarchyArray = (items, locations) => {
  const hirearchy = [];
  let uid = 0;

  items.forEach((item) => {
    const locationId = item["Location ID"];
    const locationName = locations.find(
      (location) => location.id === locationId
    )?.name;

    // calculated fields
    const totalOrderUnits =
      (+item["Reco Case Count"] || 0) * (+item["Case Pack Size"] || 0);
    const totalOrderCost = totalOrderUnits * item["AUC"];

    const itemRow = {
      uid,
      path: [locationName, item["Category"], uid],
      "Item ID": item["Item ID"],
      "Product Name": item["Product Name"],
      "Product Type ID": item["Product Type ID"],
      "Product Type": item["Product Type"],
      "Location Name": item["Location Name"],
      "Location ID": item["Location ID"],
      Category: item["Category"],
      "Category ID": +item["Category ID"],
      "Brand Name": item["Brand Name"],
      "Brand ID": parseInt(item["Brand ID"]),
      "Vendor Name": item["Vendor Name"],
      "Vendor ID": parseInt(item["Vendor ID"]),
      "Sub Category": item["Sub Category"],
      "Sub Category ID": +item["Sub Category ID"],
      "Case Rounded Reco": +item["Case Rounded Reco"],
      "Default Cost": +item["Default Cost"],
      "Default Price": +item["Default Price"],
      "Tax Rate": +item["Tax Rate"],
      "New Out": item["New Out"],
      "Case Pack Size": +item["Case Pack Size"],
      AUC: item["AUC"],
      AUV: +item["AUV"],
      WGMROI: item["Weekly Gross Margin Return On Investment"],
      "Cases to Order": +item["Reco Case Count"],
      "Final Reco": item["Final Reco"],
      "Raw Reco": item["Raw Reco"],
      "Estimated Cost": item["Estimated Cost"],
      "Avg Weekly Sales": item["Avg Weekly Sales"],
      "Current On Hand": item["Current On Hand"],
      "Future Target On Hand": item["Future Target On Hand"],
      "Reorder Notes": item["Reorder Notes"],
      cost: +(item.AUC || 0) * +(item["Final Reco"] || 0),
      WOS: item["WOS"],
      "Lead Time Sales": item["Lead Time Sales"],
      "Total Order - Units": totalOrderUnits,
      "Total Order - Cost": totalOrderCost,
      IsOutOfStock: item.IsOutOfStock === "true" ?? false,
      IsHide: item.IsHide === "true",
      IsOnOrder: item.IsOnOrder === "true",
      isDummy: item.isDummy === "true",
      IsInBudget: item.IsInBudget === "true",
    };
    hirearchy.push(itemRow);
    uid++;
  });

  return hirearchy;
};

export const convertHirerarchyArrayToConfigCategories = (
  oldConfig,
  hirearchy
) => {
  const newConfig = { ...oldConfig.categoryTargets };

  Object.keys(newConfig).forEach(([locationName]) => {
    const locationData = hirearchy.find(
      (row) => row.path?.length === 1 && row.path[0] === locationName
    );
    newConfig[locationName] = {
      unit_sales: locationData.unit_sales,
      on_hand: locationData.on_hand,
      weeks_on_hand: locationData.woh,
      target: locationData.target,
      name: locationName,
    };

    newConfig[locationName].children = [];

    const children = hirearchy.filter(
      (row) => row.path[0] === locationName && row.path.length === 2
    );

    children.forEach((child) => {
      const childName = child.path[1];
      const subChildren = hirearchy.filter(
        (row) =>
          row.path[0] === locationName &&
          row.path[1] === childName &&
          row.path.length === 3
      );

      newConfig[locationName].children = [
        ...newConfig[locationName].children,
        {
          unit_sales: child.unit_sales,
          on_hand: child.on_hand,
          weeks_on_hand: child.woh,
          target: child.target,
          name: childName,
          children: subChildren.map((subchild) => ({
            unit_sales: subchild.unit_sales,
            on_hand: subchild.on_hand,
            weeks_on_hand: subchild.woh,
            target: subchild.target,
            name: subchild.path[2],
          })),
        },
      ];
    });
  });

  return newConfig;
};

export const aggregateLevels = (items) => {
  const copyItems = cloneDeep(items);
  const uidsToFilter = [];
  copyItems.forEach((row) => {
    if (row.path.length === 2) {
      const children = items.filter(
        (item) =>
          item.path.length === 3 &&
          item.path[0] === row.path[0] &&
          item.path[1] === row.path[1]
      );

      if (children.length === 0) {
        uidsToFilter.push(row.uid);
      } else {
        const aggregatedValues = aggregateValues(children, [
          "Case Rounded Reco",
          "Case Pack Size",
          "AUC",
          "WGMROI",
          "Cases to Order",
          "Final Reco",
          "Raw Reco",
          "Estimated Cost",
          "Avg Weekly Sales",
          "Current On Hand",
          "Future Target On Hand",
          "cost",
          "WOS",
          "Total Order - Units",
          "Total Order - Cost",
        ]);

        Object.entries(aggregatedValues).forEach(([key, value]) => {
          row[key] = value;
        });
      }
    }
  });

  return copyItems.filter((row) => !uidsToFilter.includes(row.uid));
};

export const getChildren = (items, parent, productsLevel, groupByFields) => {
  if (parent.path) {
    return items.filter(row => {
      if (row.path.length !== productsLevel + 1) return false
      return range(0, parent.path.length).every(level =>
        row[groupByFields[level]] === parent.path[level]
      )
    })
  }
  return []
}

export const calculateTotalCost = (row) => {
  if (!row["Cases to Order"] && !row["Case Pack Size"]) return 0;
  const casesToOrder = +(row?.["Cases to Order"] || 0);
  const casePackSize = +(row?.["Case Pack Size"] || 0);
  const auc = +(row?.["AUC"] || 0);
  const totalCost = casesToOrder * casePackSize * auc;
  return totalCost;
};