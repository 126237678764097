import styled from 'styled-components';
import React from 'react';
import { makeStyles } from '@mui/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-bottom: 25px;
  font-size: 14px;
  padding: 25px;
`;


export const Actions = styled.div`
    display: flex;
    justify-content: space-between;
`;


export const useStyles = makeStyles(theme => ({
    modalRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
    }
}));

export const Title = styled.h2`
  margin-bottom: 22px;
  font-size: 24px;
`;
