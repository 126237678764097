export const formatDollar = (amount, decimals = 2) => {
    const sign = (amount ?? 0) === 0 ? 0 : (Math.abs(amount) / amount);
    const n = isFinite(amount) ?
        Number(Math.abs(Number(amount)))
            .toFixed(decimals)
            .toString()
        : 0;

    const formatted = n
      .toString()
      .replace(".00", "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

    return `${(sign >= 0 ? `$${formatted}` : `-$${formatted}`)}`;
}

// format quarter display: 2021_Q3 -> Q3 2021
export const formatQuarter = (year_quarter) => {
    const parts = year_quarter && year_quarter.split('_');
    const quarter = parts && parts[1] && parseInt(parts[1].replace('Q', ''));
    const year = parts && parts[0] && parseInt(parts[0]);
    return {
        value: year_quarter,
        display: `Q${quarter} ${year}`,
        quarter,
        year,
    };
}

export const formatFigures = (key, value) => {
    if (!value)
        return "0"
    if (["sales", "on_hand"].includes(key)) {
        let formattedValue = Number(value / 1000).toFixed(1)
        return formattedValue === "0.00" ?
            "0" :
            (key === "sales" ? "$" : "") + formattedValue + "k"
    } else if ([ "receipts"].includes(key)) {
        let formattedValue = Number(value / 1000).toFixed(2)
        return formattedValue === "0.00" ?
            "0" :
            (key === "sales" ? "$" : "") + formattedValue + "k"
    } else if ([ "wos"].includes(key)) {
        return Number(value).toFixed(1);
    } else if(["unit_sales"].includes(key)) {
        const toNum = Number(value);
        return toNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    else
        return value
}

export const toPercent = (value, decimals = 2, multiplyBy = 1) => (value ? Number(value * multiplyBy).toFixed(decimals).toString() : "0") + "%"

export const addCommas = (value, decimals = 0) => Number(value).toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
