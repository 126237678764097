import { ACCESS_TOKEN_KEY } from "../store";
import API from "./service";

const WorkbenchService = {
  isExist: (orgPublicId) =>
    API.get(`workbench/config/exists?org_public_id=${orgPublicId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
      },
    }),
  getConfig: (orgPublicId) =>
    API.get(`workbench/config?org_public_id=${orgPublicId}`),
  updateConfig: (orgPublicId, config) =>
    API.post(`workbench/config?org_public_id=${orgPublicId}`, config),
  getReorder: (orgPublicId) =>
    API.get(`workbench/reorder?org_public_id=${orgPublicId}`),
  updateReorder: (orgPublicId, changes) =>
    API.post(`workbench/reorder?org_public_id=${orgPublicId}`, changes),
  pingActiveReorder: (orgPublicId) =>
    API.post(`workbench/ping?org_public_id=${orgPublicId}`),
};

export default WorkbenchService;
