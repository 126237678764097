import styled from 'styled-components';
import { animated, useSpring } from 'react-spring/web.cjs';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
    row-gap: 25px;
  margin-bottom: 25px;
  font-size: 14px;
  flex: 1;
  height: 100vh;
  padding: 25px;
`;

export const CellGroup = styled.div`
  display: flex;
  margin: 0 5px;
`;

export const Cell = styled.div`
  flex: 1;
  width: 75px;
  text-align: right;
  padding: 4px 6px;
  font-size: 12px;
  border: solid 1px #e0e4eb;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


export function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)'
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    in: PropTypes.bool,
};

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: 35,
        overflowX: 'scroll'
    },
    modalRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
    },
    modalBody: {
        backgroundColor: 'white',
        padding: '15px 20px',
        width: '350px',
        borderRadius: '0 0 5px 5px',
    },
    draggable: {
        zIndex: 99999,
        background: 'white',
        boxShadow: '0px 3px 6px 0px #f1f1f1',
    }
}));

export const Title = styled.h2`
  margin-bottom: 22px;
  font-size: 24px;
`;
