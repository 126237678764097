import styled from 'styled-components';

export const EmptyHistory = styled.div`
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  border-radius: 5px;
  font-weight: bold;
  color: gray;
  border: 1px solid #ced4da;
  margin-bottom: 15px;
`;
