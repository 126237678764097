import styled from "styled-components";

export const CardHeaderStyle = styled.div`
  color: #8d8d8d;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  font-size: 16px;
`

export const CardFooterStyle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  display: flex;  
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  
  a {
    color: #19d1dd;

    &:hover {
      color: #19d1dd;
    }
  }
`;
