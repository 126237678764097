import React from "react";
import { useStyles } from "./style";
import NumberEditableText from "../../../../../../../components/NumberEditableText/NumberEditableText";
import { TARGETS_MODE } from "../utils/constants";

const FooterAggregateData = ({ values, lastElement, mode, lastElementStyle = {} }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
      }}
    >
      <div className={classes.total}>Grand Total</div>
      {values.map((value, index) => (
        <div className={classes.aggregateCell} key={index} style={{
          ...(index === values.length - 1 && lastElementStyle)
        }}>
          {index !== values.length - 1 ? (
            <NumberEditableText
              align="right"
              displayType="text"
              prefix={mode === TARGETS_MODE.cost && '$'}
              value={value?.toFixed(1) || ""}
            />
          ) : lastElement ? (
            lastElement(value)
          ) : (
            <NumberEditableText
              align="right"
              displayType="text"
              value={value?.toFixed(1) || ""}
              prefix={mode === TARGETS_MODE.cost && '$'}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default FooterAggregateData;
