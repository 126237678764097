import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// styles
import { useStyles } from "./style";
import logo from '@/assets/gs-logo.png';

const SwitchOrg = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(`/account/dashboard`);
    }, 2000)
  }, []);

  return (<div
    className={classes.wrapper}
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
    <img className={classes.logo} src={logo} alt="GS Logo" />
    <span className={classes.title}>Switching organization...</span>
  </div>
  )
}

export default SwitchOrg;
