import React, { useEffect, useState } from "react";
import { useStyles } from "./style";

// components
import CustomizedCheckbox from "@/components/CheckBox/CheckBox";

// mui
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";


const ProductTypeSelector = ({
  categories = [],
  defaultSelected = [],
  onApply = (ids) => { },
  onClose = () => { },
}) => {

  // state
  const classes = useStyles();
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState(defaultSelected);

  function getAllChildrenIds(data, id) {
    let childrenIds = [];

    const findChildren = (nodes) => {
      nodes.forEach(node => {
        if (node.id === id) {
          // If the current node matches the levelId, gather all its children's IDs
          node.children.forEach(child => {
            childrenIds.push(child.id);
            // Since you want a flat array of all children (including grandchildren),
            // you need to check if these children have their own children
            if (child.children.length > 0) {
              childrenIds = childrenIds.concat(child.children.map(grandchild => grandchild.id));
            }
          });
        } else if (node.children.length > 0) {
          // If not found, search the next level
          findChildren(node.children);
        }
      });
    };

    findChildren(data);

    return childrenIds;
  }

  const selectItem = (id) => {
    if (selectedCategoriesIds.includes(id)) {
      const descendants = getAllChildrenIds(categories, id);
      setSelectedCategoriesIds(prev => prev
        .filter((value, index, array) => array.indexOf(value) === index)
        .filter((value) => ![id, ...descendants].includes(value)));
    } else {
      const descendants = getAllChildrenIds(categories, id);
      setSelectedCategoriesIds(prev => prev
        .concat([id, ...descendants])
        .filter((value, index, array) => array.indexOf(value) === index));
    }
  };

  const TreeViewItemLabel = ({
    id,
    name
  }) => {
    return (
      <div style={{
        padding: "3px 0px",
        display: "flex",
        alignItems: "center"
      }}
           onClick={(e) => e.stopPropagation()}>
        <CustomizedCheckbox
          checked={selectedCategoriesIds.includes(id)}
          color="#4da1aa"
          onChange={(e) => {
            selectItem(id);
          }}
        />
        <span
          style={{ marginLeft: "4px" }}
          onClick={(e) => {
            selectItem(id)
          }
          }
        >
          {name}
        </span>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {categories.length === 0 ? (
        <CircularProgress />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <Typography
              component="h1"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Choose Categories
            </Typography>
            <div className={classes.actions}>
              <Button
                className={classes.selectAllButton}
                onClick={() => selectItem(1)}
                variant="text"
              >
                Select All
              </Button>
              <Button
                className={classes.selectAllButton}
                onClick={() => setSelectedCategoriesIds([])}
                variant="text"
              >
                Clear
              </Button>
            </div>
          </div>
          <TreeView
            aria-label="file system navigator"
            defaultExpanded={["1"]}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
              height: 450,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#bfbfbf",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#8d8d8d",
              },
            }}
          >
            {categories.map((parent) => (
              <TreeItem
                key={parent.id}
                nodeId={parent.id.toString()}
                label={TreeViewItemLabel(parent)}
              >
                {(parent.children ? parent.children : []).map((child) => (
                  <TreeItem
                    key={child.id}
                    nodeId={child.id.toString()}
                    label={TreeViewItemLabel(child)}
                  >
                    {(child.children ? child.children : []).map((subChild) => (
                      <TreeItem
                        key={subChild.id}
                        nodeId={subChild.id.toString()}
                        label={TreeViewItemLabel(subChild)}
                      />
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeView>
          <div>
            <br />
            <Button className={classes.selectedAllButton}
                    onClick={() => {
                      onApply(selectedCategoriesIds);
                      onClose();
                    }} variant="text">
              Apply
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductTypeSelector;
