import React, { useState, useEffect } from "react";

// styles
import { useStyles } from "./style";

// components
import Sorter from "../../../../../../../components/DataGrid/Sorter";

// mui
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";

// const
import { getGroupedFieldsValues, GROUP_BY_FIELDS } from "./utils/constants";


const GroupByDialog = ({
  show,
  groupByFields,
  onChangeGrupeByFields,
  onCloseDialog,
}) => {
  // state
  const classes = useStyles();
  const [metrics, setMetrics] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  // handlers
  const initMetrics = (groupFields) => {
    const currentMetrics = GROUP_BY_FIELDS.map((field) => {
      if (groupFields.includes(field.value)) {
        return { ...field, visible: true }
      }
      return { ...field, visible: false }
    }).sort((a, b) => groupFields.indexOf(a.value) - groupFields.indexOf(b.value));
    setMetrics(currentMetrics)
  };

  const forAllGroupByFields = (select) => {
    const allGroupByFields = GROUP_BY_FIELDS.map(field => field.value)
    if (select) {
      initMetrics(allGroupByFields);
    } else {
      initMetrics([]);
    }
    setIsAllSelected(oldStatus => !oldStatus)
  };

  const cleanAndClose = () => {
    initMetrics(groupByFields)
    onCloseDialog();
  };

  const closeDialog = () => {
    const groupedFields = getGroupedFieldsValues(metrics);
    onChangeGrupeByFields(groupedFields);
    cleanAndClose();
  };

  // effects
  useEffect(() => {
    initMetrics(groupByFields);
  }, [groupByFields]);

  return (
    <Dialog
      open={show}
      onClose={cleanAndClose}
      PaperProps={{
        sx: {
          width: "350px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }} className={classes.wrapper}>
        <Sorter
          reportName="itemPlanner-groupByFields"
          metricGroups={metrics}
          setMetricGroups={setMetrics}
          cacheInLocalStorage={false}
          headerComponent={
            <div className={classes.header}>
              <Typography
                component="h1"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Group By
              </Typography>
              <Button
                className={classes.selectAllButton}
                onClick={() => forAllGroupByFields(!isAllSelected)}
                variant="text"
              >
                {isAllSelected ? 'Deselect All' : 'Select All'}
              </Button>
            </div>}
          bodyStyle={{
            padding: '0px 20px 15px 20px'
          }}
        />
        <div className={classes.actions}>
          <Button
            onClick={closeDialog}
            variant="contained"
            color="primary"
            sx={{
              flex: 1,
              padding: "10px 0px",
              color: 'white'
            }}
          >
            Set Group By Fields
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GroupByDialog;
