import React from 'react'
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';

const Wrap = styled.div`
  *:focus {
    outline: none;
  }
  height: 34px;
  display: inline-block;
  border: 1px solid #ced4da;
  border-radius: 10px;
`;

const Filter = ({ value, options, onChange, defaultLabel, mainLabel }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = value => {
        onChange(value);
        handleClose();
    };

    const theme = createTheme({
        props: {
            MuiButtonBase: {
                disableRipple: true
            }
        }
    });

    const { label } = (options || []).find(o => o.value === value) || {};

    return <ThemeProvider theme={theme}>
        <Wrap>
            <Chip
                label={value ? (<span>
                  <span>{mainLabel}</span>
                  <span style={{ color: '#109185' }}>{label}</span>
              </span>)
                    : defaultLabel}
                aria-controls="filter-value"
                aria-haspopup="true"
                onClick={handleClick}
                icon={<TuneIcon/>}
                style={{
                    background: 'white',
                    color: '#495057',
                }}
            />
            <Menu
                id="filter"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map(option => {
                    return <MenuItem
                        key={`filter_opt_${option.value}`}
                        onClick={() => {
                            handleSelect(option.value)
                        }}
                        value={option.value}>{option.label}</MenuItem>
                })}
            </Menu>
        </Wrap>
    </ThemeProvider>;
}

export default Filter;
