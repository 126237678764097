import Box from '@mui/material/Box';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../../store';
import './looker.css';

import { LookerEmbedSDK } from '@looker/embed-sdk';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import Loader from '../../../components/Loader';

const Looker = () => {
  const navigate = useNavigate();
  let { moduleId } = useParams();

  // input validation
  if (!Number.isInteger(parseInt(moduleId))) {
    console.error('Missing valid config value for Looker - remedy: validate configs for the Looker reports');
    return <Box sx={{
      padding: '25px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '25px',
      height: '100%'
    }}>
      The report could not be loaded. Please reach out to support for more information.
    </Box>
  }

  const user = useRecoilValue(currentUser);

  const lookerReport = user
    ?.orgSettings
    ?.find(s => s.setting_key === 'looker_url' && s.setting_value === moduleId);

  if (lookerReport?.setting_value !== moduleId)
    window.location.href = "/";


  // initial query params, we want to pass this to looker
  // to take care of page refreshes or shared urls.
  const [searchParams] = useSearchParams();
  let initialParams = '';
  searchParams.forEach((value, key) => {
    initialParams += `${key}=${value}&`;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Number.isNaN(parseInt(moduleId)))
      return;

    document.getElementById("looker_report").innerHTML = "";
    LookerEmbedSDK.init(process.env.REACT_APP_LOOKER_API_URL, `${process.env.REACT_APP_BASE_API_URL}/v1/api/looker/auth/${moduleId}?${initialParams}`);

    LookerEmbedSDK
      .createDashboardWithId(parseInt(moduleId))
      .appendTo('#looker_report')
      .withClassName('looker-iframe')
      .on('dashboard:filters:changed', (e) => {
        const queryParams = e.dashboard.dashboard_filters;
        const searchParams = new URLSearchParams();

        Object.keys(queryParams).forEach(key => {
          searchParams.set(key, queryParams[key]);
        });

        navigate(`?${searchParams.toString()}`, { replace: false });
      })
      .on('dashboard:run:start',
        () => {
          setIsLoading(false);
        }
      )
      .on('dashboard:run:complete',
        () => {
          setIsLoading(false);
        }
      )
      .withDynamicIFrameHeight()
      .build()
      .connect()
      .then(() => {

      })
      .catch(error => {
        console.error('Error embedding Looker dashboard', error);
      });

  }, [moduleId]);

  let reportSettings = user
    ?.userPermissions
    ?.find(m => m.id === lookerReport.scoped_entity_id);

  let title = "";
  if (!reportSettings)
    title = "Looker Report";
  else if (reportSettings?.name_override?.length > 0)
    title = reportSettings?.name_override;
  else
    title = reportSettings?.name;

  return (
    <Box sx={{
      padding: '25px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '25px',
      height: '100%'
    }}>
      <PageTitle>
        {title}
      </PageTitle>
      <div style={{
        height: '100%',
        width: '100%'
      }}>
        {isLoading && <div>
          <Loader />
        </div>}
        <div id="looker_report" style={{
          width: '100%',
          height: '100%'
        }} />
      </div>
    </Box>
  )
}

export default Looker;
