import styled from 'styled-components';

export const ClassWrap = styled.div`
  background-color: #d8d8d8;
  padding: 15px 0 10px 15px;
  width: 230px;
  height: 100%;
`;

export const ToolbarWrap = styled.div`
  background-color: #d8d8d8;
  font-size: 12px !important;
  padding: 0;
  height: 100%;
`;

export const WidgetsWrap = styled.div`
  flex: 1;
  background-color: white;
  padding: 20px 30px;
  width: 100%;
`;

export const WrapStyle = {
  display: 'flex',
  flex: 1,
  height: '100%',
  justifyContent: 'space-between'
};

export const MiddleColumnStyle = {
  flex: '1 !important',
  width: '100%',
  overflow: 'auto',
  backgroundColor: 'white'
};
