import { Button, Section, Title } from '../style';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Sorter from '../../../../../../../components/DataGrid/Sorter';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { metricsDisplayedState } from '../../../forecast.store';

const buttonStyle = {
  backgroundColor: '#6c757d',
  borderColor: '#6c757d'
};

export default () => {
  // state
  const [isOpen, setIsOpen] = useState(false);
  const [metricGroups, setMetricGroups] = useRecoilState(metricsDisplayedState);

  return (
    <>
      <Section>
        <Title>Table Options</Title>
        <Button variant="contained" sx={buttonStyle} onClick={() => setIsOpen(true)}>
          Select Metrics
        </Button>
      </Section>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200
        }}>
        <>
          <Sorter
            metricGroups={JSON.parse(JSON.stringify(metricGroups))}
            reportName="forecast_page_metric_order"
            setMetricGroups={setMetricGroups}
            onClose={() => setIsOpen(false)}
          />
        </>
      </Modal>
    </>
  );
};
