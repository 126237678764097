import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "200px",
  },
  autoSaveContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconsContainer: {
    flex: 1,
    display: "flex",
    paddingRight: "20px",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "20px",
    opacity: 0.8,
    "&:hover, &:focus": {
      opacity: 1,
    },
  },
  icon: {
    marginBottom: "10px",
  },
  iconText: {
    fontSize: "12px",
    textTransform: "uppercase",
    textAlign: 'center'
  },
  autoSave: {
    cursor: "pointer",
    paddingLeft: "7px",
    textTransform: "capitalize",
    userSelect: "none",
  },
}));
