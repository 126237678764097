import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(theme => ({
  modalRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '15px 20px',
    width: '350px',
    borderRadius: '0 0 5px 5px',
  },
  draggable: {
    zIndex: 99999,
    background: 'white',
    boxShadow: '0px 3px 6px 0px #f1f1f1',
  }
}));

export const SortableItemWrap = styled.li`
  border-bottom: solid 1px #f1f1f1;
  border-right: solid 1px #f1f1f1;
  border-left: solid 1px #f1f1f1;
  margin: 0;
  display: flex;
  justify-content: space-between;

  .MuiFormControlLabel-root {
    margin: 0;
  }
  
  .MuiFormControlLabel-label {
    margin-left: 2px;
  }
  
  &:first-child {
    border-top: solid 1px #f1f1f1;
  }
`;

export const Dragger = styled.div`
  width: 30px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  margin-right: 5px;
  flex-direction: column;
  justify-content: center;
  &:hover {
   cursor: move;
  }
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const Title = styled.div`
  font-weight: bold;
  color: rgb(80, 80, 80);
  text-transform: uppercase;
`;

export const ModalTitle = styled.div`
  background: rgb(122, 214, 224);
  border-radius: 5px 5px 0 0;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
`;

export const SortableWrap = styled.div`
  height: 250px;
  overflow-y: auto;
  margin-top: 15px;
`;
