// libs
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

// deps
import NavMenu from '@/components/NavMenu/NavMenu';
import TargetGraph from './TargetGraph';

// styles
import './style.css';
import { useRecoilValue } from 'recoil';
import { locations as locationsState } from '../../../../../../store';
import Button from '@mui/material/Button';

const TargetsGraph = () => {
    // state
    const locations = useRecoilValue(locationsState);
    const [locationIds, setLocationIds] = useState([-1]);

    // handlers
    const handleChange = (event) => {
        const selectedId = event.target.value;

        if (selectedId?.length === 0) {
            return;
        }

        if (locationIds.includes(-1) && selectedId.length > 1) {
            setLocationIds(selectedId.filter(id => id !== -1));
            return;
        }

        if (selectedId.includes(-1)) {
            setLocationIds(locations.map(location => location.id));
            return;
        }
        setLocationIds(selectedId);
    }

    const displayValue = () =>  {
        if (locationIds.includes(-1)) {
            return 'All Locations';
        }

        return (locationIds.map(id => {
            const location = locations.find(location => location.id === id);
            return location.location_name;
        }) ?? []).join(", ");
    }

    const navigate = useNavigate();
    const buttons = [
        {
            icon: 'arrow_back',
            title: 'Back',
            description: 'Go Back',
            action: () => navigate(-1),
        },
    ];

    const reset = () => {
        setLocationIds([-1]);
        navigate('?id=');
    }

    return (
      <Box sx={{
          padding: '24px'
      }}>
          <div className="clearfix">
              <div className="float-left">
                  <div>
                      <Typography component="h2" sx={{
                          lineHeight: "1",
                          fontSize: "2rem",
                          fontWeight: "bold",
                          marginBottom: '25px',
                          textTransform: 'uppercase'
                      }}>Category Targets</Typography>
                  </div>
              </div>
              <NavMenu buttons={buttons} />
          </div>
          <p className="graph-text-paragraph">The graph below shows your inventory targets for the
              current ordering week. We also show the expected on hand, and how many purchase
              orders you’ve already placed.</p>
          <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '15px 0',
          }}>
              <Button sx={{ backgroundColor: 'lightsalmon' }}
                      onClick={reset}
                      variant="contained"
                      outline>Reset</Button>
              <Select
                multiple
                sx={{
                    width: '300px',
                    height: '40px',
                    '& legend': { display: 'none' }, '& fieldset': { top: 0 }
                }}
                value={locationIds}
                onChange={handleChange}
                renderValue={displayValue}
                label={false}
              >
                  {(locations || []).map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                        <Checkbox checked={locationIds.includes(location.id)} />
                        <ListItemText primary={location.location_name} />
                    </MenuItem>
                  ))}
              </Select>
          </Box>
          <TargetGraph locationIds={locationIds} />
      </Box>
    )
}

export default TargetsGraph;
