import React, { useEffect, useState } from 'react';
import { useSnackbar } from "notistack";
import { useNavigate, NavLink, Navigate } from 'react-router-dom';

// services
import AuthService from "../../../service/master/auth.service";

// store
import { useSetRecoilState } from "recoil";
import { accessToken } from "../../../store";

// styles
import { useStyles } from "./style";
import { FieldsWrap } from "./style.js";
import { ChevronRight } from "react-feather";
import logo from "../../../assets/gs-vertical-logo-on-light-bg-cropped.png";

// mui
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from '../../../components/Loader';
import { delay } from '../../../utils/utils';

const Login = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const setAccessToken = useSetRecoilState(accessToken);
  const token = localStorage.getItem("access-token");

  const [formData, updateFormData] = useState({
    email: "",
    password: "",
  });

  const [formDataError, updateFormDataError] = useState({
    email: {
      invalid: false,
      message: "",
      logic: (val) => (val === "" ? "Cannot be empty!" : null),
    },
    password: {
      invalid: false,
      message: "",
      logic: (val) => (val === "" ? "Cannot be empty!" : null),
    },
  });


  // cleanup intervals
  useEffect(() => {
    return () => {
      clearInterval(tenSecondsInterval);
      clearInterval(thirtySecondsInterval);
    }
  }, []);

  const isFormDataFilled = formData.email !== "" && formData.password !== "";
  const { enqueueSnackbar } = useSnackbar();
  const [loggingIn, setLoggingIn] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);

  let tenSecondsInterval, thirtySecondsInterval;

  const doFormValidationFor = (fieldName, fieldValue) => {
    const errorResponse = formDataError[fieldName].logic(fieldValue);

    if (errorResponse) {
      updateFormDataError({
        ...formDataError,
        [fieldName]: {
          ...formDataError[fieldName],
          invalid: true,
          message: errorResponse,
        },
      });
      setLoginButtonDisabled(true);
    } else {
      updateFormDataError({
        ...formDataError,
        [fieldName]: {
          ...formDataError[fieldName],
          invalid: false,
          message: "",
        },
      });
      setLoginButtonDisabled(false);
    }
  };

  const updateField = (evt) => {
    updateFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
    });
    doFormValidationFor(evt.target.name, evt.target.value);
  };

  const handleFormSubmission = async (evt) => {
    evt.preventDefault();
    setIsTimedOut(false);

    tenSecondsInterval = setTimeout(() => {
      setLoadingText("We're having troubling loading all of the configurations. Please hang on.");
    }, 10000);

    thirtySecondsInterval = setTimeout(() => {
      setIsTimedOut(true);
      setLoadingText("");
    }, 30000);

    try {
      setLoggingIn(true);

      const res = await AuthService.login(formData);
      const {
        token,
        reset_pass
      } = res.data;

      if (!token) {
        throw Error('invalid login token');
      }
      setAccessToken(token);

      await delay(600);

      if (reset_pass) {
        navigate(`/set-password`, { state: { token } });
      } else {
        navigate(`/account`, { state: { loggingIn: true } });
      }
    } catch (e) {
      clearTimeout(tenSecondsInterval);
      clearTimeout(thirtySecondsInterval);

      const { response } = e;
      const message = response?.data?.error?.message || "Unknown error";
      enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    } finally {
      setLoggingIn(false);
    }
  };

  if (token) return <Navigate to={`/account/dashboard`} />;

  return (
    <div className={classes.container}>
      { (loggingIn || isTimedOut) && <div className={classes.loadingWrap}>
        { !isTimedOut && <Loader /> }
        <div>
          { !isTimedOut && <span style={{ fontWeight: 'bold' }}>{loadingText}</span> }
          { isTimedOut && <div style={{ fontWeight: 'bold' }}>
            We’re having problems getting you into the system. <br />Tech support has been notified.
            If this issue persists, please reach out to us here: <a href='https://support.growthsayer.com'>support.growthsayer.com</a>
          </div> }
        </div>
      </div>}
      <div className={classes.formContainer}>
        <form onSubmit={handleFormSubmission}>
          <Card
            className="text-center"
            style={{
              border: "0px",
              borderRadius: "8px",
              width: "330px",
              boxShadow: "rgb(17, 17, 24) 0px 0px 40px 4px",
            }}
          >
            <CardHeader
              style={{
                backgroundColor: "red",
                background: "#eaeaea",
              }}
              title={<img src={logo} alt="GS Logo" />}
            />
            <CardContent>
              <div
                style={{
                  backgroundColor: "inherit",
                  border: "0px",
                  borderRadius: "8px 8px 0 0",
                  fontSize: "20px",
                  marginBottom: "1.2rem",
                }}
              >
                Log In
              </div>
              <FieldsWrap>
                <FormGroup>
                  <div>
                    <TextField
                      id="email"
                      type="text"
                      name="email"
                      size="small"
                      label="Email"
                      onChange={updateField}
                      style={{
                        borderColor: "#f1f1f1",
                        width: "100%",
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div>
                    <TextField
                      id="password"
                      type="password"
                      name="password"
                      label="password"
                      onChange={updateField}
                      size="small"
                      style={{
                        borderColor: "#f1f1f1",
                        width: "100%",
                      }}
                    />
                  </div>
                </FormGroup>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  <NavLink
                    to="/forgot-password"
                    style={{ color: "#565656" }}
                  >
                    Don't remember your password?
                  </NavLink>
                </div>
              </FieldsWrap>
            </CardContent>
            <CardActions
              style={{
                backgroundColor: "inherit",
                borderRadius: "0 0 8px 8px",
                padding: "0px",
              }}
            >
              <TextField
                id="do-login"
                name="do-login"
                className="d-none"
                type="submit"
                disabled={!isFormDataFilled || loggingIn}
              />
              <FormLabel
                id="login-button"
                htmlFor="do-login"
                className={`theme-button text-center btn btn-block ${loginButtonDisabled || loggingIn ? "disabled" : ""
                }`}
                style={{
                  borderRadius: "0 0 8px 8px",
                  color: "white",
                  marginBottom: "0px",
                  padding: "25px 19px",
                }}
              >
                {loggingIn ? (
                  <>
                    <CircularProgress size="sm" style={{ marginBottom: "2px" }} />{" "}
                    LOGGING IN
                  </>
                ) : (
                  <>
                    LOG IN
                    <ChevronRight size={18} style={{ marginTop: "-3px" }} />
                  </>
                )}
              </FormLabel>
            </CardActions>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default Login;
