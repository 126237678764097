import React, { useEffect, useMemo, useState } from "react";

// components
import BootstrapInput from "../../../../components/BootstrapInput";
import CustomSelectComponent from "../../../../components/CustomSelect";
import CategoriesHierarchy from "./categories-hierarchy";

// const
import { ALL_BRANDS_OPTION, ALL_VENDORS_OPTION } from "..";

// styles
import { useStyles } from "./style";

// mui
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import Autocomplete from "@mui/material/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import logo from '@/assets/gs-logo.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdvancedFiltersControl = ({ locations, categories, vendors, brands }) => {
  const classes = useStyles();

  // state
  const [showCategoriesDialog, setShowCategoriesDialog] = useState(false);
  const locationsOptions = useMemo(() => {
    return [
      // ALL_LOCATIONS_OPTION,
      ...(locations?.list ?? [])].map((location, _) => ({
      key: location.id,
      value: location.location_name,
    }));
  }, [locations.list]);

  // handlers
  const selectedCategoriesDisplay = () => {
    const allCategoriesCount = categories.list.reduce(
      (count, cat) =>
        cat.children ? count + 1 + cat.children.length : count + 1,
      0
    );

    return categories.selected.length === allCategoriesCount
      ? "All Categories"
      : "Filtered Categories";
  };

  const selectAllCategories = () => {
    const allCategoriesIds = categories.list.reduce((ids, curr) => {
      ids.push(curr.id);
      if (Array.isArray(curr.children)) {
        const childsIds = curr.children.map((child) => child.id);
        return ids.concat(childsIds);
      }
      return ids;
    }, []);
    selectCategories(allCategoriesIds);
  };


  const deselectAllCategories = () => {
    const allCategoriesIds = categories.list.reduce((ids, curr) => {
      ids.push(curr.id);
      if (Array.isArray(curr.children)) {
        const childsIds = curr.children.map((child) => child.id);
        return ids.concat(childsIds);
      }
      return ids;
    }, []);
    deselectCategories(allCategoriesIds);
  };

  const selectCategories = (categoriesIds) => {
    const newCategories = categoriesIds.filter(
      (catId) => !categories.selected.includes(catId)
    );
    categories.onChange((currentSelected) => [
      ...currentSelected,
      ...newCategories,
    ]);
  };

  const deselectCategories = (categoriesIds) => {
    categories.onChange((currentSelected) =>
      currentSelected.filter((catId) => !categoriesIds.includes(catId))
    );
  };

  const handleCategorySelectChange = (categoriesList, categoryId) => {
    const isNew = !categories.selected.includes(categoryId);
    const selectAction = isNew ? selectCategories : deselectCategories;
    selectAction([categoryId]);
    const elementRef = categoriesList.find((cat) => cat.id === categoryId);
    if (elementRef && elementRef.children) {
      selectAction(elementRef.children.map((child) => child.id));
    } else {
      const parent = categoriesList.find((cat) =>
        cat.children.some((child) => child.id === categoryId)
      );
      if (parent && isNew) {
        selectCategories([parent.id]);
      }
    }
  };

  // effects
  useEffect(async () => {
    categories.list.forEach(({ id }) => {
      handleCategorySelectChange(categories.list, id);
    });
  }, [categories.list]);

  return (
    <div className={classes.container}>
      <div className={classes.formControl}>
        <span>Location</span>
        <CustomSelectComponent
          options={locationsOptions}
          style={{ width: "200px" }}
          value={locations.selectedId}
          onChange={(newLocation) => {
            locations.onChange(newLocation);
          }}
        />
      </div>
      <div className={classes.formControl}>
        <span>Category</span>
        <BootstrapInput
          value={selectedCategoriesDisplay()}
          readOnly
          className={classes.boostrapWrapper}
          style={{ width: "200px", display: "flex", alignItems: "start" }}
          onClick={() => setShowCategoriesDialog(true)}
        />
      </div>
      <div className={classes.formControl}>
        <span>Vendor</span>
        <Autocomplete
          value={vendors.selectedId}
          autoComplete
          disablePortal
          blurOnSelect
          isOptionEqualToValue={(option, value) => {
            if (option.value === ALL_VENDORS_OPTION.value) return true;
            return option.value === value.value;
          }}
          onChange={(_, newValue) => {
            // In case user clears autocomplete or delete all content
            if (newValue) {
              vendors.onChange(newValue);
            } else {
              vendors.onChange(ALL_VENDORS_OPTION);
            }
          }}
          options={[
            ALL_VENDORS_OPTION,
            ...vendors.list.map((v) => ({
              value: v.id,
              label: v.distributor_name,
            })),
          ]}
          renderInput={(params) => {
        return (
            <div ref={params.InputProps.ref}>
            <BootstrapInput
              inputProps={{ ...params.inputProps }}
            style={{ width: "200px", padding: 0 }}
            className={classes.boostrapWrapper}
          {...params}
              InputLabelProps={{}}
            />
            </div>
            )}}
        />
      </div>
      <div className={classes.formControl}>
        <span>Brand</span>
        <Autocomplete
          value={brands.selectedId}
          autoComplete
          disablePortal
          blurOnSelect
          isOptionEqualToValue={(option, value) => {
            if (option.value === ALL_BRANDS_OPTION.value) return true;
            return option.value === value.value;
          }}
          onChange={(_, newValue) => {
            // In case user clears autocomplete or delete all content
            if (newValue) {
              brands.onChange(newValue);
            } else {
              brands.onChange(ALL_BRANDS_OPTION);
            }
          }}
          options={[
            ALL_BRANDS_OPTION,
            ...brands.list.map((b) => ({
              value: b.id,
              label: b.name,
            })),
          ]}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <BootstrapInput
                inputProps={{
                  ...params,
                }}
                {...params}
                InputLabelProps={{}}
                className={classes.boostrapWrapper}
                style={{
                  width: "200px",
                  padding: 0,
                }}
              />
            </div>
          )}
        />
      </div>
      <Dialog
        open={showCategoriesDialog}
        TransitionComponent={Transition}
        onClose={() => setShowCategoriesDialog(false)}
        PaperProps={{
          sx: {
            width: "350px",
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <CategoriesHierarchy
            categories={categories.list}
            selectedCategories={categories.selected}
            onSelectCategory={(categoryId) =>
              handleCategorySelectChange(categories.list, categoryId)
            }
            onSelectAll={selectAllCategories}
            onDeselectAll={deselectAllCategories}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdvancedFiltersControl;
