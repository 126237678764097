import { useStyles } from "./style";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { ExportToCsv } from "export-to-csv";
import { convertItemsToCsvFormat } from "./utils/exportItemsToCsvFormat";

// mui
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";

// constants

const ALL_ITEMS = "All Items";
const CURRENT_VIEW = "Current view";
const EXPORT_CSV = "CSV";
const EXPORT_OPTIONS = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  filename: "Reorder Pro Items",
  showTitle: false,
  useKeysAsHeaders: true,
  useTextFile: false,
  useBom: true,
};

const ExportItemsDialog = ({
  show,
  transition,
  onCloseDialog,
  items,
  filteredItems,
}) => {
  const classes = useStyles();

  // state
  const { enqueueSnackbar } = useSnackbar();
  const [exportItemsBy, setExportItemsBy] = useState(CURRENT_VIEW);
  const [exportFormat, setExportFormat] = useState(EXPORT_CSV);
  const [loading, setLoading] = useState(false);

  // handlers
  const handleChange = (event) => {
    setExportItemsBy(event.target.value);
  };

  const closeDialog = () => {
    setLoading(false);
    setExportItemsBy(CURRENT_VIEW);
    onCloseDialog();
  };

  const download = () => {
    try {
      const csvExporter = new ExportToCsv(EXPORT_OPTIONS);
      let itemsToExport = [];
      if (exportItemsBy === CURRENT_VIEW) {
        itemsToExport = filteredItems;
      } else if (exportItemsBy === ALL_ITEMS) {
        itemsToExport = items;
      }
      if (itemsToExport.length !== 0) {
        itemsToExport = itemsToExport.filter(item => item.path.length === 3)
        itemsToExport = convertItemsToCsvFormat(itemsToExport);
        csvExporter.generateCsv(itemsToExport);
      } else {
        enqueueSnackbar("No items to export", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("Export has failed", { variant: "error" });
    }
  };

  const exportItems = () => {
    setLoading(true);
    download();
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={transition}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          width: "350px",
        },
      }}
    >
      <DialogContent className={classes.wrapper}>
        <Typography
          component="h2"
          sx={{
            lineHeight: "1",
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Export
        </Typography>
        <Typography
          component="h2"
          sx={{
            lineHeight: "1",
            fontSize: "15px",
            marginBottom: "5px",
          }}
        >
          Items to show
        </Typography>

        <RadioGroup
          aria-label="export-type"
          value={exportItemsBy}
          onChange={handleChange}
        >
          <FormControlLabel
            value={CURRENT_VIEW}
            control={<Radio />}
            label={CURRENT_VIEW}
          />
          <FormControlLabel
            value={ALL_ITEMS}
            control={<Radio />}
            label={ALL_ITEMS}
          />
        </RadioGroup>
        <Typography
          component="h2"
          sx={{
            lineHeight: "1",
            fontSize: "15px",
            marginBottom: "5px",
          }}
        >
          Format
        </Typography>

        <RadioGroup
          aria-label="export-type"
          value={exportFormat}
          onChange={() => { }}
        >
          <FormControlLabel
            value={EXPORT_CSV}
            control={<Radio />}
            label={EXPORT_CSV}
          />
        </RadioGroup>
        <LoadingButton
          loading={loading}
          onClick={exportItems}
          variant="outlined"
          color="primary"
          startIcon={<DownloadIcon />}
          sx={{
            marginTop: "15px",
            padding: "10px 0px",
          }}
        >
          Export Items
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

export default ExportItemsDialog;
