import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  logo: {
    width: '200px',
  },
  wrapper: {
    fontFamily: "Montserrat",
    textAlign: 'center'
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
}));
