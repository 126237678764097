import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  checkboxLabel: {
    cursor: "pointer",
    paddingLeft: "10px",
  },
}));
