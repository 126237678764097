// libs
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

// UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// deps
import OrganisationService from '../../../../service/organisation-service';

// styles
import './forecast.css';
import { ClassWrap, ToolbarWrap, WidgetsWrap, WrapStyle, MiddleColumnStyle } from './style';

// components
import { useSnackbar } from 'notistack';
import ProductTypes from './widgets/products';
import Versions from './widgets/toolbar/versions';
import LocationOptions from './widgets/toolbar/locations';
import TableOptions from './widgets/toolbar/table-options';
import UnitComparison from './widgets/toolbar/unit-comparison';
import PageTitle from '../../../../components/PageTitle';
import TimeOptions, { timeState } from './widgets/toolbar/time-options';

// store
import { currentOrgId, drawerOpenState, locations as locationsState } from '../../../../store';
import {
  currentClassIds,
  locationState,
  versionsState,
  districtState
} from './forecast.store';

import {
  selectedVersionsState,
  forecastOriginalState,
  forecastState,
  fetchingState
} from './forecast.store';
import ForecastTrend from './widgets/trend';
import ForecastTable from './widgets/table';

const Forecast = () => {
  const { enqueueSnackbar } = useSnackbar();

  // state
  const orgId = useRecoilValue(currentOrgId);
  const time = useRecoilValue(timeState);
  const classIds = useRecoilValue(currentClassIds);
  const locations = useRecoilValue(locationsState);
  const location = useRecoilValue(locationState);
  const district = useRecoilValue(districtState);
  const [versions, setVersions] = useRecoilState(versionsState);
  const setFetching = useSetRecoilState(fetchingState);
  const setForecast = useSetRecoilState(forecastState);
  const setDrawerOpen = useSetRecoilState(drawerOpenState);
  const selectedVersions = useRecoilValue(selectedVersionsState);
  const setForecastOriginal = useSetRecoilState(forecastOriginalState);

  useEffect(() => {
    setDrawerOpen(false);
    fetchVersions().then();
  }, []);

  useEffect(() => {
    if (!time.startQuarter || !time.endQuarter || (classIds?.classId ?? []).length === 0) return;
    fetchData().then();
  }, [classIds, time, location, district, selectedVersions]);

  const fetchVersions = async () => {
      const { data: versions } = await OrganisationService.forecast(orgId).types();

      const formattedVersions =  versions.map((v) => ({
        key: v.typeAbv,
        value: v.typeAbv === 'WP' ? 'Working Plan' : v.typeName
      })) || [];

      setVersions(formattedVersions);
  };

  const fetchData = async () => {
    try {
      setFetching(true);

      let locationIds;
      if (district === -1) locationIds = locations.map(d => d.id);
      else if (location === -1) {
        locationIds = locations
          .filter(d => d.district_id === district)
          .map(d => d.id);
      } else {
        locationIds = [location];
      }

      const { data } = await OrganisationService.forecast(orgId).forecastCompare({
        class_id: classIds.classId,
        mainVersion: selectedVersions.main,
        compareVersion: selectedVersions.compare,
        start_quarter: time.startQuarter,
        end_quarter: time.endQuarter,
        location_id: locationIds,
        granularity: time.granularity
      });

      // save original forecast data (for reset feature and comparison for submission).
      setForecastOriginal(() => data);
      setForecast(() => data);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  // @TODO: can't use <LeftColumn /> (causes infinite rendering)
  const LeftColumn = () => (
    <Grid
      item
      sx={{
        backgroundColor: 'rgb(216, 216, 216)',
        width: '230px'
      }}>
      <ClassWrap>
        <ProductTypes />
      </ClassWrap>
    </Grid>
  );

  const MiddleColumn = () => (
    <Grid item xs="auto" sx={MiddleColumnStyle}>
      <Box
        sx={{
          flex: 1,
          height: '100%'
        }}>
        <WidgetsWrap>
          <div>
            <div className="header">
              <PageTitle typographyProps={{
                'data-qa': 'forecast-title'
              }}>Forecast</PageTitle>
            </div>
            { versions.length > 0 && <>
              <div>
                <ForecastTrend />
              </div>
              <Box className='widget-container'>
                <ForecastTable />
              </Box>
            </>
            }
          </div>
        </WidgetsWrap>
      </Box>
    </Grid>
  );

  const RightColumn = () => (
    <Grid
      item
      sx={{
        width: '250px',
        backgroundColor: 'rgb(216, 216, 216)'
      }}>
      <ToolbarWrap>
        <Versions refresh={fetchData} />
        <TimeOptions />
        <LocationOptions />
        <TableOptions />
        <UnitComparison />
      </ToolbarWrap>
    </Grid>
  );

  return (
    <Grid container spacing={0} sx={WrapStyle}>
      {LeftColumn()}
      <MiddleColumn />
      <RightColumn />
    </Grid>
  );
};

export default Forecast;
