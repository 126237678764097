
const initialState = {
	drawerOpen: true,
	userData: undefined,
	orgInfo: undefined,
	subscriptions: {
		reorderPro: {
			id: 8,
			show: false,
		},
	},
};

const reducer = (state, action) => {
	switch (action.type) {
		case "setSubscriptionsRoutes":
			return {
				...state,
				subscriptions: action.payload,
			};
		case "setUserData":
			return {
				...state,
				userData: action.payload,
			};
		case "setOrgInfo":
			return {
				...state,
				orgInfo: action.payload,
			};
		case "reset":
			return initialState;
		default:
			return state;
	}
};

export { initialState, reducer };

