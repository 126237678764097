import { Storefront } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { blue } from "../../../style";
import NumberEditableText from "../../../../../../components/NumberEditableText/NumberEditableText";
import { useStyles } from "./style";
import { sum } from "../../../../../../utils/arrays";
import { splitProportionally } from "../../../../../../utils/splitProportianlly";
import { commonStyles } from "../../../style";

const SetBudget = ({
  config,
  onDone = async () => { },
  onBack = () => { },
  locations,
}) => {
  const classes = useStyles();

  const [locationsBudgets, setLocationsBudgets] = useState(locations || []);
  const [saving, toggleSaving] = useState(false);

  const [totalBudget, setTotalBudget] = useState(
    sum(locationsBudgets.map((location) => location.budget))
  );
  const [lastTotalBudget, setLastTotalBudget] = useState(
    sum(locationsBudgets.map((location) => location.budget))
  );

  useEffect(async () => {
    setLocationsBudgets(locationsBudgets);
    const total = sum(locationsBudgets.map((location) => location.budget));
    setTotalBudget(total);
    setLastTotalBudget(total);
  }, []);

  // re-calculate the total budget (when budgets are change)
  const handleBudgetChange = (newLocationBudgets) => {
    const newTotal = sum(newLocationBudgets.map((location) => location.budget));
    setLastTotalBudget(newTotal);
    setTotalBudget(newTotal);
  };

  // re-calculate the location budgets by the changed total budget
  const calculateNewBudgets = (oldBudgets, oldTotal, newTotal) => {
    const diff = newTotal - oldTotal;
    const locationsDeltas = splitProportionally(
      diff,
      Object.values(oldBudgets).map((location) => location.budget),
      0
    );
    const newLocationsBudgets = oldBudgets.map((location, index) => ({
      ...location,
      budget: (location.budget += locationsDeltas[index]),
    }));

    setLastTotalBudget(newTotal);
    setLocationsBudgets(newLocationsBudgets);
  };

  // the onChange events of budgets
  const onLocationBudgetChange = (budgetOwner, newValue) => {
    let newBudgets = [...locationsBudgets];
    const changedLocation = newBudgets.find(
      (location) => location.id === budgetOwner.id
    );
    if (changedLocation) {
      changedLocation.budget = newValue;
    }

    setLocationsBudgets(newBudgets);
    handleBudgetChange(newBudgets);
  };

  const onTotalBudgetChange = (newValue) => {
    setTotalBudget(newValue);
    calculateNewBudgets(locationsBudgets, lastTotalBudget, newValue);
  };

  const arrayToObject = (arr) => {
    return arr.reduce(
      (obj, data) => Object.assign(obj, { [data.id]: data }),
      {}
    );
  };

  // save
  const setBudget = async () => {
    const newConfig = {
      ...config,
      budget: arrayToObject(locationsBudgets),
    };
    toggleSaving(true);
    await onDone(newConfig);
    toggleSaving(false);
  };

  return (
    <div className={classes.container}>
      <Typography
        component="h2"
        sx={{
          fontSize: "30px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Let's set a budget for this week's purchase
      </Typography>
      <Typography
        component="span"
        sx={{
          fontSize: "24px",
          fontWeight: 200,
          textAlign: "center",
        }}
      >
        You can adjust the values to your own budget
      </Typography>
      <div className={classes.setBudgetWrapper}>
        <div style={{ marginTop: "50px", fontFamily: "Montserrat" }}>
          <span className={classes.totalBudget}>
            <span className={classes.darkenText} style={{ fontSize: "18px" }}>
              TOTAL BUDGET
            </span>
            <NumberEditableText
              decimals={0}
              className={classes.totalBudgetInput}
              value={totalBudget}
              onDoneTyping={onTotalBudgetChange}
              prefix="$"
            />
          </span>
        </div>
        <div className={classes.locationsWrapper}>
          {locationsBudgets.map((location, index) => (
            <div className={classes.location} key={index}>
              <div style={{ display: "flex", width: "90%" }}>
                <Storefront sx={{ marginRight: "8px", color: blue }} />
                <span
                  className={classes.darkenText}
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {location.name}
                </span>
              </div>
              <span
                className={classes.budget}
                style={{ display: "flex", fontSize: "20px" }}
              >
                <NumberEditableText
                  decimals={0}
                  align="right"
                  className="location"
                  value={location.budget}
                  onDoneTyping={(newValue) => {
                    onLocationBudgetChange(location, newValue);
                  }}
                  prefix="$"
                />
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.actions}>
        <LoadingButton
          variant="contained"
          sx={{ ...commonStyles.controlButton }}
          loading={saving}
          onClick={setBudget}
        >
          Set Budget
        </LoadingButton>
      </div>
    </div>
  );
};

export default SetBudget;
