import React, { useState } from "react";
import ActionsMenu from "../actions-menu";
import { useNavigate } from "react-router-dom";

// mui
import Avatar from "@mui/material/Avatar";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";

// styles
import { useStyles } from "./style";
import { ChevronDown } from "react-feather";
import logoForBigScreen from "../../../../assets/gs-full-logo-horizontal-cropped.png";

// store
import { useRecoilValue } from 'recoil';
import { currentOrg, currentUser } from '../../../../store';

// const
const ORG_LOGOS_BASE_URL = 'https://storage.growthsayer.com/org_logos';
const ORG_LOGO_SRC = "https://storage.growthsayer.com/org_logos/default.png"

const TopBar = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	const user = useRecoilValue(currentUser);
	const org = useRecoilValue(currentOrg);

	const [showMenu, setShowMenu] = useState(false)
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const menuOpen = Boolean(menuAnchorEl);

	const companyName = org?.name;
	const userDetails = (user ? user.first_name : "") + (companyName ? ` - ${companyName}` : "");

	const getOrgLogo = () => {
		const logo = org?.logo;
		return logo ?
			`${ORG_LOGOS_BASE_URL}/${logo}` :
			ORG_LOGO_SRC;
	}

	const handleMenuClick = (event) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};

	const logOut = () => {
		navigate("/logout");
	};

	return (
		<AppBar
			sx={{
				zIndex: (theme) => theme.zIndex.drawer + 1,
				background: "#454545"
			}}
			position="fixed"
		>
			<Toolbar
				className={classes.toolbar}
			>
				<Typography variant="h6" noWrap component="div">
					<img
						src={logoForBigScreen}
						alt="Growthsayer"
						className={classes.logo}
						onClick={() => navigate(`/account/dashboard`)}
					/>
				</Typography>
				<div>
					<div
						id="demo-customized-button"
						aria-controls={showMenu ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={showMenu ? "true" : undefined}
						className={classes.actionsMenuTrigger}
						onClick={handleMenuClick}
					>
						<Button
							variant="text"
							disableRipple
							disableElevation
							sx={{
								color: "white",
								textTransform: "capitalize"
							}}
						>
							{userDetails}
						</Button>
						<Avatar
							className={classes.avatar}
							src={getOrgLogo()}
						/>
						<ChevronDown color="white" style={{ marginLeft: "3px" }} />
					</div>
					<ActionsMenu
						menuAnchorEl={menuAnchorEl}
						menuOpen={menuOpen}
						onLogOut={logOut}
						onMenuClose={handleMenuClose}
					/>
				</div>
			</Toolbar>
		</AppBar >)
}

export default TopBar;
