import { range } from "lodash";

export const convertOrderItemsToHierarchyArray = (items, locations) => {
  const hierarchy = [];
  let uid = 0;

  items.forEach((item) => {
    const locationId = item["Location ID"];
    const locationName = locations.find(
      (location) => location.id === locationId
    )?.name;

    const itemRow = {
      uid,
      path: [locationName, item["Category"], uid],
      "Product ID": item["Product ID"],
      "Product Name": item["Product Name"],
      "Product Type ID": item["Product Type ID"],
      "Product Type": item["Product Type"],
      "Location Name": item["Location Name"],
      "Location Type": item["Location Type"],
      "Location ID": item["Location ID"],
      Category: item["Category"],
      "Category ID": +item["Category ID"],
      "Brand Name": item["Brand Name"],
      "Brand ID": parseInt(item["Brand ID"]),
      "Vendor Name": item["Vendor Name"],
      "Vendor ID": parseInt(item["Vendor ID"]),
      "Sub Category": item["Sub Category"],
      "Sub Category ID": +item["Sub Category ID"],
      AUC: item["AUC"],
      AUV: +item["AUV"],
      WGMROI: item["WGMROI"],
      cost: +(item.AUC || 0) * +(item["Final Reco"] || 0),
      WOS: item["WOS"],
      'Warehouse OH - Units': +item['Warehouse OH - Units'],
      'Store OH - Units': +item['Store OH - Units'],
      'Target Store OH - Units': +item['Target Store OH - Units'],
      'Store Need - Units': +item['Store Need - Units'],
      'Avg Weekly Sales - Units': +item['Avg Weekly Sales - Units'],
      'Warehouse OH - Cost': +item['Warehouse OH - Cost'],
      'In Transit - Units': +item['In Transit - Units'],
      'In Transit - Cost': +item['In Transit - Cost'],
      'Store OH - Cost': +item['Store OH - Cost'],
      'Target Store OH - Cost': +item['Target Store OH - Cost'],
      'Store Need - Cost': +item['Store Need - Cost'],
      'Suggested Allocation - Units': +item['Suggested Allocation - Units'],
      'Suggested Allocation - Cost': +item['Suggested Allocation - Cost'],
      'Shortfall - Units': item['Shortfall - Units'],
      'Shortfall - Cost': +item['Shortfall - Cost'],
      '% Committed': item['% Committed'],
      'Gross Margin $': +item['Gross Margin $'],
      'Gross Margin %': +item['Gross Margin %'],
      'COGS': +item['COGS'],
      'Retail $': +item['Retail $'],
      'Warehouse OOS': item['Warehouse OOS'],
      'Warehouse': item['Warehouse'],
      'Store OOS': item['Store OOS']
    };

    hierarchy.push(itemRow);
    uid++;
  });

  return hierarchy;
};


export const getChildren = (items, parent, productsLevel, groupByFields) => {
  if (parent.path) {
    return items.filter(row => {
      if (row.path.length !== productsLevel + 1) return false
      return range(0, parent.path.length).every(level =>
        row[groupByFields[level]] === parent.path[level]
      )
    })
  }
  return []
}
