// libs
import React, { useState, useEffect, useCallback } from "react";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// deps
import VendorService from '@/service/org/vendor.service';
import NavMenu from '@/components/NavMenu/NavMenu';

// styles
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import Backdrop from "@mui/material/Backdrop";
import Radio from "@mui/material/Radio";
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentOrg, currentOrgId, locations as locationsState } from '../../../../../../store';
import { useSnackbar } from 'notistack';
import OrganisationService from '../../../../../../service/organisation-service';

export const PO_CREATE_TYPE = {
  RECOMMENDED_ITEMS: 0,
  ALL_CURRENT_ITEMS: 1,
  BLANK: 2,
};

export const importedPurchaseOrderAtom = atom({
  key: 'imported_purchase_order',
  default: {}
});

const PurchaseOrderCreate = () => {
  // state
  const { enqueueSnackbar } = useSnackbar();
  const { po } = OrganisationService;
  const setImportedPurchaseOrder = useSetRecoilState(importedPurchaseOrderAtom);

  const org = useRecoilValue(currentOrg);
  const orgId = useRecoilValue(currentOrgId);

  const [loading, setLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const navigate = useNavigate();

  // location state
  const [loc, setLocation] = useState(null);
  const locations = useRecoilValue(locationsState);

  // vendor state
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState(null);

  // import mode
  const [selectedItem, ] = useState(0);

  const importItems = async (vendor, location, importType) => {
    try {
      setIsImporting(true);
      const { data } = await po.importItems(orgId);

      if (data) {
        let lineItems;
        if (importType === PO_CREATE_TYPE.RECOMMENDED_ITEMS) {
          lineItems = data.filter(item => item?.["Vendor Name"] === vendor?.distributor_name &&
            (item?.["Location Name"] === location?.location_name || location?.id === -1) &&
            parseInt(item["Raw Reco"]) > 0);
        } else if (importType === PO_CREATE_TYPE.ALL_CURRENT_ITEMS) {
          lineItems = data.filter(item => item?.["Vendor Name"] === vendor?.distributor_name
            &&
            (item?.["Location Name"] === location?.location_name || location?.id === -1)
          );
        }

        setImportedPurchaseOrder({
          status: 'NOT_SUBMITTED',
          mode: 'CREATE',
          location_id: location.id,
          location_name: location.location_name,
          brand_id: vendor.id,
          brand: vendor.distributor_name,
          lineItems: lineItems
        });
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      });
    } finally {
      setIsImporting(false);
    }
  }

  // data fetching
  const getVendors = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data ,
      } = await VendorService.getVendors();
      const sorted = data.sort((a, b) => a.distributor_name.localeCompare(b.distributor_name));
      setVendors(sorted);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [orgId]);

  const handleCreateOrder = async () => {
    await importItems(vendor, loc, selectedItem);
    navigate(`/account/orders/edit`);
  };

  // effects
  useEffect(() => {
    const loadDropDowns = async () => {
      await getVendors();
    };
    loadDropDowns().then();
  }, []);

  return (
    <form onSubmit={handleCreateOrder}>
      <Box display="flex" flexDirection="column" rowGap="30px" padding="25px">
        <Box display="flex" justifyContent="space-between">
          <Typography
            component="h2"
            sx={{
              lineHeight: "1",
              marginBottom: "0px",
              fontSize: "2rem",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Create Purchase Order
          </Typography>
          <NavMenu
            buttons={[
              {
                icon: "arrow_back",
                title: "Back",
                description: "Go Back",
                action: () => navigate(-1),
              },
            ]}
          />
        </Box>
        <Box>
          <FormControl>
            <Autocomplete
              size="small"
              disablePortal
              id="distributor_name"
              onChange={(_, newValue) => {
                setVendor(newValue);
              }}
              options={(vendors ?? []).map((v) => ({
                ...v,
                value: v.id,
                label: v.distributor_name,
              }))}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "400px" }}
                  {...params}
                  label="Which Vendor is this for?"
                />
              )}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <Autocomplete
              size="small"
              disablePortal
              id="location"
              onChange={(_, newValue) => {
                setLocation(newValue);
              }}
              options={locations
                .filter((l) => l.id !== -1)
                .map((l) => ({
                  ...l,
                  value: l.id,
                  label: l.location_name,
                }))?.sort((a, b) => a.location_name.localeCompare(b.location_name)) }
              renderInput={(params) => (
                <TextField
                  sx={{ width: "400px" }}
                  {...params}
                  label="Where is it going?"
                />
              )}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Which items should we import?
            </FormLabel>
            <RadioGroup
              aria-label="import_type"
              defaultValue={PO_CREATE_TYPE.RECOMMENDED_ITEMS}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value={PO_CREATE_TYPE.RECOMMENDED_ITEMS}
                control={<Radio />}
                label="Only recommended items"
              />
              <FormControlLabel
                value={PO_CREATE_TYPE.ALL_CURRENT_ITEMS}
                control={<Radio />}
                label="All current items"
              />
              <FormControlLabel
                value={PO_CREATE_TYPE.BLANK}
                control={<Radio />}
                label="No items (I'll do it myself)"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleCreateOrder}
              disabled={!(loc && vendor)}
              style={{
                border: "0px",
                backgroundColor: "#00A9A7",
                textTransform: "uppercase",
                color: "white",
              }}
            >
              Start new order
            </Button>
          )}
        </Box>
      </Box>
      <Backdrop
        open={isImporting}
        sx={{
          background: "rgba(255, 255, 255, 0.8)",
          color: "black",
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
          <div style={{ background: "white" }}>Importing items...</div>
        </Box>
      </Backdrop>
    </form>
  );
};

export default PurchaseOrderCreate;
