import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { Input } from '@mui/material';

const CellEditor = ({
  text,
  onValueChange,
  number,
  cost,
  style,
  options
}) => {
  const [value, setValue] = useState(text);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    // Use regex to allow numbers and decimals
    const decimalRegex = /^-?\d*\.?\d*$/;
    if (decimalRegex.test(inputValue)) {
      setValue(inputValue);
      if (onValueChange) {
        onValueChange(inputValue);
      }
    }
  };

  const onChangeCostVal = (value) => {
    var removeCommas = value.replace(",", "");
    if (!isNaN(removeCommas)) {
      setValue(removeCommas);
      if (onValueChange) {
        onValueChange(Number(removeCommas));
      }
    }
  };

  const numFormat = (num) => {
    let numFormat = (Math.round(num * 100) / 100).toFixed(2)
    numFormat = numFormat.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    return numFormat
  }

  if (!number) {
    return <Autocomplete
      freeSolo
      autoSelect
      size="small"
      options={options}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        if (onValueChange) {
          onValueChange(newValue);
        }
      }}
      renderInput={(params) => {
        const ps = {
          ...params,
          InputProps: {
            ...params.InputProps,
            style: {
              fontSize: '13px',
            }
            // disableUnderline: true
          }
        }
        return <TextField
          variant="standard"
          style={{ width: 200 }}
          {...ps}
        />
      }}
    />
  }

  return <div style={{
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  }}>
    <input
      type="text"
      value={value}
      onChange={handleChange}
      className="hide-input-border indent-input"
      style={{ textAlign: 'right' }}
    />
  </div>

  // return <Input
  //   value={value}
  //   onChange={handleChange}
  //   style={{
  //     border: 'none',
  //     fontSize: '13px',
  //   }}
  //   InputProps={{
  //     style: {
  //       border: 'none',
  //       fontSize: '13px',
  //     }
  //   }}
  //   startAdornment={<InputAdornment position="start">$</InputAdornment>}
  // />

  return number && value == "" ? (
    <input
      type="text"
      value={value}
      onChange={onChangeVal}
      autoFocus
      className="hide-input-border"
      style={style}
    />
  ) : number && cost ? (
    <>
      <label className="dollar-sign">$</label>
      <input
        type="text"
        value={value}
        // onBlur={(e) => onChangeCostVal(e.target.value)}
        onChange={(e) => setValue(e.target.value)}
        className="hide-input-border indent-input"
        style={style}
      />
    </>
  ) : number ? (
    <input
      type="text"
      value={numFormat(value)}
      onChange={(e) => onChangeCostVal(e.target.value)}
      className="hide-input-border"
      style={style}
    />
  ) : (
    <div>{value}</div>
  );
};

export default CellEditor;
