import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

// recoil
import { useRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';

// icons
import { DeleteOutline, EditRoad } from '@mui/icons-material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

// state
import { BomsState } from '../production';
import DeleteConfirmation from '../../../../components/DeleteConfirmation';

// services
import bomService from '../../../../service/org/bom.service';

// hooks
import { useFetchBoms } from './hooks'

export const BOMS = () => {
  const navigate = useNavigate();
  const boms = useRecoilValue(BomsState);
  const bomHook = useFetchBoms(BomsState);
  const [selectedBom, setSelectedBom] = useState();
  const [deleteVisible, setDeleteVisible]= useState(false);
  const columns = [
    {
      field: 'bom_id',
      headerName: 'BOM ID',
      width: 90
    },
    {
      field: 'description',
      headerName: 'BOM Description',
      flex: 1
    },
    {
      field: 'items',
      headerName: 'Items',
      width: 120,
    },
    {
      field: 'in_use',
      headerName: 'In Use',
      width: 100,
    },
    {
      field: 'production_lead_time',
      headerName: 'Production Lead Time',
      width: 120,
    },
    {
      field: 'min_production_qty',
      headerName: 'Min Prod Qty',
      width: 120,
    },
    {
      field: 'case_rounding',
      headerName: 'Case Rounding',
      width: 140,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      renderCell: (params) => {
        return <div style={{ color: '#868686', display: 'flex', columnGap: '5px', cursor: 'pointer' }}>
          <EditRoad onClick={() => {
            navigate(`bom?id=${params.row.bom_id}`);
          }} />
          <DeleteOutline onClick={(e) =>  {
            e.preventDefault();
            e.stopPropagation();
            setSelectedBom(params.row);
            setDeleteVisible(true);
          }} />
        </div>
      }
    },
  ];

  const onDeleteConfirmed = async () => {
    if (!selectedBom) return;
    await bomService.deleteBom(selectedBom.bom_id);
    setSelectedBom(null);
    setDeleteVisible(false);
    await bomHook.refresh();
  };

  const onRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    navigate(`bom?id=${params.row.bom_id}`);
  };

  return <>
    <DataGrid
      compact={true}
      rows={boms}
      columns={columns}
      onRowClick={onRowClick}
      getRowId={row => row.bom_id}
      hideFooter
      density="compact"
      autoHeight={true}
      headerHeight={90}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1,
        }
      }}
    />
    <div
      style={{
        position: 'absolute',
        top: 10,
        right: 25
      }}>
      <a
        onClick={() => navigate('bom')}
        style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '13px',
      }}>
        <i className='material-icons navmenu-icon'>{'add_circle_outline'}</i>
        <span className='d-block'>New BOM</span>
      </a>
      <DeleteConfirmation
        title={`Delete BOM`}
        content={`Are you sure you want to delete this BOM?`}
        onClose={() => {
          setDeleteVisible(false);
        }}
        onDelete={onDeleteConfirmed}
        open={deleteVisible} />
    </div>
  </>
}

export default BOMS;
