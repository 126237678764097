import React, { useEffect, useState } from "react";
import moment from "moment";
import "./ArrivalDatePicker.css";

const ArrivalDatePicker = ({
  record,
  onSelectDate,
  position,
  calendarOpen,
}) => {
  const [arrivalDate, setArrivalDate] = useState(null);

  const handleChange = (value, formattedValue) => {
    setArrivalDate(value);
    if (onSelectDate) {
      onSelectDate(record, formattedValue);
    }
  }

  useEffect(() => {
    setArrivalDate(moment(record.delivery_date));
    const popOver = document.getElementsByClassName("bs-popover-auto");
    setTimeout(() => {
      if (popOver.length) {
        popOver[0].style.top = `${position.y - 20}px`;
        popOver[0].style.left = `${position.x - 120}px`;
      }
    }, 0);
  }, [position.x, position.y, record.delivery_date]);

  return (
      <div className="arrive-date-picker">
        {
          calendarOpen ? (
              <DatePicker
                  showClearButton={false}
                  label="Basic example"
                  value={arrivalDate}
                  onChange={handleChange}
              />
          ) : (
              <React.Fragment>
                {
                  arrivalDate && moment(arrivalDate)
                      .format('MMM DD, YYYY')
                }
              </React.Fragment>
          )
        }
      </div>
  );
};

export default ArrivalDatePicker;
