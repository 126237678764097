// styles
import { useStyles } from "./style";

// mui
import Button from '@mui/material/Button';
import { PivotTableChart } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { GridToolbarContainer } from '@mui/x-data-grid';

const CustomToolBar = ({ actions }) => {
  // state
  const classes = useStyles();

  return (
    <GridToolbarContainer
      className={classes.toolbarContainer}>
      <Button
        onClick={actions.onColumns}
        size="small"
        startIcon={<ViewWeekIcon htmlColor="#17a2b8" />}
        className={classes.toolbarButton}>
        Columns
      </Button>
      {/*<Button*/}
      {/*  onClick={actions.onGroupBy}*/}
      {/*  size="small"*/}
      {/*  startIcon={<PivotTableChart htmlColor="#17a2b8" />}*/}
      {/*  className={classes.toolbarButton}>*/}
      {/*  Group By*/}
      {/*</Button>*/}
      <Button
        onClick={actions.onExport}
        size="small"
        startIcon={<DownloadIcon htmlColor="#17a2b8" />}
        className={classes.toolbarButton}>
        Export
      </Button>
    </GridToolbarContainer >
  );
}

export default CustomToolBar;
