class EventBus {

    on(eventName, callback) {
        document.addEventListener(eventName, (e) => callback(e.detail));
    }

    dispatch(eventName, data) {
        // window.eventBus.dispatch("couponApply", { message: "coupone applied" })
        document.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    }

    remove(eventName, callback) {
        // window.eventBus.remove("couponApply")
        document.removeEventListener(eventName, callback);
    }

}

export default new EventBus();
