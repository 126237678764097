import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import "./style.css";

const NumberEditableText = ({
  value = 0,
  className = "",
  onChange,
  onDoneTyping,
  prefix = "",
  displayType = "input",
  align = "center",
  decimals = 2,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  // const [inputRef, setInputRef] = useState();

  const onValueChange = (newValue) => {
    const convertedValue = +newValue.value;
    setCurrentValue(convertedValue);
    if (onChange) {
      onChange(convertedValue);
    }
  };

  const onDoneTypingInside = (newValue) => {
    const newestValue = Number.isFinite(newValue) ? newValue : currentValue;
    if (newestValue !== value) onDoneTyping && onDoneTyping(newestValue);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div onBlur={onDoneTypingInside}>
      <NumberFormat
        displayType={displayType}
        value={currentValue}
        onValueChange={onValueChange}
        className={`numeric-format-input ${className} ${align}`}
        thousandSeparator
        decimalScale={decimals}
        prefix={prefix}
      />
    </div>
  );
};

export default NumberEditableText;
