import styled from "styled-components";
import { makeStyles } from "@mui/styles";

export const Wrap = styled.div`
  padding: 25px;
`;

export const Container = styled.div`
  flex-grow: 1;
`;

export const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    flexDirection: "column",
    "& > span": {
      fontSize: "12px",
      color: "#717171",
    },
  },
  boostrapWrapper: {
    "& .MuiInputBase-input": {
      backgroundColor: "white !important",
    },
  },
  filters: {
    margin: '5px 0',
    display: 'flex',
    flexWrap: 'wrap'
  },
  checkboxes: {
    display: 'flex',
    gap: 5,
    paddingTop: '5px'
  },
  main: {
    height: 700,
    width: '100%',
    marginTop: '5px'
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 220px)',
    gridTemplateRows: 'repeat(2, 50px)',
    gap: '10px',
  }
}));
