import React from "react";

// styles
import { useStyles } from "./style";
import { DrawerHeader } from "./side-drawer/mui-drawer-styles";

// components
import TopBar from "./top-bar";
import SideDrawer from "./side-drawer";

// sentry
import * as Sentry from "@sentry/react";
import ErrorFallback from "../fallback";

// mui
import Box from "@mui/material/Box";

// store
import { useRecoilValue } from 'recoil';
import { drawerOpenState } from '../../../store';
import { useTrackedState } from '../../../context/store';

const Layout = ({ children }) => {
	const classes = useStyles();
	const drawerOpen = useRecoilValue(drawerOpenState);
	const { subscriptions } = useTrackedState()['layout'];

	return (
		<Box
			className={classes.layout}
		>
			<TopBar />
			<SideDrawer
				open={drawerOpen}
				subscriptions={subscriptions}
			/>
			<Box
				component="main"
				className={classes.content}
			>
				<DrawerHeader />
				<Sentry.ErrorBoundary fallback={ErrorFallback}>
					{children}
				</Sentry.ErrorBoundary>
			</Box>
		</Box>
	);
};

export default React.memo(Layout);
