// libs
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

// deps
import { addCommas, formatDollar } from "../../../../../utils/textFormatter";

// styles
import {
  CardFooterStyle,
  CardHeaderStyle,
} from "../../../../../components/Card/card";
import { Td, Th } from "./styles";
import CardActions from "@mui/material/CardActions";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentOrgId } from "../../../../../store";

const Sales = ({
  endpoint: api,
  onStartLoading = () => { },
  onEndLoading = () => { },
}) => {
  const [salesData, updateSalesData] = useState({
    rows: [],
    columns: [],
    values: {},
  });

  const isDefault = salesData.rows.length === 0 &&
    salesData.columns.length === 0 &&
    salesData.values.length === 0;

  const [loading, setLoading] = useState(false);
  const orgId = useRecoilValue(currentOrgId);

  useEffect(() => {
    setLoading(true);
    onStartLoading();

  }, []);

  useEffect(() => {
    if (orgId) {
      api()
        .then((res) => {
          if (res.data.errors) {
            // TBD: show snackbar
          } else {
            let tempValues = {};
            Object.entries(res.data.values).forEach(
              (item) =>
              (tempValues[Object.keys(item[1])[0]] = Object.entries(
                item[1]
              )[0][1])
            );

            updateSalesData({
              rows: res.data.rows,
              columns: res.data.columns,
              values: {
                ...tempValues,
                wos: {
                  lw: res.data.wos.last_week_wos,
                  forecast: res.data.wos.forecast_wos,
                  var: res.data.wos.var,
                  var_percent: res.data.wos.var_per,
                },
              },
            });
          }
        })
        .catch((err) => {
          // TBD: show snackbar
        })
        .finally(() => {
          setLoading(false);
          onEndLoading();
        });
    }
  }, [orgId]);

  const formatFigure = (rowKey, colKey, figure) => {
    if (rowKey === "gm" || colKey === "var_percent")
      return isFinite(figure) ? (
        Number(figure).toFixed(0).replace(".00", "") + "%"
      ) : (
        <i>N/A</i>
      );
    else if (rowKey === "auv")
      return figure >= 0 ? `$${figure}` : `-$${Math.abs(figure)}`;
    else if (rowKey === "wos") return Number(figure).toFixed(1);
    else if (rowKey === "sales")
      return "$" + Number(figure / 1000).toFixed(1) + "k";
    else if (rowKey === "unit_sales") return addCommas(parseInt(figure), 0);
    else return formatDollar(figure);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        flex: 1,
      }}
    >
      <CardHeader title={<CardHeaderStyle>Sales</CardHeaderStyle>} />
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 0,
        }}
        className={isDefault ? undefined : 'sales-data'}
      >
        {!loading && (
          <table id="sales-table" data-testid="sales_widget" cellSpacing="20px" cellPadding="5px">
            <thead>
              <tr>
                <Th style={{ minWidth: "100px" }} />
                {salesData.columns.map((columnHeader, index) => (
                  <Th
                    key={index}
                    style={{
                      paddingBottom: "10px",
                      minWidth:
                        columnHeader.key === "var"
                          ? "80px"
                          : columnHeader.key === "var_percent"
                            ? "70px"
                            : "auto",
                    }}
                  >
                    {columnHeader.value}
                  </Th>
                ))}
              </tr>
            </thead>
            <tbody>
              {salesData.rows.map((row, index) => (
                <tr key={index}>
                  <Th key={`${index}_th`} style={{ paddingRight: "5px" }}>
                    {row.value}
                  </Th>
                  {salesData.columns.map((column, index2) => (
                    <Td key={index2}>
                      {formatFigure(
                        row.key,
                        column.key,
                        Number(salesData.values[row.key][column.key]).toFixed(2)
                      )}
                    </Td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </CardContent>
      <CardActions>
        <CardFooterStyle>
          <NavLink
            to="../reports/recap/category-recap"
            style={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            VIEW REPORT
          </NavLink>
        </CardFooterStyle>
      </CardActions>
    </Card>
  );
};

export default Sales;
