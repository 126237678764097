import API from "../api"

const MaterialService = {
  getMaterials: () => API.get(`org/materials`),
  getMaterial: (materialId, newValues) => API.put(`org/materials/${materialId}`, newValues),
  createMaterial: (material) => API.post(`org/materials`, material),
  updateMaterial: (materialId, newMaterial) => API.put(`org/materials/${materialId}`, newMaterial),
  deleteMaterial: (materialId) => API.post(`org/materials/${materialId}`),
};

export default MaterialService;
