/// libs
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { getUntrackedObject } from "react-tracked";
import PulseLoader from "@/components/PulseLoader/index";
import React, { useEffect, useMemo, useState } from "react";
import { AlertOctagon, AlertTriangle, EyeOff, Info } from "react-feather";

// mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";

// deps
import { useRecoilState, useRecoilValue } from "recoil";
import NoDataImage from "@/assets/roi-booster-no-data.png";
import ProgressRing from "@/components/ProgressRing";
import OrganisationService from "@/service/organisation-service";
import { currentOrgId } from "@/store";
import {
  boosters as boostersState,
  currentPageNumber as currentPageNumberState,
  optimizedPercentage as optimizedPercentageState,
  rowCount as rowCountState,
  totalRecordCount as totalRecordCountState,
  totalValue as totalValueState
} from "@/store/roi-booster/roi-booster.store";
import { formatDollar } from "@/utils/textFormatter";
import FeedbackModal from "./components/feedback-modal";
import PageTitle from '../../../components/PageTitle';

const ROIBooster = () => {
  const navigate = useNavigate();
  const orgId = useRecoilValue(currentOrgId);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useRecoilState(rowCountState);
  const [boosters, updateBoosters] = useRecoilState(boostersState);
  const [totalValue, setTotalValue] = useRecoilState(totalValueState);
  const serviceProvider = OrganisationService.forecast(orgId).roiBooster;
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [totalRecordCount, setTotalRecordCount] = useRecoilState(totalRecordCountState);
  const [currentPageNumber, setCurrentPageNumber] = useRecoilState(currentPageNumberState);
  const [optimizedPercentage, setOptimizedPercentage] = useRecoilState(optimizedPercentageState);

  const hideDeleteModal = () => {
    setFeedbackModalOpen(false);
  };

  const fetchBoosters = async (pageNumber, rowCount_Optional) => {
    try {
      setLoading(true);
      const { data } = await serviceProvider.list(
        rowCount_Optional || rowCount,
        pageNumber
      );

      if (data.errors) {
        // TBD: show snackbar
      } else {
        updateBoosters(data.roiBoosterList);
        setOptimizedPercentage(data.optimized_percentage);
        setTotalValue(data.total_value);
        setTotalRecordCount(data.total_count);
        setCurrentPageNumber(pageNumber);
      }
    } catch (e) {
      // TBD: show snackbar
    } finally {
      setLoading(false);
    }

    setTotalRecordCount(16);
    setCurrentPageNumber(pageNumber);
  };

  const handlePerRowsChange = async (newRowCount, currentPageNumber) => {
    setRowCount(newRowCount);
    fetchBoosters(currentPageNumber, newRowCount);
  };

  useEffect(() => {
    fetchBoosters(currentPageNumber)
      .then();
  }, []);

  const goToDetailsPage = (record) =>
    navigate(`product-booster-info/${record.id}`, {
      productBoosterDetails: getUntrackedObject(record),
    });

  const getColour = (priority, forWhat) => {
    switch (forWhat) {
      case "tablePriority": {
        if (priority === "Action") {
          return {
            className: "theme-text-color",
            icon: <Info />,
          };
        } else if (priority === "Warning") {
          return {
            className: "text-warning",
            icon: <AlertTriangle />,
          };
        } else if (priority === "Critical") {
          return {
            className: "text-danger",
            icon: <AlertOctagon />,
          };
        }
        break;
      }
      case "tableDescription": {
        if (priority === "Action") {
          return "theme-text-color";
        } else if (priority === "Warning") {
          return "text-warning";
        } else if (priority === "Critical") {
          return "text-danger";
        }
        break;
      }
    }
  };

  const columns = useMemo(() => [
    {
      name: "Type",
      selector: "priority",
      cell: (record) => {
        const style = getColour(record.priority, "tablePriority");
        return (
          <span
            onClick={() => goToDetailsPage(record)}
            className={style.className}
          >
            {style.icon}
            {record.viewed === "0" ? (
              <span
                className="d-block mt-1"
                style={{
                  color: "#535353",
                  fontSize: "12px",
                  fontWeight: "900",
                }}
              >
                New
              </span>
            ) : null}
          </span>
        );
      },
      width: "40px",
    },
    {
      name: "Description",
      cell: (record) => (
        <div onClick={() => goToDetailsPage(record)}>
          <div
            className={getColour(record.priority, "tableDescription")}
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              padding: "14px 0px 7px 0px",
            }}
          >
            {record.head_line}
          </div>
          <div
            className=""
            style={{
              fontSize: "12px",
              padding: "0px 0px 14px 0px"
            }}
          >
            {record.summary}
          </div>
        </div>
      ),
      minWidth: "500px",
      maxWidth: "100%",
    },
    {
      name: "Booster Size",
      selector: "value",
      cell: (record) => `${formatDollar(record.value)}`,
      width: "130px",
      right: true,
      style: {
        color: "rgba(60,60,60,0.87)",
        fontWeight: "bold",
        fontSize: "22px",
        padding: "12px",
      },
    },
    {
      name: "TimeFrame",
      selector: "deadline_description",
      center: true,
      wrap: true,
    },
    {
      name: "Actions",
      cell: () => (
        <>
          <Button className="action-no-view" disabled>
            <EyeOff size={18} />
          </Button>
        </>
      ),
      width: "100px",
    },
  ]);

  const conditionalRowStyles = [
    {
      when: (record) => record.priority === "Action",
      style: {
        border: "2px solid #19d1dd !important",
        borderRadius: "8px",
        marginBottom: "6px",
        cursor: "pointer",
      },
    },
    {
      when: (record) => record.priority === "Critical",
      style: {
        border: "2px solid #dc3545 !important",
        borderRadius: "8px",
        marginBottom: "6px",
        cursor: "pointer",
      },
    },
    {
      when: (record) => record.priority === "Warning",
      style: {
        border: "2px solid #ffc107 !important",
        borderRadius: "8px",
        marginBottom: "6px",
        cursor: "pointer",
      },
    },
  ];

  const handleDelete = () => {
    // const locPublicId = recordToDelete.loc_public_id
    // OrganisationService.location.delete(orgId, locPublicId)
    //     .then(res => {
    //         if (res.data.errors)
    //             notify({ type: "error", title: "Failure", message: "Could not delete location!" })
    //         else {
    //             deleteBooster(recordToDelete)
    //             notify({ type: "info", title: "Success", message: "Location deleted!" })
    //         }
    //     })
  };

  return (
    <Stack style={{ padding: "25px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Stack>
          <PageTitle typographyProps={{
            'data-qa': "roi-booster-title"
          }}>ROI BOOSTER</PageTitle>
          <Typography
            component="h4"
            sx={{
              fontSize: "26px",
              color: "rgba(68,68,68,0.87)",
            }}
          >
            OPPORTUNITIES
          </Typography>
        </Stack>
        <Stack>
          <div
            className="d-flex"
            style={{
              marginRight: "0px",
              marginLeft: "0px"
            }}
          >
            <div>
              <div className="text-center mr-2" style={{ marginTop: "46px" }}>
                <span className="big-dollar" style={{ fontSize: "xxx-large" }}>
                  {loading ? (
                    <Skeleton height={50} width={"70%"} />
                  ) : (
                    <> {formatDollar(totalValue || "0")}</>
                  )}
                </span>
                <span
                  style={{
                    color: "#8D8D8D",
                    fontWeight: "bolder",
                    fontSize: "22px",
                    lineHeight: "35px",
                  }}
                >
                  Opportunity on the table
                </span>
              </div>
            </div>
            <div className="text-center">
              {loading ? (
                <Skeleton circle height={160} width={160} />
              ) : (
                <ProgressRing
                  stroke={18}
                  radius={80}
                  progress={optimizedPercentage}
                />
              )}
            </div>
          </div>
        </Stack>
      </Box>
      <Card style={{
        border: "0px",
        marginTop: "20px"
      }}>
        <CardContent style={{ padding: "0px" }}>
          {loading && <PulseLoader />}
          {boosters.length < 1 && !loading && (
            <div>
              <h2
                style={{
                  textAlign: "center",
                  color: "rgba(68, 68, 68, 0.87)",
                  fontWeight: "900",
                }}
              >
                You're all caught up for the week!
              </h2>
              <img
                alt="No Data"
                src={NoDataImage}
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </div>
          )}
          {boosters.length > 0 && !loading && (
            <DataTable
              data={boosters}
              columns={columns}
              conditionalRowStyles={conditionalRowStyles}
              noTableHead
              noHeader
              highlightOnHover
              progressPending={loading}
              progressComponent={<CircularProgress />}
              pagination
              paginationServer
              paginationDefaultPage={currentPageNumber}
              paginationPerPage={rowCount}
              paginationTotalRows={boosters.length}
              paginationRowsPerPageOptions={[25, 50, 100, 500]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={(page) => fetchBoosters(page)}
              onRowClicked={goToDetailsPage}
            />
          )}
        </CardContent>
      </Card>
      {feedbackModalOpen && (
        <FeedbackModal
          actionHandler={handleDelete}
          closeModal={hideDeleteModal}
        />
      )}
    </Stack>
  );
};

export default ROIBooster;
