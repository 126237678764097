import { makeStyles } from '@mui/styles';
import { sectionCommonStyle } from '../shared/sections-common-style';

export const useStyles = makeStyles((theme) => ({
  ...sectionCommonStyle,
  usersContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  pendingChip: {
    marginLeft: '10px',
    color: 'white !Important',
  },
}));
