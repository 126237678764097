import { ALLOCATION_COLUMNS } from "../../utils/columns";

const UNDEFINED_COLUMN_SIGN = '-'

export const convertItemsToCsvFormat = (items) => {
  return items.map((item) => {
    const values = {};
    Object.entries(ALLOCATION_COLUMNS).forEach(([header, columnData]) => {
      if (item[columnData.field]) {
        values[header] = columnData.formatter ?
          columnData.formatter({ value: +item[columnData.field] }) :
          item[columnData.field]
      } else {
        values[header] = UNDEFINED_COLUMN_SIGN
      }
    })
    return values;
  });
};
