import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';

const StatusSelect = ({ status, onStatusChange }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = value => {
        onStatusChange(value);
        handleClose();
    };

    const colors = {
        warning: {
            value: '#ff9f43',
            statuses: [
                'NEEDS_APPROVAL',
                'NOT_SUBMITTED',
                'DELAYED',
                'ISSUE'
            ],
        },
        critical: {
            value: '#e84748',
            statuses: [
                'CANCELLED',
                'REJECTED'
            ]
        },
        action: {
            value: '#19d1dd',
            statuses: [
                'REQUESTED',
                'CONFIRMED',
                'DELIVERED',
                'PAID',
            ]
        }
    };

    const options = [
        {
            value: 'NEEDS_APPROVAL',
            label: 'Needs Approval'
        },
        {
            value: 'NOT_SUBMITTED',
            label: 'Not Submitted'
        },
        {
            value: 'REQUESTED',
            label: 'Requested'
        },
        {
            value: 'CONFIRMED',
            label: 'Confirmed'
        },
        {
            value: 'DELIVERED',
            label: 'Delivered'
        },
        {
            value: 'CANCELLED',
            label: 'Cancelled'
        },
        {
            value: 'PAID',
            label: 'Paid'
        },
        {
            value: 'REJECTED',
            label: 'Rejected'
        },
        {
            value: 'DELAYED',
            label: 'Delayed'
        },
        {
            value: 'ISSUE',
            label: 'Issue'
        },
        {
            value: 'DELETED',
            label: 'Deleted'
        },
    ]

    const getColor = value => {
        let color
        Object.entries(colors)
            .map((v) => {
                if (v[1].statuses.indexOf(value) > -1) {
                    color = v[1].value
                }
            });
        return color;
    };

    const color = getColor(status);
    const text = options.find(o => o.value === status);

    return <div>
        <Chip label={text && text.label}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ backgroundColor: color }}
        />
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {options.map(option => {
                return <MenuItem
                    onClick={() => {
                        handleSelect(option.value)
                    }}
                    value={option.value}>{option.label}</MenuItem>
            })}
        </Menu>
    </div>;
}

export default StatusSelect;
