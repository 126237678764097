import { cloneDeep } from "lodash";
import { Actions, ACTIONS_FIELD_NAMES, ACTIONS_AFFECT_ALL } from "./actions";

export const editItems = (items, changes) => {
  let copyItems = cloneDeep(items)

  changes.forEach(({ uid, action, value }) => {
    const field = ACTIONS_FIELD_NAMES[action];
    if (action === Actions.Duplicate) {
      // handle duplicate
    } else {
      const itemIndex = copyItems.findIndex((i) => i.uid === uid);
      if (itemIndex !== -1) {
        const item = copyItems[itemIndex];
        // In case the action affect only one item
        if (!ACTIONS_AFFECT_ALL[action]) {
          item[field] = value;

          copyItems[itemIndex] = item;
          // if (Number.isFinite(value)) {
          //   popupChangeToParents(copyItems, item, { field, value });
          // }
          if (
            action === Actions.CasePackSize ||
            action === Actions.AUC ||
            action === Actions.OrderCases
          ) {
            const totalOrderUnits =
              (+item["Cases to Order"] || 0) * (+item["Case Pack Size"] || 0);
            const totalOrderCost = totalOrderUnits * item["AUC"];

            item["Total Order - Units"] = totalOrderUnits;
            item["Total Order - Cost"] = totalOrderCost;
          }
        } else {
          // In case the action affect few rows
          const relaventItems = copyItems.filter(
            (i) => i["Item ID"] === item["Item ID"]
          );
          const relaventItemsIds = relaventItems.map((i) => i.uid);
          const newItems = copyItems.map((currItem) => {
            const copy = { ...currItem };
            if (relaventItemsIds.includes(currItem.uid)) {
              copy[field] = value;
            }
            return copy;
          });
          copyItems = newItems;
        }
      }
    }
  })

  return copyItems;
};