import React from "react";
import { Cell, CellGroup, CellGroupWrap, LevelHeader2 } from "../styles";
import { toPercent } from "../../../utils/textFormatter";

const Level2 = ({ data, styles, fields, levelName, isTotal }) => {
  return (
    <div
      style={{
        ...styles,
        display: "flex",
      }}
    >
      <LevelHeader2>{data[levelName]}</LevelHeader2>
      <CellGroupWrap>
        {(fields || [])
          .filter((f) => !!f.visible)
          .map((metricGroup, i) => {
            let main = data[metricGroup.value];
            let compare = data[`fcst_${metricGroup.value}`];
            let variance =
              compare === 0 ? 0 : (main - compare) / Math.abs(compare);
            let mainFormatted, compareFormatted;

            if (isTotal) {
              mainFormatted = metricGroup.format(main);
              compareFormatted = metricGroup.format(compare);
            } else {
              mainFormatted = metricGroup.format(main);
              compareFormatted = metricGroup.format(compare);

              if (compare === 0) variance = 0;
               else {
                variance =
                  data[`var_${metricGroup.value}`] === 0
                    ? 0
                    : (main - compare) / Math.abs(compare);
              }
            }

            return (
              <CellGroup key={`mg2_${i}_${isTotal}`}>
                <Cell>{main === "Infinity" ? "∞" : mainFormatted}</Cell>
                <Cell>{compare === "Infinity" ? "∞" : compareFormatted}</Cell>
                <Cell>
                  {variance === Infinity ? "%" : toPercent(variance, 0, 100)}
                </Cell>
              </CellGroup>
            );
          })}
      </CellGroupWrap>
    </div>
  );
};

export default Level2;
