import { useState, useMemo } from "react";
import { useSnackbar } from "notistack";

// styles
import { useStyles } from "./style";

// store
import { useRecoilValue } from "recoil";
import { vendorsState } from "@/store/admin-panel/admin-panel.store";

// components
import Actions from "../shared/actions";
import CreateVendorDialog from "./create-vendor-dialog";
import CustomizedCheckbox from "@/components/CheckBox/CheckBox";

// services
import VendorService from "../../../../service/org/vendor.service";

// mui
import { AddOutlined } from "@mui/icons-material";
import {
  DataGridPro, gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';
import { Box, Button, FormControlLabel, FormGroup } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import DeleteConfirmation from '../../../../components/DeleteConfirmation';

const Vendors = ({ onRefetch, onProcessing }) => {
  // state
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const vendors = useRecoilValue(vendorsState);
  const [selectedVendor, setSelectedVendor] = useState();
  const [pageSize, setPageSize] = useState(100);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [showActivatedOnly, setShowActivatedOnly] = useState(false);
  const [showUpdateCreatePopup, setShowUpdateCreatePopup] = useState(false);

  const deleteVendor = (vendor) => {
    setSelectedVendor(vendor);
    setDeletePopupOpen(true);
  };

  // handlers
  const onDeleteConfirmed = async () => {
    try {
      onProcessing();
      await VendorService.deleteVendor(selectedVendor.id);
      enqueueSnackbar('Vendor deleted successfully', {
        variant: 'success'
      });
      onRefetch();
    } catch (err) {
      enqueueSnackbar('Delete vendor failed', {
        variant: 'error'
      });
    }
  }

  const VENDORS_COLUMNS = [
    {
      field: 'distributor_name',
      headerName: 'Vendor Name',
      width: 200,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 150,
    },
    {
      field: 'Status',
      type: 'status',
      renderCell: ({ row }) => {
        return <span>
          { +row.status_id === 1 ? "Active" : "Inactive"}
        </span>
      }
    },
    {
      field: 'contact_name',
      headerName: 'Contact Person',
      width: 150,
    },
    {
      field: 'phone_number',
      headerName: 'Phone',
      width: 120,
    },
    {
      field: 'actions',
      type: 'actions',
      renderCell: ({ row }) => {
        return <Actions
          onDelete={() => deleteVendor(row)}
          onEdit={() => {
            setSelectedVendor(row);
            setShowUpdateCreatePopup(true);
          }}
        />
      }
    }
  ];

  const filteredVendors = useMemo(() =>
    showActivatedOnly ? vendors : vendors.filter(vendor => +vendor.status_id === 1)
    , [vendors, showActivatedOnly])

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  return (
    <Box className={classes.locationsContainer} sx={{ width: '100%' }}>
      <div className={classes.actions}>
        <Button className={classes.addButton} variant="contained" onClick={() => { setShowUpdateCreatePopup(true) }}>
          <AddOutlined sx={{ marginRight: '8px' }} />
          Add Vendor
        </Button>
      </div>
      <DataGridPro
        className={classes.table}
        loading={vendors.length === 0}
        rows={filteredVendors}
        columns={VENDORS_COLUMNS}
        getRowId={(row) => row.id}
        getRowClassName={(params) => +params.row.status_id !== 1 && 'inactive'}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        components={{
          Footer: () => (
            <div className={classes.activeFilter}>
              <FormGroup>
                <FormControlLabel control={<CustomizedCheckbox
                  checked={showActivatedOnly}
                  color="#19d1dd"
                  onChange={() => {
                    setShowActivatedOnly(currActivity => !currActivity);
                  }}
                />} label={<span style={{ marginLeft: '2px' }}>Show inactive vendors</span>} />
              </FormGroup>
              <CustomPagination />
            </div>),
        }}
      />
      <CreateVendorDialog
        show={showUpdateCreatePopup}
        onCloseDialog={() => {
          setShowUpdateCreatePopup(false);
          setSelectedVendor(undefined)
        }}
        onCreate={onRefetch}
        onUpdate={onRefetch}
        vendor={selectedVendor}
      />
      <DeleteConfirmation
        title={`Delete vendor`}
        content={`Are you sure you want to delete ${selectedVendor?.distributor_name}?`}
        onClose={() => {
          setDeletePopupOpen(false);
          setTimeout(() => setSelectedVendor(null), 1000);
        }}
        onDelete={onDeleteConfirmed}
        open={deletePopupOpen} />
    </Box>
  )
}

export default Vendors
