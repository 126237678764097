import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  topRow: {
    padding: "20px",
    marginBottom: "5vh",
    width: "100%",
  },
  stepContainer: {
    width: "100%",
    flex: 1,
  },
}));
