import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentOrg as currentOrgState, currentOrgId }
	from '../../store';
import { useTrackedState } from "../../context/store";
import AuthService from "../../service/auth-service";
import OrganisationService from "../../service/organisation-service";

const useUserData = () => {
	const orgId = useRecoilValue(currentOrgId);
	const setCurrentOrg = useSetRecoilState(currentOrgState);
	const { userData } = useTrackedState()['layout'];

	if (!userData) {
		const fromLocalStorage = AuthService.getUserInfo();
		if (!orgId && fromLocalStorage?.org_public_id) {
			OrganisationService.org.getInfo(fromLocalStorage.org_public_id).then((org) => {
				setCurrentOrg(org);
			})
		}

		return {
			org_public_id: orgId ?? fromLocalStorage?.org_public_id,
			user_type_id: fromLocalStorage?.user_type_id,
		}
	}

	if (!orgId && userData?.org_public_id) {
		OrganisationService.org.getInfo(userData.org_public_id).then((org) => {
			setCurrentOrg(org);
		})
	}

	return {
		org_public_id: orgId ?? userData?.org_public_id,
		user_type_id: userData?.user_type_id,
		all: userData,
	};

};

export default useUserData;
