import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Loader from '../../../../../../components/Loader';
import { OKButton } from '../PurchaseOrderEdit/style';
import OrganisationService from '../../../../../../service/organisation-service';
import { EmptyHistory } from './styles';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'created_at', numeric: false, disablePadding: true, label: 'Time' },
    { id: 'message', numeric: false, disablePadding: false, label: 'Event' },
    { id: 'user_id', numeric: false, disablePadding: false, label: 'User' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        {
            color: '#109185',
            backgroundColor: '#109185',
        },
    title: {
        color: 'white',
        flex: '1 1 100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { poNumber } = props;

    return (
        <Toolbar
            className={classes.highlight}
        >
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                <div>
                    Change Log
                </div>
                <div style={{ paddingRight: '10px' }}>
                    { poNumber }
                </div>
            </Typography>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '5px',
        padding: '20px',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {
        poNumber,
        onClose,
        orgId
    } = props;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 15,
            height: 40,
            padding: 50
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, events.length - page * rowsPerPage);

    useEffect(() => {
        const loadHistory = async () => {
            try {
                setLoading(true);
                const { data: events } = await OrganisationService.po.history(orgId, poNumber);
                setEvents(events);
            } catch (e) {
                enqueueSnackbar(e.message, {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'bottom'
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        loadHistory().then();
    }, []);

    const formatMessage = row => {
        let words = (row.message || []).split(' ');
        words = words.map(w => {
            if (w.toString()
                .indexOf('_') > -1) {
                return w.toString()
                    .replace('_', ' ');
            } else return w;
        })
        return words.join(' ');
    };

    const LoadingScreen = () => {
        return <div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <h5>
                    Loading change log...
                </h5>
            </div>
            <Loader/>
        </div>
    };

    return (
        <div className={classes.root} style={{ backgroundColor: loading ? 'white' : '#f5f5f5', }}>
            {loading && <LoadingScreen/>}
            {!loading && events.length === 0 && <EmptyHistory>
                No History
            </EmptyHistory>}
            {!loading && events.length > 0 && <Paper className={classes.paper}>
                <EnhancedTableToolbar poNumber={poNumber} numSelected={selected.length}/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Event</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(events, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <StyledTableRow
                                      hover
                                      tabIndex={-1}
                                      key={row.name}
                                    >
                                        <StyledTableCell component="th" id={labelId}
                                                         scope="row">
                                            <div style={{
                                                paddingLeft: '20px',
                                                overflow: 'auto'
                                            }}>
                                                {formatMessage(row)}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{ paddingLeft: '20px' }}>{row.created_at}</StyledTableCell>
                                        <StyledTableCell>{row.full_name}</StyledTableCell>
                                    </StyledTableRow>
                                  );
                              })}
                            {emptyRows > 0 && (
                              <StyledTableRow style={{ height: 53 * emptyRows }}>
                                  <StyledTableCell colSpan={6}/>
                              </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={events.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>}
            <br/>
            <OKButton onClick={onClose}>
                {loading ? 'Cancel' : 'Close'}
            </OKButton>
        </div>
    );
}
