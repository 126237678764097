import React, { useEffect } from 'react';
import arrayMove from 'array-move';

// styles
import {
    useStyles,
    SortableItemWrap,
    Dragger,
    Body,
    Actions,
    ModalTitle,
    SortableWrap
} from './styles';

// mui
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// components
import { OKButton } from '../../Button';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

const Sorter = ({ reportName = "", onClose, metricGroups, setMetricGroups, title = "Metrics", titleStyle = {}, bodyStyle = {}, cacheInLocalStorage = true, headerComponent }) => {
    // state
    const classes = useStyles();

    // handlers
    const onSortEnd = ({
        oldIndex,
        newIndex
    }) => {
        const rearranged = arrayMove(metricGroups, oldIndex, newIndex);
        saveOrder(rearranged);
        setMetricGroups(rearranged);
    };

    const saveOrder = (metrics) => {
        const orderMap = metrics.filter(m => m.visible)
            .map((f) => f.value);
        if (cacheInLocalStorage) {
            localStorage.setItem(reportName, JSON.stringify(orderMap));
        }
    };

    const sort = (a, b) => {
        const cat1 = a?.display?.toUpperCase();
        const cat2 = b?.display?.toUpperCase();
        return (cat1 < cat2) ? -1 : (cat1 > cat2) ? 1 : 0;
    };

    const onCheck = (e, checked, value) => {
        const metrics = metricGroups.map(g => {
            if (g.value === value)
                g.visible = checked;
            return g;
        });
        const visible = metrics.filter(m => m.visible);
        const hidden = metrics.filter(m => !m.visible)
            .sort(sort);

        const newMetrics = [
            ...visible,
            ...hidden,
        ];

        saveOrder(newMetrics);
        setMetricGroups(newMetrics);
    };

    const SortableContainer = sortableContainer(({ children }) => {
        return <ul style={{
            padding: 0,
            margin: 0
        }}>{children}</ul>;
    });

    // effects
    useEffect(() => {
        const orderMap = cacheInLocalStorage ? JSON.parse(localStorage.getItem(reportName)) : undefined;
        if (!orderMap) return;
        let ordered = [...metricGroups.map(m => ({
            ...m,
            visible: false
        }))];
        const visible = [];
        orderMap.map((metric) => {
            const index = ordered.findIndex(o => o.value === metric);
            const m = ordered.splice(index, 1);
            visible.push({
                ...m[0],
                visible: true,
            })
        });
        ordered = ordered.sort(sort);
        setMetricGroups([...visible, ...ordered]);
    }, []);

    const DragHandle = sortableHandle(() => <Dragger>
        <div style={{
            height: '2px',
            backgroundColor: 'gray',
            width: '100%',
            padding: 0,
            marginBottom: '5px'
        }} />
        <div style={{
            height: '2px',
            backgroundColor: 'gray',
            width: '100%',
            padding: 0
        }} />
    </Dragger>);

    const SortableItem = sortableElement(({
        value,
        visible,
        display
    }) => (
        <SortableItemWrap>
            <FormControlLabel
                control={<Checkbox
                    key={value}
                    checked={visible}
                    onChange={(e, checked) => onCheck(e, checked, value)}
                />}
                label={display}
                labelPlacement="end"
            />
            <DragHandle />
        </SortableItemWrap>
    ));

    const sortItems = (x, y) => (x.visible === y.visible) ? 0 : x.visible ? -1 : 1;

    return <Box sx={{ backgroundColor: 'white', zIndex: 9999, borderRadius: '15px' }}>
        {headerComponent ? headerComponent : (<ModalTitle style={titleStyle}>{title}</ModalTitle>)}
        <div className={classes.modalBody} style={bodyStyle}>
            <Body>
                <SortableWrap>
                    <SortableContainer helperClass={classes.draggable} onSortEnd={onSortEnd}
                        useDragHandle>
                        {(metricGroups || []).sort(sortItems)
                            .map((field, index) => (
                                <SortableItem visible={field.visible}
                                    key={`shown-item-${field.value}`}
                                    index={index}
                                    display={field.display}
                                    value={field.value} />
                            ))}
                    </SortableContainer>
                </SortableWrap>
            </Body>
            {onClose && (
                <Actions>
                    <OKButton onClick={onClose}>
                        Close
                    </OKButton>
                </Actions>
            )}
        </div>
    </Box>;
}

export default Sorter;
