import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#5b5b5b",
  },
  loadingWrap: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9,
    flexDirection: 'column',
  }
}));

