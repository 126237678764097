import React, { useEffect, useState } from "react";
import { useStyles } from "./style";

import { useSnackbar } from "notistack";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import UserService from "@/service/master/user.service";
import { getError } from "@/utils/server-error-types";
import { GENERAL_SPACING } from "../../shared/sections-common-style";
import CustomSelect from "@/components/CustomSelect";

const emptyFormData = {
  firstName: { display: 'First Name', value: "", hasError: false, required: true },
  lastName: { display: 'Last Name', value: "", hasError: false, required: true },
  email: { display: 'Email', value: "", hasError: false, required: true },
}

const CreateUserDialog = ({
  userTypes,
  show,
  onCloseDialog,
  onCreate,
  onUpdate,
  user,
}) => {
  const classes = useStyles();

  const initialFormData = {
    firstName: { display: 'First Name', value: user ? user.first_name : "", hasError: false, required: true },
    lastName: { display: 'Last Name', value: user ? user.last_name : "", hasError: false, required: true },
    email: { display: 'Email', value: user ? user.email : "", hasError: false, required: true },
    userTypeId: { display: 'Role', value: user && user.user_type_id, hasError: false, required: true },
  }

  const [formData, updateFormData] = useState({ ...initialFormData });
  const [formError, setFormError] = useState("");
  const [loading, toggleLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    updateFormData(initialFormData)
  }, [user])

  // Remove super admins from options
  const userTypesOptions = userTypes.
    map(userType => ({ key: userType.user_type_id, value: userType.user_type_name }));


  const handleFormInputChange = (event) => {
    const newValue = event.target.value;
    updateFormData({
      ...formData,
      [event.target.name]: {
        ...formData[event.target.name],
        value: newValue
      }
    })
  }

  const validateField = (formInput, value) => {
    const validateMap = {
      firstName: (val) => {
        if (val === "") {
          return "First Name is a required field";
        } else {
          return ""
        }
      },
      lastName: (val) => {
        if (val === "") {
          return "Last Name is a required field";
        } else {
          return ""
        }
      },
      email: (val) => {
        // TOOD: validate email pattern
        if (val === "") {
          return "Email is invalid";
        } else {
          return ""
        }
      },
      userTypeId: (val) => {
        // TOOD: validate email pattern
        if (val === undefined) {
          return "Role is invalid";
        } else {
          return ""
        }
      }
    }
    return validateMap[formInput](value)
  }

  const validate = () => {
    let formDataCopy = { ...formData };
    let foundErrors = false;

    Object.keys(formData).forEach(inputKey => {
      const fieldValidate = (validateField(inputKey, formData[inputKey].value))
      if (fieldValidate !== "") {
        if (!foundErrors) {
          setFormError(fieldValidate);
          foundErrors = true;
        }
        formDataCopy[inputKey].hasError = true;
      } else {
        formDataCopy[inputKey].hasError = false;
      }
    })

    if (!foundErrors) {
      setFormError("")
      handleFormSubmission(formDataCopy)
    }

    updateFormData({ ...formDataCopy })
  }

  const handleFormSubmission = async (data) => {
    toggleLoading(true);
    try {
      const newData = {
        first_name: formData.firstName.value,
        last_name: formData.lastName.value,
        email: formData.email.value.trim(),
        user_type_id: formData.userTypeId.value,
      }
      if (user) {
        await UserService.updateUser(user.user_id, newData);
        onUpdate(newData)
        enqueueSnackbar("User updated successfully", {
          variant: 'success'
        })
      } else {
        await UserService.createUser(newData)
        onCreate(newData)
        enqueueSnackbar(`An email has been sent to ${newData.email} with their login info and next steps.`,
          {
            variant: 'success'
          })
      }

      closeDialog();
    } catch (err) {
      toggleLoading(false);
      const { response } = err;
      const errorMessage = getError(response, `Failed to ${user ? 'update' : 'create'} user`);
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  }

  const closeDialog = () => {
    updateFormData({ ...emptyFormData });
    setFormError("");
    toggleLoading(false);
    onCloseDialog();
  };

  const isDisabled = () => {
    return formError ||
      formData.email.value?.trim().length === 0 ||
      formData.firstName.value?.trim().length === 0 ||
      formData.lastName.value?.trim().length === 0 ||
      !formData.userTypeId.value
  };

  return (
    <Dialog
      open={show}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          width: "350px",
        },
      }}
    >
      <DialogContent className={classes.wrapper}>
        <Box className={classes.form}>
          <Typography
            component="h2"
            sx={{
              lineHeight: "1",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {user ? 'Update user details' : 'Create a user'}
          </Typography>
          <TextField
            error={formData.firstName.hasError}
            value={formData.firstName.value}
            label={formData.firstName.display}
            required={formData.firstName.required}
            name="firstName"
            onChange={handleFormInputChange}
            size="small"
            color='primary'
            sx={{
              marginBottom: GENERAL_SPACING
            }}
          />
          <TextField
            error={formData.lastName.hasError}
            value={formData.lastName.value}
            label={formData.lastName.display}
            required={formData.firstName.required}
            name="lastName"
            onChange={handleFormInputChange}
            size="small"
            color='primary'
            sx={{
              marginBottom: GENERAL_SPACING
            }}
          />
          <TextField
            error={formData.email.hasError}
            value={formData.email.value}
            label={formData.email.display}
            required={formData.firstName.required}
            name="email"
            onChange={handleFormInputChange}
            size="small"
            color='primary'
            sx={{
              marginBottom: GENERAL_SPACING
            }}
          />
          {
            userTypes.length > 0 &&
            (<CustomSelect
              options={userTypesOptions}
              style={{
                fontSize: 'inherit',
                margin: '0px 0px 20px 0px',
                '& .MuiSelectUnstyled-popper': { zIndex: 2 },
                '& button': {
                  fontSize: 'inherit !Important',
                  borderRadius: '5px',
                  color: 'currentColor',
                  padding: '8.5px 14px',
                  minHeight: 'unset'
                },
              }}
              value={formData.userTypeId?.value}
              placeholder="Select user role *"
              onChange={(newUserTypeId) => {
                handleFormInputChange({
                  target: {
                    name: 'userTypeId',
                    value: newUserTypeId
                  }
                });
              }}
            />)
          }
          {formError &&
            <div style={{ marginBottom: GENERAL_SPACING }}>
              <span className={classes.error}>{formError}</span>
            </div>
          }
          <LoadingButton
            loading={loading}
            onClick={validate}
            disabled={isDisabled()}
            variant="contained"
            className={classes.submitButton}
          >
            {user ? 'Update' : 'Create'}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserDialog;
