import React, { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilValue } from 'recoil';
import { NumberParam, useQueryParam } from 'use-query-params';

// store
import { currentOrg, currentOrgId } from '../../../store';

// ui components
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import PageTitle from '../../../components/PageTitle';
import TabPanel, { a11yProps } from '../../../components/tabs/TabPanel';

// production components
import Boms from './boms';
import Materials from './materials';
import FinishedGoods from './finished-goods';

// services
import VendorService from '@/service/org/vendor.service';
import OrganisationService from '../../../service/organisation-service';

import MrpForecastData from './data/forecast.json';

// hooks
import { useFetchBoms } from './boms/hooks';
import { useFetchMaterials } from './materials/hooks';

export const mrpForecastDataState = atom({
  key: `${Math.random()}_mrp_forecast_data`,
  default: MrpForecastData
});

export const BomsState = atom({
  key: `${Math.random()}_boms`,
  default: []
});

export const materialsState = atom({
  key: `${Math.random()}_materials`,
  default: []
});

const Production = () => {
  const [tabQuery, setTabQuery] = useQueryParam('tab', NumberParam);
  const [tab, setTab] = useState(tabQuery ?? 0);
  const orgId = useRecoilValue(currentOrgId);
  const [vendors, setVendors] = useState([]);
  const forecastAPI = OrganisationService.forecast(orgId);

  // hooks
  const bomHook = useFetchBoms(BomsState);
  const materialHook = useFetchMaterials(materialsState);

  // data fetching
  const getVendors = useCallback(async () => {
    try {
      const {
        data ,
      } = await VendorService.getVendors();
      setVendors(data);
    } catch (e) {
      console.log(e);
    }
  }, [orgId]);

  const getBoms = useCallback(async () => {
    await bomHook.refresh();
  }, []);

  const getMaterials = useCallback(async () => {
    await materialHook.refresh();
  }, []);

  useEffect(() => {
    getBoms().then();
    getMaterials().then();
    getVendors().then();
  } ,[]);

  return <Stack style={{ padding: '25px', flex: 1 }}>
    <Box display="flex" justifyContent="space-between"
         style={{ marginBottom: '20px' }}>
      <Box>
        <PageTitle>
          Production
        </PageTitle>
      </Box>
    </Box>
    <Box style={{ flex: 1, position: 'relative' }}>
      <Tabs
        value={tab}
        onChange={(event, newTab) => {
          setTabQuery(newTab);
          setTab(newTab);
        }}
        aria-label="Production"
      >
        <Tab label="Finished Goods" {...a11yProps(0)} />
        <Tab label="Materials" {...a11yProps(1)} />
        <Tab label="BOMS" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <div style={{ height: '500px' }}>
          <FinishedGoods />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <div style={{ height: '500px' }}>
          <Materials vendors={vendors} forecastAPI={forecastAPI} />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <div style={{ height: '500px' }}>
          <Boms />
        </div>
      </TabPanel>
    </Box>
  </Stack>
}

export default Production;
