import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        height: "380px",
        flexDirection: "column",
        padding: "20px 20px 20px 20px",
    },
    selectAllButton: {
        color: "#4da1aa",
    },
    actions: {
        marginLeft: 'auto'
    }
}));
