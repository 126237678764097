import React, { useEffect, useState } from 'react';
import { atom, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import ProductService from "@/service/org/product.service";
import { Select, MenuItem } from '@mui/material';

// hooks
import { useGetFinishedGood } from './hooks';

// icons
import { DeleteOutline } from '@mui/icons-material';
import DeleteConfirmation from '../../../../components/DeleteConfirmation';
import { BomsState } from '../production';

export const finishedGoodsState = atom({
  key: `${Math.random()}_finished_goods`,
  default: []
});

export const FinishedGoods = () => {
  const navigate = useNavigate();
  const boms = useRecoilValue(BomsState);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const finishedGoodHook = useGetFinishedGood(finishedGoodsState);

  useEffect(() => {
    finishedGoodHook.refresh();
  }, []);

  const handleEvent = (
    params,
    event,
    details
  ) => {
    navigate(`../finished-goods/${params.row?.['Product ID']}/dependencies`);
  };

  const onDeleteConfirmed = async () => {
    await ProductService.updateProduct(currentProductId, { is_production_enabled: false });
    await finishedGoodHook.refresh();
    setDeletePopupOpen(false);
    setCurrentProductId(null);
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Product ID',
      width: 110,
      resizable: true,
    },
    {
      field: 'product_name',
      headerName: 'Product Description',
      width: 150,
      editable: true,
      resizable: true,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 80,
      editable: true,
      resizable: true,

    },
    {
      field: 'sub_category',
      headerName: 'Sub Category',
      width: 120,
      resizable: true,
    },
    {
      field: 'bom_id',
      headerName: 'BOM ID',
      resizable: true,
      width: 200,
      renderCell: (params) => {
        return <div>
          <Select
            value={params.row.bom_id}
            onChange={async (e) => {
              const newBomId = e.target.value;
              await ProductService.updateProduct(params.id, { bom_id: newBomId });
              await finishedGoodHook.refresh();
            }}
            style={{width: '200px', fontSize: 14}}
          >
            <MenuItem key="detach" value={undefined}>
              None
            </MenuItem>
            {boms.map((bom) => (
              <MenuItem key={bom.id} value={bom.id} style={{ fontSize: 14 }}>
                {bom.description} - {bom.id}
              </MenuItem>
            ))}
          </Select>
        </div>
      }
    },
    {
      field: 'mat_lead_time',
      headerName: 'Mat Lead Time',
      width: 80,
      resizable: true,
    },
    {
      field: 'mfg_lead_time',
      headerName: 'Mfg Lead Time',
      width: 80,
      resizable: true,
    },
    {
      field: 'min_prod_qty',
      headerName: 'Min Prod Qty',
      resizable: true,
      width: 80,
    },
    {
      field: 'case_rounding',
      headerName: 'Case Rounding',
      resizeable: true,
      width: 80
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => <div style={{
        color: '#868686',
        display: 'flex',
        columnGap: '5px',
        cursor: 'pointer'
      }}>
        <DeleteOutline onClick={(e) => {
          setCurrentProductId(params.row.id);
          e.stopPropagation();
          setDeletePopupOpen(true);
        }} />
      </div>
    },
  ];

  return <>
    <DeleteConfirmation
      title={`Delete finished good`}
      content={`Are you sure you want to delete this finished good?`}
      onClose={() => {
        setDeletePopupOpen(false);
      }}
      onDelete={onDeleteConfirmed}
      open={deletePopupOpen} />
    <DataGrid
      compact={true}
      rows={finishedGoodHook?.finishedGoods ?? []}
      columns={columns}
      onRowClick={handleEvent}
      density="compact"
      hideFooter
      autoHeight={true}
      headerHeight={90}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1,
        }
      }}
    />
  </>
}

export default FinishedGoods;
