import React, { useState } from "react"
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import TextField from '@mui/material/TextField';

const FeedbackModal = ({ actionHandler }) => {
    const [modalOpen, showModal] = useState(true);
    const [stage, setStage] = useState("Stage1")

    const doSubmit = () => {
        setStage("Stage3")
    };

    const reasonStyle = {
        borderRadius: "27px",
        border: "1px solid #009785",
        "&:hover": {
            backgroundColor: "#009785",
            color: "white"
        }
    }

    return (
        <Modal open={modalOpen} backdrop={"static"} keyboard={true}
               className="modal-dialog-centered">
            <div className="text-center">
                {
                    stage === "Stage1" && <div>
                        <div style={{ color: "#3C3C3C", fontWeight: "bolder", fontSize: "24px" }}>Sorry you didn't like this booster</div>
                        <div className="text-muted">What was the reason you didn’t like it?</div>
                        <div style={{ marginTop: "15px" }}>
                            {/*<ToggleButton outline className="mr-2 mb-2 reason-button" style={reasonStyle}>knew this already</ToggleButton>*/}
                            {/*<ToggleButton outline className="mr-2 mb-2 reason-button" style={reasonStyle}>can't act on this</ToggleButton>*/}
                            {/*<ToggleButton outline className="mr-2 mb-2 reason-button" style={reasonStyle}>this is incorrect</ToggleButton>*/}
                            {/*<ToggleButton outline className="mr-2 mb-2 reason-button" style={reasonStyle}>too confusing</ToggleButton>*/}
                            <Button className="mr-2 mb-2 " style={{ borderRadius: "50%", backgroundColor: "inherit", border: "1px solid #009785", color: "#6c757d", padding: "6px 14px" }} onClick={() => setStage("Stage2")}>+</Button>
                        </div>
                    </div>
                }
                {
                    stage === "Stage2" && <div>
                        <div style={{ color: "#3C3C3C", fontWeight: "bolder", fontSize: "24px" }}>What feedback do you have?</div>
                        <div style={{ marginTop: "15px" }}>
                            <TextField type="textarea" name="text" id="exampleText" placeholder="You can be as honest as you want to here ..." rows="5" style={{ resize: "none" }} />
                        </div>
                    </div>
                }
                {
                    stage === "Stage3" && <div>
                        <div style={{ color: "#3C3C3C", fontWeight: "bolder", fontSize: "24px" }}>Thanks for the feedback.</div>
                        <div className="text-muted" style={{paddingBottom: "30px"}}>We are always trying to be better and this really helps</div>
                    </div>
                }
            </div>
            <div style={{ border: "none", justifyContent: `${stage === "Stage2" ? "flex-end" : "center"}`, paddingTop: "0px", paddingBottom: "20px" }}>
                {stage !== "Stage3" &&
                <Button className="theme-button" onClick={doSubmit}>SUBMIT</Button>
                }
            </div>
        </Modal>
    )
}

export default FeedbackModal
