import { sum, maxDiff } from "./arrays";

const valuesWereEquals = (values, sum, fixed) => {
  // the max difference between all element in array
  let maxChildDifference = +maxDiff(values).toFixed(fixed);

  // If so, so childs were not equals in the past
  // cause 0 < remainder < 1
  if (Math.abs(maxChildDifference) > 1) {
    return false;
  }

  let valuesSum = +sum.toFixed(fixed);
  // The equal values the childs were supposed to be
  let equallyDivide = +(valuesSum / values.length).toFixed(fixed);

  // the reminder should be
  let remainder = +(valuesSum - equallyDivide * values.length).toFixed(fixed);

  // if the should-be remainder is equals to the current remainder
  // (the max difference in the array)
  return Math.abs(remainder) === Math.abs(maxChildDifference);
};

// an usage exmaple:
// ----------------
// amount: 30
// values: [5, 10]
// fixed: 0
// output:
// [10, 20]
export const splitProportionally = (amount, values, fixed = 1) => {
  if (amount === 0) return values.map((_) => 0);

  let splittedValues = [];
  let sumValues = sum(values);
  const targetAmount = sumValues + amount;

  // Check if values should split equaly
  const wereEquals = valuesWereEquals(values, sumValues, fixed);

  // In case we split the amount between zeros
  if (values.every((value) => value === 0) || wereEquals) {
    const equalPart = +(targetAmount / values.length).toFixed(fixed);
    splittedValues = values.map((_) => equalPart);
  } else {
    splittedValues = values.map((value) => {
      const relativePart = value / sumValues;
      return +(value + relativePart * amount).toFixed(fixed);
    });
  }

  const splittedValuesSum = sum(splittedValues);
  // remainder = lefties = totalAmount - sumOfEqualySplitting
  const remainder = +(targetAmount - splittedValuesSum).toFixed(fixed);

  if (Math.abs(remainder) !== 0) {
    // values' indexes which will get value != 0
    const indexesInDividing = splittedValues.reduce((indexes, curr, index) => {
      if (curr !== 0) {
        return [...indexes, index];
      }
      return indexes;
    }, []);

    const randomIndexToAddRemainder = Math.floor(
      Math.random() * indexesInDividing.length
    );

    splittedValues[indexesInDividing[randomIndexToAddRemainder]] += remainder;
  }

  // Get only the delta for each value
  splittedValues = splittedValues.map(
    (value, index) => +(value - +values[index].toFixed(1)).toFixed(1)
  );

  return splittedValues;
};

export const valuesPowers = (values) => {
  const sumValues = values.reduce((sum, curr) => sum + curr, 0);
  return values.map((value) => value / sumValues);
};
