import { makeStyles } from "@mui/styles";
import { commonStyles} from '../reorder-pro/style';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    paddingBottom: "2vh",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "end",
    padding: "0px 20px 30px 20px",
  },
  divider: {
    width: "calc(100% - 40px)",
    height: "2px",
    background: "#bcbcbc",
    margin: "7px 0px",
  },
  backButton: {
    position: "absolute",
    bottom: "10vh",
    left: "30vw",
  },
  ...commonStyles,
}));
