import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	layout: {
		display: "flex",
		height: "100vh",
	},
	content: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
	}
}));
