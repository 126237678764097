import axios from 'axios';
import React, { useEffect, useState } from 'react';

// mui
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// styles
import "./topmovers.css";

// store
import { useRecoilValue } from 'recoil';
import { ACCESS_TOKEN_KEY, currentOrgId } from '../../../../store';

// components
import PageTitle from '../../../../components/PageTitle';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: 'white',
        border: '2px solid #000',
        overflowY: 'scroll',
        maxHeight: '700px',
        minWidth: '350px',
    },
}));

const TopMoversReport = () => {
    // state
    const orgID = useRecoilValue(currentOrgId);
    const [, setBrands] = useState([]);
    const [, setVendors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [, setWindowHeight] = useState('560px');
    const [, setDoneLoadingData] = useState(false);
    const [, setTopMoversOriginalData] = useState([]);
    const [topMoversData, setTopMoversData] = useState([]);

    // load data
    const loadTopMovers = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/v1/api/org/reports/top-movers?org_id=${orgID}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                },
            });

            let brandArr = [];
            let vendorArr = [];

            res.data.forEach(data => {
                if (brandArr.indexOf(data.Brand) === -1) {
                    brandArr.push(data.Brand)
                }

                if (vendorArr.indexOf(data.Vendor) === -1) {
                    vendorArr.push(data.Vendor)
                }
            })

            setBrands(brandArr)
            setVendors(vendorArr)
            setDoneLoadingData(true)
            const stringToNumConverter = res.data.map(item => {
                item.GM_dlrs = parseInt(item.GM_dlrs)
                item.Cost = parseFloat(item.Cost)
                item.Price = parseFloat(item.Price)
                return item;
            });
            setTopMoversOriginalData(stringToNumConverter)
            return setTopMoversData(stringToNumConverter)
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!orgID) return;
        if (window.innerHeight > 750) {
            setWindowHeight('620px')
        }
        loadTopMovers().then();
    }, [orgID]);

    window.eventBus.dispatch("sidebarCollapse", false)

    const d = {
        columns: [
            { field: 'Vendor', headerName: 'Vendor', flex: 0.8, },
            { field: 'Brand', headerName: 'Brand', flex: 1, },
            { field: 'Product Name', headerName: 'Product', flex: 1, },
            { field: 'Sales', headerName: 'Unit Sales', width: 85, type: 'number' },
            {
                field: 'On Hand',
                headerName: 'On Hand',
                width: 85,
                type: 'number'
            },
            {
                field: 'Price',
                headerName: 'Price',
                width: 85,
                type: 'number',
                renderCell: params => {
                    return `$${params.value}`;
                }
            },
            {
                field: 'Cost',
                headerName: 'Cost',
                width: 80,
                type: 'number',
                renderCell: params => {
                    return `$${params.value}`;
                }
            },
            {
                field: 'GM_dlrs',
                headerName: 'GM $',
                width: 80,
                type: 'number',
                renderCell: params => {
                    return `$${params.value}`;
                }
            },
            {
                field: 'GM_pct',
                headerName: 'GM %',
                width: 80,
                type: 'number',
                renderCell: params => {
                    return `${(params.value * 100).toFixed(0)}%`;
                }
            },
        ],
        rows: topMoversData.map((di, i) => ({
            ...di,
            id: i
        }))
    }

    return (
        <Box className="top-movers-wrap" sx={{ padding: '25px', display: 'flex', flexDirection: 'column', rowGap: '25px' }}>
            <PageTitle>
                Top Movers Report
            </PageTitle>
            <div style={{ height: 700, width: '100%' }}>
                <DataGrid
                  loading={loading}
                    density="compact"
                    {...d}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                />
            </div>
        </Box>);
};

export default TopMoversReport
