// libs
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from "prop-types";

// services
import OrganisationService from "../../../../service/organisation-service";

// components
import PageTitle from '../../../../components/PageTitle';

// styles
import "./reorders.css";
import { useStyles } from "./styles";

import { Container, Wrap } from "./styles";
import { useRecoilValue } from "recoil";
import { addCommas, formatDollar, toPercent } from "../../../../utils/textFormatter";

// mui
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import Tooltip from '@mui/material/Tooltip';
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import FormControlLabel from "@mui/material/FormControlLabel";

// store
import { useDispatch } from '../../../../context/store';
import { currentOrgId, locations as locationsState } from '../../../../store';
import {
  ALL_LOCATIONS_OPTION
} from '../../reorder-pro/stepper/steps/category-targets/utils/constants';
import {
  ALL_BRANDS_OPTION,
  ALL_VENDORS_OPTION
} from '../../reorder-pro/stepper/steps/item-planner';
import { uniqBy } from 'lodash';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import DialogContent from '@mui/material/DialogContent';
import { sortAlphabetically } from '../../../../utils/arrays';
import BootstrapInput from '../../../../components/BootstrapInput';
import CustomSelectComponent from '../../../../components/CustomSelect';
import ProductTypeSelector from '../../../../components/ProductTypeSelecter/ProductTypeSelector';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ReOrdersReport = (props) => {
  // states
  const [checkedOnHandVendor, setCheckedOnHandVendor] = useState(false);
  const [showRecommended, setShowRecommended] = useState(false);
  const [vendorCombinedvalues, setVendorCombinedValues] = useState([]);
  const [brandCombinedvalues, setBrandCombinedValues] = useState([]);
  const [reOrderData, setReOrderData] = useState([]);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterModel, setFilterModel] = useState();
  const orgId = useRecoilValue(currentOrgId);

  const [categories, setCategories] = useState([]);
  const [showCategoriesDialog, setShowCategoriesDialog] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(ALL_VENDORS_OPTION);

  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(ALL_BRANDS_OPTION);

  const locations = useRecoilValue(locationsState)
  const [selectedLocationId, setSelectedLocationId] = useState(
    ALL_LOCATIONS_OPTION.id
  );

  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSeason, setSelectedSeason] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedSalesType, setSelectedSalesType] = useState('');
  const [selectedReplenishment, setSelectedReplenishment] = useState('');

  const classes = useStyles();

  // sorting
  const [sortModelVendor, setSortModelVendor] = React.useState([
    {
      field: 'Estimated Cost',
      sort: 'desc',
    },
  ]);

  const [sortModelBrand, setSortModelBrand] = React.useState([
    {
      field: 'Estimated Cost',
      sort: 'desc',
    },
  ]);

  const dispatcher = useDispatch();
  const layoutDispatcher = (obj) => dispatcher({ target: 'layout', ...obj });

  const sortIt = (v1, v2) => {
    return v1 - v2;
  };

  // handlers
  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  // data fetching
  const fetchReorders = async () => {
    try {
      setLoading(true);
      const { data } = await OrganisationService.reports.reorder(
        orgId
      );

      let brandArr = [];
      let vendorArr = [];
      let productTypeArr = [];
      let productTypeArrObj = {};
      let productSubtypeArr = [];
      let productSubtypeArrObj = [];
      let vendorList = [];
      let brandList = [];

      data.forEach((data) => {
        if (vendorArr.indexOf(data["Vendor Name"]) === -1) {
          vendorArr.push(data["Vendor Name"]);
          vendorList.push({
            vendorId: parseInt(data.vendor_id, 10),
            "Vendor Name": data["Vendor Name"],
            "Raw Reco": data["Raw Reco"],
            "Estimated Cost": data["Estimated Cost"],
          });
        } else if (checkedOnHandVendor && Number(data.on_hand) === 0) {
          return;
        } else {
          vendorList.map((o, i) => {
            if (o["Vendor Name"] === data["Vendor Name"]) {
              vendorList[i] = {
                vendorId: parseInt(data.vendor_id, 10),
                "Vendor Name": data["Vendor Name"],
                "Raw Reco": Number(o["Raw Reco"]) + Number(data["Raw Reco"]),
                "Estimated Cost":
                  Number(o["Estimated Cost"]) + Number(data["Estimated Cost"]),
              };
            }
          });
        }

        if (brandArr.indexOf(data["Brand Name"]) === -1) {
          brandArr.push(data["Brand Name"]);
          brandList.push({
            brandId: parseInt(data.brand_id, 10),
            "Brand Name": data["Brand Name"],
            "Raw Reco": data["Raw Reco"],
            "Estimated Cost": data["Estimated Cost"],
          });
        } else {
          brandList.map((o, i) => {
            if (o["Brand Name"] === data["Brand Name"]) {
              brandList[i] = {
                brandId: parseInt(data.brand_id, 10),
                "Brand Name": data["Brand Name"],
                "Raw Reco": Number(o["Raw Reco"]) + Number(data["Raw Reco"]),
                "Estimated Cost":
                  Number(o["Estimated Cost"]) + Number(data["Estimated Cost"]),
              };
            }
          });
        }


        let productTypeName = data.H2;
        if (productTypeArr.indexOf(data.H2) === -1) {
          productTypeArr.push(data.H2);
          productTypeArrObj[productTypeName] = productTypeName;
        }

        let productySubtypeName = data.H3;
        if (productSubtypeArr.indexOf(data.H3) === -1) {
          productSubtypeArr.push(data.H3);
          productSubtypeArrObj[productySubtypeName] = productySubtypeName;
        }
      });

      setVendorCombinedValues(vendorList);
      setBrandCombinedValues(brandList);
      return setReOrderData(data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    } finally {
      setLoading(false);
    }
  };

  // effects
  useEffect(() => {
    fetchReorders()
      .then();
    layoutDispatcher({
      type: 'closeDrawer'
    })
  }, [orgId]);

  const fetchCategories = async () => {
    const productsTypes = await OrganisationService.forecast(
      orgId
    )
      .productTypes();
    const newCategories = productsTypes?.data?.hierarchy?.children ?? [];
    const sortedCategories = sortAlphabetically(newCategories, 'name');
    setCategories(sortedCategories);
  };

  const fetchVendors = async () => {
    const {
      data: { dist },
    } = await OrganisationService.vendor.list(orgId, 10000, 1);
    const vendors = uniqBy(dist, "distributor_name");
    const sortedVendors = sortAlphabetically(vendors, 'distributor_name');
    setVendors(sortedVendors);
  };

  const fetchBrands = async () => {
    const { data: brands } = await OrganisationService.brands.list(orgId);
    const sortedBrands = sortAlphabetically(brands, 'name');
    setBrands(sortedBrands);
  };

  useEffect(() => {
    try {
        fetchCategories().then();
        fetchVendors().then();
        fetchBrands().then();
    } catch (e) {
      console.error(e);
    }
  }, []);

  // by vendor
  const byVendorData = {
    columns: [
      {
        field: "Vendor Name",
        headerName: "Vendor",
        flex: 1,
      },
      {
        field: "Raw Reco",
        headerName: "Raw Reco",
        sortComparator: sortIt,
        flex: 1,
        renderCell: (params) => {
          return `${addCommas(params.value)}`;
        },
      },
      {
        field: "Estimated Cost",
        headerName: "Estimated Cost",
        sortComparator: sortIt,
        flex: 1,
        renderCell: (params) => {
          return `$${addCommas(params.value)}`;
        },
      },
    ],
    rows: vendorCombinedvalues?.map((d, i) => ({
      ...d,
      id: i,
    })),
  };


  const ByVendorTable = () => {
    return (
      <TabPanel value={tab} index={0}>
        <Box
          sx={{
            height: 700,
            width: "100%",
            marginTop: "10px",
          }}
        >
          <DataGrid
            onSelectionModelChange={(ids) => {
              const id = ids?.[0];
              if (!id) return;
              const row = byVendorData.rows.filter((r) => r.id === id);
              if (row?.[0]) {
                setFilterModel({
                  items: [
                    {
                      columnField: "Vendor Name",
                      operatorValue: "contains",
                      value: row[0]?.["Vendor Name"],
                    },
                  ],
                });
                setTab(2);
              }
            }}
            density="compact"
            {...byVendorData}
            sortModel={sortModelVendor}
            onSortModelChange={(model) => setSortModelVendor(model)}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </Box>
      </TabPanel>
    );
  };

  const byBrandData = {
    columns: [
      {
        field: "Brand Name",
        headerName: "Brand Name",
        flex: 1
      },
      {
        field: "Raw Reco",
        headerName: "Raw Reco",
        flex: 1,
        sortComparator: sortIt,
        renderCell: (params) => {
          return `${addCommas(params.value)}`;
        },
      },
      {
        field: "Estimated Cost",
        headerName: "Estimated Cost",
        flex: 1,
        sortComparator: sortIt,
        renderCell: (params) => {
          return `$${addCommas(params.value)}`;
        },
      },
    ],
    rows: brandCombinedvalues?.map((d, i) => ({
      ...d,
      id: i,
    })),
  };

  const ByBrandTable = () => {
    return (
      <TabPanel value={tab} index={1}>
        <Box
          sx={{
            height: 700,
            width: "100%",
            marginTop: "25px",
          }}
        >
          <DataGrid
            onSelectionModelChange={(ids) => {
              const id = ids?.[0];
              if (!id) return;
              const row = byBrandData.rows.filter((r) => r.id === id);
              if (row?.[0]) {
                setFilterModel({
                  items: [
                    {
                      columnField: "Brand Name",
                      operatorValue: "contains",
                      value: row[0]?.["Brand Name"],
                    },
                  ],
                });
                setTab(2);
              }
            }}
            density="compact"
            {...byBrandData}
            sortModel={sortModelBrand}
            onSortModelChange={(model) => setSortModelBrand(model)}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </Box>
      </TabPanel>
    );
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const locationsOptions = useMemo(() => {
    return locations
      ?.filter(l => l.key !== -1)
      ?.map((location, _) => ({
      key: location.id,
      value: location.location_name,
    }))
  }, [locations]);

  const colorOptions = useMemo(() => {
    const colors = new Set(reOrderData.map(item => item.Color)
      .filter(color => color != null)
      .sort((a, b) => a.localeCompare(b)));

    return [{
      key: '',
      value: 'All Colors'
    }].concat(Array.from(colors)
      .map(color => ({
        key: color,
        value: color
      })))
  }, [reOrderData]);

  const seasonOptions = useMemo(() => {
    const seasons = new Set(reOrderData
      .map(item => item.Season)
      .filter(season => season != null)
      .sort((a, b) => a.localeCompare(b)));

    return [{
      key: '',
      value: 'All Seasons'
    }].concat(Array.from(seasons).map(season => ({
      key: season,
      value: season
    })));
  }, [reOrderData]);

  const teamOptions = useMemo(() => {
    const teams = new Set(reOrderData
      .map(item => item.Team)
      .filter(team => team != null)
      .sort((a, b) => a.localeCompare(b)));

    return [{
      key: '',
      value: 'All Teams'
    }].concat(Array.from(teams).map(team => ({
      key: team,
      value: team
    })));
  }, [reOrderData]);

  const salesTypesOptions = useMemo(() => {
    const salesTypes = new Set(reOrderData
      .map(item => item['Sales Type'])
      .filter(salesType => salesType != null)
      .sort((a, b) => a.localeCompare(b)));

    return [{
      key: '',
      value: 'All Sales Types'
    }].concat(Array.from(salesTypes).map(salesType => ({
      key: salesType,
      value: salesType
    })));
  }, [reOrderData]);

  const replenishmentOptions = useMemo(() => {
    const replenishes = new Set(reOrderData
      .map(item => item.Replenishment)
      .filter(replenishment => replenishment != null)
      .sort((a, b) => a.localeCompare(b)));

    return [{
      key: '',
      value: 'All Replenishments'
    }].concat(Array.from(replenishes).map(replenish => ({
      key: replenish,
      value: replenish
    })));
  });

  const colorFieldExists = useMemo(() => reOrderData.length > 0 && "Color" in reOrderData[0], [reOrderData]);
  const seasonFieldExists = useMemo(() => reOrderData.length > 0 && "Season" in reOrderData[0], [reOrderData]);
  const teamFieldExists = useMemo(() => reOrderData.length > 0 && "Team" in reOrderData[0], [reOrderData]);
  const salesTypeFieldExists = useMemo(() => reOrderData.length > 0 && "Sales Type" in reOrderData[0], [reOrderData]);
  const replenishmentFieldExists = useMemo(() => reOrderData.length > 0 && "Replenishment" in reOrderData[0], [reOrderData]);

  const ByProductTable = () => {
    const columns = useMemo(() => {
      return [
        {
          field: "Item ID",
          headerName: "Item ID",
          hide: true,
        },
        {
          field: "Vendor ID",
          headerName: "Vendor ID",
          hide: true,
        },
        {
          field: "Category ID",
          headerName: "Category ID",
          hide: true,
        },
        {
          field: "Sub Category ID",
          headerName: "Sub Category ID",
          hide: true,
        },
        {
          field: "Product Type ID",
          headerName: "Product Type ID",
          hide: true,
        },
        {
          field: "Category",
          headerName: "Category",
        },
        {
          field: "Sub Category",
          headerName: "Sub Category",
        },
        {
          field: "Product Type",
          headerName: "Product Type",
          hide: true,
        },
        {
          field: "Location ID",
          headerName: "Location ID",
          hide: true,
        },
        {
          field: "Location Name",
          headerName: "Location Name",
        },
        {
          field: "Brand ID",
          headerName: "Brand ID",
          hide: true,
        },
        {
          field: "Brand Name",
          headerName: "Brand Name",
        },
        {
          field: "Vendor Name",
          headerName: "Vendor",
          width: 150,
        },
        {
          field: "Product Name",
          headerName: "Product",
          renderCell: (params) => (
            <Tooltip title={params.value} placement="top" enterDelay={500}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {params.value}
              </div>
            </Tooltip>
          ),
          width: 250,
        },

        ...(colorFieldExists ? [{
          field: "Color",
          headerName: "Color",
          width: 150,
        }] : []),

        ...(replenishmentFieldExists ? [{
          field: "Replenishment",
          headerName: "Replenishment",
          width: 150,
        }] : []),

        ...(salesTypeFieldExists ? [{
          field: "Sales Type",
          headerName: "Sales Type",
          width: 150,
        }] : []),

        ...(seasonFieldExists ? [{
          field: "Season",
          headerName: "Season",
          width: 150,
        }] : []),

        ...(teamFieldExists ? [{
          field: "Team",
          headerName: "Team",
          width: 150,
        }] : []),

        {
          field: "New Out",
          headerName: "New Out",
          width: 250,
          hide: true,
        },
        {
          field: "Category",
          headerName: "Product type",
        },
        {
          field: "Sub Category",
          headerName: "Product subtype",
        },
        {
          field: "Avg Weekly Sales",
          headerName: "Avg. weekly unit sales",
          minWidth: 150,
          sortComparator: sortIt,
          renderCell: (params) => {
            return Number(params.value)
              .toFixed(0);
          },
        },
        {
          field: "Current On Hand",
          headerName: "Current on hand",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "Future Target On Hand",
          headerName: "Future target on hand",
          minWidth: 150,
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "Lead Time Sales",
          headerName: "Lead Time Sales",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "Lead Time Demand",
          headerName: "Lead Time Demand",
          hide: true,
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "On Order",
          headerName: "On Order",
          sortComparator: sortIt,
          hide: false,
          renderCell: (params) => {
            if (!params.value || Number.isNaN(params.value)) return '';
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "Raw Reco",
          headerName: "Raw Reco",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "Case Rounded Reco",
          headerName: "Case Rounded Reco",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "Final Reco",
          headerName: "Final Reco",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "WOS",
          headerName: "W.O.S",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value), 1)}`;
          },
        },
        {
          field: "Estimated Cost",
          headerName: "Estimated Cost",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `$${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "AUC",
          headerName: "AUC",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `$${addCommas(params.value, 2)}`;
          },
        },
        {
          field: "Gross Margin $",
          headerName: "Gross Margin $",
          sortComparator: sortIt,
          hide: true,
          renderCell: (params) => {
            return `$${addCommas(params.value, 2)}`;
          },
        },
        {
          field: "Gross Margin %",
          headerName: "Gross Margin %",
          sortComparator: sortIt,
          hide: false,
          renderCell: (params) => {
            return `${toPercent(params.value, 1, 100)}`;
          },
        },
        {
          field: "Default Cost",
          headerName: "Default Cost",
          sortComparator: sortIt,
          hide: true,
          renderCell: (params) => {
            return `$${addCommas(params.value, 2)}`;
          },
        },
        {
          field: "Default Price",
          headerName: "Default Price",
          sortComparator: sortIt,
          hide: true,
          renderCell: (params) => {
            return `$${addCommas(params.value, 2)}`;
          },
        },
        {
          field: "Case Pack Size",
          headerName: "Case Pack Size",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "Reco Case Count",
          headerName: "Reco Case Count",
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${addCommas(Number(params.value)
              .toFixed(0))}`;
          },
        },
        {
          field: "Future Target On Hand",
          headerName: "Future Target On Hand",
          sortComparator: sortIt,
        },
        {
          field: "Tax Rate",
          headerName: "Tax Rate",
          hide: true,
          sortComparator: sortIt,
          renderCell: (params) => {
            return `${params.value * 100}%`;
          },
        },
        {
          field: "Expected Taxes",
          headerName: "Expected Taxes",
          hide: true,
          sortComparator: sortIt,
          renderCell: (params) => {
            return formatDollar(params.value, 0);
          },
        },
        {
          field: "Inventory Speed",
          headerName: "Inventory Speed",
        },
        {
          field: "Reorder Notes",
          headerName: "Reorder Notes",
          width: 250,
        },
        {
          field: "AUV",
          headerName: "AUV",
          hide: true,
          sortComparator: sortIt,
          renderCell: (params) => {
            return `$${addCommas(params.value, 2)}`;
          },
        },
      ]
    }, [seasonFieldExists]);

    const rows = useMemo(() => reOrderData
      ?.filter(
        (d) =>
          !checkedOnHandVendor ||
          (checkedOnHandVendor && Number(d["Current On Hand"]) > 0)
      )
      ?.filter(
        (d) =>
          !showRecommended || (showRecommended && Number(d["Raw Reco"] > 0))
      )
      ?.filter((d) => selectedBrand.value === -1 || selectedBrand.value?.toString() === d["Brand ID"])
      ?.filter((d) => selectedVendor.value === -1 || selectedVendor.value?.toString() === d["Vendor ID"])
      ?.filter((d) => selectedLocationId === -1 || selectedLocationId?.toString() === d["Location ID"])
      ?.filter((d) => {
        return selectedCategories.length === 0 ||
          selectedCategories.indexOf(+d["Sub Category ID"] ?? '') > -1;
      })
      ?.filter((d) => !colorFieldExists || (selectedColor === '' || selectedColor === d["Color"]))
      ?.filter((d) => !seasonFieldExists || (selectedSeason === '' || selectedSeason === d["Season"]))
      ?.filter((d) => !teamFieldExists || (selectedTeam === '' || selectedTeam === d["Team"]))
      ?.filter((d) => !salesTypeFieldExists || (selectedSalesType === '' || selectedSalesType === d["Sales Type"]))
      ?.filter((d) => !replenishmentFieldExists || (selectedReplenishment === '' || selectedReplenishment === d["Replenishment"]))
      ?.map((r, i) => ({
        ...r,
        id: i,
      })), [reOrderData]);

    return (
      <TabPanel value={tab} index={2}>
        <Box className={classes.filters}>
          <div className={classes.checkboxes}>
            <FormControlLabel
              control={
                <Checkbox
                  id='on hand'
                  checked={checkedOnHandVendor}
                  color='primary'
                  onChange={(e) => {
                    setCheckedOnHandVendor(e.target.checked);
                  }}
                />
              }
              label='Show in-stock only'
              labelPlacement='end'
            />
            <Box margin='0 10px' />
            <FormControlLabel
              control={
                <Checkbox
                  id='recommended'
                  checked={showRecommended}
                  color='primary'
                  onChange={(e) => {
                    setShowRecommended(e.target.checked);
                  }}
                />
              }
              label='Recommended'
              labelPlacement='end'
            />
          </div>
        </Box>
        <Box className={classes.grid}>
          <div className={classes.formControl} style={{
            gridRow: 1,
            gridColumn: 1
          }}>
            <span>Brand</span>
            <Autocomplete
              value={selectedBrand}
              autoComplete
              disablePortal
              blurOnSelect
              isOptionEqualToValue={(option, value) => {
                if (option.value === ALL_BRANDS_OPTION.value) return true;
                return option.value === value.value;
              }}
              onChange={(_, newValue) => {
                // In case user clears autocomplete or delete all content
                if (newValue) {
                  setSelectedBrand(newValue);
                } else {
                  setSelectedBrand(ALL_BRANDS_OPTION);
                }
              }}
              options={[
                ALL_BRANDS_OPTION,
                ...brands.map((b) => ({
                  value: b.id,
                  label: b.name
                }))
              ]}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <BootstrapInput
                    inputProps={{
                      ...params
                    }}
                    {...params}
                    style={{
                      width: '200px',
                      padding: 0
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className={classes.formControl} style={{
            gridRow: 2,
            gridColumn: 1
          }}>
            <span>Location</span>
            <CustomSelectComponent
              options={locationsOptions}
              style={{
                width: '200px',
                zIndex: 21
              }}
              value={selectedLocationId}
              onChange={(newLocation) => {
                setSelectedLocationId(newLocation);
              }}
            />
          </div>
          <div className={classes.formControl} style={{
            gridRow: 1,
            gridColumn: 2
          }}>
            <span>Category</span>
            <BootstrapInput
              value={selectedCategories.length > 0 ? 'Filtered Categories' : 'All Categories'}
              readOnly
              style={{
                width: '200px',
                display: 'flex',
                alignItems: 'start'
              }}
              onClick={() => setShowCategoriesDialog(true)}
            />
          </div>
          <div className={classes.formControl} style={{
            gridRow: 2,
            gridColumn: 2
          }}>
            <span>Vendor</span>
            <Autocomplete
              value={selectedVendor}
              autoComplete
              disablePortal
              blurOnSelect
              isOptionEqualToValue={(option, value) => {
                if (option.value === ALL_VENDORS_OPTION.value) return true;
                return option.value === value.value;
              }}
              onChange={(_, newValue) => {
                // In case user clears autocomplete or delete all content
                if (newValue) {
                  setSelectedVendor(newValue);
                } else {
                  setSelectedVendor(ALL_VENDORS_OPTION);
                }
              }}
              options={[
                ALL_VENDORS_OPTION,
                ...vendors.map((v) => ({
                  value: v.id,
                  label: v.distributor_name
                }))
              ]}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <BootstrapInput
                    inputProps={{ ...params }}
                    style={{
                      width: '200px',
                      padding: 0
                    }}
                    {...params}
                  />
                </div>
              )}
            />
          </div>
          {colorFieldExists && <div className={classes.formControl} style={{
            gridRow: 1,
            gridColumn: 3
          }}>
            <span>Color</span>
            <CustomSelectComponent
              options={colorOptions}
              style={{
                width: '200px',
                zIndex: 20
              }}
              value={selectedColor}
              onChange={(newColor) => {
                setSelectedColor(newColor);
              }}
            />
          </div>}
          {seasonFieldExists && <div className={classes.formControl} style={{
            gridRow: 2,
            gridColumn: 3
          }}>
            <span>Season</span>
            <CustomSelectComponent
              options={seasonOptions}
              style={{
                width: '200px',
                zIndex: 19
              }}
              value={selectedSeason}
              onChange={(newSeason) => {
                setSelectedSeason(newSeason);
              }}
            />
          </div>}
          {teamFieldExists && <div className={classes.formControl} style={{
            gridRow: 1,
            gridColumn: 4
          }}>
            <span>Team</span>
            <CustomSelectComponent
              options={teamOptions}
              style={{
                width: '200px',
                zIndex: 18
              }}
              value={selectedTeam}
              onChange={(newSeason) => {
                setSelectedTeam(newSeason);
              }}
            />
          </div>}
          {salesTypeFieldExists && <div className={classes.formControl} style={{
            gridRow: 2,
            gridColumn: 4
          }}>
            <span>Sales Type</span>
            <CustomSelectComponent
              options={salesTypesOptions}
              style={{
                width: '200px',
                zIndex: 17
              }}
              value={selectedSalesType}
              onChange={(newSalesType) => {
                setSelectedSalesType(newSalesType);
              }}
            />
          </div>}
          {replenishmentFieldExists && <div className={classes.formControl} style={{
            gridRow: 1,
            gridColumn: 5
          }}>
            <span>Replenishment</span>
            <CustomSelectComponent
              options={replenishmentOptions}
              style={{
                width: '200px',
                zIndex: 16
              }}
              value={selectedReplenishment}
              onChange={(newReplenishment) => {
                setSelectedReplenishment(newReplenishment);
              }}
            />
          </div>}
        </Box>
        <Box className={classes.main}>
          <Dialog
            open={showCategoriesDialog}
            TransitionComponent={Transition}
            onClose={() => setShowCategoriesDialog(false)}
            PaperProps={{
              sx: {
                width: '480px'
              }
            }}
          >
            <DialogContent sx={{ padding: 0 }}>
              <ProductTypeSelector
                defaultSelected={selectedCategories}
                categories={categories}
                onClose={() => setShowCategoriesDialog(false)}
                onApply={(ids) => {
                  setSelectedCategories(ids);
                }}
              />
            </DialogContent>
          </Dialog>
          <br />
          <DataGridPro filterModel={filterModel}
                       sx={{
                         '& .MuiDataGrid-columnHeaderTitle': {
                           whiteSpace: 'normal',
                           lineHeight: 'normal'
                         },
                         '& .MuiDataGrid-columnHeader': {
                           height: 'unset !important'
                         },
                         '& .MuiDataGrid-columnHeaders': {
                           maxHeight: '168px !important',
                           backgroundColor: 'white',
                           zIndex: 2
                         }
                       }}
                       disableColumnResize={false}
                       onFilterModelChange={(newFilterModel) =>
                         setFilterModel(newFilterModel)
                       }
                       density='compact'
                       columns={columns}
                       rows={rows}
                       components={{
                         Toolbar: GridToolbar
                       }}
          />
        </Box>
      </TabPanel>
    );
  };

  return (
    <Wrap id="reorders">
      <PageTitle>
        Reorder Report
      </PageTitle>
      <Container>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="Reorders Report"
          >
            <Tab label="By Vendor" {...a11yProps(0)} />
            <Tab label="By Brand" {...a11yProps(1)} />
            <Tab label="By Product" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        {loading && <CircularProgress sx={{ marginTop: "50px" }} />}
        {!loading && (
          <>
            <ByVendorTable />
            <ByBrandTable />
            <ByProductTable />
          </>
        )}
      </Container>
    </Wrap>
  );
};

export default ReOrdersReport;
