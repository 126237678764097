// store
import { useRecoilState } from 'recoil';
import { settingsDraftState } from "@/store/admin-panel/admin-panel.store";

// styles
import { useStyles } from "./style";
import classNames from "classnames";

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// components
import Slider from '@mui/material/Slider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CustomSelectComponent from "../../../../../components/CustomSelect";
import CircularProgress from '@mui/material/CircularProgress';

const ForecastSettings = () => {
  const classes = useStyles();
  const [copySettings, setCopySettings] = useRecoilState(settingsDraftState);

  const weeks = [1, 2, 3, 4, 5, 6, 8, 10, 13, 26];
  const weeksOptions = weeks.map(weekId => ({
    key: weekId,
    value: `${weekId} weeks`
  }));

  const onFieldChange = (field, value) => {
    const newSettings = copySettings.map(setting => {
      if (setting.setting_key === field) {
        return {
          ...setting,
          setting_value: value
        }
      }
      return setting;
    });

    setCopySettings(newSettings);
  };

  if (!copySettings) {
    return (<div>
      <CircularProgress
        color="primary"
        sx={{
          width: "15px !important",
          height: "15px !important",
          marginRight: "8px",
        }}
      />
      Loading...
    </div>)
  }

  const trendHorizonWeeks = weeksOptions.find(a => a.key === +(copySettings?.find(s => s.setting_key === 'trend_horizon_weeks')?.setting_value ?? 0));
  const forecastHorizonWeeks = weeksOptions.find(a => a.key === +(copySettings?.find(s => s.setting_key === 'forecast_horizon_weeks')?.setting_value ?? 0));

  return (
    <>
      <div className={classNames(classes.input, classes.sliderInput)}>
        <span className={classes.label}>WOS Target</span>
        <div className={classes.sliderWrapper}>
          <span className={classes.sliderValue}>{copySettings?.wos_target?.setting_value}</span>
          <Slider
            aria-label="WOS Target"
            value={+copySettings?.find(s => s.setting_key === 'wos_target')?.setting_value}
            onChange={(_, newValue) => onFieldChange('wos_target', newValue)}
            step={0.25}
            min={0}
            max={25}
          />
        </div>
      </div>
      <div className={classes.input}>
        <span className={classes.label}>Trend Look Back Weeks</span>
        <CustomSelectComponent
          options={weeksOptions}
          style={{ width: "120px", margin: '5px 0px' }}
          value={trendHorizonWeeks?.key ?? 13}
          onChange={(newValue) => onFieldChange('trend_horizon_weeks', newValue)}
        />
      </div>
      <div className={classes.input}>
        <span className={classes.label}>Plan forward week</span>
        <CustomSelectComponent
          options={weeksOptions}
          style={{ width: "120px", margin: '5px 0px' }}
          value={forecastHorizonWeeks?.key ?? 13}
          onChange={(newValue) => onFieldChange('forecast_horizon_weeks', newValue)}
        />
      </div>
      <Accordion
        defaultExpanded
        className={classNames(classes.advanced, 'Mui-expanded-default')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <span className={classes.headingLabel}>Advanced Forecast Settings</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.input}>
            <span className={classes.label}>Max Growth Rate</span>
            <div className={classes.sliderWrapper}>
              <span className={classes.sliderValue}>{copySettings?.find(s => s.setting_key === 'negative_gradient_tolerance')?.setting_value}%</span>
              <Slider
                aria-label="Max Growth Rate"
                value={+(copySettings?.find(s => s.setting_key === 'negative_gradient_tolerance')?.setting_value)}
                onChange={(_, newValue) => onFieldChange('negative_gradient_tolerance', newValue)}
                min={0}
                step={1}
                max={100}
              />
            </div>
            <span className={classes.label}>Max Decrease Rate</span>
            <div className={classes.sliderWrapper}>
              <span className={classes.sliderValue}>{copySettings?.find(s => s.setting_key === 'maximum_gradient_tolerance')?.setting_value}%</span>
              <Slider
                aria-label="Max Decrease Rate"
                value={+(copySettings?.find(s => s.setting_key === 'maximum_gradient_tolerance')?.setting_value)}
                onChange={(_, newValue) => onFieldChange('maximum_gradient_tolerance', newValue)}
                min={0}
                step={1}
                max={100}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default ForecastSettings;
