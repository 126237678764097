import React, { useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { default as style } from "styled-components";

// icons
import { LockOutlined, LogoutOutlined, PersonOutline } from "@mui/icons-material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

// components
import { NavLink } from "react-router-dom";
import UserType from "../../../../utils/role-components/user-type-guard";
import { Divider, Menu, MenuItem } from "@mui/material";
import SwitchOrganizationDialog from "./switch-organization-dialog"

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const Link = style(NavLink)`
	  display: flex;
	  color: #373737;
	  flex: 1;
	;`;

const ActionsMenu = ({ menuOpen, menuAnchorEl, onLogOut, onMenuClose }) => {

	const [isSwitchOrganizationOpen, setSwitchOrganizationOpen] = useState(false);

	return (
		<StyledMenu
			id="demo-customized-menu"
			MenuListProps={{
				"aria-labelledby": "demo-customized-button",
			}}
			anchorEl={menuAnchorEl}
			open={menuOpen}
			onClose={onMenuClose}
		>
			<Link to="/account/profile">
				<MenuItem
					onClick={onMenuClose}
					disableRipple
					style={{ width: "100%" }}
				>
					<PersonOutline />
					View Profile
				</MenuItem>
			</Link>
			<Link to="/account/profile/change-password">
				<MenuItem onClick={onMenuClose} disableRipple>
					<LockOutlined />
					Change Password
				</MenuItem>
			</Link>
			<UserType type={1}>
				<MenuItem onClick={() => setSwitchOrganizationOpen(true)} disableRipple>
					<SwapHorizIcon />
					Switch Organization
				</MenuItem>
				<SwitchOrganizationDialog
					show={isSwitchOrganizationOpen}
					onCloseDialog={() => setSwitchOrganizationOpen(false)} />
			</UserType>
			<Divider sx={{ my: 0.5 }} />
			<MenuItem onClick={onLogOut} disableRipple>
				<LogoutOutlined />
				Log Out
			</MenuItem>
		</StyledMenu>
	)
}

export default ActionsMenu;
