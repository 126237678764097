import React from "react";
import { useStyles } from "./style";
import classNames from "classnames";

// components
import Avatar from "@mui/material/Avatar";

const ORG_LOGO_SRC_BASE = "https://storage.growthsayer.com/org_logos"
const DEFAULT_ORG_LOGO = 'default.png';

const OrganizationList = ({ organizations, selectedOrgPublicId, onOrgClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.organizationsList}>
      {
        organizations.length > 0 ? organizations.map(org => (
          <div
            className={classNames(classes.org,
              selectedOrgPublicId === org.public_id && "active")}
            onClick={() => onOrgClick(org)}
            key={org.id}>
            <Avatar
              className={classes.avatar}
              src={`${ORG_LOGO_SRC_BASE}/${org.logo || DEFAULT_ORG_LOGO}`}
            />
            <span >{org.name}</span>
          </div>
        ))
          : <div className={classes.noOrganizations}>No active organizations</div>
      }
    </div>)
};

export default OrganizationList;