import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	drawer: {
		position: "relative"
	},
	menuList: {
		position: "relative",
		flex: 1,
		paddingTop: "15px !important",
	},
	bottomNavList: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end'
	},
	arrowControl: {
		position: "absolute",
		cursor: "pointer",
		top: "350px",
		right: 0,
		zIndex: 99999,
		border: "none",
		background: "#f4f4f4",
	}
}));
